import { createElement as e } from "../../../../../external/preact/dist/preact.js";
import t from "../../../../../external/classnames/index.js";
import c from "./CtPLogoutLink.js";
import { CtPBrand as n } from "../CtPBrand/CtPBrand.js";
import a from "../../context/useClickToPayContext.js";
const o = ({
  children: o
}) => {
  const {
    isStandaloneComponent: s
  } = a();
  return e("div", {
    className: t("adyen-checkout-ctp__section", {
      "adyen-checkout-ctp__section--standalone": s
    })
  }, e("div", {
    className: "adyen-checkout-ctp__section-brand"
  }, e(n, null), e(c, null)), o);
};
o.Title = ({
  endAdornment: t,
  children: c
}) => e("div", {
  className: "adyen-checkout-ctp__section-header"
}, e("h1", {
  className: "adyen-checkout-ctp__section-header-title"
}, c), t && e("span", {
  className: "adyen-checkout-ctp__section-header-adornment"
}, t)), o.Text = ({
  children: t
}) => e("p", {
  className: "adyen-checkout-ctp__section-text"
}, t);
export { o as default };
