import { createElement as e } from "../../../../../external/preact/dist/preact.js";
import { useRef as r, useState as o, useCallback as s, useEffect as t } from "../../../../../external/preact/hooks/dist/hooks.js";
import { loginValidationRules as n } from "./validate.js";
import { useCoreContext as a } from "../../../../../core/Context/CoreProvider.js";
import i from "../../../../../utils/useForm/useForm.js";
import p from "../../../FormFields/Field/Field.js";
import l from "../../../FormFields/InputEmail.js";
const u = u => {
  const {
      i18n: d
    } = a(),
    {
      handleChangeFor: m,
      data: c,
      triggerValidation: g,
      valid: h,
      errors: f,
      isValid: L
    } = i({
      schema: ["shopperLogin"],
      rules: n
    }),
    F = r({
      validateInput: null
    }),
    [v, j] = o(!1),
    E = s(() => {
      j(!0), g();
    }, [g]);
  t(() => {
    c.shopperLogin && j(!0);
  }, [c.shopperLogin]), t(() => {
    F.current.validateInput = E, u.onSetInputHandlers(F.current);
  }, [E, u.onSetInputHandlers]);
  const b = s(e => {
      "Enter" === e.key && u.onPressEnter();
    }, [u.onPressEnter]),
    k = s(e => {
      "Enter" === e.key && e.preventDefault();
    }, []);
  return t(() => {
    u.onChange({
      data: c,
      valid: h,
      errors: f,
      isValid: L
    });
  }, [c, h, f]), e(p, {
    name: "shopperLogin",
    label: d.get("ctp.login.inputLabel"),
    errorMessage: v ? u.errorMessage || !!f.shopperLogin : null,
    classNameModifiers: ["shopperLogin"]
  }, e(l, {
    name: "shopperLogin",
    autocorrect: "off",
    spellcheck: !1,
    value: c.shopperLogin,
    disabled: u.disabled,
    onInput: m("shopperLogin", "input"),
    onBlur: m("shopperLogin", "blur"),
    onKeyPress: k,
    onKeyUp: b
  }));
};
export { u as default };
