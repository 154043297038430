<div *ngIf="planogram" class="print-page">
  <div class="back no-print cursor-pointer" (click)="back()">
    <div class="icon"><i class="fa-solid fa-arrow-left"></i></div>
    <div class="option-name" translate>BACK</div>
  </div>
  <div class="page {{ layout }}">
    <div class="row">
      <div class="col-5">
        <table class="table planogram-table-info">
          <tr>
            <th translate>CUSTOMER</th>
            <td>{{ planogram.customer }}</td>
          </tr>
          <tr>
            <th translate>PLANOGRAM</th>
            <td>{{ planogram.name }}</td>
          </tr>
          <tr>
            <th translate>DATE</th>
            <td>{{ planogram.period }}{{ planogram.year }}</td>
          </tr>
          <tr>
            <th translate>ITEM_NO</th>
            <td>{{ planogram.itemNo }}</td>
          </tr>
        </table>
      </div>
      <div class="col-7">
        <div class="logo-print text-center">
          <img src="./assets/planogram.png" />
        </div>
      </div>
    </div>    
    <div class="grid-wrapper">
      <div class="grid-content">
        <div class="ruler text-center">
          <div class="mb-2">{{ planogram.width }} mm</div>
          <div class="ruler-points d-flex justify-content-between">
            <div class="ruler-point-print" *ngFor="let i of [].constructor(gridWidth + 1); let index = index">
              <div class="ruler-value">{{ planogram.widthStep * index }}</div>
            </div>
          </div>
        </div>
        <div class="grid-container" #gridContainer>
          <div
            *ngFor="let item of data"
            [style.grid-column]="item.x + 1"
            [style.grid-row]="item.y + 1"
            [style.grid-column-end]="item.x + item.cols + 1"
            [style.grid-row-end]="item.y + item.rows + 1"
            class="grid-item planogram-space"
          >
            <div class="grid-item-content" [class.module-new]="item.newModule" [class.module-expired]="item.expired">
              <div class="grid-item-image">
                <img
                  *ngIf="item.cols === 1"
                  [src]="item.data?.extra?.images[0].s3Uri | gungImageUrl
                    :'size:500x900'
                  "
                />
                <img
                  *ngIf="item.cols !== 1"
                  [src]="item.data?.extra?.images[0].s3Uri | gungImageUrl"
                />
              </div>
              <div class="grid-item-details" [style.height.px]="boxHeight" [style.font-size.px]="fontSize">
                <div class="grid-item-name">
                  {{ item.data.name }}
                </div>
                <div [innerHTML]="item.data?.extra?.pim?.spaceManagementInformation"></div>
                <div *ngIf="item.data?.quantityInDisplay">{{ item.data?.quantityInDisplay }}</div>
                <div class="articles">
                  <div>ID:[{{ item.data?.artNo }}]</div>
                  <div *ngIf="item.data?.floorOne && item.data?.floorOne.length > 0">
                    Col: <span *ngFor="let floorOne of item.data?.floorOne">{{ floorOne.id }} </span>
                  </div>
                  <div *ngIf="item.data?.floorTwo && item.data?.floorTwo.length > 0">
                    Col: <span *ngFor="let floorTwo of item.data?.floorTwo">{{ floorTwo.id }} </span>
                  </div>
                  <div *ngIf="item.data?.floorThree && item.data?.floorThree.length > 0">
                    Col: <span *ngFor="let floorThree of item.data?.floorThree">{{ floorThree.id }} </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="shelf-bottom"></div>
          </div>
          <div
            *ngFor="let emptySpace of emptySpaces"
            [style.grid-column]="emptySpace.x + 1"
            [style.grid-row]="emptySpace.y + 1"
            class="empty-space planogram-space"
          >
            <div class="shelf-bottom"></div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="grids-addons" *ngIf="newModules.length > 0 || removeModules.length > 0">
      <div class="removed-modules mt-3" *ngIf="removeModules.length > 0">
        <div class="addon-title p-3" translate>REMOVED</div>
        <div class="addon-list">
          
          <div class="addon-item removed-modules-item" [style.width]="addonsWidth[item.itemId]" *ngFor="let item of removeModules">
            <div class="grid-item-content module-expired" [class.module-new]="item.newModule">
              <div class="grid-item-image">
                <img
                  *ngIf="item.cols === 1"
                  [src]="item.data?.extra.images[0].s3Uri | gungImageUrl
                    :'size:500x900'
                  "
                />
                <img
                  *ngIf="item.cols !== 1"
                  [src]="item.data?.extra.images[0].s3Uri | gungImageUrl"
                />
              </div>
              <div class="grid-item-details" [style.height.px]="boxHeight" [style.font-size.px]="fontSize">
                <div class="grid-item-name">
                  {{ item.data?.name }}
                </div>
                <div [innerHTML]="item.data?.extra?.pim?.spaceManagementInformation"></div>
                <div *ngIf="item.data?.quantityInDisplay">{{ item.data?.quantityInDisplay }}</div>
                <div class="articles">
                  <div>ID:[{{ item.data?.artNo }}]</div>
                  <div *ngIf="item.data?.floorOne && item.data?.floorOne.length > 0">
                    Col: <span *ngFor="let floorOne of item.data?.floorOne">{{ floorOne.id }} </span>
                  </div>
                  <div *ngIf="item.data?.floorTwo && item.data?.floorTwo.length > 0">
                    Col: <span *ngFor="let floorTwo of item.data?.floorTwo">{{ floorTwo.id }} </span>
                  </div>
                  <div *ngIf="item.data?.floorThree && item.data?.floorThree.length > 0">
                    Col: <span *ngFor="let floorThree of item.data?.floorThree">{{ floorThree.id }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      <div class="new-modules mt-3" *ngIf="newModules.length > 0">
        <div class="addon-title p-3" translate>NEW</div>
        <div class="addon-list">
          <div class="addon-item new-modules-item" [style.width]="addonsWidth[item.itemId]" *ngFor="let item of newModules">
            <div class="grid-item-content" [class.module-new]="item.newModule" [class.module-expired]="item.expired">
              <div class="grid-item-image">
                <img
                  *ngIf="item.cols === 1"
                  [src]="item.data?.extra.images[0].s3Uri | gungImageUrl
                    :'size:500x900'
                  "
                />
                <img
                  *ngIf="item.cols !== 1"
                  [src]="item.data?.extra.images[0].s3Uri | gungImageUrl"
                />
              </div>
              <div class="grid-item-details" [style.height.px]="boxHeight" [style.font-size.px]="fontSize">
                <div class="grid-item-name">
                  {{ item.data?.name }}
                </div>
                <div [innerHTML]="item.data?.extra?.pim?.spaceManagementInformation"></div>
                <div *ngIf="item.data?.quantityInDisplay">{{ item.data?.quantityInDisplay }}</div>
                <div class="articles">
                  <div>ID:[{{ item.data?.artNo }}]</div>
                  <div *ngIf="item.data?.floorOne && item.data?.floorOne.length > 0">
                    Col: <span *ngFor="let floorOne of item.data?.floorOne">{{ floorOne.id }} </span>
                  </div>
                  <div *ngIf="item.data?.floorTwo && item.data?.floorTwo.length > 0">
                    Col: <span *ngFor="let floorTwo of item.data?.floorTwo">{{ floorTwo.id }} </span>
                  </div>
                  <div *ngIf="item.data?.floorThree && item.data?.floorThree.length > 0">
                    Col: <span *ngFor="let floorThree of item.data?.floorThree">{{ floorThree.id }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        
      </div>
    </div>
    
    
  </div>
  <div class="menu-print no-print d-flex">
    <div class="font-setting" *ngIf="layout !== 'image'">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="font-size" class="control-label" translate>Font Size (px)</label>
            <input class="form-control" type="number" name="font-size" id="font-size" [(ngModel)]="fontSize" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="font-size" class="control-label" translate>text height (px)</label>
            <input class="form-control" type="number" name="font-size" id="font-size" [(ngModel)]="boxHeight" />
          </div>
        </div>
      </div>
      
      
    </div>
    <div class="print-btn bg-primary" (click)="print()">
      <i class="fa-solid fa-print"></i>
    </div>
  </div>
</div>
