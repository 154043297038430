import { Component as e, createElement as s } from "../../../../external/preact/dist/preact.js";
import t from "../../../../external/classnames/index.js";
import a from "../../../internal/IFrame/Iframe.js";
import r from "../../../internal/Spinner/Spinner.js";
import n from "../Form/ThreeDS2Form.js";
import o from "../../../../utils/get-process-message-handler.js";
import { encodeBase64URL as i } from "../utils.js";
import m from "../../../../utils/promiseTimeout.js";
import { THREEDS2_NUM as l, CHALLENGE_TIMEOUT as c, CHALLENGE_TIMEOUT_REJECT_OBJECT as h } from "../../constants.js";
const p = "threeDSIframe";
class d extends e {
  processMessageHandler;
  challengePromise;
  constructor(e) {
    super(e);
    const s = JSON.stringify(this.props.cReqData),
      t = i(s);
    this.state = {
      base64URLencodedData: t,
      status: "init"
    };
  }
  iframeCallback = () => {
    this.setState({
      status: "iframeLoaded"
    }), "init" === this.state.status && this.props.onActionHandled({
      componentType: "3DS2Challenge",
      actionDescription: `${l} challenge iframe loaded`
    });
  };
  get3DS2ChallengePromise() {
    return new Promise((e, s) => {
      this.processMessageHandler = o(this.props.postMessageDomain, e, s, "challengeResult"), window.addEventListener("message", this.processMessageHandler);
    });
  }
  componentDidMount() {
    this.challengePromise = m(c, this.get3DS2ChallengePromise(), h), this.challengePromise.promise.then(e => {
      window.removeEventListener("message", this.processMessageHandler), this.props.onCompleteChallenge(e);
    }).catch(e => {
      window.removeEventListener("message", this.processMessageHandler), this.props.onErrorChallenge(e);
    });
  }
  componentWillUnmount() {
    this.challengePromise && this.challengePromise.cancel(), window.removeEventListener("message", this.processMessageHandler);
  }
  render({
    acsURL: e,
    cReqData: o,
    iframeSizeArr: i,
    onFormSubmit: m
  }, {
    base64URLencodedData: l,
    status: c
  }) {
    const [h, d] = i;
    return s("div", {
      className: t(["adyen-checkout__threeds2__challenge", `adyen-checkout__threeds2__challenge--${o.challengeWindowSize}`])
    }, "iframeLoaded" !== c && s(r, null), s(a, {
      name: p,
      width: h,
      height: d,
      callback: this.iframeCallback
    }), s(n, {
      name: "cReqForm",
      action: e,
      target: p,
      inputName: "creq",
      inputValue: l,
      onFormSubmit: m
    }));
  }
}
export { d as default };
