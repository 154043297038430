import { createElement as t } from "../../../../external/preact/dist/preact.js";
import { KlarnaWidget as a } from "../KlarnaWidget/KlarnaWidget.js";
import { useState as e } from "../../../../external/preact/hooks/dist/hooks.js";
function o(o) {
  const [n, s] = e({
      sdkData: o.sdkData,
      paymentMethodType: o.paymentMethodType,
      paymentData: o.paymentData
    }),
    [p, r] = e("ready");
  return this.setAction = s, this.setStatus = r, n.sdkData ? t(a, {
    sdkData: n.sdkData,
    paymentMethodType: n.paymentMethodType,
    paymentData: n.paymentData,
    payButton: o.payButton,
    onComplete: o.onComplete,
    onError: o.onError,
    onLoaded: o.onLoaded
  }) : o.showPayButton ? o.payButton({
    ...o,
    status: p,
    disabled: "loading" === p,
    classNameModifiers: ["standalone"],
    label: `${this.props.i18n.get("continueTo")} ${o.displayName}`
  }) : null;
}
export { o as KlarnaContainer };
