import { createElement as e, Fragment as r } from "../../../../../../external/preact/dist/preact.js";
import a from "../../../../../../external/classnames/index.js";
import t from "../../../../Img/Img.js";
import { useCoreContext as s } from "../../../../../../core/Context/CoreProvider.js";
import c from "../../../../../../core/Context/useImage.js";
const o = ({
  card: o,
  errorMessage: i
}) => {
  const {
      i18n: d
    } = s(),
    n = c(),
    m = o.artUri || n()(o.scheme);
  return e(r, null, e("div", {
    className: "adyen-checkout-ctp__card-list-single-card"
  }, e(t, {
    src: m,
    height: 24,
    className: "adyen-checkout-ctp__card-image"
  }), e("span", {
    className: a({
      "adyen-checkout-ctp__card-list-single-card-expired": o.isExpired
    })
  }, o.title, " ", `•••• ${o.panLastFour}`), o.isExpired && e("span", {
    className: "adyen-checkout-ctp__expired-label"
  }, d.get("ctp.cards.expiredCard"))), i && e("div", {
    className: "adyen-checkout-contextual-text--error"
  }, i));
};
export { o as default };
