import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../models';
import { ProductService } from '../../services/products/product.service';
import { of } from 'rxjs';
import { GungFile } from '../../models/pim';
import { CloudPimPropertiesHelperService } from '../../services/cloud-pim-properties-helper/cloud-pim-properties-helper.service';
import { S3Item, S3UploadService, gungGetPropertyOfObject, gungSetPropertyOfObject } from 'gung-common';
import { PimTemplateProperties } from 'gung-list';

@Component({
  selector: 'lib-edit-product-modal',
  templateUrl: './edit-product-modal.component.html',
  styleUrl: './edit-product-modal.component.scss'
})
export class EditProductModalComponent implements OnInit {
  @Input()
  public productId: string;

  @Input()
  public product: Product;

  public loading = true;
  public comments: string;
  public productTemplateProperties: any[];

  public uploading = false;

  public getValueByPath = (path: string) => {
    const value = gungGetPropertyOfObject(path, this.product.extra);
    return value?.description || value;
  };
  public setValueByPath = (templatePath, event) => gungSetPropertyOfObject(this.product.extra, templatePath, event);

  constructor(
    public activeModal: NgbActiveModal,
    protected productService: ProductService,
    protected s3UploadService: S3UploadService
  ) { }

  ngOnInit() {
    if (this.productId) {
      this.productService.getProduct(this.productId, true).subscribe(product => {
        this.product = product;
        this.initData();
      });
    }
  }

  initData() {
    this.comments = '';
    this.loading = false;
  }

  remove(index, type: 'images' | 'documents') {
    (this.product.extra[type] as S3Item[]).splice(index, 1);
  }

  uploadFile(file: File[] /* GungFile */, type: 'images' | 'documents') {
    this.uploading = true;
    this.s3UploadService.postImage(file[0]).subscribe(data => {
      (this.product.extra[type] as S3Item[]).push(data);
      this.uploading = false;
    })

  }

  save() {
    this.activeModal.close()
  }
}
