import { createElement as e } from "../../../../external/preact/dist/preact.js";
import a from "../../../internal/Img/Img.js";
import t from "../../../../external/classnames/index.js";
const m = ["googlepay", "paywithgoogle"],
  o = ({
    src: o,
    altDescription: r,
    type: _,
    disabled: p = !1
  }) => e("span", {
    className: t("adyen-checkout__payment-method__image__wrapper", {
      "adyen-checkout__payment-method__image__wrapper--outline": !m.includes(_),
      "adyen-checkout__payment-method__image__wrapper--disabled": !!p
    })
  }, e(a, {
    className: "adyen-checkout__payment-method__image",
    src: o,
    alt: r
  }));
export { o as default };
