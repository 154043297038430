import { createElement as e, Fragment as t } from "../../../../external/preact/dist/preact.js";
import o from "../../../../utils/getProp.js";
import { OrderPaymentMethods as r } from "./OrderPaymentMethods.js";
import n from "./InstantPaymentMethods.js";
import { useCoreContext as s } from "../../../../core/Context/CoreProvider.js";
import { useBrandLogoConfiguration as a } from "./useBrandLogoConfiguration.js";
import m from "./PaymentMethodsContainer.js";
import { useEffect as d } from "../../../../external/preact/hooks/dist/hooks.js";
const i = ({
  paymentMethods: i = [],
  instantPaymentMethods: p = [],
  storedPaymentMethods: l = [],
  openFirstStoredPaymentMethod: y,
  openFirstPaymentMethod: h,
  openPaymentMethod: f,
  order: c,
  orderStatus: M = null,
  onOrderCancel: u,
  onSelect: P = () => {},
  ...g
}) => {
  const {
      i18n: j
    } = s(),
    C = a(i),
    S = p.length > 0,
    b = l.length > 0,
    x = S || b ? j.get("paymentMethodsList.otherPayments.label") : "";
  return d(() => {
    if (f?.type) {
      const e = i.find(e => e.type === f?.type);
      if (e) return void P(e);
      console.warn(`Drop-in: payment method type "${f?.type}" not found`);
    }
    const e = l[0],
      t = i[0];
    if (e || t) {
      if (y && !0 === o(e, "props.oneClick")) return void P(e);
      h && P(t);
    }
  }, [l, i, y, h, f]), e(t, null, M && e(r, {
    order: c,
    orderStatus: M,
    onOrderCancel: u,
    brandLogoConfiguration: C
  }), S && e(n, {
    paymentMethods: p
  }), b && e(m, {
    ...g,
    label: j.get("paymentMethodsList.storedPayments.label"),
    classNameModifiers: ["storedPayments"],
    paymentMethods: l,
    onSelect: P
  }), !!i.length && e(m, {
    ...g,
    label: x,
    classNameModifiers: ["otherPayments"],
    paymentMethods: i,
    onSelect: P
  }));
};
export { i as default };
