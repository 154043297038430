import e from "../utils/iframes/postMessageToIframe.js";
import { ENCRYPTED_EXPIRY_DATE as t, ENCRYPTED_EXPIRY_MONTH as n, ENCRYPTED_EXPIRY_YEAR as s } from "../../constants.js";
import { hasOwnProperty as o } from "../../../../../../utils/hasOwnProperty.js";
import i from "../utils/iframes/getIframeContentWin.js";
function r({
  csfState: r,
  csfConfig: l,
  csfCallbacks: a
}, u) {
  if ("cc-name" === u.name) {
    const e = {
      ...u
    };
    delete e.numKey;
    const t = e;
    return a.onAutoComplete(t), !0;
  }
  if ("cc-exp" === u.name) {
    const a = u.value.replace(/[^0-9]/gi, "/").split("/");
    if (2 !== a.length) return !1;
    1 === a[0].length && (a[0] = `0${a[0]}`);
    const m = a[0],
      c = a[1];
    if (!((4 === c?.length || 2 === c?.length) && !isNaN(parseInt(c)))) return !1;
    const f = c.slice(-2),
      p = `${m}/${f}`;
    if (o(r.securedFields, t)) {
      const n = {
        txVariant: r.type,
        fieldType: t,
        autoComplete: p,
        numKey: r.securedFields[t].numKey
      };
      return e(n, i(r, t), l.loadingContext), !0;
    }
    if (o(r.securedFields, n)) {
      const t = {
        txVariant: r.type,
        fieldType: n,
        autoComplete: m,
        numKey: r.securedFields[n].numKey
      };
      e(t, i(r, n), l.loadingContext);
    }
    return o(r.securedFields, s) && setTimeout(() => {
      const t = {
        txVariant: r.type,
        fieldType: s,
        autoComplete: f,
        numKey: r.securedFields[s].numKey
      };
      e(t, i(r, s), l.loadingContext);
    }, 0), !0;
  }
  return !1;
}
export { r as processAutoComplete };
