class t {
  storage;
  constructor() {
    this.storage = {};
  }
  get length() {
    return Object.keys(this.storage).length;
  }
  key(t) {
    return Object.keys(this.storage)[t];
  }
  getItem(t) {
    return this.storage[t] || null;
  }
  setItem(t, e) {
    return this.storage[t] = e;
  }
  removeItem(t) {
    delete this.storage[t];
  }
  clear() {
    this.storage = {};
  }
}
class e {
  prefix = "adyen-checkout__";
  key;
  storage;
  constructor(e, r) {
    try {
      if (this.storage = r ? window[r] : window.localStorage, !this.storage) throw new Error("storage does not exist");
    } catch (e) {
      this.storage = new t();
    }
    this.key = this.prefix + e;
  }
  get() {
    try {
      return JSON.parse(this.storage.getItem(this.key));
    } catch (t) {
      return null;
    }
  }
  set(t) {
    this.storage.setItem(this.key, JSON.stringify(t));
  }
  remove() {
    this.storage.removeItem(this.key);
  }
  clear() {
    this.storage.clear();
  }
  keyByIndex(t) {
    return this.storage.key(t);
  }
  get length() {
    return this.storage.length;
  }
}
export { e as default };
