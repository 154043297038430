function e(e) {
  const [d, a] = e;
  return {
    dualBrandSelectElements: [{
      id: d.brand,
      brandObject: d
    }, {
      id: a.brand,
      brandObject: a
    }],
    selectedBrandValue: "",
    leadBrand: d
  };
}
export { e as default };
