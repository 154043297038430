const t = (t, o = !1) => {
  let r;
  try {
    r = new URL(t);
  } catch (t) {
    return !1;
  }
  return o && "http:" === r.protocol || "https:" === r.protocol;
};
export { t as isValidHttpUrl };
