import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter, ListLayout, ConfigBaseFilter } from 'gung-list';
import { Order, OrderListConfigService, SelectedCustomerService, OrderService, MetadataService } from 'gung-standard';
import { JeevesOrderCardListComponent } from '../../components/jeeves-order-card-list/jeeves-order-card-list.component';
import { differenceInDays, format } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin, of } from 'rxjs';
import { CustomerService } from 'gung-standard';
import { map, switchMap } from 'rxjs';
import { DateUtilService } from 'gung-common';

export class OrderDateFilter extends SimpleConfigBaseFilter<Order> {
  type = 'dateRangeFilter';

  constructor() {
    super();
  }

  getName(): string {
    return 'ORDER_DATE';
  }

  getOptionIds(item: Order): string[] {
    return [format(new Date(item.extra.oh.orddatum), 'yyMMdd')];
  }

  getOptionName(key: string): string {
    return key;
  }
}
/* 
export class OrderDateFilter extends SimpleConfigBaseFilter<Order> {
  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'ORDER_DATE';
  }

  getOptionIds(item: Order): string[] {
    const dayDifferense = differenceInDays(new Date(), new Date(item.extra.oh.orddatum));

    if (dayDifferense <= 1) {
      return ['TODAY'];
    }
    if (dayDifferense <= 7) {
      return ['THIS_WEEK'];
    }
    if (dayDifferense <= 30) {
      return ['THIS_MONTH'];
    }
    if (dayDifferense <= 90) {
      return ['LAST_THREE_MONTHS'];
    }
    return ['OVER_THREE_MONTHS'];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
} */

export class OrderExpectedDeliveryFilter extends SimpleConfigBaseFilter<Order> {
  type = 'dateRangeFilter';

  constructor() {
    super();
  }

  getName(): string {
    return 'EXPECTED_DELIVERY_DATE';
  }

  getOptionIds(item: Order): string[] {
    return [format(new Date(item.extra.oh.ordberlevdat), 'yyMMdd')];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class OrderStatusFilter extends SimpleConfigBaseFilter<Order> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'ORDER_STATUS';
  }
  getOptionIds(item: Order): string[] {
    const value = this.metadataService.getMetadataValue('x7', 'ordstatbeskr', item.extra.oh.ordstat);
    return [value];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class OrderTypeFilter extends SimpleConfigBaseFilter<Order> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'ORDER_TYPE';
  }
  getOptionIds(item: Order): string[] {
    const value = this.metadataService.getMetadataValue('x6', 'ordtypbeskr', item.extra.oh.ordtyp);
    return [value];
  }
  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class JeevesOrderListConfigService extends OrderListConfigService {
  constructor(
    protected translationService: TranslateService,
    protected orderService: OrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService,
    protected dateUtilService: DateUtilService
  ) {
    super(orderService, selectedCustomerService, dateUtilService);
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        ...super.getLayouts()[0],
        getListItemComponent: () => JeevesOrderCardListComponent
      }
    ];
  }

  getFilters(): ConfigBaseFilter<Order>[] {
    return [
      new OrderDateFilter(),
      new OrderExpectedDeliveryFilter(),
      new OrderStatusFilter(this.metadataService),
      new OrderTypeFilter(this.metadataService)
    ];
  }

  getSearchTerms(item: Order): string[] {
    return [item.id, item.extra.oh.kundref2, item.extra.oh.kundbestnr];
  }
}
