import { createElement as e } from "../../../../external/preact/dist/preact.js";
import r from "../../../internal/Img/Img.js";
import { useCoreContext as t } from "../../../../core/Context/CoreProvider.js";
import o from "../../../../core/Context/useImage.js";
import { useA11yReporter as s } from "../../../../core/Errors/useA11yReporter.js";
const a = ({
  message: a
}) => {
  const {
      i18n: n
    } = t(),
    c = o(),
    m = n.get(a || "error.message.unknown");
  return s(m), e("div", {
    className: "adyen-checkout__status adyen-checkout__status--error"
  }, e(r, {
    className: "adyen-checkout__status__icon",
    src: c({
      extension: "gif",
      imageFolder: "components/"
    })("error"),
    alt: n.get(a || "error.message.unknown"),
    height: "88"
  }), e("span", {
    className: "adyen-checkout__status__text"
  }, m));
};
export { a as default };
