function e({
  csfState: e,
  csfCallbacks: n
}, t) {
  const {
      binValue: i,
      encryptedBin: u,
      uuid: c
    } = t,
    a = {
      binValue: i,
      type: e.type
    };
  u && (a.encryptedBin = u, a.uuid = c), n.onBinValue(a);
}
export { e as handleBinValue };
