import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { first, map, Observable, tap } from 'rxjs';
import { User } from '../../state/auth/types';
import { Customer } from '../../models';
import { BackendTemplate } from '../../components/custom-page/custom-page-interface/template-component';
import { ConfigurationsService } from '../configurations.service';
@Injectable({
  providedIn: 'root'
})
export class HomeNavigationConfigService {
  adminLink = ['/sales-dashboard'];
  salesLink = ['/sales-dashboard'];
  multiCustomersUserLink = ['/delivery-location'];
  singleCustomerUserLink = ['/categories'];
  anonymousLink = ['/products'];
  supplierBuyerLink = ['/supplier-portal'];
  public dynamicCustomPageByRoles = false;
  homePageName = 'home';
  homeGroupPageName = 'home-group';

  /*
   * Key is the role, value is the link to navigate to
   * Configured from the backend. If we have values in here, they should trump the default values.
   */
  roleToLinkConfig: { [role: string]: string } = {};

  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected configurationsService: ConfigurationsService
  ) {}

  /**
   * Checks if the configurations are loaded and loads them otherwise.
   * Required to be called before getHomeLink is called the first time in order for the backend configuration to be
   * loaded correctly.
   */
  isLoaded(): Observable<boolean> {
    return this.configurationsService.getConfigurationsByIds(['HomePageConfiguration']).pipe(
      first(),
      tap(config => {
        if (!!config && config.length > 0) {
          this.roleToLinkConfig = config[0]['roleHomePages'];
        }
      }),
      map(() => true)
    );
  }

  setAdminHomeLink(link: string[]) {
    this.adminLink = link;
  }

  setSalesHomeLink(link: string[]) {
    this.salesLink = link;
  }

  setMultiCustomersUserLink(link: string[]) {
    this.multiCustomersUserLink = link;
  }

  setSingleCustomerUserLink(link: string[]) {
    this.singleCustomerUserLink = link;
  }

  setAnonymousHomeLink(link: string[]) {
    this.anonymousLink = link;
  }

  getHomeLink(user: User, customers?: Customer[], customPages?: BackendTemplate[]): string[] {
    if (this.getHomeLinkByBackendConfiguration(user)) {
      return [this.getHomeLinkByBackendConfiguration(user)];
    }

    if (this.dynamicCustomPageByRoles && customPages?.length > 0) {
      const activeGroups = Object.keys(user.activeGroups).filter(ag => !!user.activeGroups[ag]);
      const pagesByGroup = customPages.filter(
        p => activeGroups.filter(ag => p.activeForUserGroups?.includes(ag)).length > 0
      );
      const homePage = pagesByGroup.find(p => p.pageName === this.homePageName);
      if (homePage) {
        return ['page', homePage.pageName];
      }
      const homeGroupPage = pagesByGroup.find(p => p.pageName.startsWith(this.homeGroupPageName));
      if (homeGroupPage) {
        return ['page', homeGroupPage.pageName];
      }
    }
    if (user.roles.includes('SUPPLIER') || user.roles.includes('BUYER')) {
      return this.supplierBuyerLink;
    } else if (user.roles.includes('ADMIN')) {
      return this.adminLink;
    } else if (user.roles.includes('SALES')) {
      return this.salesLink;
    } else if (user.roles.includes('USER')) {
      if (customers && customers.length > 1) {
        return this.multiCustomersUserLink;
      } else {
        return this.singleCustomerUserLink;
      }
    } else if (user.roles.includes('ANONYMOUS')) {
      return this.anonymousLink;
    }
  }

  getHomeLinkByBackendConfiguration(user: User): string | undefined {
    if (user.roles.includes('SUPPLIER')) {
      return this.roleToLinkConfig['SUPPLIER'];
    } else if (user.roles.includes('BUYER')) {
      return this.roleToLinkConfig['BUYER'];
    } else if (user.roles.includes('ADMIN')) {
      return this.roleToLinkConfig['ADMIN'];
    } else if (user.roles.includes('SALES')) {
      return this.roleToLinkConfig['SALES'];
    } else if (user.roles.includes('USER')) {
      return this.roleToLinkConfig['USER'];
    } else if (user.roles.includes('ANONYMOUS')) {
      return this.roleToLinkConfig['ANONYMOUS'];
    }
    return undefined;
  }
}
