import { ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { TemplateComponent } from '../custom-page-interface/template-component';
import { Input, Directive, OnDestroy } from '@angular/core';
import { CustomPageEditorModalComponent } from '../custom-page-editor-modal/custom-page-editor-modal.component';
import { first, takeUntil } from 'rxjs';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { Assortment, AssortmentService } from '../../../services/assortment.service';
import { GungFlow } from '../../../state/flow/types';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { GungSlider } from 'gung-list';
import { CustomPageConfigService } from '../../../services/custom-page/custom-page-config.service';
import { CustomPageHtmlEditorComponent } from '../custom-page-html-editor/custom-page-html-editor.component';
import { CustomPageTextInputEditorModalComponent } from '../custom-page-text-input-editor-modal/custom-page-text-input-editor-modal.component';
import { GungNoImageUrl } from 'gung-common';

const default_slide = {
  link: {
    enabled: true,
    internal: undefined,
    url: ''
  },
  image: {
    enabled: true,
    sourceUrl: GungNoImageUrl // 400x400
  },
  text: {
    enabled: true,
    text: ''
  }
};

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class CustomPageTemplateComponent implements OnDestroy {
  @Input()
  edit = false;

  @Input()
  data: TemplateComponent;

  @Input()
  initialSetup: boolean;

  protected defaultNoImage = GungNoImageUrl;

  public userAssortment: Assortment;
  public currentFlowId: GungFlow;

  public currentLang = this.translateService.currentLang || 'se';

  unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    protected router: Router,
    protected modalService: NgbModal,
    protected gungFlowService: GungFlowService,
    protected assortmentService: AssortmentService,
    protected translateService: TranslateService,
    protected customPageConfigService: CustomPageConfigService
  ) {
    this.assortmentService
      .getUserAssortment()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userAssortment => {
        this.userAssortment = userAssortment;
      });
    this.gungFlowService
      .getSelectedFlow()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(currentFlow => {
        this.currentFlowId = currentFlow;
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public openHtmlModal(elementId) {
    if (!this.edit) {
      return;
    }

    const element = [this.data.elements[elementId]];
    const modalRef = this.modalService.open(CustomPageHtmlEditorComponent, { 
      size: 'xl',
      backdrop: 'static',
      keyboard: true
    });
    
    modalRef.componentInstance.data = JSON.parse(JSON.stringify(element));
    modalRef.result.then(
      result => {
        const newElement = JSON.parse(result);
        this.data.elements[elementId] = newElement[0];
      },
      reason => {}
    );
  }

  public getHtmlText(htmlText: { enabled: boolean; text: string; i18n: {} }) {
    if (htmlText && !htmlText.enabled) {
      return null;
    }
    if (htmlText && (htmlText.text || htmlText?.i18n?.[this.currentLang]?.text)) {
      return htmlText?.i18n?.[this.currentLang]?.text || htmlText.text;
    }
    if (this.edit) {
      return this.translateService.instant('INSERT_TEXT_HERE');
    }
    return '';
  }

  public openModal(elementId: string, isSlider?: boolean, isAssortment?: boolean) {
    if (!this.edit) {
      return;
    }

    let data;
    let templateElementKeys: string[];

    if (!isSlider) {
      data = [this.data.elements[elementId]];
    } else {
      templateElementKeys = Object.getOwnPropertyNames(this.data.elements).filter(elementName => elementName.includes(elementId));
      data = templateElementKeys.map(key => {
        return this.data.elements[key];
      });
    }
    
    const modalRef = this.modalService.open(CustomPageEditorModalComponent, { backdrop: 'static', keyboard: true, size: 'lg' });
    modalRef.componentInstance.data = JSON.parse(JSON.stringify(data));
    modalRef.componentInstance.isDataSlider = isSlider;
    modalRef.componentInstance.isAssortment = isAssortment;
    modalRef.result.then(
      result => {
        const newElement = JSON.parse(result);

        if (isSlider) {
          for (const key of templateElementKeys) {
            delete this.data.elements[key];
          }
  
          for (const [index, slide] of newElement.entries()) {
            const newName = elementId + '_' + index;
            this.data.elements[newName] = slide;
          }
        } else {
          this.data.elements[elementId] = newElement[0];
        }

        this.refreshData();
      },
      reason => {}
    );
  }

  public openModalTextInput(elementId: string, isSlider?: boolean, isAssortment?: boolean) {
    if (!this.edit) {
      return;
    }

    let data;
    let templateElementKeys: string[];

    if (!isSlider) {
      data = [this.data.elements[elementId]];
    } else {
      templateElementKeys = Object.getOwnPropertyNames(this.data.elements).filter(elementName => elementName.includes(elementId));
      data = templateElementKeys.map(key => {
        return this.data.elements[key];
      });
    }
    
    const modalRef = this.modalService.open(CustomPageTextInputEditorModalComponent, { backdrop: 'static', keyboard: true, size: 'lg' });
    modalRef.componentInstance.data = JSON.parse(JSON.stringify(data));
    modalRef.componentInstance.isDataSlider = isSlider;
    modalRef.componentInstance.isAssortment = isAssortment;
    modalRef.result.then(
      result => {
        const newElement = JSON.parse(result);

        if (isSlider) {
          for (const key of templateElementKeys) {
            delete this.data.elements[key];
          }
  
          for (const [index, slide] of newElement.entries()) {
            const newName = elementId + '_' + index;
            this.data.elements[newName] = slide;
          }
        } else {
          this.data.elements[elementId] = newElement[0];
        }

        this.refreshData();
      },
      reason => {}
    );
  }

  public navigateTo(link: { enabled: boolean; internal: boolean; url: string; openExternal?: boolean }) {
    if (link && (!link.enabled || !link.url)) {
      return;
    }
    if (link.url.includes('{currentlang}')) {
      link.url = link.url.replace(new RegExp('{currentlang}', 'g'), this.currentLang);
      return;
    }
    if (link.url.includes('{CURRENTLANG}')) {
      link.url = link.url.replace(new RegExp('{CURRENTLANG}', 'g'), this.currentLang.toLocaleUpperCase());
      return;
    }
    if (link && !link.internal) {
      if (link.openExternal) {
        window.open(link.url, '_blank');
      } else {
        window.location.href = link.url;
      }
    }
    if (link.url.includes('{userassortment}')) {
      this.navigateWithUserAssortment(link.url);
      return;
    }
    if (link.url.includes('flowid=')) {
      this.navigateWithFlow(link.url);
      return;
    }
    if (link && link.internal) {
      this.router.navigateByUrl(link.url);
      return;
    }
  }

  public getImage(image: { enabled: boolean; sourceUrl: string }) {
    let urlImg = this.defaultNoImage;
    if (image && !image.enabled) {
      return null;
    }
    if (image && image.sourceUrl) {
      urlImg = image.sourceUrl;
    }
    // return `url(${urlImg})`;
    // return `${urlImg}`;
    return `${encodeURI(urlImg)}`;
  }

  public getText(text: { enabled: boolean; text: string; i18n?: {} }, textField = 'title') {
    if (text && !text.enabled) {
      return null;
    }
    if (text && (text.text || text?.i18n?.[this.currentLang]?.[textField])) {
      return text?.i18n?.[this.currentLang]?.[textField] || text.text;
    }
    if (this.edit) {
      return 'INSERT_TEXT_HERE';
    }
    return '';
  }

  public getTextColor(text: { enabled: boolean; text: string; i18n?: {}, color?: string }, textField = 'title') {
    return text?.color/*  + '!important' */ || undefined;
  }

  public navigateWithFlow(linkUrlWithFlowId: string) {
    const url = new URL('http://localhost' + linkUrlWithFlowId);
    const queryString = url.search;
    const urlParams = new URLSearchParams(queryString);
    const flowid = urlParams.get('flowid');
    urlParams.delete('flowid');
    const newUrl = urlParams.toString().length > 0 ? url.pathname + '?' + urlParams.toString() : url.pathname;
    if (flowid && this.currentFlowId.id !== flowid) {
      this.gungFlowService
        .getSelectedFlow()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(flow => {
          if (flow.id === flowid) {
            if ((urlParams as any)?.size > 0) {
              const params = Object.fromEntries((urlParams as any).entries());
              this.router.navigate(url.pathname.split('/').filter(p => p !== ''), { queryParams: params});
            } else {
              this.router.navigateByUrl(newUrl);
            }
          }
        });
      this.gungFlowService.selectFlow(flowid); // Change the page when the flow subscribe detect the flow was changed
      return;
    }
    this.router.navigateByUrl(newUrl);
  }

  public navigateWithUserAssortment(linkUrl: string) {
    const newUrl = linkUrl.replace('{userassortment}', this.userAssortment.id);
    this.router.navigateByUrl(newUrl);
  }

  abstract getDefaultData(): TemplateComponent;

  getSliderDataFromTemplate(template: TemplateComponent, sliderId: string): GungSlider[] {
    // Get slider data
    const templateElementKeys: string[] = template && Object.getOwnPropertyNames(template.elements).filter(elementName => elementName.includes(sliderId));

    if (templateElementKeys?.length === 0) {
      // Get Default
      const defaultSlider: GungSlider = {
        id: 0,
        description: this.getText(default_slide.text),
        button: 'SHOW_MORE',
        link: default_slide.link.url,
        background: default_slide.image.sourceUrl,
        customClass: 'gung-image-only'
      }
      return [defaultSlider];
    }

    const result: GungSlider[] = templateElementKeys.map((key, index) => {
      const slide: GungSlider = {
        id: index,
        description: this.getText(template.elements[key].text),
        button: 'SHOW_MORE',
        link: template.elements[key].link.url,
        background: template.elements[key].image.sourceUrl,
        customClass: template.elements[key].link.internal ? 'gung-image-and-caption' : 'gung-image-and-caption external-link'
      };
      return slide;
    });

    return result;
  }

  previousCard(productList: ElementRef) {
    productList.nativeElement.scrollTo({ left: (productList.nativeElement.scrollLeft - 320), behavior: 'smooth' });
  }

  nextCard(productList: ElementRef) {
    productList.nativeElement.scrollTo({ left: (productList.nativeElement.scrollLeft + 320), behavior: 'smooth' });
  }

  refreshData(): void {};
}
