import { createElement as t } from "../../external/preact/dist/preact.js";
import { UIElement as e } from "../internal/UIElement/UIElement.js";
import o from "./defaultProps.js";
import { DropinComponent as s } from "./components/DropinComponent.js";
import { CoreProvider as n } from "../../core/Context/CoreProvider.js";
import { getCommonProps as i } from "./components/utils.js";
import r from "./elements/createElements.js";
import a from "./elements/createStoredElements.js";
import h from "./elements/createInstantPaymentElements.js";
import { hasOwnProperty as d } from "../../utils/hasOwnProperty.js";
import m from "../../core/Errors/SRPanelProvider.js";
import p from "./elements/splitPaymentMethods.js";
import { TxVariants as c } from "../tx-variants.js";
const l = ["paywithgoogle", "googlepay", "applepay"];
class y extends e {
  static type = c.dropin;
  static defaultProps = o;
  dropinRef = null;
  paymentMethodsConfiguration;
  componentFromAction;
  constructor(t, e) {
    super(t, e), this.submit = this.submit.bind(this), this.handleAction = this.handleAction.bind(this), this.props.paymentMethodComponents.forEach(t => this.core.register(t)), this.paymentMethodsConfiguration = this.props.paymentMethodsConfiguration || {};
  }
  storeElementRefOnCore() {
    this.core.storeElementReference(this);
  }
  formatProps(t) {
    return {
      type: "dropin",
      ...super.formatProps(t),
      instantPaymentTypes: Array.from(new Set(t.instantPaymentTypes)).filter(t => l.includes(t))
    };
  }
  get isValid() {
    return !!this.dropinRef && !!this.dropinRef.state.activePaymentMethod && !!this.dropinRef.state.activePaymentMethod.isValid;
  }
  showValidation() {
    return this.dropinRef.state.activePaymentMethod && this.dropinRef.state.activePaymentMethod.showValidation(), this;
  }
  setStatus(t, e = {}) {
    return this.dropinRef?.setStatus(t, e), this;
  }
  get activePaymentMethod() {
    return this.dropinRef?.state || this.dropinRef?.state.activePaymentMethod ? this.dropinRef.state.activePaymentMethod : null;
  }
  get data() {
    return this.activePaymentMethod ? this.dropinRef.state.activePaymentMethod.data : null;
  }
  displayFinalAnimation(t) {
    this.props.disableFinalAnimation || this.dropinRef.setStatus(t);
  }
  submit() {
    if (!this.activePaymentMethod) throw new Error("No active payment method.");
    this.activePaymentMethod.isValid || this.activePaymentMethod.showValidation(), this.activePaymentMethod.isInstantPayment && this.closeActivePaymentMethod(), this.activePaymentMethod.submit();
  }
  handleCreate = () => {
    const {
        paymentMethodsConfiguration: t,
        showStoredPaymentMethods: e,
        showPaymentMethods: o,
        instantPaymentTypes: s
      } = this.props,
      {
        paymentMethods: n,
        storedPaymentMethods: d,
        instantPaymentMethods: m
      } = p(this.core.paymentMethodsResponse, s),
      c = i({
        ...this.props,
        elementRef: this.elementRef
      });
    return [e ? a(d, t, c, this.core) : [], o ? r(n, t, c, this.core) : [], h(m, t, c, this.core)];
  };
  handleAction(t, e = {}) {
    if (!t || !t.type) {
      if (d(t, "action") && d(t, "resultCode")) throw new Error('handleAction::Invalid Action - the passed action object itself has an "action" property and a "resultCode": have you passed in the whole response object by mistake?');
      throw new Error('handleAction::Invalid Action - the passed action object does not have a "type" property');
    }
    if ("redirect" !== t.type && this.activePaymentMethod?.updateWithAction) return this.activePaymentMethod.updateWithAction(t);
    this.elementRef instanceof y && (e = {
      ...this.elementRef.activePaymentMethod?.props,
      ...e
    });
    const o = this.core.createFromAction(t, {
      ...e,
      elementRef: this.elementRef,
      onAdditionalDetails: this.handleAdditionalDetails,
      isDropin: !0
    });
    return o ? (this.setStatus(o.props.statusType, {
      component: o
    }), this.componentFromAction = o, this) : null;
  }
  handleOrder = ({
    order: t
  }) => {
    this.updateParent({
      order: t
    });
  };
  closeActivePaymentMethod() {
    this.dropinRef.closeActivePaymentMethod();
  }
  handleKeyPress(t) {
    if ("Enter" === t.key || "Enter" === t.code) {
      if ("radio" === document?.activeElement?.getAttribute("role")) return;
      super.handleKeyPress(t);
    }
  }
  onEnterKeyPressed(t, e) {
    const o = this.activePaymentMethod ?? e;
    this.activePaymentMethod?.onEnterKeyPressed(t, o);
  }
  render() {
    return t(n, {
      i18n: this.props.i18n,
      loadingContext: this.props.loadingContext,
      resources: this.resources
    }, t(m, {
      srPanel: this.props.modules.srPanel
    }, t(s, {
      ...this.props,
      core: this.core,
      elementRef: this.elementRef,
      onCreateElements: this.handleCreate,
      ref: t => {
        this.dropinRef = t;
      }
    })));
  }
}
export { y as default };
