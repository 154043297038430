import e from "./VisaSdk.js";
import s from "./MastercardSdk.js";
import t from "../../../../../core/Errors/AdyenCheckoutError.js";
import { isRejected as o, isFulfilled as r } from "../../../../../utils/promise-util.js";
const a = {
  visa: e,
  mc: s,
  default: null
};
class i {
  schemes;
  customSdkConfiguration;
  constructor(e, {
    dpaLocale: s = "en_US",
    dpaPresentationName: t = ""
  }) {
    this.schemes = e, this.customSdkConfiguration = {
      dpaLocale: s,
      dpaPresentationName: t
    };
  }
  async load(e) {
    if (!this.schemes || 0 === this.schemes.length) throw new t("ERROR", "ClickToPay -> SrcSdkLoader: There are no schemes set to be loaded");
    return new Promise((s, i) => {
      const n = this.schemes.map(s => ((e, s, t) => {
          const o = a[e] || a.default;
          return o ? new o(s, t) : null;
        })(s, e, this.customSdkConfiguration)),
        c = n.map(e => e.loadSdkScript());
      Promise.allSettled(c).then(e => {
        e.every(o) && i(new t("ERROR", `ClickToPay -> SrcSdkLoader # Unable to load network schemes: ${this.schemes.toString()}`));
        const a = n.filter((s, t) => r(e[t]));
        s(a);
      });
    });
  }
}
export { i as default };
