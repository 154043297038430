import { createElement as e } from "../../../../../../external/preact/dist/preact.js";
import { getCardImageUrl as a, getFullBrandName as r } from "../../utils.js";
import t from "../../../../../../core/Context/useImage.js";
const c = ({
  brand: c,
  onClick: n,
  dataValue: o,
  notSelected: d,
  brandsConfiguration: s = {}
}) => {
  const i = t(),
    l = "card" === c ? "nocard" : c,
    _ = s[c]?.icon ?? a(l, i);
  return e("img", {
    className: `adyen-checkout-card-input__icon ${d ? "adyen-checkout__card__cardNumber__brandIcon--not-selected" : ""} adyen-checkout__card__cardNumber__brandIcon`,
    onError: e => {
      e.target.style.cssText = "display: none";
    },
    alt: r(c),
    src: _,
    onClick: n,
    "data-value": o
  });
};
export { c as default };
