import { getTranslation as t } from "./utils.js";
import { getLocalisedAmount as o } from "../utils/amount-util.js";
import i from "../core/Errors/AdyenCheckoutError.js";
class a {
  locale;
  languageCode;
  translations;
  customTranslations;
  timeFormatOptions = {
    hour: "numeric",
    minute: "numeric"
  };
  timeAndDateFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    ...this.timeFormatOptions
  };
  timeAndDateFormatter;
  constructor(t) {
    const {
      locale: o,
      translations: a,
      customTranslations: e
    } = t;
    if (!o) throw new i("IMPLEMENTATION_ERROR", 'Language: "locale" property is not defined');
    this.locale = o, this.languageCode = this.locale.split("-")[0], this.customTranslations = e || {}, this.timeAndDateFormatter = Intl.DateTimeFormat(this.locale, this.timeAndDateFormatOptions), this.translations = {
      ...a,
      ...(!!this.customTranslations[this.locale] && this.customTranslations[this.locale])
    };
  }
  get(o, i) {
    const a = t(this.translations, o, i);
    return null !== a ? a : o;
  }
  amount(t, i, a) {
    return o(t, this.locale, i, a);
  }
  date(t, o = {}) {
    if (void 0 === t) return "";
    const i = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      ...o
    };
    return new Date(t).toLocaleDateString(this.locale, i);
  }
  dateTime(t) {
    return void 0 === t ? "" : this.timeAndDateFormatter.format(new Date(t));
  }
}
export { a as Language, a as default };
