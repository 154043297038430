function t(t, e) {
  return {
    instantPaymentMethods: t.paymentMethods.filter(({
      type: t
    }) => e.includes(t)),
    paymentMethods: t.paymentMethods.filter(({
      type: t
    }) => !e.includes(t)),
    storedPaymentMethods: t.storedPaymentMethods
  };
}
export { t as default };
