<div class="row">
  <div class="col-12">
    <h3 class="mt-0 text-center text-md-left" translate>NAVIGATION</h3>
    <hr>
  </div>
  <div class="col-12">
    <lib-home-navigation-config></lib-home-navigation-config>
  </div>
  <div class="col-12 mt-4">
    <lib-menu-configuration-matrix></lib-menu-configuration-matrix>
  </div>
</div>
