import { httpPost as e } from "../../core/Services/http.js";
import { pick as t } from "../../utils/commonUtils.js";
import { THREEDS2_ERROR as o, THREEDS2_FINGERPRINT_SUBMIT as n } from "./constants.js";
import { Analytics3DS2Errors as i, ANALYTICS_API_ERROR as r, ANALYTICS_SDK_ERROR as s } from "../../core/Analytics/constants.js";
function a({
  data: a
}) {
  e({
    path: `v1/submitThreeDS2Fingerprint?token=${this.props.clientKey}`,
    loadingContext: this.props.loadingContext,
    errorLevel: "fatal"
  }, {
    ...a
  }).then(e => {
    let a;
    if ("completed" === e.type) {
      const {
        details: t
      } = e;
      return e.details ? this.onComplete({
        data: {
          details: t
        }
      }) : (console.debug('Handled Error::callSubmit3DS2Fingerprint::FAILED:: no details object in a response indicating either a "frictionless" flow, or a "refused" response. resData=', e), a = {
        type: o,
        code: i.NO_DETAILS_FOR_FRICTIONLESS_OR_REFUSED,
        errorType: r,
        message: `${n}: no details object in a response indicating either a "frictionless" flow, or a "refused" response`
      }, void this.submitAnalytics(a));
    }
    if (!e.action) return console.debug('Handled Error::callSubmit3DS2Fingerprint::FAILED:: no action object in a response indicating a "challenge". resData=', e), a = {
      type: o,
      code: i.NO_ACTION_FOR_CHALLENGE,
      errorType: r,
      message: `${n}: no action object in a response indicating a "challenge" flow`
    }, void this.submitAnalytics(a);
    const c = this.props.elementRef ?? this;
    return c ? "threeDS2" === e.action?.type ? c.handleAction(e.action, t("challengeWindowSize").from(this.props)) : "redirect" === e.action?.type ? c.handleAction(e.action) : void 0 : (console.debug("Handled Error::callSubmit3DS2Fingerprint::FAILED:: no actionHandler"), a = {
      type: o,
      code: i.NO_COMPONENT_FOR_ACTION,
      errorType: s,
      message: `${n}: no component defined to handle the action response`
    }, void this.submitAnalytics(a));
  }).catch(e => {
    this.handleError(e);
  });
}
export { a as default };
