import { Component } from '@angular/core';
import { GlobalSearchConfigService, GlobalSearchResult } from '../../services/global-search-config.service';
import { FastsearchItemRendererComponent } from 'gung-list';

@Component({
  selector: 'lib-global-search-card',
  templateUrl: './global-search-card.component.html',
  styleUrls: ['./global-search-card.component.scss']
})
export class GlobalSearchCardComponent extends FastsearchItemRendererComponent<GlobalSearchResult> {
  constructor(
    protected globalSearchConfig: GlobalSearchConfigService
  ) {
    super();
  }
  public sendCloseSignal(): void {
    this.globalSearchConfig.closeSubject.next();
  }

  public sendClearSearchSignal(): void {
    if (this.globalSearchConfig.clearSearchTermsOnResultOpen) {
      this.globalSearchConfig.clearSearchTermsSubject.next();
    }
  }
}
