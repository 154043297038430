import { createElement as e } from "../../../../../external/preact/dist/preact.js";
import t from "./CVC.js";
import r from "../../../../internal/FormFields/Field/Field.js";
import { useCoreContext as a } from "../../../../../core/Context/CoreProvider.js";
import { ENCRYPTED_SECURITY_CODE as d } from "../../../../internal/SecuredFields/lib/constants.js";
import c from "../../../../internal/FormFields/InputText.js";
function i({
  brand: i,
  hasCVC: o,
  onFocusField: l,
  errors: s,
  valid: n,
  cvcPolicy: u,
  focusedElement: m,
  lastFour: p,
  expiryMonth: y,
  expiryYear: _,
  showContextualElement: C
}) {
  const {
      i18n: x
    } = a(),
    f = `${x.get("creditCard.storedCard.description.ariaLabel").replace("%@", p)}${y && _ ? ` ${x.get("creditCard.expiryDate.label")} ${y}/${_}` : ""}`,
    F = "amex" === i,
    h = F ? x.get("creditCard.securityCode.contextualText.4digits") : x.get("creditCard.securityCode.contextualText.3digits");
  return e("div", {
    className: "adyen-checkout__card__form adyen-checkout__card__form--oneClick",
    "aria-label": f
  }, e("div", {
    className: "adyen-checkout__card__exp-cvc adyen-checkout__field-wrapper"
  }, y && _ && e(r, {
    label: x.get("creditCard.expiryDate.label"),
    className: "adyen-checkout__field--50",
    classNameModifiers: ["storedCard"],
    name: "expiryDateField",
    disabled: !0
  }, e(c, {
    name: "expiryDateField",
    className: "adyen-checkout__input adyen-checkout__input--disabled adyen-checkout__card__exp-date__input--oneclick",
    value: `${y} / ${_}`,
    disabled: !0,
    dir: "ltr"
  })), o && e(t, {
    cvcPolicy: u,
    error: ((e, t) => e[t] ? x.get(e[t]) : null)(s, d),
    focused: "encryptedSecurityCode" === m,
    filled: !!n.encryptedSecurityCode || !!s.encryptedSecurityCode,
    isValid: !!n.encryptedSecurityCode,
    label: x.get("creditCard.securityCode.label"),
    onFocusField: l,
    ...(y && _ && {
      className: "adyen-checkout__field--50"
    }),
    classNameModifiers: ["storedCard"],
    frontCVC: F,
    showContextualElement: C,
    contextualText: h
  })));
}
export { i as default };
