function t(t, e, n) {
  let l;
  const o = n;
  return 1 !== t.length || e || (l = t), t.length > e?.length && (l = t.filter(({
    [o]: t
  }) => !e.some(({
    [o]: e
  }) => e === t))), l;
}
export { t as getArrayDifferences };
