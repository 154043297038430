import { createElement as t } from "../../external/preact/dist/preact.js";
import { UIElement as e } from "../internal/UIElement/UIElement.js";
import r from "./components/Challenge/PrepareChallenge3DS2.js";
import { DEFAULT_CHALLENGE_WINDOW_SIZE as o, THREEDS2_CHALLENGE as s, THREEDS2_ERROR as n, THREEDS2_CHALLENGE_ERROR as i } from "./constants.js";
import { existy as a } from "../../utils/commonUtils.js";
import { hasOwnProperty as p } from "../../utils/hasOwnProperty.js";
import { TxVariants as m } from "../tx-variants.js";
import l, { API_ERROR as c } from "../../core/Errors/AdyenCheckoutError.js";
import { ANALYTICS_RENDERED_STR as h, Analytics3DS2Errors as u, ANALYTICS_API_ERROR as y } from "../../core/Analytics/constants.js";
import { CoreProvider as f } from "../../core/Context/CoreProvider.js";
class C extends e {
  static type = m.threeDS2Challenge;
  static defaultProps = {
    dataKey: "threeDSResult",
    size: o,
    type: s
  };
  submitAnalytics = t => {
    t.type !== h && super.submitAnalytics(t);
  };
  onComplete(t) {
    t && super.onComplete(t), this.unmount();
  }
  render() {
    if (!a(this.props.paymentData)) {
      const t = p(this.props, "isMDFlow") ? "paymentData" : "authorisationToken";
      return this.props.onError(new l(c, `No ${t} received. 3DS2 Challenge cannot proceed`)), this.submitAnalytics({
        type: n,
        code: u.ACTION_IS_MISSING_PAYMENT_DATA,
        errorType: y,
        message: `${i}: Missing 'paymentData' property from threeDS2 action`
      }), null;
    }
    return t(f, {
      i18n: this.props.i18n,
      loadingContext: this.props.loadingContext,
      resources: this.resources
    }, t(r, {
      ...this.props,
      onComplete: this.onComplete,
      onSubmitAnalytics: this.submitAnalytics,
      isMDFlow: this.props.paymentData.length < 15
    }));
  }
}
export { C as default };
