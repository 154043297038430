import { createElement as t } from "../../external/preact/dist/preact.js";
import { UIElement as r } from "../internal/UIElement/UIElement.js";
import e from "./components/DeviceFingerprint/PrepareFingerprint3DS2.js";
import i from "./callSubmit3DS2Fingerprint.js";
import { existy as s } from "../../utils/commonUtils.js";
import { TxVariants as n } from "../tx-variants.js";
import o, { API_ERROR as p } from "../../core/Errors/AdyenCheckoutError.js";
import { ANALYTICS_RENDERED_STR as a, Analytics3DS2Errors as m, ANALYTICS_API_ERROR as l } from "../../core/Analytics/constants.js";
import { THREEDS2_FINGERPRINT as c, THREEDS2_ERROR as u, THREEDS2_FINGERPRINT_ERROR as y } from "./constants.js";
class h extends r {
  static type = n.threeDS2Fingerprint;
  static defaultProps = {
    dataKey: "fingerprintResult",
    type: c
  };
  callSubmit3DS2Fingerprint = i.bind(this);
  submitAnalytics = t => {
    t.type !== a && super.submitAnalytics(t);
  };
  onComplete(t) {
    super.onComplete(t), this.unmount();
  }
  render() {
    return s(this.props.paymentData) ? t(e, {
      ...this.props,
      onComplete: this.props.isMDFlow ? this.onComplete : this.callSubmit3DS2Fingerprint,
      onSubmitAnalytics: this.submitAnalytics,
      isMDFlow: this.props.paymentData.length < 15
    }) : (this.props.onError(new o(p, "No paymentData received. 3DS2 Fingerprint cannot proceed")), this.submitAnalytics({
      type: u,
      code: m.ACTION_IS_MISSING_PAYMENT_DATA,
      errorType: l,
      message: `${y}: Missing 'paymentData' property from threeDS2 action`
    }), null);
  }
}
export { h as default };
