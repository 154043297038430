import { getSupportedLocale as e } from "./get-paypal-locale.js";
import { ADYEN_CLIENTID_TEST as n, ADYEN_CLIENTID_LIVE as t, INTEGRATION_DATE as o } from "../config.js";
const i = ({
  amount: i,
  countryCode: r,
  debug: a,
  environment: c = "",
  locale: l,
  configuration: u,
  commit: m,
  vault: s,
  enableMessages: g
}) => {
  const d = e(l),
    y = i ? i.currency : null,
    b = "test" === c.toLowerCase(),
    p = b ? n : t,
    {
      merchantId: f,
      intent: v
    } = u;
  return {
    ...(f && {
      "merchant-id": f
    }),
    ...(d && {
      locale: d
    }),
    ...(r && b && {
      "buyer-country": r
    }),
    ...(a && b && {
      debug: a
    }),
    ...(y && {
      currency: y
    }),
    ...(v && {
      intent: v
    }),
    commit: m,
    vault: s,
    "client-id": p,
    "integration-date": o,
    "enable-funding": "paylater,venmo",
    components: "buttons,funding-eligibility" + (g ? ",messages" : "")
  };
};
export { i as getPaypalSettings };
