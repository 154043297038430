<div class="row" *ngIf="homePageConfiguration">
  <div class="col">
    <div class="d-flex justify-content-between align-items-baseline">
      <div>
        <h5 class="mb-0 mt-0" translate>HOME_PAGE_CONFIG_TITLE</h5>
        <span class="font-italic small mb-2 d-inline-block" translate>HOME_PAGE_CONFIG_DESCRIPTION</span>
      </div>


      <button
        class="btn btn-default"
        style="font-size: 20px;"
        (click)="save()"
      >
        <i ngbTooltip="{{'SAVE' | translate}}" class="fas fa-save"></i>
      </button>
    </div>

    <div class="d-flex flex-column">
      <div class="d-flex align-items-center mb-2" *ngFor="let role of rolesToShow">
        <div style="width: 150px">
          <lib-gung-text-input
            [suppressLabel]="true"
            [renderStatic]="true"
            [placeholder]="role"
          ></lib-gung-text-input>
        </div>
        <div style="width: 400px;">
          <lib-gung-text-input
            [ngClass]="{ 'is-invalid': hasRoleError[role] }"
            [suppressLabel]="true"
            [(ngModel)]="homePageConfiguration.roleHomePages[role]"
            (ngModelChange)="validateInput($event, role)"
          ></lib-gung-text-input>
        </div>
        <div>
          <i *ngIf="hasRoleError[role]"
             [ngbTooltip]="'HOME_PAGE_LINK_ERROR_TOOLTIP' | translate"
             class="fa-regular fa-circle-exclamation ml-2 tooltip-error"></i>
        </div>
      </div>
    </div>
  </div>
</div>
