import { createElement as e } from "../../external/preact/dist/preact.js";
import { SRPanelContext as s } from "./SRPanelContext.js";
import { useCoreContext as t } from "../Context/CoreProvider.js";
import { partial as r } from "../../components/internal/SecuredFields/lib/utilities/commonUtils.js";
import { setSRMessagesFromErrors as o } from "./utils.js";
const n = ({
  srPanel: n,
  children: i
}) => {
  const {
      i18n: l
    } = t(),
    a = n.moveFocus;
  return e(s.Provider, {
    value: {
      srPanel: n,
      setSRMessagesFromObjects: ({
        fieldTypeMappingFn: e
      }) => r(o, {
        SRPanelRef: n,
        i18n: l,
        fieldTypeMappingFn: e
      }),
      setSRMessagesFromStrings: e => {
        n.setMessages(e);
      },
      clearSRPanel: () => {
        n.setMessages(null);
      },
      shouldMoveFocusSR: a
    }
  }, i);
};
export { n as default };
