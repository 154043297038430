import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { first, map, Observable } from 'rxjs';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';

@Injectable({
  providedIn: 'root'
})
export class SelectedCustomerRedirectGuard {
  constructor(
    private router: Router,
    protected selectedCustomerService: SelectedCustomerService
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    //this.selectedCustomerService.getSelectedCustomer().pipe(first()).subscribe()
    return this.selectedCustomerService.getSelectedCustomer().pipe(
      first(),
      map(c => c.id),
      map(id => {
        if (!!id) {
          this.router.navigate(['/customers', id]);
          return false;
        }
        return true;
      })
    );
  }
}
