import { GENERIC_OPTIONS as e } from "./config.js";
function o(o) {
  return Object.keys(o).reduce((n, t) => (e.includes(t) && (n[t] = o[t]), n), {});
}
function n(e) {
  const o = (e => e)(["session", "environment", "_environmentUrls", "showPayButton", "clientKey", "locale", "translations", "paymentMethodsResponse", "amount", "secondaryAmount", "countryCode", "allowPaymentMethods", "removePaymentMethods", "srConfig", "analytics", "risk", "order", "exposeLibraryMetadata", "beforeRedirect", "beforeSubmit", "onPaymentCompleted", "onPaymentFailed", "onSubmit", "onAdditionalDetails", "onActionHandled", "onChange", "onError", "onBalanceCheck", "onOrderRequest", "onPaymentMethodsRequest", "onOrderCancel", "onOrderUpdated", "loadingContext", "onEnterKeyPressed"]);
  Object.keys(e).forEach(e => {
    o.includes(e) || console.warn(`AdyenCheckout - Configuration property "${e}" is not a valid AdyenCheckout property. If it is a payment method configuration, make sure to pass it directly to the Component. If you are using Drop-in, make sure to pass it to "paymentMethodsConfiguration" object`);
  });
}
export { n as assertConfigurationPropertiesAreValid, o as processGlobalOptions };
