import { isReadyToPayRequest as t, initiatePaymentRequest as e } from "./requests.js";
import { resolveEnvironment as n } from "./utils.js";
import a from "../../utils/Script.js";
import i from "./config.js";
class o {
  paymentsClient;
  constructor(t, e) {
    const a = n(t);
    this.paymentsClient = this.getGooglePaymentsClient({
      environment: a,
      paymentDataCallbacks: e
    });
  }
  async getGooglePaymentsClient(t) {
    if (!window.google?.payments) {
      const t = new a(i.URL);
      await t.load();
    }
    return new google.payments.api.PaymentsClient(t);
  }
  isReadyToPay(e) {
    return this.paymentsClient ? this.paymentsClient.then(n => n.isReadyToPay(t(e))) : Promise.reject(new Error("Google Pay is not available"));
  }
  prefetchPaymentData(t, n) {
    if (!this.paymentsClient) throw new Error("Google Pay is not available");
    const a = e(t, n);
    this.paymentsClient.then(t => t.prefetchPaymentData(a));
  }
  initiatePayment(t, n) {
    if (!this.paymentsClient) throw new Error("Google Pay is not available");
    const a = e(t, n);
    return this.paymentsClient.then(t => t.loadPaymentData(a));
  }
}
export { o as default };
