import e from "./core.js";
async function t(t) {
  const r = new e(t);
  return await r.initialize();
}
t.register = (...t) => {
  e.register(...t);
}, t.setBundleType = t => {
  e.setBundleType(t);
};
export { t as AdyenCheckout };
