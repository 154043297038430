import { createElement as e } from "../../../external/preact/dist/preact.js";
import t from "../Button/Button.js";
import { useCoreContext as o } from "../../../core/Context/CoreProvider.js";
import { payAmountLabel as r, secondaryAmountLabel as a } from "./utils.js";
import s from "./components/SecondaryButtonLabel.js";
const l = ({
  amount: l,
  secondaryAmount: n,
  classNameModifiers: i = [],
  label: m,
  ...c
}) => {
  const {
      i18n: u
    } = o(),
    d = l && {}.hasOwnProperty.call(l, "value") && 0 === l.value,
    p = d ? u.get("confirmPreauthorization") : r(u, l),
    f = !d && !m && l && n && Object.keys(n).length ? a(u, n) : null;
  return e(t, {
    ...c,
    disabled: c.disabled || "loading" === c.status,
    classNameModifiers: [...i, "pay"],
    label: m || p
  }, f && e(s, {
    label: f
  }));
};
export { l as default, r as payAmountLabel };
