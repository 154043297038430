import { createElement as e } from "../../../external/preact/dist/preact.js";
import r from "./InputBase.js";
function t(t) {
  return e(r, {
    classNameModifiers: ["large"],
    ...t,
    "aria-required": t.required,
    type: "text"
  });
}
export { t as default };
