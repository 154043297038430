import t from "../core/Errors/AdyenCheckoutError.js";
class s {
  src;
  node;
  attributes;
  dataAttributes;
  isScriptLoadCalled = !1;
  script;
  constructor(t, s = "body", i = {}, r = {}) {
    this.src = t, this.node = s, this.attributes = i, this.dataAttributes = r;
  }
  load = () => {
    if (!this.isScriptLoadCalled) return new Promise((s, i) => {
      const r = () => {
          this.script.setAttribute("data-script-loaded", "true"), s();
        },
        e = s => {
          this.remove(), i(new t("SCRIPT_ERROR", `Unable to load script ${this.src}. Message: ${s.message}`, {
            cause: s.error
          }));
        };
      this.isScriptLoadCalled = !0;
      const c = document.querySelector(this.node);
      if (this.script = c.querySelector(`script[src="${this.src}"]`), this.script && this.script.getAttribute("data-script-loaded")) s();else {
        if (this.script) return this.script.addEventListener("load", r), void this.script.addEventListener("error", e);
        this.script = document.createElement("script"), Object.assign(this.script, this.attributes), Object.assign(this.script.dataset, this.dataAttributes), this.script.src = this.src, this.script.async = !0, this.script.addEventListener("load", r), this.script.addEventListener("error", e), c.appendChild(this.script);
      }
    });
  };
  remove = () => this.script.parentNode && this.script.parentNode.removeChild(this.script);
}
export { s as default };
