import { filterAllowedPaymentMethods as e, filterRemovedPaymentMethods as t, filterSupportedStoredPaymentMethods as o, filterEcomStoredPaymentMethods as r } from "./filters.js";
const s = e => ({
    ...e,
    storedPaymentMethodId: e.id,
    isStoredPaymentMethod: !0
  }),
  n = (o, {
    allowPaymentMethods: r = [],
    removePaymentMethods: s = []
  }) => o ? o.filter(e, r).filter(t, s) : [],
  a = (n, {
    allowPaymentMethods: a = [],
    removePaymentMethods: d = []
  }) => n ? n.filter(o).filter(e, a).filter(t, d).filter(r).map(s) : [],
  d = e => {
    if ("string" == typeof e) throw new Error('paymentMethodsResponse was provided but of an incorrect type (should be an object but a string was provided).Try JSON.parse("{...}") your paymentMethodsResponse.');
    if (e instanceof Array) throw new Error("paymentMethodsResponse was provided but of an incorrect type (should be an object but an array was provided).Please check you are passing the whole response.");
    !e || e?.paymentMethods?.length || e?.storedPaymentMethods?.length || console.warn("paymentMethodsResponse was provided but no payment methods were found.");
  };
export { d as checkPaymentMethodsResponse, n as processPaymentMethods, a as processStoredPaymentMethods };
