import { selectOne as t } from "../../utilities/dom.js";
import e from "../utils/userAgent.js";
import { on as n, off as s } from "../../../../../../utils/listenerUtils.js";
var o = {
  touchendListener: function (t) {
    const e = t.target;
    if (e instanceof HTMLInputElement || HTMLTextAreaElement && e instanceof HTMLTextAreaElement) {
      const s = e.value;
      let o = "selectionStart" in (n = e) ? n.selectionStart : 0,
        i = !1;
      o === s.length && (o -= 1, i = !0), e.value = s;
      try {
        e.setSelectionRange && (e.focus(), e.setSelectionRange(o, o), i && (o += 1, setTimeout(() => {
          e.setSelectionRange(o, o);
        }, 0)));
      } catch (t) {}
    } else {
      if (this.config.keypadFix) {
        const t = this.props.rootNode,
          e = document.createElement("input");
        e.style.width = "1px", e.style.height = "1px", e.style.opacity = "0", e.style.fontSize = "18px", t.appendChild(e), e.focus(), t.removeChild(e);
      }
    }
    var n;
    this.destroyTouchendListener(), this.state.registerFieldForIos = !1, this.postMessageToAllIframes({
      fieldType: "webInternalElement",
      fieldClick: !0
    });
  },
  touchstartListener: function (t) {
    this.hasGenuineTouchEvents = !0;
    const e = t.target;
    if (e instanceof HTMLInputElement || e instanceof HTMLSpanElement) {
      this.postMessageToAllIframes({
        fieldType: "webInternalElement",
        checkoutTouchEvent: !0
      });
      const t = e.getAttribute("name") ?? e.getAttribute("data-id");
      this.callbacks.onTouchstartIOS?.({
        fieldType: "webInternalElement",
        name: t
      });
    }
  },
  handleTouchend: function () {
    const e = t(document, "body");
    e.style.cursor = "pointer", n(e, "touchend", this.touchendListener), this.state.registerFieldForIos = !0;
  },
  destroyTouchendListener: function () {
    if (!e.__IS_IOS) return !1;
    const n = t(document, "body");
    return n.style.cursor = "auto", s(n, "touchend", this.touchendListener), !0;
  },
  destroyTouchstartListener: function () {
    return !!e.__IS_IOS && (s(document, "touchstart", this.touchstartListener), !0);
  }
};
export { o as default };
