<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>EDIT_PRODUCT_DETAILS</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  @if (product) {
    <div>
      <lib-gung-tabset [destroyOnHide]="false">
        <lib-gung-tab [title]="'PRODUCT_INFO' | translate">
          <ng-template libGungTabContent>
            @for(template of product.extra.supplierTemplateProperties || []; track (index + '_' + template.path); let index = $index) {
              <div class="row">
                <div class="col-12">

                  <lib-gung-text-input-horizontal
                    [ngModel]="getValueByPath(template.path)"
                    [gungTranslate]="template.translationKey"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="setValueByPath(template.path, $event)" />
                </div>
              </div>
            }

          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'IMAGES' | translate">
          <ng-template libGungTabContent>
            <div class="row">
              @for(image of product.extra.images; track image.s3Uri; let index = $index) {
                <div class="col-12 col-md-6">
                  <div class="mb-3 position-relative">
                    <img [src]="image.s3Uri
                      | gungImageUrl
                        :'type:thumbnail'
                        :'etag:'+image.s3ETag"
                      alt="" class="img-thumbnail"/>
                    <div class="position-absolute top-0 end-0">
                      <button type="button" class="btn btn-danger" (click)="remove(index, 'images')">
                        <i class="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                  </div>
                </div>
              }

              <div class="col-12">
                <lib-gung-input-file
                  accept=".jpg,.jpeg,.png"
                  [multiple]="false"
                  [enableDragDrop]="true"
                  [uploading]="uploading"
                  (onFileSelect)="uploadFile($event, 'images')"/>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'DOCUMENTS' | translate">
          <ng-template libGungTabContent>
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col" width="1%">#</th>
                        <th scope="col" translate>FILE</th>
                        <th scope="col" width="1%" translate>ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      @for(document of product.extra.documents; track document.s3Uri; let index = $index) {
                        <tr>
                          <th scope="row">{{index + 1}}</th>
                          <td>
                            {{document.name || document.s3Uri}}
                            <a target="_blank" [href]="'https://cdn2.gung.io/' + document.s3Uri"><i class="fa-solid fa-download"></i></a>
                          </td>
                          <td>
                            <button type="button" class="btn btn-danger" (click)="remove(index, 'documents')">
                              <i class="fa-solid fa-xmark"></i>
                            </button>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12">
                <lib-gung-input-file
                  accept="*"
                  [multiple]="false"
                  [enableDragDrop]="true"
                  [uploading]="uploading"
                  (onFileSelect)="uploadFile($event, 'documents')"/>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'COMMENTS' | translate">
          <ng-template libGungTabContent>
            <div class="row">
              <div class="col-12">
                <lib-gung-text-area-input
                  gungTranslate="COMMENTS"
                  rowsNumber="5"
                  [(ngModel)]="product.extra.comments"/>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
      </lib-gung-tabset>
    </div>
  }
</div>

<div class="modal-footer">
  <div class="row w-100">
    <div class="col-12">
      <div class="btn-group btn-group-justified w-100">
        <button class="btn btn-sm btn-outline-secondary btn-default" (click)="activeModal.dismiss()" translate>CANCEL</button>
        <button class="btn btn-sm btn-primary btn-default" (click)="save()" translate>SAVE</button>
      </div>
    </div>
  </div>
</div>
