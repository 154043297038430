const e = {
  IDR: 1,
  JPY: 1,
  KRW: 1,
  VND: 1,
  BYR: 1,
  CVE: 1,
  DJF: 1,
  GHC: 1,
  GNF: 1,
  KMF: 1,
  ISK: 1,
  PYG: 1,
  RWF: 1,
  UGX: 1,
  VUV: 1,
  XAF: 1,
  XOF: 1,
  XPF: 1,
  MRO: 10,
  BHD: 1e3,
  IQD: 1e3,
  JOD: 1e3,
  KWD: 1e3,
  OMR: 1e3,
  LYD: 1e3,
  TND: 1e3
};
export { e as default };
