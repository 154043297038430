const e = "threeDS2Fingerprint",
  r = "3DS2Fingerprint_Error",
  t = "callSubmit3DS2Fingerprint_Response",
  n = "threeDS2Challenge",
  p = "3DS2Challenge_Error",
  o = "threeDS2Error",
  i = "threeDS2",
  l = "3DS2",
  S = 'Missing "token" property from threeDS2 action',
  s = "02",
  D = 1e4,
  h = 6e5,
  x = "timeout",
  g = {
    result: {
      transStatus: "U"
    },
    type: "challengeResult",
    errorCode: x
  },
  a = {
    result: {
      threeDSCompInd: "N"
    },
    type: "fingerPrintResult",
    errorCode: x
  },
  u = {
    "01": ["250px", "400px"],
    "02": ["390px", "400px"],
    "03": ["500px", "600px"],
    "04": ["600px", "400px"],
    "05": ["100%", "100%"]
  };
export { h as CHALLENGE_TIMEOUT, g as CHALLENGE_TIMEOUT_REJECT_OBJECT, u as CHALLENGE_WINDOW_SIZES, s as DEFAULT_CHALLENGE_WINDOW_SIZE, a as FAILED_METHOD_STATUS_RESOLVE_OBJECT_TIMEOUT, S as MISSING_TOKEN_IN_ACTION_MSG, n as THREEDS2_CHALLENGE, p as THREEDS2_CHALLENGE_ERROR, o as THREEDS2_ERROR, e as THREEDS2_FINGERPRINT, r as THREEDS2_FINGERPRINT_ERROR, t as THREEDS2_FINGERPRINT_SUBMIT, i as THREEDS2_FULL, l as THREEDS2_NUM, D as THREEDS_METHOD_TIMEOUT, x as TIMEOUT };
