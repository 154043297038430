import { getDecimalAmount as e } from "../../utils/amount-util.js";
import t from "./config.js";
function r({
  allowedAuthMethods: e,
  allowedCardNetworks: r,
  existingPaymentMethodRequired: a = !1
}) {
  return {
    apiVersion: t.API_VERSION,
    apiVersionMinor: t.API_VERSION_MINOR,
    allowedPaymentMethods: [{
      type: "CARD",
      parameters: {
        allowedAuthMethods: e,
        allowedCardNetworks: r
      },
      tokenizationSpecification: {
        type: "PAYMENT_GATEWAY",
        parameters: {}
      }
    }],
    existingPaymentMethodRequired: a
  };
}
function a({
  amount: t,
  countryCode: r = "US",
  totalPriceStatus: a = "FINAL",
  ...i
}) {
  const n = String(e(t.value, t.currency));
  return {
    countryCode: r,
    currencyCode: t.currency,
    totalPrice: n,
    totalPriceStatus: a,
    ...i.transactionInfo
  };
}
function i({
  configuration: e,
  ...r
}, i) {
  return {
    apiVersion: t.API_VERSION,
    apiVersionMinor: t.API_VERSION_MINOR,
    transactionInfo: a({
      countryCode: i,
      ...r
    }),
    merchantInfo: {
      merchantId: e.merchantId,
      merchantName: e.merchantName,
      ...(e.merchantOrigin ? {
        merchantOrigin: e.merchantOrigin
      } : {}),
      ...(e.authJwt ? {
        authJwt: e.authJwt
      } : {})
    },
    allowedPaymentMethods: [{
      type: "CARD",
      tokenizationSpecification: {
        type: "PAYMENT_GATEWAY",
        parameters: {
          gateway: t.GATEWAY,
          gatewayMerchantId: e.gatewayMerchantId
        }
      },
      parameters: {
        allowedAuthMethods: r.allowedAuthMethods,
        allowedCardNetworks: r.allowedCardNetworks,
        assuranceDetailsRequired: r.assuranceDetailsRequired,
        allowPrepaidCards: r.allowPrepaidCards,
        allowCreditCards: r.allowCreditCards,
        billingAddressRequired: r.billingAddressRequired,
        billingAddressParameters: r.billingAddressParameters
      }
    }],
    emailRequired: r.emailRequired,
    shippingAddressRequired: r.shippingAddressRequired,
    shippingAddressParameters: r.shippingAddressParameters,
    shippingOptionRequired: r.shippingOptionRequired,
    shippingOptionParameters: r.shippingOptionParameters,
    callbackIntents: r.callbackIntents
  };
}
export { a as getTransactionInfo, i as initiatePaymentRequest, r as isReadyToPayRequest };
