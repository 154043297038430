const r = (r, e) => Object.keys(r).filter(r => !e.includes(r)).reduce((e, a) => (e[a] = r[a], e), {}),
  e = (r, e, a, l, d) => e.reduce((r, e) => ({
    ...r,
    [e]: r[e] ?? d?.[e] ?? l?.[e] ?? a
  }), r);
function a({
  schema: r,
  defaultData: e,
  processField: a,
  fieldProblems: l
}) {
  const d = r => {
      if (void 0 === e[r]) return {
        valid: !1,
        errors: null,
        data: null,
        fieldProblems: l?.[r] ?? null
      };
      const [d, s] = a({
        key: r,
        value: e[r],
        mode: "blur"
      }, {
        state: {
          data: e
        }
      });
      return {
        valid: s.isValid && !l?.[r] || !1,
        errors: s.hasError() ? s.getError() : null,
        data: d,
        fieldProblems: l?.[r] ?? null
      };
    },
    s = r.reduce((r, e) => {
      const {
        valid: a,
        errors: l,
        data: s,
        fieldProblems: o
      } = d(e);
      return {
        valid: {
          ...r.valid,
          [e]: a
        },
        errors: {
          ...r.errors,
          [e]: l
        },
        data: {
          ...r.data,
          [e]: s
        },
        fieldProblems: {
          ...r.fieldProblems,
          [e]: o
        }
      };
    }, {
      data: {},
      valid: {},
      errors: {},
      fieldProblems: {}
    });
  return {
    schema: r,
    data: s.data,
    valid: s.valid,
    errors: s.errors,
    fieldProblems: s.fieldProblems
  };
}
function l(l) {
  return function (d, {
    type: s,
    key: o,
    value: t,
    mode: i,
    schema: c,
    defaultData: u,
    formValue: n,
    selectedSchema: m,
    fieldProblems: v
  }) {
    const f = m || d.schema;
    switch (s) {
      case "setData":
        return {
          ...d,
          data: {
            ...d.data,
            [o]: t
          }
        };
      case "setValid":
        return {
          ...d,
          valid: {
            ...d.valid,
            [o]: t
          }
        };
      case "setErrors":
        return {
          ...d,
          errors: {
            ...d.errors,
            [o]: t
          }
        };
      case "setFieldProblems":
        return d?.schema?.reduce((r, e) => ({
          ...r,
          fieldProblems: {
            ...d.fieldProblems,
            [e]: v?.[e] ?? null
          },
          valid: {
            ...d.valid,
            [e]: d.valid?.[e] && !v[e]
          }
        }), d) ?? d;
      case "updateField":
        {
          const [r, e] = l({
              key: o,
              value: t,
              mode: i
            }, {
              state: d
            }),
            a = d.data[o],
            s = {
              ...d.fieldProblems
            };
          return a !== r && (s[o] = null), {
            ...d,
            data: {
              ...d.data,
              [o]: r
            },
            errors: {
              ...d.errors,
              [o]: e.hasError() ? e.getError() : null
            },
            valid: {
              ...d.valid,
              [o]: e.isValid && !s[o] || !1
            },
            fieldProblems: s
          };
        }
      case "mergeForm":
        {
          const r = {
            ...d,
            data: {
              ...d.data,
              ...n.data
            },
            errors: {
              ...d.errors,
              ...n.errors
            },
            valid: {
              ...d.valid,
              ...n.valid
            },
            fieldProblems: {
              ...d.fieldProblems,
              ...n.fieldProblems
            }
          };
          return r.valid && (r.isValid = Object.values(r.valid).every(r => r)), r;
        }
      case "setSchema":
        {
          const s = a({
              schema: c,
              defaultData: u,
              processField: l,
              fieldProblems: v
            }),
            o = d.schema.filter(r => !c.includes(r)),
            t = c.filter(r => !d.schema.includes(r)),
            i = {
              data: r(d.data, t),
              errors: r(d.errors, t),
              valid: r(d.valid, t)
            },
            n = e(r(d.data, o), t, null, s.data, d.local?.data),
            m = e(r(d.valid, o), t, !1, s.valid, d.local?.valid),
            f = e(r(d.errors, o), t, null, s.errors, d.local?.errors);
          return {
            ...d,
            schema: c,
            data: n,
            valid: m,
            errors: f,
            local: i
          };
        }
      case "validateForm":
        {
          const r = f.reduce((r, e) => {
            const [, a] = l({
              key: e,
              value: d.data[e],
              mode: "blur"
            }, {
              state: d
            });
            return {
              valid: {
                ...r.valid,
                [e]: a.isValid && !d.fieldProblems[e] || !1
              },
              errors: {
                ...r.errors,
                [e]: a.hasError(!0) ? a.getError(!0) : null
              }
            };
          }, {
            valid: d.valid,
            errors: d.errors
          });
          return {
            ...d,
            valid: r.valid,
            errors: r.errors
          };
        }
      default:
        throw new Error("Undefined useForm action");
    }
  };
}
export { l as getReducer, a as init };
