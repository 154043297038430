const e = {
  isExpress: !1,
  existingPaymentMethodRequired: !1,
  buttonColor: "default",
  buttonType: "buy",
  buttonSizeMode: "fill",
  configuration: {
    gatewayMerchantId: "",
    merchantId: "",
    merchantName: ""
  },
  amount: {
    value: 0,
    currency: "USD"
  },
  totalPriceStatus: "FINAL",
  onClick: e => e(),
  allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
  allowedCardNetworks: ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"],
  allowCreditCards: !0,
  allowPrepaidCards: !0,
  billingAddressRequired: !1,
  billingAddressParameters: void 0,
  assuranceDetailsRequired: !1,
  emailRequired: !1,
  shippingAddressRequired: !1,
  shippingAddressParameters: void 0,
  shippingOptionRequired: !1,
  shippingOptionParameters: void 0,
  callbackIntents: []
};
export { e as default };
