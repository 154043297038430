<div *ngIf="loading">
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</div>
<div class="d-flex justify-content-center align-items-center">
  <div class="w-50" id="adyen-container">
    <div #hook id="drop-in"></div>
  </div>
</div>
<div *ngIf="payByLink && paymentLink" class="card mb-2 mt-3 bg-light shadow-sm border">
  <div class="card-body">
    <h4>{{ 'ADYEN_PAYMENT_URL' | translate }}</h4>
    <a [href]="paymentLink.url" target="_blank">{{ paymentLink.url }}</a>
    <p>{{ 'ADYEN_PAYMENT_LINK_DESCRIPTION' | translate }}</p>
    <p class="mb-1">
      <span> {{ 'ADYEN_TOTAL_PRICE' | translate }}</span
      ><span> - {{ paymentLink.amount.value / 100 }} {{ paymentLink.amount.currency }}</span>
    </p>
    <p>
      <span>{{ 'REFERENCE' | translate }}</span> - {{ paymentLink.reference }}
    </p>
    <p class="mb-1">
      <span>{{ 'STATUS' | translate }}</span
      ><span [ngClass]="'link-' + paymentLink.status"> - {{ paymentLink.status }}</span>
    </p>
    <p>
      <span> {{ 'EXPIRE_TIME' | translate }}</span> - {{ paymentLink.expiresAt | date: 'd MMM yyyy, HH:mm:ss' }}
    </p>

    <span>
      <i *ngIf="isPolling" class="fa-light fa-spinner-third fa-spin fast-spin mr-2 mb-2"></i>
      <i *ngIf="!isPolling && currentStatus === 'COMPLETED'" class="fa-light fa-circle-check mr-2 mb-2"></i>
      <span>{{ currentStatus | translate }}</span>
    </span>
    <div class="polling-progress-bar mb-3">
      <div class="inner-polling-progress-bar" [style.width.%]="currentStatusPercentage"></div>
    </div>

    <div *ngIf="allowCancellationOfLink">
      <button
        class="btn btn-danger"
        (click)="cancelPaymentLink()"
        [disabled]="paymentLink.status == 'EXPIRED' || paymentLink.status == 'COMPLETED'"
      >
        {{ 'CANCEL_LINK' | translate }}
      </button>
    </div>
  </div>
</div>
<div *ngIf="errorMessage">
  <div class="alert alert-danger" role="alert">
    <span>{{ errorMessage | translate }}</span>
  </div>
</div>
