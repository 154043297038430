<div class="row" [ngClass]="{ 'mb-4': mappedData && mappedData.length > 0 }">
  <div *ngFor="let data of mappedData" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
    <div class="card product-card">
      <div class="card-body">
        <a [routerLink]="['./', data.product.id]" class="border-0">
        <div class="image-wrapper">
          <img
            *ngIf="data.product.extra.images && data.product.extra.images[0] as image; else noimage"
            class="img-fluid mx-auto d-block"
            [src]="image.s3Uri | gungImageUrl
              :'type:list'
              :'etag:'+image.s3ETag
          "
            alt="{{ image.description }}"
          />
          <ng-template #noimage>
            <img
              class="img-fluid w-100"
              [src]="'images/no-image.jpg' | gungImageUrl"
              alt="NO IMAGE"
              class="img-fluid mx-auto d-block"
            />
          </ng-template>
        </div>
        </a>
        <div class="product-text px-3">
          <div>{{ data.product.id }}</div>
          <div class="text-truncate" translate>{{ data.product.name }}</div>
          <div class="d-block" *ngFor="let value of data.product.dynamicCollumnsValues">{{ value }}</div>
          <div translate *ngIf="data.price" class="font-weight-bold">
            <lib-price [price]="data.price" type="NET"></lib-price>
          </div>
          <lib-availability [availability]="data.availability" [isSales]="isSales" *ngIf="includeAvailability" (click)="$event.preventDefault(); $event.stopPropagation()">
          </lib-availability>
          <div class="product-card-button py-2 px-4">
            <div *ngIf="data.product.productType === 'concept'; else buybtn">
              <a
                role="button" class="border-0"
                translate
                class="btn btn-sm btn-default btn-block btn-primary"
                [routerLink]="['./', data.product.id]"
              >
                SHOW_PRODUCTS
              </a>
            </div>
            <ng-template #buybtn>
              <lib-buy-btn
                name="buy-btn-package-size"
                [id]="data.product?.id"
                [product]="data.product"
                [minimumOrderQuantity]="data.product?.packageSize"
                [stepAmount]="data.product?.packageSize"
              ></lib-buy-btn>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="findError" class="col-12 mb-2 text-center">
    {{ errorMessage }}
  </div>
</div>
