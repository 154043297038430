const t = {
  isExpress: !1,
  amount: {
    currency: "USD",
    value: 0
  },
  totalPriceStatus: "final",
  initiative: "web",
  merchantCapabilities: ["supports3DS"],
  supportedNetworks: ["amex", "discover", "masterCard", "visa"],
  buttonType: "plain",
  buttonColor: "black",
  onClick: t => t()
};
export { t as default };
