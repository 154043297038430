import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsersService } from '../../../services/users/users.service';
import { User } from '../../../state/auth/types';
import { BackendFeatureService } from '../../../services/backend-feature.service';
import { PasswordPolicyService } from '../../../services/password-policy.service';
import { first, of, switchMap } from 'rxjs';
import { ConfigurationsService } from '../../../services/configurations.service';

interface Alert {
  type: string;
  message: string;
  invalidFields?: string[];
}

@Component({
  selector: 'lib-create-user-modal',
  templateUrl: './create-user-modal.component.html',
  styleUrls: ['./create-user-modal.component.css']
})
export class CreateUserModalComponent implements OnInit {
  userForm: FormGroup;
  alertMessage: Alert;
  loading = false;
  userToCopyFrom: User;
  @Input()
  createUserFrom?: User; // used in account request create user

  allowSendEmailToUser = this.usersService.allowSendEmailToUser; // Only for fortnox for now
  sendEmail = false;

  password: string;
  passwordRequirements: any;

  constructor(
    protected formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    protected usersService: UsersService,
    protected backendFeatureService: BackendFeatureService,
    protected passwordPolicyService: PasswordPolicyService,
    protected configurationsService: ConfigurationsService
  ) {}

  ngOnInit() {
    let managedCompanyIdsInput = '';
    let assortmentInput = '';

    if (!!this.userToCopyFrom) {
      assortmentInput = this.userToCopyFrom.assortment;
      if (this.userToCopyFrom.managedCompanyIds.length > 0) {
        managedCompanyIdsInput = this.userToCopyFrom.managedCompanyIds[0];
      }
    }

    const featureId = 'passwordPolicies';
    this.backendFeatureService
      .isActivated(featureId)
      .pipe(
        first(),
        switchMap(isActivated => {
          if (isActivated) {
            return this.passwordPolicyService.getPasswordRequirements();
          } else {
            return of({});
          }
        })
      )
      .subscribe(requirements => {
        this.passwordRequirements = requirements;
      });

    this.initForm(assortmentInput, managedCompanyIdsInput);
    
  }

  initForm(assortmentInput: string, managedCompanyIdsInput: string) {
    this.userForm = this.formBuilder.group({
      username: this.formBuilder.control((this.createUserFrom && this.createUserFrom.username) || '', [
        Validators.required
      ]),
      password: this.formBuilder.control(''),
      name: this.formBuilder.control((this.createUserFrom && this.createUserFrom.name) || '', []),
      email: this.formBuilder.control(this.createUserFrom?.email || '', [Validators.required, Validators.email]),
      phone: this.formBuilder.control('', []),
      managedCompanyIds: this.formBuilder.control(
        (this.createUserFrom && this.createUserFrom.managedCompanyIds) || managedCompanyIdsInput
      ),
      assortment: this.formBuilder.control(assortmentInput, [Validators.required])
    });
  }

  onAdd() {
    if (!this.userForm.valid) {
      const invalidFields = [];
      for (const key of Object.keys(this.userForm.controls)) {
        const field = this.userForm.controls[key];
        if (!field.valid) {
          invalidFields.push(key.toUpperCase());
        }
      }
      this.alertMessage = {
        type: 'danger',
        message: 'FORM_CONTAINS_ERROR',
        invalidFields
      };
      return;
    }

    this.loading = true;

    if (this.userToCopyFrom) {
      const newExtraCopyFrom: { [s: string]: any } = {};
      const keysExclude: string[] = ['latestLogin', 'loginData', '_successLatestLoginDays', '_failedLatestLoginDays', '_isCurrentlyLoggedIn'];
      for (const key of Object.keys(this.userToCopyFrom?.extra)) {
        if (!keysExclude.includes(key)) {
          newExtraCopyFrom[key] = this.userToCopyFrom.extra[key];
        }
      }

      this.userToCopyFrom = {
        ...this.userToCopyFrom,
        extra: newExtraCopyFrom
      };
    }

    let userForm: User;
    if (!!this.userToCopyFrom) {
      userForm = {
        ...this.userToCopyFrom,
        ...this.userForm.getRawValue(),
        managedCompanyIds: [this.userForm.getRawValue().managedCompanyIds]
      };
    } else {
      userForm = {
        ...this.userForm.getRawValue(),
        managedCompanyIds: [this.userForm.getRawValue().managedCompanyIds]
      };
    }

    this.usersService.createUser(userForm).subscribe(
      user => {
        this.loading = false;
        this.userForm.reset();
        this.activeModal.close(userForm);
        if (!!this.sendEmail) {
          const domain = location.origin;
          this.usersService.sendWelcomeUserMessage(user.username, domain).subscribe();
        }
      },
      error => {
        this.loading = false;
        this.alertMessage = {
          type: 'danger',
          message: error.error.message
        };
      }
    );
  }

  isValid(event) {
    if (!!this.password && event) {
      this.userForm.get('password').setValue(this.password);
    }
  }
}
