import { useState as r, useEffect as s } from "../../../../external/preact/hooks/dist/hooks.js";
import e from "../../../../core/Errors/useSRPanelContext.js";
import { mapFieldKey as o, handlePartialAddressMode as t, lookupBlurBasedErrors as i } from "./utils.js";
import { usePrevious as a } from "../../../../utils/hookUtils.js";
import { ERROR_ACTION_BLUR_SCENARIO as l, ERROR_ACTION_FOCUS_FIELD as n } from "../../../../core/Errors/constants.js";
import { setFocusOnFirstField as c } from "./handlers.js";
import { getArrayDifferences as u } from "../../../../utils/arrayUtils.js";
const d = ({
  errors: d,
  props: p,
  isValidating: f,
  retrieveLayout: m,
  specifications: g,
  billingAddress: S,
  sfp: y
}) => {
  const {
      setSRMessagesFromObjects: b,
      setSRMessagesFromStrings: j,
      clearSRPanel: R,
      shouldMoveFocusSR: h
    } = e(),
    E = b?.({
      fieldTypeMappingFn: o
    }),
    F = t(p.billingAddressMode),
    [M, k] = r(null),
    L = a(M),
    A = y.current?.mapErrorsToValidationRuleResult(),
    T = {
      ...d,
      ...A
    };
  return s(() => {
    try {
      const {
          billingAddress: r,
          ...s
        } = T,
        e = {
          ...s,
          ...r
        },
        o = E?.({
          errors: e,
          isValidating: f.current,
          layout: m(),
          countrySpecificLabels: g.getAddressLabelsForCountry(S?.country) ?? F?.default?.labels
        }),
        t = o?.currentErrorsSortedByLayout;
      switch (k(t), o?.action) {
        case n:
          h && c(f.current, y, o?.fieldToFocus), setTimeout(() => {
            f.current = !1;
          }, 300);
          break;
        case l:
          {
            const r = u(t, L, "field"),
              s = r?.[0];
            if (s) {
              const r = i(s.errorCode) ? s.errorMessage : null;
              j(r);
            } else R();
            break;
          }
      }
    } catch (r) {}
  }, [d]), {
    sortedErrorList: M,
    previousSortedErrors: L,
    clearSRPanel: R
  };
};
export { d as default };
