import { getDecimalAmount as t } from "../../utils/amount-util.js";
const e = e => {
  const {
      countryCode: i,
      companyName: n,
      amount: o,
      ...r
    } = e,
    a = (e => String(t(e.value, e.currency)))(o);
  return i || console.warn("ApplePay - Make sure to set the countryCode in the AdyenCheckout configuration or in the Checkout Session creation"), {
    countryCode: i,
    currencyCode: o.currency,
    total: {
      label: r.totalPriceLabel,
      amount: a,
      type: r.totalPriceStatus
    },
    lineItems: r.lineItems,
    shippingMethods: r.shippingMethods,
    shippingType: r.shippingType,
    recurringPaymentRequest: r.recurringPaymentRequest,
    merchantCapabilities: r.merchantCapabilities,
    supportedCountries: r.supportedCountries,
    supportedNetworks: r.supportedNetworks,
    requiredShippingContactFields: r.requiredShippingContactFields,
    requiredBillingContactFields: r.requiredBillingContactFields,
    billingContact: r.billingContact,
    shippingContact: r.shippingContact,
    applicationData: r.applicationData
  };
};
export { e as default, e as preparePaymentRequest };
