import t from "../../../utils/promiseTimeout.js";
const e = ["androidpay", "samsungpay", "clicktopay"],
  i = t => !e.includes(t.type),
  r = t => !!t,
  s = e => {
    const i = e.map(e => {
      const {
        promise: i
      } = t(5e3, e.isAvailable(), {});
      return i;
    });
    return Promise.allSettled(i).then(t => e.filter((e, i) => "fulfilled" === t[i].status));
  },
  l = t => {
    if (!t.some(t => "upi" === t?.type)) return t;
    const e = ["upi_qr", "upi_collect", "upi_intent"];
    return t.filter(t => !e.includes(t?.type));
  };
export { e as UNSUPPORTED_PAYMENT_METHODS, s as filterAvailable, r as filterPresent, i as filterUnsupportedPaymentMethod, l as optionallyFilterUpiSubTxVariants };
