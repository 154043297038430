import { httpPost as e } from "../../../../core/Services/http.js";
import { DEFAULT_CARD_GROUP_TYPES as r } from "../lib/constants.js";
import { SF_ErrorCodes as n } from "../../../../core/Errors/constants.js";
var s = s => {
  let o = null;
  return t => {
    if (!1 !== s.props.doBinLookup) {
      if (t.encryptedBin && s.props.clientKey) o = t.uuid, e({
        loadingContext: s.props.loadingContext,
        path: `v3/bin/binLookup?token=${s.props.clientKey}`
      }, {
        type: s.props.brand,
        supportedBrands: s.props.brands || r,
        encryptedBin: t.encryptedBin,
        requestId: t.uuid
      }).then(e => {
        if (e?.requestId === o) {
          if (e.brands?.length) {
            const o = e.brands.reduce((e, r) => (e.detectedBrands.push(r.brand), e.paymentMethodVariants.push(r.paymentMethodVariant), !0 === r.supported ? (e.supportedBrands.push(r), e) : e), {
              supportedBrands: [],
              detectedBrands: [],
              paymentMethodVariants: []
            });
            if (o.supportedBrands.length) return s.processBinLookupResponse({
              issuingCountryCode: e.issuingCountryCode,
              supportedBrands: o.supportedBrands,
              ...(e.showSocialSecurityNumber ? {
                showSocialSecurityNumber: e.showSocialSecurityNumber
              } : {})
            }), void s.onBinLookup({
              type: t.type,
              detectedBrands: o.detectedBrands,
              supportedBrands: o.supportedBrands.map(e => e.brand),
              paymentMethodVariants: o.paymentMethodVariants,
              supportedBrandsRaw: o.supportedBrands,
              brands: s.props.brands || r,
              issuingCountryCode: e.issuingCountryCode
            });
            if (o.detectedBrands.length) {
              const e = {
                type: "card",
                fieldType: "encryptedCardNumber",
                error: n.ERROR_MSG_UNSUPPORTED_CARD_ENTERED,
                detectedBrands: o.detectedBrands
              };
              return s.handleUnsupportedCard(e), void s.onBinLookup({
                type: t.type,
                detectedBrands: o.detectedBrands,
                supportedBrands: null,
                paymentMethodVariants: o.paymentMethodVariants,
                brands: s.props.brands || r
              });
            }
          } else s.onBinLookup({
            type: t.type,
            detectedBrands: null,
            supportedBrands: null,
            paymentMethodVariants: null,
            brands: s.props.brands || r
          }), s.processBinLookupResponse({}, !0);
        } else e?.requestId || s.props.onError(e || {
          errorType: "binLookup",
          message: "unknownError"
        });
      });else if (o) {
        s.processBinLookupResponse(null, !0), o = null;
        const e = {
          type: "card",
          fieldType: "encryptedCardNumber",
          error: ""
        };
        s.handleUnsupportedCard(e), s.onBinLookup({
          isReset: !0
        });
      }
      s.props.onBinValue && s.props.onBinValue(t);
    } else s.props.onBinValue && s.props.onBinValue(t);
  };
};
export { s as default };
