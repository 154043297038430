var e,
  n,
  t,
  _,
  o,
  l,
  r,
  u,
  i,
  s,
  c,
  f = {},
  p = [],
  a = /acit|ex(?:s|g|n|p|$)|rph|grid|ows|mnc|ntw|ine[ch]|zoo|^ord|itera/i,
  h = Array.isArray;
function d(e, n) {
  for (var t in n) e[t] = n[t];
  return e;
}
function v(e) {
  var n = e.parentNode;
  n && n.removeChild(e);
}
function y(n, t, _) {
  var o,
    l,
    r,
    u = {};
  for (r in t) "key" == r ? o = t[r] : "ref" == r ? l = t[r] : u[r] = t[r];
  if (arguments.length > 2 && (u.children = arguments.length > 3 ? e.call(arguments, 2) : _), "function" == typeof n && null != n.defaultProps) for (r in n.defaultProps) void 0 === u[r] && (u[r] = n.defaultProps[r]);
  return m(n, u, o, l, null);
}
function m(e, _, o, l, r) {
  var u = {
    type: e,
    props: _,
    key: o,
    ref: l,
    __k: null,
    __: null,
    __b: 0,
    __e: null,
    __d: void 0,
    __c: null,
    constructor: void 0,
    __v: null == r ? ++t : r,
    __i: -1,
    __u: 0
  };
  return null == r && null != n.vnode && n.vnode(u), u;
}
function g(e) {
  return e.children;
}
function k(e, n) {
  this.props = e, this.context = n;
}
function b(e, n) {
  if (null == n) return e.__ ? b(e.__, e.__i + 1) : null;
  for (var t; n < e.__k.length; n++) if (null != (t = e.__k[n]) && null != t.__e) return t.__e;
  return "function" == typeof e.type ? b(e) : null;
}
function w(e) {
  var n, t;
  if (null != (e = e.__) && null != e.__c) {
    for (e.__e = e.__c.base = null, n = 0; n < e.__k.length; n++) if (null != (t = e.__k[n]) && null != t.__e) {
      e.__e = e.__c.base = t.__e;
      break;
    }
    return w(e);
  }
}
function C(e) {
  (!e.__d && (e.__d = !0) && _.push(e) && !x.__r++ || o !== n.debounceRendering) && ((o = n.debounceRendering) || l)(x);
}
function x() {
  var e, t, o, l, u, i, s, c;
  for (_.sort(r); e = _.shift();) e.__d && (t = _.length, l = void 0, i = (u = (o = e).__v).__e, s = [], c = [], o.__P && ((l = d({}, u)).__v = u.__v + 1, n.vnode && n.vnode(l), L(o.__P, l, u, o.__n, o.__P.namespaceURI, 32 & u.__u ? [i] : null, s, null == i ? b(u) : i, !!(32 & u.__u), c), l.__v = u.__v, l.__.__k[l.__i] = l, F(s, l, c), l.__e != i && w(l)), _.length > t && _.sort(r));
  x.__r = 0;
}
function P(e, n, t, _, o, l, r, u, i, s, c) {
  var a,
    h,
    d,
    v,
    y,
    m = _ && _.__k || p,
    g = n.length;
  for (t.__d = i, S(t, n, m), i = t.__d, a = 0; a < g; a++) null != (d = t.__k[a]) && "boolean" != typeof d && "function" != typeof d && (h = -1 === d.__i ? f : m[d.__i] || f, d.__i = a, L(e, d, h, o, l, r, u, i, s, c), v = d.__e, d.ref && h.ref != d.ref && (h.ref && A(h.ref, null, d), c.push(d.ref, d.__c || v, d)), null == y && null != v && (y = v), 65536 & d.__u || h.__k === d.__k ? (i && "string" == typeof d.type && !e.contains(i) && (i = b(h)), i = U(d, i, e)) : "function" == typeof d.type && void 0 !== d.__d ? i = d.__d : v && (i = v.nextSibling), d.__d = void 0, d.__u &= -196609);
  t.__d = i, t.__e = y;
}
function S(e, n, t) {
  var _,
    o,
    l,
    r,
    u,
    i = n.length,
    s = t.length,
    c = s,
    f = 0;
  for (e.__k = [], _ = 0; _ < i; _++) r = _ + f, null != (o = e.__k[_] = null == (o = n[_]) || "boolean" == typeof o || "function" == typeof o ? null : "string" == typeof o || "number" == typeof o || "bigint" == typeof o || o.constructor == String ? m(null, o, null, null, null) : h(o) ? m(g, {
    children: o
  }, null, null, null) : void 0 === o.constructor && o.__b > 0 ? m(o.type, o.props, o.key, o.ref ? o.ref : null, o.__v) : o) ? (o.__ = e, o.__b = e.__b + 1, u = D(o, t, r, c), o.__i = u, l = null, -1 !== u && (c--, (l = t[u]) && (l.__u |= 131072)), null == l || null === l.__v ? (-1 == u && f--, "function" != typeof o.type && (o.__u |= 65536)) : u !== r && (u == r - 1 ? f = u - r : u == r + 1 ? f++ : u > r ? c > i - r ? f += u - r : f-- : u < r && f++, u !== _ + f && (o.__u |= 65536))) : (l = t[r]) && null == l.key && l.__e && !(131072 & l.__u) && (l.__e == e.__d && (e.__d = b(l)), H(l, l, !1), t[r] = null, c--);
  if (c) for (_ = 0; _ < s; _++) null != (l = t[_]) && !(131072 & l.__u) && (l.__e == e.__d && (e.__d = b(l)), H(l, l));
}
function U(e, n, t) {
  var _, o;
  if ("function" == typeof e.type) {
    for (_ = e.__k, o = 0; _ && o < _.length; o++) _[o] && (_[o].__ = e, n = U(_[o], n, t));
    return n;
  }
  e.__e != n && (t.insertBefore(e.__e, n || null), n = e.__e);
  do {
    n = n && n.nextSibling;
  } while (null != n && 8 === n.nodeType);
  return n;
}
function T(e, n) {
  return n = n || [], null == e || "boolean" == typeof e || (h(e) ? e.some(function (e) {
    T(e, n);
  }) : n.push(e)), n;
}
function D(e, n, t, _) {
  var o = e.key,
    l = e.type,
    r = t - 1,
    u = t + 1,
    i = n[t];
  if (null === i || i && o == i.key && l === i.type && !(131072 & i.__u)) return t;
  if (_ > (null == i || 131072 & i.__u ? 0 : 1)) for (; r >= 0 || u < n.length;) {
    if (r >= 0) {
      if ((i = n[r]) && !(131072 & i.__u) && o == i.key && l === i.type) return r;
      r--;
    }
    if (u < n.length) {
      if ((i = n[u]) && !(131072 & i.__u) && o == i.key && l === i.type) return u;
      u++;
    }
  }
  return -1;
}
function M(e, n, t) {
  "-" === n[0] ? e.setProperty(n, null == t ? "" : t) : e[n] = null == t ? "" : "number" != typeof t || a.test(n) ? t : t + "px";
}
function E(e, n, t, _, o) {
  var l;
  e: if ("style" === n) {
    if ("string" == typeof t) e.style.cssText = t;else {
      if ("string" == typeof _ && (e.style.cssText = _ = ""), _) for (n in _) t && n in t || M(e.style, n, "");
      if (t) for (n in t) _ && t[n] === _[n] || M(e.style, n, t[n]);
    }
  } else if ("o" === n[0] && "n" === n[1]) l = n !== (n = n.replace(/(PointerCapture)$|Capture$/i, "$1")), n = n.toLowerCase() in e || "onFocusOut" === n || "onFocusIn" === n ? n.toLowerCase().slice(2) : n.slice(2), e.l || (e.l = {}), e.l[n + l] = t, t ? _ ? t.u = _.u : (t.u = u, e.addEventListener(n, l ? s : i, l)) : e.removeEventListener(n, l ? s : i, l);else {
    if ("http://www.w3.org/2000/svg" == o) n = n.replace(/xlink(H|:h)/, "h").replace(/sName$/, "s");else if ("width" != n && "height" != n && "href" != n && "list" != n && "form" != n && "tabIndex" != n && "download" != n && "rowSpan" != n && "colSpan" != n && "role" != n && "popover" != n && n in e) try {
      e[n] = null == t ? "" : t;
      break e;
    } catch (e) {}
    "function" == typeof t || (null == t || !1 === t && "-" !== n[4] ? e.removeAttribute(n) : e.setAttribute(n, "popover" == n && 1 == t ? "" : t));
  }
}
function W(e) {
  return function (t) {
    if (this.l) {
      var _ = this.l[t.type + e];
      if (null == t.t) t.t = u++;else if (t.t < _.u) return;
      return _(n.event ? n.event(t) : t);
    }
  };
}
function L(e, t, _, o, l, r, u, i, s, c) {
  var f,
    p,
    a,
    v,
    y,
    m,
    b,
    w,
    C,
    x,
    S,
    U,
    T,
    D,
    M,
    E,
    W = t.type;
  if (void 0 !== t.constructor) return null;
  128 & _.__u && (s = !!(32 & _.__u), r = [i = t.__e = _.__e]), (f = n.__b) && f(t);
  e: if ("function" == typeof W) try {
    if (w = t.props, C = "prototype" in W && W.prototype.render, x = (f = W.contextType) && o[f.__c], S = f ? x ? x.props.value : f.__ : o, _.__c ? b = (p = t.__c = _.__c).__ = p.__E : (C ? t.__c = p = new W(w, S) : (t.__c = p = new k(w, S), p.constructor = W, p.render = I), x && x.sub(p), p.props = w, p.state || (p.state = {}), p.context = S, p.__n = o, a = p.__d = !0, p.__h = [], p._sb = []), C && null == p.__s && (p.__s = p.state), C && null != W.getDerivedStateFromProps && (p.__s == p.state && (p.__s = d({}, p.__s)), d(p.__s, W.getDerivedStateFromProps(w, p.__s))), v = p.props, y = p.state, p.__v = t, a) C && null == W.getDerivedStateFromProps && null != p.componentWillMount && p.componentWillMount(), C && null != p.componentDidMount && p.__h.push(p.componentDidMount);else {
      if (C && null == W.getDerivedStateFromProps && w !== v && null != p.componentWillReceiveProps && p.componentWillReceiveProps(w, S), !p.__e && (null != p.shouldComponentUpdate && !1 === p.shouldComponentUpdate(w, p.__s, S) || t.__v === _.__v)) {
        for (t.__v !== _.__v && (p.props = w, p.state = p.__s, p.__d = !1), t.__e = _.__e, t.__k = _.__k, t.__k.forEach(function (e) {
          e && (e.__ = t);
        }), U = 0; U < p._sb.length; U++) p.__h.push(p._sb[U]);
        p._sb = [], p.__h.length && u.push(p);
        break e;
      }
      null != p.componentWillUpdate && p.componentWillUpdate(w, p.__s, S), C && null != p.componentDidUpdate && p.__h.push(function () {
        p.componentDidUpdate(v, y, m);
      });
    }
    if (p.context = S, p.props = w, p.__P = e, p.__e = !1, T = n.__r, D = 0, C) {
      for (p.state = p.__s, p.__d = !1, T && T(t), f = p.render(p.props, p.state, p.context), M = 0; M < p._sb.length; M++) p.__h.push(p._sb[M]);
      p._sb = [];
    } else do {
      p.__d = !1, T && T(t), f = p.render(p.props, p.state, p.context), p.state = p.__s;
    } while (p.__d && ++D < 25);
    p.state = p.__s, null != p.getChildContext && (o = d(d({}, o), p.getChildContext())), C && !a && null != p.getSnapshotBeforeUpdate && (m = p.getSnapshotBeforeUpdate(v, y)), P(e, h(E = null != f && f.type === g && null == f.key ? f.props.children : f) ? E : [E], t, _, o, l, r, u, i, s, c), p.base = t.__e, t.__u &= -161, p.__h.length && u.push(p), b && (p.__E = p.__ = null);
  } catch (e) {
    t.__v = null, s || null != r ? (t.__e = i, t.__u |= s ? 160 : 32, r[r.indexOf(i)] = null) : (t.__e = _.__e, t.__k = _.__k), n.__e(e, t, _);
  } else null == r && t.__v === _.__v ? (t.__k = _.__k, t.__e = _.__e) : t.__e = N(_.__e, t, _, o, l, r, u, s, c);
  (f = n.diffed) && f(t);
}
function F(e, t, _) {
  t.__d = void 0;
  for (var o = 0; o < _.length; o++) A(_[o], _[++o], _[++o]);
  n.__c && n.__c(t, e), e.some(function (t) {
    try {
      e = t.__h, t.__h = [], e.some(function (e) {
        e.call(t);
      });
    } catch (e) {
      n.__e(e, t.__v);
    }
  });
}
function N(n, t, _, o, l, r, u, i, s) {
  var c,
    p,
    a,
    d,
    y,
    m,
    g,
    k = _.props,
    w = t.props,
    C = t.type;
  if ("svg" === C ? l = "http://www.w3.org/2000/svg" : "math" === C ? l = "http://www.w3.org/1998/Math/MathML" : l || (l = "http://www.w3.org/1999/xhtml"), null != r) for (c = 0; c < r.length; c++) if ((y = r[c]) && "setAttribute" in y == !!C && (C ? y.localName === C : 3 === y.nodeType)) {
    n = y, r[c] = null;
    break;
  }
  if (null == n) {
    if (null === C) return document.createTextNode(w);
    n = document.createElementNS(l, C, w.is && w), r = null, i = !1;
  }
  if (null === C) k === w || i && n.data === w || (n.data = w);else {
    if (r = r && e.call(n.childNodes), k = _.props || f, !i && null != r) for (k = {}, c = 0; c < n.attributes.length; c++) k[(y = n.attributes[c]).name] = y.value;
    for (c in k) if (y = k[c], "children" == c) ;else if ("dangerouslySetInnerHTML" == c) a = y;else if ("key" !== c && !(c in w)) {
      if ("value" == c && "defaultValue" in w || "checked" == c && "defaultChecked" in w) continue;
      E(n, c, null, y, l);
    }
    for (c in w) y = w[c], "children" == c ? d = y : "dangerouslySetInnerHTML" == c ? p = y : "value" == c ? m = y : "checked" == c ? g = y : "key" === c || i && "function" != typeof y || k[c] === y || E(n, c, y, k[c], l);
    if (p) i || a && (p.__html === a.__html || p.__html === n.innerHTML) || (n.innerHTML = p.__html), t.__k = [];else if (a && (n.innerHTML = ""), P(n, h(d) ? d : [d], t, _, o, "foreignObject" === C ? "http://www.w3.org/1999/xhtml" : l, r, u, r ? r[0] : _.__k && b(_, 0), i, s), null != r) for (c = r.length; c--;) null != r[c] && v(r[c]);
    i || (c = "value", void 0 !== m && (m !== n[c] || "progress" === C && !m || "option" === C && m !== k[c]) && E(n, c, m, k[c], l), c = "checked", void 0 !== g && g !== n[c] && E(n, c, g, k[c], l));
  }
  return n;
}
function A(e, t, _) {
  try {
    "function" == typeof e ? e(t) : e.current = t;
  } catch (e) {
    n.__e(e, _);
  }
}
function H(e, t, _) {
  var o, l;
  if (n.unmount && n.unmount(e), (o = e.ref) && (o.current && o.current !== e.__e || A(o, null, t)), null != (o = e.__c)) {
    if (o.componentWillUnmount) try {
      o.componentWillUnmount();
    } catch (e) {
      n.__e(e, t);
    }
    o.base = o.__P = null;
  }
  if (o = e.__k) for (l = 0; l < o.length; l++) o[l] && H(o[l], t, _ || "function" != typeof e.type);
  _ || null == e.__e || v(e.__e), e.__c = e.__ = e.__e = e.__d = void 0;
}
function I(e, n, t) {
  return this.constructor(e, t);
}
function R(t, _, o) {
  var l, r, u, i;
  n.__ && n.__(t, _), r = (l = "function" == typeof o) ? null : _.__k, u = [], i = [], L(_, t = (!l && o || _).__k = y(g, null, [t]), r || f, f, _.namespaceURI, !l && o ? [o] : r ? null : _.firstChild ? e.call(_.childNodes) : null, u, !l && o ? o : r ? r.__e : _.firstChild, l, i), F(u, t, i);
}
function $(n, t, _) {
  var o,
    l,
    r,
    u,
    i = d({}, n.props);
  for (r in n.type && n.type.defaultProps && (u = n.type.defaultProps), t) "key" == r ? o = t[r] : "ref" == r ? l = t[r] : i[r] = void 0 === t[r] && void 0 !== u ? u[r] : t[r];
  return arguments.length > 2 && (i.children = arguments.length > 3 ? e.call(arguments, 2) : _), m(n.type, i, o || n.key, l || n.ref, null);
}
function O(e, n) {
  var t = {
    __c: n = "__cC" + c++,
    __: e,
    Consumer: function (e, n) {
      return e.children(n);
    },
    Provider: function (e) {
      var t, _;
      return this.getChildContext || (t = [], (_ = {})[n] = this, this.getChildContext = function () {
        return _;
      }, this.componentWillUnmount = function () {
        t = null;
      }, this.shouldComponentUpdate = function (e) {
        this.props.value !== e.value && t.some(function (e) {
          e.__e = !0, C(e);
        });
      }, this.sub = function (e) {
        t.push(e);
        var n = e.componentWillUnmount;
        e.componentWillUnmount = function () {
          t && t.splice(t.indexOf(e), 1), n && n.call(e);
        };
      }), e.children;
    }
  };
  return t.Provider.__ = t.Consumer.contextType = t;
}
e = p.slice, n = {
  __e: function (e, n, t, _) {
    for (var o, l, r; n = n.__;) if ((o = n.__c) && !o.__) try {
      if ((l = o.constructor) && null != l.getDerivedStateFromError && (o.setState(l.getDerivedStateFromError(e)), r = o.__d), null != o.componentDidCatch && (o.componentDidCatch(e, _ || {}), r = o.__d), r) return o.__E = o;
    } catch (n) {
      e = n;
    }
    throw e;
  }
}, t = 0, k.prototype.setState = function (e, n) {
  var t;
  t = null != this.__s && this.__s !== this.state ? this.__s : this.__s = d({}, this.state), "function" == typeof e && (e = e(d({}, t), this.props)), e && d(t, e), null != e && this.__v && (n && this._sb.push(n), C(this));
}, k.prototype.forceUpdate = function (e) {
  this.__v && (this.__e = !0, e && this.__h.push(e), C(this));
}, k.prototype.render = g, _ = [], l = "function" == typeof Promise ? Promise.prototype.then.bind(Promise.resolve()) : setTimeout, r = function (e, n) {
  return e.__v.__b - n.__v.__b;
}, x.__r = 0, u = 0, i = W(!1), s = W(!0), c = 0;
export { k as Component, g as Fragment, $ as cloneElement, O as createContext, y as createElement, y as h, n as options, R as render, T as toChildArray };
