import { ENCRYPTED_CARD_NUMBER as o, ENCRYPTED_SECURITY_CODE as r } from "../../constants.js";
import t from "../utils/iframes/postMessageToIframe.js";
import { objectsDeepEqual as e } from "../../utilities/commonUtils.js";
import { pick as i } from "../../../../../../utils/commonUtils.js";
import { hasOwnProperty as c } from "../../../../../../utils/hasOwnProperty.js";
import s from "../utils/iframes/getIframeContentWin.js";
function n({
  csfState: n,
  csfConfig: a,
  csfProps: l,
  csfCallbacks: m
}, f) {
  if (f.fieldType === o) {
    const o = {
        brand: f.brand,
        cvcPolicy: f.cvcPolicy,
        expiryDatePolicy: f.expiryDatePolicy,
        showSocialSecurityNumber: f.showSocialSecurityNumber
      },
      d = (y = o, u = n.brand, !e(y, u));
    if (!d) return null;
    const p = "card" === n.type || "bcmc" === n.type;
    if (p && d && (n.brand = o, c(n.securedFields, r))) {
      const e = {
        txVariant: n.type,
        brand: o.brand,
        fieldType: r,
        cvcPolicy: f.cvcPolicy,
        numKey: n.securedFields[r].numKey
      };
      t(e, s(n, r), a.loadingContext);
    }
    const b = p ? i(["brand", "cvcPolicy", "cvcText", "expiryDatePolicy", "showSocialSecurityNumber"]).from(f) : null;
    if (b && b.brand) {
      const o = b;
      o.type = n.type, o.rootNode = l.rootNode, m.onBrand(o);
    }
    return !0;
  }
  var y, u;
  return !1;
}
export { n as default };
