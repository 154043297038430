import { createElement as t, Fragment as e } from "../../../../../external/preact/dist/preact.js";
import { useState as o, useCallback as n } from "../../../../../external/preact/hooks/dist/hooks.js";
import s from "../../../Button/Button.js";
import r from "../../context/useClickToPayContext.js";
import a from "./CtPOneTimePasswordInput/CtPOneTimePasswordInput.js";
import { CtPInfo as i } from "../CtPInfo/CtPInfo.js";
import l from "../CtPSection/CtPSection.js";
import { useCoreContext as p } from "../../../../../core/Context/CoreProvider.js";
import d from "./CtPSaveCookiesCheckbox/CtPSaveCookiesCheckbox.js";
import { isSrciError as m } from "../../services/utils.js";
const c = ({
  onDisplayCardComponent: c
}) => {
  const {
      i18n: u
    } = p(),
    {
      finishIdentityValidation: C,
      otpMaskedContact: f,
      otpNetwork: P,
      isCtpPrimaryPaymentMethod: h
    } = r(),
    [k, g] = o(null),
    [j, y] = o(!1),
    [x, I] = o(!1),
    [b, S] = o(null),
    [v, T] = o(null),
    [w, B] = o(!1),
    E = n(t => {
      T(t);
    }, []),
    O = n(({
      data: t,
      isValid: e
    }) => {
      g(t.otp), y(e);
    }, []),
    A = n(() => {
      S(null);
    }, []),
    M = n(async () => {
      if (S(null), j) {
        I(!0);
        try {
          await C(k);
        } catch (t) {
          if (!m(t)) return void I(!1);
          S(t?.reason), I(!1), "ACCT_INACCESSIBLE" === t?.reason && (B(!0), c?.());
        }
      } else v.validateInput();
    }, [k, j, v, c]),
    N = u.get("ctp.otp.subtitle").split("%@");
  return t(e, null, t(l.Title, {
    endAdornment: t(i, null)
  }, u.get("ctp.otp.title")), t(l.Text, null, N[0], " ", P, " ", N[1], t("span", {
    className: "adyen-checkout-ctp__otp-subtitle--highlighted"
  }, f), N[2]), t(a, {
    hideResendOtpButton: w,
    onChange: O,
    onSetInputHandlers: E,
    disabled: x,
    errorMessage: b && u.get(`ctp.errors.${b}`),
    onPressEnter: M,
    onResendCode: A,
    isValidatingOtp: x
  }), t(d, null), t(s, {
    disabled: w,
    label: u.get("continue"),
    variant: h ? "primary" : "secondary",
    onClick: M,
    status: x && "loading"
  }));
};
export { c as default };
