<nav class="navbar gung-navbar navbar-expand-md d-block">
  <div class="d-flex navbar-top-info justify-content-center">
    <a *ngIf="topInformation" class="small" routerLink="{{ topInformation.link }}">
      <span>{{ topInformation.heading }}</span>
      <!-- TEST-->
    </a>
  </div>
  <div class="d-flex container justify-content-center align-items-center navbar-items">
    <div class="navbar-nav flex-grow-0">
      <div class="nav-option nav-item" *ngIf="mainMenu">
        <i class="fas fa-bars"> </i>
        <div class="gung-mega-menu">
          <div class="container">
            <div class="row">
              <div class="col-6 col-md-3 col-lg-2 link-block" *ngFor="let linkGroup of mainMenu.links">
                <h6>
                  <a [routerLink]="linkGroup.link ? linkGroup.link : undefined"
                    [routerLinkActive]="linkGroup.link ? 'active' : ''"
                    (click)="linkGroup.action ? linkGroup.action() : undefined">
                    <i *ngIf="linkGroup.icon" class="fa {{ linkGroup.icon }} mr-2"></i>
                    <span translate>{{ linkGroup.heading }}</span></a>
                </h6>

                <ul>
                  <li *ngFor="let childLink of linkGroup.links">
                    <a [routerLink]="childLink.link ? childLink.link : undefined"
                      [routerLinkActive]="childLink.link ? 'active' : ''"
                      (click)="childLink.action ? childLink.action() : undefined">
                      <i *ngIf="childLink.icon" class="fa {{ childLink.icon }} mr-2"></i>
                      <span translate>{{ childLink.heading }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="left-menu">
      <div class="mr-auto">
        <ul class="navbar-nav" *ngIf="leftMenus">
          <li class="nav-item" *ngFor="let leftMenuLink of leftMenus">
            <div class="nav-item nav-link">
              <a class="px-0" (click)="
                  leftMenuLink.action ? leftMenuLink.action() : undefined;
                  leftMenuLink.links ? toggleTopMenu() : undefined
                " [routerLink]="!leftMenuLink.links && leftMenuLink.link ? leftMenuLink.link : undefined">
                <i *ngIf="leftMenuLink.icon" class="fa {{ leftMenuLink.icon }} mr-2"></i>
                <span translate>{{ leftMenuLink?.heading }}</span>
                <i *ngIf="leftMenuLink.links && !isTopMenuExpanded" class="fas fa-angle-down pl-2"></i>
                <i *ngIf="leftMenuLink.links && isTopMenuExpanded" class="fas fa-angle-up pl-2"></i>
              </a>
              <div id="gung-mega-top-menu" *ngIf="leftMenuLink.links"
                class="gung-mega-menu gung-mega-top-menu-collapsed">
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-3 col-lg-2 link-block mb-2"
                      *ngFor="let linkGroup of leftMenuLink.links; index as topMainMenuIndex">
                      <h6>
                        <a [routerLink]="linkGroup.link ? linkGroup.link : undefined"
                          [routerLinkActive]="linkGroup.link ? 'active' : ''" (click)="
                            linkGroup.action ? linkGroup.action() : undefined;
                            selectedTopMainMenuIndex = topMainMenuIndex
                          ">
                          <i *ngIf="linkGroup.icon" class="fa {{ linkGroup.icon }} mr-2"></i>
                          <span translate>{{ linkGroup.heading }}</span></a>
                      </h6>

                      <ul *ngIf="linkGroup.links" [ngClass]="{
                          'gung-mega-top-main-menu-expanded':
                            isSmallScreen && selectedTopMainMenuIndex === topMainMenuIndex,
                          'gung-mega-top-main-menu-collapsed':
                            isSmallScreen && selectedTopMainMenuIndex !== topMainMenuIndex
                        }">
                        <li *ngFor="let childLink of linkGroup.links.slice(0, 7)">
                          <a [routerLink]="childLink.link ? childLink.link : undefined"
                            [routerLinkActive]="childLink.link ? 'active' : ''"
                            (click)="childLink.action ? childLink.action() : undefined">
                            <i *ngIf="childLink.icon" class="fa {{ childLink.icon }} mr-2"></i>
                            <span translate>{{ childLink.heading }}</span>
                          </a>
                        </li>
                        <li *ngIf="linkGroup.links.length > 7">
                          <a [routerLink]="linkGroup.link"> &darr; <span translate>SHOW_MORE</span> &darr; </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="logo">
      <div class="nav-brand-wrapper d-flex justify-content-center">
        <a class="navbar-brand" routerLink="/">
          <img src="{{ logoUrl }}" width="auto" height="36px" alt="Logotype" />
        </a>
      </div>
    </div>
    <div class="right-menu" [ngClass]="{ 'flex-grow-0': rightMenus.length <= 0 }">
      <div>
        <ul class="navbar-nav" *ngIf="rightMenus">
          <li class="nav-item" *ngFor="let rightMenuLink of rightMenus">
            <div class="nav-item nav-link">
              <a class="px-0" [routerLink]="rightMenuLink.link ? rightMenuLink.link : undefined">
                <i *ngIf="rightMenuLink.icon" class="fa {{ rightMenuLink.icon }} mr-2"></i>
                <span translate>{{ rightMenuLink?.heading }}</span>
              </a>
              <div *ngIf="rightMenuLink.links" class="gung-mega-menu">
                <div class="container">
                  <div class="row">
                    <div class="col-6 col-md-3 col-lg-2 link-block" *ngFor="let linkGroup of rightMenuLink.links">
                      <h6>
                        <a [routerLink]="linkGroup.link ? linkGroup.link : undefined"
                          [routerLinkActive]="linkGroup.link ? 'active' : ''"
                          (click)="linkGroup.action ? linkGroup.action() : undefined">
                          <i *ngIf="linkGroup.icon" class="fa {{ linkGroup.icon }} mr-2"></i>
                          <span translate>{{ linkGroup.heading }}</span></a>
                      </h6>

                      <ul>
                        <li *ngFor="let childLink of linkGroup.links">
                          <a [routerLink]="childLink.link ? childLink.link : undefined"
                            [routerLinkActive]="childLink.link ? 'active' : ''"
                            (click)="childLink.action ? childLink.action() : undefined">
                            <i *ngIf="childLink.icon" class="fa {{ childLink.icon }} mr-2"></i>
                            <span translate>{{ childLink.heading }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div *ngIf="!isAnonymous">
      <div *ngIf="authenticated" class="cart-menu-wrapper d-flex">
        <a class="px-0 text-center mr-2" [routerLink]="'/favourites'">
          <div class="fav-menu">
            <i class="fal fa-star fas"></i>
            <span class="badge nav-bar-icon-badge">{{ favouritesCount }}</span>
          </div>
        </a>
        <div class="mr-5">
          <lib-navbarcart *ngIf="isCartVisible && !isOnlyDisplayFlow"></lib-navbarcart>
        </div>

        <a class="px-0 side-menu-icon" (click)="sidenavToggleEvent()">
          <i class="fas fa-bars"></i>
        </a>
      </div>
    </div>
  </div>
</nav>
