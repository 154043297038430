import { formatCPFCNPJ as e } from "../../../internal/SocialSecurityNumberBrazil/utils.js";
import r from "../../../internal/SocialSecurityNumberBrazil/validate.js";
import { isEmpty as l } from "../../../../utils/validator-utils.js";
import { BOLETO_SOCIAL_SECURITY_NUMBER_INVALID as t, CREDITCARD_TAX_NUMBER_INVALID as a, CREDITCARD_HOLDER_NAME_INVALID as i } from "../../../../core/Errors/constants.js";
const o = {
    socialSecurityNumber: e
  },
  s = {
    socialSecurityNumber: [{
      modes: ["blur"],
      validate: e => l(e) ? null : r(e),
      errorMessage: t
    }],
    taxNumber: [{
      modes: ["blur"],
      validate: e => l(e) ? null : 6 === e?.length || 10 === e?.length,
      errorMessage: a
    }],
    holderName: [{
      modes: ["blur"],
      validate: e => !l(e) || null,
      errorMessage: i
    }],
    default: [{
      modes: ["blur"],
      validate: e => !!e && "string" == typeof e && e.trim().length > 0
    }]
  },
  u = (e, r) => s[e].reduce((e, l) => (e.length || l.modes.includes(r) && e.push(l.validate), e), [])[0];
export { o as cardInputFormatters, s as cardInputValidationRules, u as getRuleByNameAndMode };
