import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Customer } from '../../models';
import { ListItemRendererComponent } from 'gung-list';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { Router } from '@angular/router';
import { CustomerActionConfigService } from '../../services/customer-action-config.service';
import { GungTableConfig } from 'gung-common';
import { CustomerService } from '../../services/customers/customer.service';
import { first } from 'rxjs';
import { CustomerCustomTableBodyComponent } from './customer-custom-table-body/customer-custom-table-body.component';

@Component({
  selector: 'lib-customer-list-table',
  templateUrl: './customer-list-table.component.html',
  styleUrl: './customer-list-table.component.scss'
})
export class CustomerListTableComponent {
  constructor(
    public selectCustomerService: SelectedCustomerService,
    protected customerService: CustomerService,
    protected router: Router,
    public actionButtonsConfig?: CustomerActionConfigService
  ) { }

  config: GungTableConfig<Customer>;

  sort() {
    return (a, b) => a.customerId?.localeCompare(b.customerId);
  }

  sortName() {
    return (a, b) => a.name?.localeCompare(b.name);
  }

  ngOnInit() {
    this.customerService
      .getCustomers()
      .pipe(first())
      .subscribe(customers => {
        this.initTable(customers);
      });
  }

  initTable(customers: Customer[]) {
    this.config = {
      data: customers,
      headers: [
        { name: 'ID', path: 'id', sortable: false, extraClass: 'd-none' },
        { name: 'ID', path: 'extra.gme.displayId', sortable: true },
        { name: 'NAME', path: 'extra.gme.displayName', sortable: true, searchable: true, enableFilter: true },
        {
          name: 'ADDRESS',
          path: 'extra.gme.billingAddress.address1',
          sortable: true,
          searchable: true,
          enableFilter: false
        },
        { name: 'CITY', path: 'extra.gme.billingAddress.city', sortable: true, searchable: true, enableFilter: true },
        {
          name: 'COUNTRY',
          path: 'extra.gme.billingAddress.countryCode',
          sortable: true,
          searchable: true,
          enableFilter: true
        },
        {
          name: 'ORG_NR',
          path: 'extra.gme.billingAddress.organizationNumber',
          sortable: true,
          searchable: true,
          enableFilter: false
        },
        {
          name: '',
          path: null,
          sortable: false,
          searchable: false,
          enableFilter: false
        }
      ],
      title: 'CUSTOMERS',
      enableSearch: true,
      itemsPerPage: 10,
      customBodyComponent: CustomerCustomTableBodyComponent
    };
  }

  selectCustomer(id) {
    this.router.navigate(['customers', id]);
    this.selectCustomerService.selectCustomer(id);
  }

  selectCustomerWithoutNavigate(id) {
    this.selectCustomerService.selectCustomer(id);
  }
}
