import { Injectable, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ExportSelection,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SelectionAction,
  SimpleConfigBaseFilter
} from 'gung-list';
import { BehaviorSubject, forkJoin, of, ReplaySubject, Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { filter, first, map, mergeMap, switchMap } from 'rxjs';
import { DigitalAssetsGridViewComponent } from '../../components/digital-assets/digital-assets-grid-view/digital-assets-grid-view.component';
import { AuthService } from '../auth/auth.service';
import { DigitalAsset, DigitalAssetFile, DigitalAssetsService } from '../digital-assets/digital-assets.service';
import { DownloadService } from '../download/download.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { MetadataService } from '../metadata/metadata.service';
import { ActivatedRouteSnapshot } from '@angular/router';

export class DigitalAssetsFilter extends SimpleConfigBaseFilter<DigitalAsset> {
  constructor(
    private filterName: string,
    private filterKey: string,
    private metadataService?: MetadataService,
    private tableName?: string,
    private valueName?: string,
    private metadataSuffix?: string,
    private metadataPrefix?: string
  ) {
    super();
  }

  getOptionIds(item: DigitalAsset): string[] {
    if (item && item.extra && item.extra[this.filterKey]) {
      return [item.extra[this.filterKey]];
    }
    return ['OTHER'];
  }
  getOptionName(key: string): string {
    if (this.metadataService && this.tableName && this.valueName) {
      const modifiedKey = this.metadataSuffix
        ? this.metadataSuffix + key + (this.metadataPrefix ? this.metadataPrefix : '')
        : key + (this.metadataPrefix ? this.metadataPrefix : '');
      return this.metadataService.getMetadataValue(this.tableName, this.valueName, modifiedKey);
    }
    return key;
  }
  getName(): string {
    return this.filterName;
  }
}

export class DigitalAssetsCommaSplitFilter extends SimpleConfigBaseFilter<DigitalAsset> {
  constructor(private filterName: string, private filterKey: string) {
    super();
  }

  getOptionIds(item: DigitalAsset): string[] {
    if (item && item.extra && item.extra[this.filterKey]) {
      return item.extra[this.filterKey].split(',');
    }
    return ['OTHER'];
  }
  getOptionName(key: string): string {
    return key;
  }
  getName(): string {
    return this.filterName;
  }
}

@Injectable({
  providedIn: 'root'
})
export class DigitalAssetsListConfigService implements ConfigService<DigitalAsset> {
  topFilter = true;

  @Input()
  isManagement: boolean;

  digitalAssetsObjectsSubject: Subject<string[]> = new BehaviorSubject(null);

  constructor(
    protected gungModalService: GungModalService,
    protected translateService: TranslateService,
    protected metadataService: MetadataService,
    protected authService: AuthService,
    protected downloadService: DownloadService,
    protected digitalAssetsService: DigitalAssetsService
  ) { }

  getFilters(route?: ActivatedRouteSnapshot): ConfigBaseFilter<DigitalAsset>[] {
    return [
      new DigitalAssetsFilter('BRAND', 'brand', this.metadataService, 'TA40', 'TX1'),
      new DigitalAssetsFilter('SEASON', 'season'),
      new DigitalAssetsCommaSplitFilter('TAGS', 'tags')
    ];
  }

  getSortOptions(route?: ActivatedRouteSnapshot): ListSortOption<DigitalAsset>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(route?: ActivatedRouteSnapshot): number[] {
    return [24];
  }

  getItemId(item: DigitalAsset) {
    return item.id;
  }

  getSearchTerms(item: DigitalAsset) {
    return [item.id, item.name];
  }

  getLayouts(route?: ActivatedRouteSnapshot): ListLayout<DigitalAsset>[] {
    // if (this.isManagement) {
    //   return [{
    //     getIconClass: () => '',
    //     getListItemComponent: () => DigitalAssetsManagementGridViewComponent,
    //     getListLayoutComponent: () => ListLayoutMultipleComponent,
    //     getName: () => 'PimConceptGrid'
    //   }];
    // }
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => DigitalAssetsGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'PimConceptGrid'
      }
    ];
  }

  getItems(route?: ActivatedRouteSnapshot): Observable<DigitalAsset[]> {
    return this.digitalAssetsObjectsSubject.pipe(
      // filter(ids => !!ids),
      switchMap(ids => forkJoin([of(ids), this.digitalAssetsService.getDigitalAssets(true).pipe(first())])),
      map(([filterIds, digitalAssets]) => {
        if (filterIds) {
          // Filter by Ids
          return digitalAssets.filter(d => filterIds.includes(d.id));
        }
        return digitalAssets;
      }),
      map(digitalAssets => {
        return digitalAssets.sort((a, b) => {
          if (!a.extra?.sequence && !b.extra?.sequence) {
            return 0;
          }
          if (a.extra?.sequence && !b.extra?.sequence) {
            return -1;
          }
          if (b.extra?.sequence && !a.extra?.sequence) {
            return 1;
          }
          if (Number(a.extra.sequence) > Number(b.extra.sequence)) {
            return -1;
          }
          if (Number(a.extra.sequence) < Number(b.extra.sequence)) {
            return 1;
          }
          return 0;
        });
      })
    );
  }

  getSelectionActions(route?: ActivatedRouteSnapshot): Observable<SelectionAction<DigitalAsset>[]> {
    if (this.isManagement) {
      return of();
    }
    return this.authService.getCurrentUser().pipe(
      first(),
      switchMap(user => {
        return of([
          {
            label: 'EXPORT_ASSETS',
            performAction: (selection: ExportSelection<DigitalAsset>) => {
              const itemList = Object.values(selection.selectedItems);
              this.digitalAssetsService.sendDigitalAssetsFiles(itemList, this.gungModalService, user.email);
              return of();
            }
          }
        ]);
      })
    );
  }

  getSelectionActionsButtonTitle(route?: ActivatedRouteSnapshot): string {
    if (this.isManagement) {
      return null;
    }
    return 'ACTIONS';
  }

  getNothingFoundTranslateTag(route?: ActivatedRouteSnapshot) {
    if (this.isManagement) {
      return null;
    }
    return 'NOTHING_FOUND_CONTACT_GUNG';
  }

  getSearchGroupCss(route?: ActivatedRouteSnapshot) {
    return 'hide-search';
  }
}
