import { createElement as e } from "../../../../../../external/preact/dist/preact.js";
import { useMemo as r, useEffect as a } from "../../../../../../external/preact/hooks/dist/hooks.js";
import t from "../../../context/useClickToPayContext.js";
import { useCoreContext as s } from "../../../../../../core/Context/CoreProvider.js";
import o from "../../../../../../core/Context/useImage.js";
import i from "../../../../../../utils/useForm/useForm.js";
import d from "../../../../../../utils/isMobile.js";
import c from "../../../../FormFields/Field/Field.js";
import l from "../../../../FormFields/Select/Select.js";
const m = ["srcDigitalCardId"],
  n = ({
    cardSelected: n,
    cards: p,
    errorMessage: C,
    onChangeCard: g
  }) => {
    const {
        i18n: f
      } = s(),
      u = o(),
      {
        status: x
      } = t(),
      {
        handleChangeFor: F,
        data: h
      } = i({
        schema: m,
        defaultData: {
          srcDigitalCardId: n.srcDigitalCardId
        }
      }),
      j = r(() => p.map(e => ({
        icon: e.artUri || u()(e.scheme),
        name: `${d() ? "" : e.title} •••• ${e.panLastFour} `,
        secondaryText: e.isExpired && f.get("ctp.cards.expiredCard"),
        id: e.srcDigitalCardId,
        disabled: e.isExpired
      })), [p]);
    return a(() => {
      const {
          srcDigitalCardId: e
        } = h,
        r = p.find(r => r.srcDigitalCardId === e);
      g(r);
    }, [h, g]), e(c, {
      name: "clickToPayCards",
      errorMessage: C,
      readOnly: "loading" === x
    }, e(l, {
      items: j,
      selectedValue: h.srcDigitalCardId,
      name: "cards",
      filterable: !1,
      className: "adyen-checkout-ctp__cards-list-dropdown",
      readonly: "loading" === x,
      onChange: F("srcDigitalCardId")
    }));
  };
export { n as default };
