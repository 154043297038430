import { createElement as e } from "../../../external/preact/dist/preact.js";
import { useCallback as n } from "../../../external/preact/hooks/dist/hooks.js";
import r from "../../../external/classnames/index.js";
import { ARIA_ERROR_SUFFIX as a, ARIA_CONTEXT_SUFFIX as o } from "../../../core/Errors/constants.js";
function t({
  setRef: t,
  ...s
}) {
  const {
      autoCorrect: l,
      classNameModifiers: i,
      isInvalid: u,
      isValid: d,
      readonly: c = null,
      spellCheck: p,
      type: y,
      uniqueId: m,
      disabled: h
    } = s,
    f = s.className;
  Object.prototype.hasOwnProperty.call(s, "onChange") && console.error("Error: Form fields that rely on InputBase may not have an onChange property");
  const k = n(e => {
      s.onInput(e);
    }, [s.onInput]),
    _ = n(e => {
      s?.onKeyPress && s.onKeyPress(e);
    }, [s?.onKeyPress]),
    v = n(e => {
      s?.onKeyUp && s.onKeyUp(e);
    }, [s?.onKeyUp]),
    C = n(e => {
      s?.onBlurHandler?.(e), s.trimOnBlur && (e.target.value = e.target.value.trim()), s?.onBlur?.(e);
    }, [s.onBlur, s.onBlurHandler]),
    I = n(e => {
      s?.onFocusHandler?.(e);
    }, [s.onFocusHandler]),
    K = r("adyen-checkout__input", [`adyen-checkout__input--${y}`], f, {
      "adyen-checkout__input--invalid": u,
      "adyen-checkout__input--valid": d
    }, i.map(e => `adyen-checkout__input--${e}`)),
    {
      classNameModifiers: x,
      uniqueId: B,
      isInvalid: b,
      isValid: P,
      addContextualElement: j,
      ...N
    } = s;
  return e("input", {
    id: m,
    ...N,
    "aria-required": N.required,
    type: y,
    className: K,
    readOnly: c,
    spellCheck: p,
    autoCorrect: l,
    "aria-describedby": `${m}${u ? a : o}`,
    "aria-invalid": u,
    onInput: k,
    onBlur: C,
    onFocus: I,
    onKeyUp: v,
    onKeyPress: _,
    disabled: h,
    ref: t
  });
}
t.defaultProps = {
  type: "text",
  classNameModifiers: []
};
export { t as default };
