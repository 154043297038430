import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StripeService } from '../../../services/stripe/stripe.service';
import { ThemeService } from 'ng2-charts';
import { CartService } from '../../../services/cart/cart.service';

@Component({
  selector: 'lib-stripe-payment-success',
  templateUrl: './stripe-payment-success.component.html',
  styleUrls: ['./stripe-payment-success.component.css']
})
export class StripePaymentSuccessComponent implements OnInit {
  orderContextId = '';

  constructor(
    protected stripeService: StripeService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected cartService: CartService
  ) { }

  ngOnInit() {
    this.cartService.clearCart();
    const queryParams = this.route.snapshot.queryParams;

    if (!!queryParams && queryParams.storageId) {
      this.stripeService.getOrderStripe(queryParams.storageId).subscribe(orderContextStripe => {
        console.log(orderContextStripe);
        if (!!orderContextStripe) {
          this.orderContextId = orderContextStripe.id;

          this.router.navigateByUrl('order-confirmation/' + this.orderContextId + '?stripe=success');
        }
      });
    }
  }
}
