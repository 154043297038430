import { CTP_IFRAME_NAME as e, createCheckoutPayloadBasedOnScheme as t, createShopperCardsList as i } from "./utils.js";
import s from "./sdks/SrciError.js";
import { SchemeNames as o } from "./sdks/utils.js";
import n from "../../../../utils/uuid.js";
import r from "../../../../core/Errors/AdyenCheckoutError.js";
import { isRejected as a, isFulfilled as d } from "../../../../utils/promise-util.js";
import c from "../errors/TimeoutError.js";
var h;
function l(e, t, i) {
  const s = new Promise((e, s) => setTimeout(() => s(i), t));
  return Promise.race([e(), s]);
}
!function (e) {
  e.Idle = "Idle", e.Loading = "Loading", e.ShopperIdentified = "ShopperIdentified", e.OneTimePassword = "OneTimePassword", e.Ready = "Ready", e.Login = "Login", e.NotAvailable = "NotAvailable";
}(h || (h = {}));
class m {
  sdkLoader;
  schemesConfig;
  shopperIdentity;
  environment;
  onTimeout;
  srciTransactionId = n();
  sdks;
  validationSchemeSdk = null;
  stateSubscriber;
  state = "Idle";
  shopperCards = null;
  identityValidationData = null;
  storeCookies = !1;
  constructor(e, t, i, s, o) {
    this.sdkLoader = t, this.schemesConfig = e, this.shopperIdentity = s, this.environment = i, this.onTimeout = o;
  }
  get shopperAccountFound() {
    return ["Ready", "ShopperIdentified"].includes(this.state);
  }
  get schemes() {
    return this.sdkLoader.schemes;
  }
  updateStoreCookiesConsent(e) {
    this.storeCookies = e;
  }
  async initialize() {
    this.setState("Loading");
    try {
      this.sdks = await this.sdkLoader.load(this.environment), await this.initiateSdks();
      const {
        recognized: e = !1,
        idTokens: t = null
      } = await this.verifyIfShopperIsRecognized();
      if (e) return await this.getShopperProfile(t), void this.setState("Ready");
      if (!this.shopperIdentity) return void this.setState("NotAvailable");
      const {
        isEnrolled: i
      } = await this.verifyIfShopperIsEnrolled(this.shopperIdentity);
      if (i) return void this.setState("ShopperIdentified");
      this.setState("NotAvailable");
    } catch (e) {
      if (e instanceof s && "REQUEST_TIMEOUT" === e?.reason) {
        const t = new c(`ClickToPayService - Timeout during ${e.source}() of the scheme '${e.scheme}'`);
        this.onTimeout?.(t);
      } else e instanceof c ? (console.warn(e.toString()), this.onTimeout?.(e)) : e instanceof s ? console.warn(`Error at ClickToPayService # init: ${e.toString()}`) : console.warn(e);
      this.setState("NotAvailable");
    }
  }
  subscribeOnStateChange(e) {
    this.stateSubscriber = e;
  }
  async startIdentityValidation() {
    if (!this.validationSchemeSdk) throw Error("startIdentityValidation: No ValidationSDK set for the validation process");
    const {
      maskedValidationChannel: e
    } = await this.validationSchemeSdk.initiateIdentityValidation();
    this.identityValidationData = {
      maskedShopperContact: e.replace(/\*/g, "•"),
      selectedNetwork: o[this.validationSchemeSdk.schemeName]
    }, this.setState("OneTimePassword");
  }
  async finishIdentityValidation(e) {
    if (!this.validationSchemeSdk) throw Error("finishIdentityValidation: No ValidationSDK set for the validation process");
    const t = await this.validationSchemeSdk.completeIdentityValidation(e);
    await this.getShopperProfile([t.idToken]), this.setState("Ready"), this.validationSchemeSdk = null;
  }
  async checkout(i) {
    if (!i) throw Error("ClickToPayService # checkout: Missing card data");
    const s = this.sdks.find(e => e.schemeName === i.scheme),
      o = await s.checkout({
        srcDigitalCardId: i.srcDigitalCardId,
        srcCorrelationId: i.srcCorrelationId,
        ...(i.isDcfPopupEmbedded && {
          windowRef: window.frames[e]
        }),
        ...(this.storeCookies && {
          complianceSettings: {
            complianceResources: [{
              complianceType: "REMEMBER_ME",
              uri: ""
            }]
          }
        })
      });
    if ("COMPLETE" !== o.dcfActionCode) throw new r("ERROR", `Checkout through Scheme DCF did not complete. DCF Action code received: ${o.dcfActionCode}`);
    return t(i, o, this.environment);
  }
  async logout() {
    if (!this.sdks) throw new r("ERROR", "ClickToPayService is not initialized");
    try {
      const e = this.sdks.map(e => e.unbindAppInstance());
      await Promise.all(e);
    } catch (e) {
      e instanceof s ? console.warn(`Error at ClickToPayService # logout: ${e.toString()}`) : console.warn(e);
    }
    this.shopperCards = null, this.identityValidationData = null, this.validationSchemeSdk = null, this.setState("Login");
  }
  async verifyIfShopperIsEnrolled(e) {
    const {
      shopperEmail: t
    } = e;
    return new Promise((e, i) => {
      const s = this.sdks.map(s => {
        const o = l(() => s.identityLookup({
          identityValue: t,
          type: "email"
        }), 5e3, new c(`ClickToPayService - Timeout during identityLookup() of the scheme '${s.schemeName}'`));
        return o.then(t => {
          t.consumerPresent && !this.validationSchemeSdk && (this.setSdkForPerformingShopperIdentityValidation(s), e({
            isEnrolled: !0
          }));
        }).catch(e => {
          i(e);
        }), o;
      });
      Promise.allSettled(s).then(() => {
        e({
          isEnrolled: !1
        });
      });
    });
  }
  setState(e) {
    this.state = e, this.stateSubscriber?.(this.state);
  }
  setSdkForPerformingShopperIdentityValidation(e) {
    this.validationSchemeSdk = e;
  }
  async getShopperProfile(e) {
    return new Promise((t, s) => {
      const o = this.sdks.map(t => t.getSrcProfile(e));
      Promise.allSettled(o).then(e => {
        e.every(a) && s(e[0].reason);
        const o = e.map((e, t) => d(e) && {
          ...e.value,
          scheme: this.sdks[t].schemeName
        }).filter(e => !!e);
        this.shopperCards = i(o), t();
      });
    });
  }
  async verifyIfShopperIsRecognized() {
    return new Promise((e, t) => {
      const i = this.sdks.map(i => {
        const s = l(() => i.isRecognized(), 5e3, new c(`ClickToPayService - Timeout during isRecognized() of the scheme '${i.schemeName}'`));
        return s.then(t => t.recognized && e(t)).catch(e => t(e)), s;
      });
      Promise.allSettled(i).then(() => e({
        recognized: !1
      }));
    });
  }
  async initiateSdks() {
    const e = this.sdks.map(e => {
      const t = this.schemesConfig[e.schemeName];
      return l(() => e.init(t, this.srciTransactionId), 5e3, new c(`ClickToPayService - Timeout during init() of the scheme '${e.schemeName}'`));
    });
    await Promise.all(e);
  }
}
export { h as CtpState, m as default };
