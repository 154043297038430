import { createElement as a, Fragment as e } from "../../../../../external/preact/dist/preact.js";
import { useCoreContext as t } from "../../../../../core/Context/CoreProvider.js";
const c = () => {
  const {
    i18n: c
  } = t();
  return a(e, null, a("div", {
    className: "adyen-checkout-ctp__card-animation"
  }, a("div", {
    className: "adyen-checkout-ctp__card-animation-layer"
  }), a("div", {
    className: "adyen-checkout-ctp__card-animation-layer"
  }), a("div", {
    className: "adyen-checkout-ctp__card-animation-layer"
  })), a("div", {
    className: "adyen-checkout-ctp__loading-subtitle"
  }, c.get("ctp.loading.intro")));
};
export { c as default };
