import { createElement as e } from "../../../../external/preact/dist/preact.js";
import t from "./PaymentMethodIcon.js";
import { useCoreContext as a } from "../../../../core/Context/CoreProvider.js";
import o from "../../../../core/Context/useImage.js";
const n = ({
  order: n,
  orderStatus: d,
  onOrderCancel: r,
  brandLogoConfiguration: m
}) => {
  const {
      i18n: c
    } = a(),
    u = o();
  return e("div", null, e("ul", {
    className: "adyen-checkout__order-payment-methods-list"
  }, d?.paymentMethods?.map((a, o) => e("li", {
    key: `${a.type}-${o}`,
    className: "adyen-checkout__order-payment-method"
  }, e("div", {
    className: "adyen-checkout__order-payment-method__header"
  }, e("div", {
    className: "adyen-checkout__payment-method__header__title"
  }, e(t, {
    altDescription: a.name,
    type: a.type,
    src: m[a.type] || u()(a.type)
  }), a.label ? `${a.label}` : `•••• ${a.lastFour}`), r && e("button", {
    type: "button",
    className: "adyen-checkout__button adyen-checkout__button--inline adyen-checkout__button--link",
    onClick: () => {
      r({
        order: n
      });
    }
  }, c.get("storedPaymentMethod.disable.button"))), e("div", {
    className: "adyen-checkout__order-payment-method__details"
  }, e("div", {
    className: "adyen-checkout__order-payment-method__deducted-amount"
  }, e("div", {
    className: "adyen-checkout__order-payment-method__deducted-amount__label"
  }, c.get("deductedBalance")), e("div", {
    className: "adyen-checkout__order-payment-method__deducted-amount__value"
  }, c.amount(a.amount.value, a.amount.currency))))))), d.remainingAmount && e("div", {
    className: "adyen-checkout__order-remaining-amount"
  }, c.get("partialPayment.warning"), " ", e("strong", null, c.amount(d.remainingAmount.value, d.remainingAmount.currency))));
};
export { n as OrderPaymentMethods, n as default };
