import { useState as e, useEffect as t } from "../../../external/preact/hooks/dist/hooks.js";
import { createElement as o } from "../../../external/preact/dist/preact.js";
import { useCoreContext as r } from "../../../core/Context/CoreProvider.js";
import s from "../FormFields/Checkbox/Checkbox.js";
function a({
  storeDetails: a = !1,
  ...c
}) {
  const {
      i18n: i
    } = r(),
    [n, l] = e(a);
  return t(() => {
    c.onChange(n);
  }, [n]), o("div", {
    className: "adyen-checkout__store-details"
  }, o(s, {
    onChange: e => {
      l(e.target.checked);
    },
    label: i.get("storeDetails"),
    name: "storeDetails"
  }));
}
export { a as default };
