export interface User {
  authenticated: boolean;
  authorities: {
    authority: string;
  }[];
  details: {
    username: string;
    name: string;
    assortment: string;
    email: string;
    roles: string[];
    managedCompanyIds: string[];
    managedMultistockIds: string[];
    managedAssortmentIds: string[];
    password: string;
    activeGroups: { [s: string]: any };
    phone: string;
  };
}

export const ROLE_ADMIN = 'ADMIN';
export const ROLE_SALES = 'SALES';
export const ROLE_USER = 'USER';
export const ROLE_SUPPLIER = 'SUPPLIER';
export const ROLE_BUYER = 'BUYER';
export const ROLE_ANONYMOUS = 'ANONYMOUS';
export const ROLE_ACTUATOR = 'ACTUATOR';
