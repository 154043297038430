import { useState as e, useRef as a, useEffect as s } from "../../../external/preact/hooks/dist/hooks.js";
import r from "../../../external/classnames/index.js";
import { createElement as t } from "../../../external/preact/dist/preact.js";
function o(o) {
  const {
      backgroundUrl: c = "",
      className: n = "",
      classNameModifiers: l = [],
      src: m = "",
      alt: d = "",
      showOnError: i = !1
    } = o,
    [u, f] = e(!1),
    p = a(null),
    g = () => {
      f(!0);
    },
    k = r([n], "adyen-checkout__image", {
      "adyen-checkout__image--loaded": u
    }, ...l.map(e => `adyen-checkout__image--${e}`));
  return s(() => {
    const e = c ? new Image() : p.current;
    e.src = c || m, e.onload = g, f(!!e.complete);
  }, []), c ? t("div", {
    "data-testid": "background",
    style: {
      backgroundUrl: c
    },
    ...o,
    className: k
  }) : t("img", {
    ...o,
    alt: d,
    ref: p,
    className: k,
    onError: () => {
      f(i);
    }
  });
}
export { o as default };
