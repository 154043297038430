import { createElement as e, Fragment as r } from "../../../../external/preact/dist/preact.js";
import { useRef as s, useMemo as t, useState as o, useCallback as n, useEffect as a } from "../../../../external/preact/hooks/dist/hooks.js";
import i from "../../../internal/SecuredFields/SFP/SecuredFieldsProvider.js";
import l from "./defaultProps.js";
import { AddressModeOptions as d } from "./types.js";
import { ENCRYPTED_CARD_NUMBER as u, DATE_POLICY_REQUIRED as c, CVC_POLICY_REQUIRED as m } from "../../../internal/SecuredFields/lib/constants.js";
import { cardInputFormatters as p, cardInputValidationRules as h, getRuleByNameAndMode as f } from "./validate.js";
import y from "../../../internal/SecuredFields/binLookup/extensions.js";
import b from "../../../../utils/useForm/useForm.js";
import { handlePartialAddressMode as N, extractPropsForSFP as S, extractPropsForCardFields as g, getLayout as A } from "./utils.js";
import F from "../../../internal/Address/Specifications.js";
import { StoredCardFieldsWrapper as C } from "./components/StoredCardFieldsWrapper.js";
import { CardFieldsWrapper as j } from "./components/CardFieldsWrapper.js";
import { getAutoJumpHandler as k, getFocusHandler as x, getAddressHandler as R } from "./handlers.js";
import P from "../../../../external/classnames/index.js";
import { getPartialAddressValidationRules as v } from "../../../internal/Address/validate.js";
import w from "../../../../core/Context/useImage.js";
import { getArrayDifferences as I } from "../../../../utils/arrayUtils.js";
import B from "../../../internal/FormInstruction/FormInstruction.js";
import { PREFIX as V } from "../../../internal/Icon/constants.js";
import E from "./useSRPanelForCardInputErrors.js";
const O = l => {
  const O = s(null),
    q = s(!1),
    M = w(),
    D = s(null),
    _ = e => {
      D.current = e;
    },
    L = s({});
  Object.keys(L.current).length || l.setComponentRef(L.current);
  const T = s(0),
    K = s(!1),
    H = t(() => new F(l.specifications), [l.specifications]);
  L.current.sfp = O;
  const [U, z] = o("ready"),
    [W, $] = o({}),
    [G, J] = o({
      ...(l.holderNameRequired && {
        holderName: !1
      })
    }),
    [Q, X] = o({
      ...(l.hasHolderName && {
        holderName: l.data.holderName ?? ""
      })
    }),
    [Y, Z] = o(""),
    [ee, re] = o(!1),
    [se, te] = o(c),
    [oe, ne] = o(m),
    [ae, ie] = o(null),
    [le, de] = o([]),
    [ue, ce] = o(l.storedPaymentMethodId ? l.brand : ""),
    me = l.billingAddressMode !== d.none && l.billingAddressRequired,
    pe = N(l.billingAddressMode),
    he = s(pe && l.data?.billingAddress?.country),
    [fe, ye] = o(!1),
    [be, Ne] = o(me ? l.data.billingAddress : null),
    [Se, ge] = o(!1),
    [Ae, Fe] = o(""),
    [Ce, je] = o({
      value: null
    }),
    [ke, xe] = o(null),
    {
      handleChangeFor: Re,
      triggerValidation: Pe,
      data: ve,
      valid: we,
      errors: Ie,
      setSchema: Be,
      setData: Ve,
      setValid: Ee,
      setErrors: Oe
    } = b({
      schema: [],
      defaultData: l.data,
      formatters: p,
      rules: h
    }),
    qe = !!Object.keys(l.installmentOptions).length && "debit" !== l.fundingSource,
    Me = l.showInstallmentAmounts ?? !0,
    De = "kr" === (ae ?? l.countryCode),
    _e = l.configuration.koreanAuthenticationRequired && De,
    Le = Se && "auto" === l.configuration.socialSecurityNumberMode || "show" === l.configuration.socialSecurityNumberMode,
    Te = (e, r) => {
      l.onFocus({
        fieldType: e,
        event: r
      });
    },
    Ke = (e, r) => {
      l.onBlur({
        fieldType: e,
        event: r
      });
    },
    He = x(Z, Te, Ke),
    Ue = () => A({
      props: l,
      showKCP: _e,
      showBrazilianSSN: Le,
      ...(l.billingAddressRequired && {
        countrySpecificSchemas: H.getAddressSchemaForCountry(be?.country),
        billingAddressRequiredFields: l.billingAddressRequiredFields
      })
    }),
    ze = n(e => {
      const r = "webInternalElement" !== e.fieldType ? e.fieldType : e.name;
      xe(r);
    }, []),
    We = R(Ve, Ee, Oe),
    $e = k(K, O, Ue()),
    Ge = t(() => y(l, {
      sfp: O
    }, {
      dualBrandSelectElements: le,
      setDualBrandSelectElements: de,
      setSelectedBrandValue: ce,
      issuingCountryCode: ae,
      setIssuingCountryCode: ie
    }, T), [le, ae]);
  L.current.showValidation = () => {
    q.current = !0, Xe?.(), O.current.showValidation(), Pe(["holderName", "socialSecurityNumber", "taxNumber"]), D?.current && D.current.showValidation();
  }, L.current.processBinLookupResponse = (e, r) => {
    Ge.processBinLookup(e, r);
  }, L.current.setStatus = z, a(() => (L.current.setFocusOn = O.current.setFocusOn, L.current.updateStyles = O.current.updateStyles, L.current.handleUnsupportedCard = O.current.handleUnsupportedCard, () => {
    O.current.destroy();
  }), []), a(() => {
    const e = [...(l.hasHolderName ? ["holderName"] : []), ...(Le ? ["socialSecurityNumber"] : []), ...(_e ? ["taxNumber"] : []), ...(me ? ["billingAddress"] : [])];
    Be(e);
  }, [l.hasHolderName, Le, _e]), a(() => {
    X({
      ...Q,
      holderName: ve.holderName ?? "",
      taxNumber: ve.taxNumber
    }), Fe(ve.socialSecurityNumber), me && Ne({
      ...ve.billingAddress
    }), J({
      ...G,
      holderName: !l.holderNameRequired || we.holderName,
      socialSecurityNumber: !!we.socialSecurityNumber && we.socialSecurityNumber,
      taxNumber: !!we.taxNumber && we.taxNumber,
      billingAddress: !!we.billingAddress && we.billingAddress
    });
    const e = !!Ie.billingAddress && Object.entries(Ie.billingAddress).reduce((e, [, r]) => e || null != r, !1);
    $({
      ...W,
      holderName: l.holderNameRequired && Ie.holderName ? Ie.holderName : null,
      socialSecurityNumber: Le && Ie.socialSecurityNumber ? Ie.socialSecurityNumber : null,
      taxNumber: _e && Ie.taxNumber ? Ie.taxNumber : null,
      billingAddress: me && e ? Ie.billingAddress : null
    });
  }, [ve, we, Ie]);
  const {
    sortedErrorList: Je,
    previousSortedErrors: Qe,
    clearSRPanel: Xe
  } = E({
    errors: W,
    props: l,
    isValidating: q,
    retrieveLayout: Ue,
    specifications: H,
    billingAddress: be,
    sfp: O
  });
  if (Je) {
    const e = I(Je, Qe, "field");
    e?.forEach(e => {
      const r = {
        fieldType: e.field,
        errorCode: e.errorCode
      };
      l.onValidationErrorAnalytics(r);
    });
  }
  a(() => {
    const e = G.holderName,
      r = ee,
      s = !me || G.billingAddress,
      t = !_e || !!G.taxNumber && !!G.encryptedPassword,
      o = !Le || !!G.socialSecurityNumber,
      n = r && e && s && t && o,
      a = O.current.mapErrorsToValidationRuleResult(),
      i = {
        ...W,
        ...a
      };
    l.onChange({
      data: Q,
      valid: G,
      errors: i,
      isValid: n,
      billingAddress: be,
      selectedBrandValue: ue,
      storePaymentMethod: fe,
      socialSecurityNumber: Ae,
      installments: Ce
    });
  }, [Q, G, W, ue, fe, Ce]);
  const Ye = l.storedPaymentMethodId ? C : j;
  return e(r, null, e(i, {
    ref: O,
    ...S(l),
    styles: {
      ...l.styles
    },
    koreanAuthenticationRequired: l.configuration.koreanAuthenticationRequired,
    hasKoreanFields: !(!l.configuration.koreanAuthenticationRequired || "kr" !== l.countryCode),
    onChange: (e, r) => {
      if (e.autoCompleteName) {
        if (!l.hasHolderName) return;
        const r = f("holderName", "blur")(e.autoCompleteName) ? e.autoCompleteName : null;
        r && (Ve("holderName", r), Ee("holderName", !0), Oe("holderName", null));
      } else l.autoFocus && T.current > 0 && "handleOnFieldValid" === r?.event && r?.fieldType === u && e.valid.encryptedCardNumber && $e(), X({
        ...Q,
        ...e.data
      }), $({
        ...W,
        ...e.errors
      }), J({
        ...G,
        ...e.valid
      }), re(e.isSfpValid), ne(e.cvcPolicy), ge(e.showSocialSecurityNumber), te(e.expiryDatePolicy);
    },
    onBrand: l.onBrand,
    onFocus: He,
    type: l.brand,
    disableIOSArrowKeys: l.disableIOSArrowKeys ? ze : null,
    render: ({
      setRootNode: r,
      setFocusOn: s
    }, t) => e("div", {
      ref: r,
      className: P({
        "adyen-checkout__card-input": !0,
        "adyen-checkout-card-input__wrapper": !0,
        [`adyen-checkout__card-input--${l.fundingSource ?? "credit"}`]: !0,
        "adyen-checkout__card-input--loading": "loading" === U
      }),
      role: "form"
    }, e(B, null), e(Ye, {
      ...g(l),
      data: Q,
      valid: G,
      errors: W,
      handleChangeFor: Re,
      focusedElement: Y,
      setFocusOn: s,
      sfpState: t,
      cvcPolicy: oe,
      hasInstallments: qe,
      showAmountsInInstallments: Me,
      handleInstallments: je,
      brandsIcons: l.brandsIcons,
      formData: ve,
      formErrors: Ie,
      formValid: we,
      expiryDatePolicy: se,
      dualBrandSelectElements: le,
      extensions: Ge,
      selectedBrandValue: ue,
      showKCP: _e,
      showBrazilianSSN: Le,
      socialSecurityNumber: Ae,
      handleOnStoreDetails: ye,
      setAddressRef: _,
      billingAddress: be,
      billingAddressValidationRules: pe && v(he.current),
      partialAddressSchema: pe,
      handleAddress: We,
      onAddressLookup: l.onAddressLookup,
      onAddressSelected: l.onAddressSelected,
      addressSearchDebounceMs: l.addressSearchDebounceMs,
      iOSFocusedField: ke,
      onFieldFocusAnalytics: Te,
      onFieldBlurAnalytics: Ke
    }))
  }), l.showPayButton && l.payButton({
    status: U,
    variant: l.isPayButtonPrimaryVariant ? "primary" : "secondary",
    icon: M({
      imageFolder: "components/"
    })(`${V}lock`)
  }));
};
O.defaultProps = l;
export { O as default };
