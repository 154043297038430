@if (loaded) {
  <lib-adyen-payment
    [checkout]="checkout"
    [payByLink]="payByLink"
    [isAdvancedFlow]="isAdvancedFlow"
    [adyenOrderType]="adyenOrderType"
    (onPaymentCompleted)="onPaymentCompleted($event)"
    (onPaymentFailed)="onPaymentFailed($event)"
  ></lib-adyen-payment>
}
