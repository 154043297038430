import e from "../components/ThreeDS2/ThreeDS2Challenge.js";
import t from "../components/ThreeDS2/ThreeDS2DeviceFingerprint.js";
import r from "../components/Redirect/Redirect.js";
import { TxVariants as n } from "../components/tx-variants.js";
const o = {
  [n.redirect]: r,
  [n.threeDS2Challenge]: e,
  [n.threeDS2DeviceFingerprint]: t
};
var s = new class {
  componentsMap = o;
  supportedTxVariants = new Set(Object.values(n));
  add(...e) {
    this.componentsMap = {
      ...this.componentsMap,
      ...this.createComponentsMap(e)
    };
  }
  getComponent(e) {
    const t = this.componentsMap[e];
    return t || (this.supportedTxVariants.has(e) ? void 0 : r);
  }
  createComponentsMap(e) {
    return e.reduce((e, t) => {
      var r;
      if (!("string" == typeof (r = t).type && !!r.type)) return console.error("CoreRegistry: Attempt to register Class failed. The Class is not a valid UIElement"), e;
      if ("dropin" === t.type) return e;
      return [t.type, ...t.txVariants].filter(e => e).forEach(r => {
        e = {
          ...e,
          [r]: t
        };
      }), e;
    }, {});
  }
}();
export { s as default };
