const e = {
  shopperLogin: {
    validate: e => !!e && e.length > 0,
    errorMessage: "",
    modes: ["blur"]
  },
  default: {
    validate: e => !!e && e.length > 0,
    errorMessage: "",
    modes: ["blur"]
  }
};
export { e as loginValidationRules };
