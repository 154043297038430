import { MC_SDK_PROD as t, MC_SDK_TEST as e, getMastercardSettings as i } from "./config.js";
import n from "./AbstractSrcInitiator.js";
import o from "./SrciError.js";
const s = {
  email: "EMAIL_ADDRESS",
  telephoneNumber: "MOBILE_PHONE_NUMBER"
};
class a extends n {
  schemeName = "mc";
  constructor(i, n) {
    super(i.toLowerCase().includes("live") ? t : e, n);
  }
  isSdkIsAvailableOnWindow() {
    return !!window.SRCSDK_MASTERCARD;
  }
  assignSdkReference() {
    this.schemeSdk = window.SRCSDK_MASTERCARD;
  }
  async init(t, e) {
    try {
      const n = {
        ...t,
        ...i(this.customSdkConfiguration),
        srciTransactionId: e
      };
      await this.schemeSdk.init(n);
    } catch (t) {
      throw new o(t, "init", this.schemeName);
    }
  }
  async identityLookup({
    identityValue: t,
    type: e
  }) {
    try {
      const i = {
        identityValue: t,
        identityType: s[e]
      };
      return await this.schemeSdk.identityLookup({
        consumerIdentity: i
      });
    } catch (t) {
      throw new o(t, "identityLookup", this.schemeName);
    }
  }
  async completeIdentityValidation(t) {
    try {
      return await this.schemeSdk.completeIdentityValidation({
        validationData: t
      });
    } catch (t) {
      throw new o(t, "completeIdentityValidation", this.schemeName);
    }
  }
}
export { a as default };
