import { Component, OnInit } from '@angular/core';
import { OrderContextService } from '../../services/order-context.service';

import { Router, ActivatedRoute } from '@angular/router';
import { SimplifiedOrderContext } from '../../models/simplifiedOrderContext';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { first, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'lib-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css']
})
export class OrderConfirmationComponent implements OnInit {
  public context?: SimplifiedOrderContext;

  timeInterval: Subscription;
  public error?: any;
  public processing = true;
  public stripe: boolean;

  constructor(protected orderContextService: OrderContextService, protected router: ActivatedRoute) { }

  ngOnInit() {
    const contextId = this.router.snapshot.params.contextId;
    const stripe = this.router.snapshot.queryParams.stripe;
    this.stripe = stripe === 'success';

    this.timeInterval = interval(1000)
      .pipe(
        startWith(0),
        switchMap(() => this.orderContextService.getSimplifiedOrderContext(contextId)),
        first(e => this.isOrderFinalized(e))
      )
      .subscribe(
        context => {
          this.processing = false;
          this.context = context;
          console.log('context', context);
        },
        err => {
          console.log('err', err);
        }
      );
  }

  protected isOrderFinalized(context: SimplifiedOrderContext): boolean {
    return context.finalized || (context.erpOrderIds && context.erpOrderIds.length > 0);
  }
}
