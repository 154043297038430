import { createElement as e, Fragment as t } from "../../../external/preact/dist/preact.js";
import { useRef as s, useMemo as o, useState as r, useCallback as a, useEffect as n } from "../../../external/preact/hooks/dist/hooks.js";
import i from "../FormFields/Fieldset/Fieldset.js";
import l from "./components/ReadOnlyAddress.js";
import { getAddressValidationRules as d } from "./validate.js";
import { addressFormatters as c, countrySpecificFormatters as u } from "./validate.formats.js";
import m from "./components/FieldContainer.js";
import p from "../../../utils/useForm/useForm.js";
import f from "./Specifications.js";
import { FALLBACK_VALUE as y, ADDRESS_SCHEMA as F } from "./constants.js";
import { getMaxLengthByFieldAndCountry as h } from "../../../utils/validator-utils.js";
import { useCoreContext as C } from "../../../core/Context/CoreProvider.js";
import g from "./components/AddressSearch.js";
function A(A) {
  const {
      i18n: v
    } = C(),
    {
      label: b = "",
      requiredFields: j,
      visibility: x,
      iOSFocusedField: S = null,
      showContextualElement: k
    } = A,
    M = s({});
  Object.keys(M.current).length || A.setComponentRef?.(M.current);
  const O = o(() => new f(A.specifications), [A.specifications]),
    w = O.getAddressSchemaForCountryFlat(A.countryCode).filter(e => j.includes(e)),
    [D, E] = r(!1),
    [B, N] = r(!1),
    [P, L] = r(""),
    R = !!A.onAddressLookup,
    V = !A.onAddressLookup || D || B,
    {
      data: q,
      errors: H,
      valid: T,
      isValid: _,
      handleChangeFor: I,
      triggerValidation: $,
      setData: z
    } = p({
      schema: w,
      defaultData: A.data,
      rules: {
        ...d(O),
        ...A.validationRules
      },
      formatters: c
    }),
    G = a(e => {
      F.forEach(t => {
        const s = e[t];
        null != s && z(t, String(s));
      }), $(), E(!0);
    }, [E, $, z]),
    J = a(() => {
      N(!0);
    }, []);
  M.current.showValidation = () => {
    $(), L(!R || V || _ ? "" : v.get("address.errors.incomplete"));
  };
  const K = w.filter(e => !S || e === S);
  if (n(() => {
    const e = O.countryHasDataset(q.country) ? "" : y,
      t = {
        ...q,
        stateOrProvince: e
      };
    j.forEach(e => {
      I(e, "input")(t[e] ?? "");
    }), t.postalCode && I("postalCode", "blur")(q.postalCode);
  }, [q.country]), n(() => {
    const e = j.includes("stateOrProvince"),
      t = q.country && O.countryHasDataset(q.country),
      s = e && t,
      o = q.stateOrProvince || (s ? "" : y);
    I("stateOrProvince", "input")(o);
  }, []), n(() => {
    const e = O.getOptionalFieldsForCountry(q.country),
      t = F.reduce((t, s) => {
        const o = e.includes(s),
          r = j.includes(s),
          a = q[s],
          n = A.data[s],
          i = o && !a || !r ? r || a || !n ? y : n : a;
        return i?.length && (t[s] = i), t;
      }, {});
    A.onChange({
      data: t,
      valid: T,
      errors: H,
      isValid: _
    });
  }, [q, T, H, _]), "hidden" === x) return null;
  if ("readOnly" === x) return e(l, {
    data: q,
    label: b
  });
  const Q = (t, {
      classNameModifiers: s = []
    }) => j.includes(t) ? e(m, {
      key: t,
      allowedCountries: A.allowedCountries,
      classNameModifiers: [...s, t],
      data: q,
      errors: H,
      valid: T,
      fieldName: t,
      onInput: I(t, "input"),
      onBlur: I(t, "blur"),
      onDropdownChange: I(t, "blur"),
      specifications: O,
      maxLength: h(u, t, q.country),
      trimOnBlur: !0,
      disabled: !K.includes(t),
      onFieldFocusAnalytics: A.onFieldFocusAnalytics,
      onFieldBlurAnalytics: A.onFieldBlurAnalytics
    }) : null,
    U = O.getAddressSchemaForCountry(q.country);
  return e(t, null, e(i, {
    classNameModifiers: [b || "address"],
    label: b
  }, R && e(g, {
    onAddressLookup: A.onAddressLookup,
    onAddressSelected: A.onAddressSelected,
    onSelect: G,
    onManualAddress: J,
    externalErrorMessage: P,
    hideManualButton: V,
    showContextualElement: k,
    contextualText: v.get("address.search.contextualText"),
    addressSearchDebounceMs: A.addressSearchDebounceMs
  }), V && e(t, null, U.map(t => t instanceof Array ? e("div", {
    className: "adyen-checkout__field-group"
  }, t.map(([e, t]) => Q(e, {
    classNameModifiers: [`col-${t}`]
  }))) : Q(t, {})))));
}
A.defaultProps = {
  countryCode: null,
  validationRules: null,
  data: {},
  onChange: () => {},
  visibility: "editable",
  requiredFields: F,
  specifications: {},
  onFieldFocusAnalytics: () => {},
  onFieldBlurAnalytics: () => {}
};
export { A as default };
