import { createContext as t } from "../../../../external/preact/dist/preact.js";
const l = t({
  status: null,
  onSubmit: null,
  onSetStatus: null,
  onError: null,
  onReady: null,
  amount: null,
  configuration: null,
  isStandaloneComponent: null,
  isCtpPrimaryPaymentMethod: null,
  isStoringCookies: !1,
  setIsCtpPrimaryPaymentMethod: null,
  logoutShopper: null,
  updateStoreCookiesConsent: null,
  ctpState: null,
  cards: [],
  schemes: [],
  otpMaskedContact: null,
  otpNetwork: null,
  checkout: null,
  verifyIfShopperIsEnrolled: null,
  startIdentityValidation: null,
  finishIdentityValidation: null
});
export { l as ClickToPayContext };
