import { createElement as e, Fragment as t } from "../../../../external/preact/dist/preact.js";
import a from "../../FormFields/Fieldset/Fieldset.js";
import { FALLBACK_VALUE as r } from "../constants.js";
const s = ({
    firstName: a,
    lastName: r
  }) => e(t, null, a && `${a} `, r && `${r}`, e("br", null)),
  l = ({
    data: l,
    label: m
  }) => {
    const {
      street: o,
      houseNumberOrName: n,
      city: i,
      postalCode: c,
      stateOrProvince: d,
      country: u,
      firstName: N,
      lastName: f
    } = l;
    return e(a, {
      classNameModifiers: [m],
      label: m,
      readonly: !0
    }, e(t, null, (N || f) && e(s, {
      firstName: N,
      lastName: f
    }), !!o && o, n && `, ${n},`, e("br", null), c && `${c}`, i && `, ${i}`, d && d !== r && `, ${d}`, u && `, ${u} `));
  };
export { l as default };
