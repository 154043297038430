import { Injectable, Inject } from '@angular/core';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { GOOGLE_ANALYTICS_ID, GOOGLE_TAG_MANAGER_ID } from '../utils/google-analytics.provider';
import { GungAnalyticsConsentService } from './gung-analytics-consent.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  constructor(
    @Inject(GOOGLE_ANALYTICS_ID) protected analyticsId: string,
    @Inject(GOOGLE_TAG_MANAGER_ID) protected tagManagerId: string,
    protected angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    protected gungAnalyticsConsentService: GungAnalyticsConsentService
  ) {
    this.initialize();
  }

  protected initialize() {
    if (this.gungAnalyticsConsentService.isUserConsentingToAnalytics()) {
      if (this.analyticsId !== '') {
        this.loadGa(this.analyticsId);
      } else if (this.tagManagerId !== '') {
        this.loadTag(this.tagManagerId);
        this.angulartics2GoogleTagManager.startTracking();
      }
    }
  }

  protected loadTag(tagId: string) {
    const headScriptId = 'google-tag-manager-head';
    const bodyScriptId = 'google-tag-manager-body';

    if (!document.getElementById(headScriptId)) {
      const headScript = document.createElement('script');
      headScript.type = 'text/javascript';
      headScript.id = headScriptId;
      headScript.innerText = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${tagId}');
      `;
      document.getElementsByTagName('head')[0].appendChild(headScript);
    }

    if (!document.getElementById(bodyScriptId)) {
      const bodyScript = document.createElement('noscript');
      bodyScript.id = bodyScriptId;
      bodyScript.innerText = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${tagId}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.getElementsByTagName('body')[0].appendChild(bodyScript);
    }
  }

  protected loadGa(analyticsId: string) {
    const scriptId = 'google-analytics';
    if (document.getElementById(scriptId)) {
      return;
    }

    const comp = document.createElement('script');
    comp.type = 'text/javascript';
    comp.id = scriptId;
    comp.innerText = `
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
      ga('create', '${analyticsId}', 'auto');
      ga('send', 'pageview');
    `;
    document.getElementsByTagName('head')[0].appendChild(comp);
  }
}
