import { createElement as t, Fragment as e } from "../../../external/preact/dist/preact.js";
import { useCoreContext as a } from "../../../core/Context/CoreProvider.js";
import { useState as o } from "../../../external/preact/hooks/dist/hooks.js";
function n({
  label: n = null,
  icon: r = null,
  payButton: s,
  onSubmit: l,
  amount: i = null,
  name: u,
  showPayButton: c,
  ...m
}) {
  const {
      i18n: p
    } = a(),
    [d, f] = o("ready");
  this.setStatus = t => {
    f(t);
  };
  if (c) return t(e, null, s({
    ...m,
    status: d,
    icon: r,
    classNameModifiers: ["standalone"],
    label: n || (i && {}.hasOwnProperty.call(i, "value") && 0 === i.value ? `${p.get("preauthorizeWith")} ${u}` : `${p.get("continueTo")} ${u}`),
    onClick: l
  }));
}
export { n as default };
