import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

/**
 * Service for handling user consent to analytics, stored in local storage by default.
 * The localStorage is currently set through the cookie consent banner upon acceptance.
 * The service is used to determine if the user has consented to analytics.
 */
export class GungAnalyticsConsentService {
  public isUserConsentingToAnalytics(): boolean {
    return localStorage.getItem('cookiesAccepted') === 'true' || localStorage.getItem('cookiesAccepted') === null;
  }
}
