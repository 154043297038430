import { createElement as t } from "../../external/preact/dist/preact.js";
import { UIElement as e } from "../internal/UIElement/UIElement.js";
import o from "./GooglePayService.js";
import s from "./components/GooglePayButton.js";
import r from "./defaultProps.js";
import { getGooglePayLocale as n, formatGooglePayContactToAdyenAddressFormat as a } from "./utils.js";
import i from "../../utils/browserInfo.js";
import l from "../../core/Errors/AdyenCheckoutError.js";
import { TxVariants as h } from "../tx-variants.js";
import { sanitizeResponse as p, verifyPaymentDidNotFail as d } from "../internal/UIElement/utils.js";
import { ANALYTICS_SELECTED_STR as y, ANALYTICS_INSTANT_PAYMENT_BUTTON as u } from "../../core/Analytics/constants.js";
class g extends e {
  static type = h.googlepay;
  static txVariants = [h.googlepay, h.paywithgoogle];
  static defaultProps = r;
  googlePay;
  constructor(t, e) {
    super(t, e), this.handleAuthorization = this.handleAuthorization.bind(this);
    const {
      isExpress: s,
      paymentDataCallbacks: r
    } = this.props;
    if (!1 === s && r?.onPaymentDataChanged) throw new l("IMPLEMENTATION_ERROR", 'GooglePay - You must set "isExpress" flag to "true" in order to use "onPaymentDataChanged" callback');
    this.googlePay = new o(this.props.environment, {
      ...(s && r?.onPaymentDataChanged && {
        onPaymentDataChanged: r.onPaymentDataChanged
      }),
      onPaymentAuthorized: this.onPaymentAuthorized
    });
  }
  formatProps(t) {
    const e = t.buttonSizeMode ?? (t.isDropin ? "fill" : "static"),
      o = n(t.buttonLocale ?? t.i18n?.locale),
      s = [...t.callbackIntents, "PAYMENT_AUTHORIZATION"];
    return {
      ...t,
      configuration: t.configuration,
      buttonSizeMode: e,
      buttonLocale: o,
      callbackIntents: s
    };
  }
  formatData() {
    const {
        googlePayCardNetwork: t,
        googlePayToken: e,
        billingAddress: o,
        deliveryAddress: s
      } = this.state,
      {
        isExpress: r
      } = this.props;
    return {
      paymentMethod: {
        type: this.type,
        googlePayCardNetwork: t,
        googlePayToken: e,
        ...(r && {
          subtype: "express"
        })
      },
      browserInfo: this.browserInfo,
      origin: !!window && window.location.origin,
      ...(o && {
        billingAddress: o
      }),
      ...(s && {
        deliveryAddress: s
      })
    };
  }
  submitAnalytics(t) {
    super.submitAnalytics({
      ...t
    }, this.props);
  }
  submit = () => {
    this.props.isInstantPayment && this.submitAnalytics({
      type: y,
      target: u
    }), new Promise((t, e) => this.props.onClick(t, e)).then(() => this.googlePay.initiatePayment(this.props, this.core.options.countryCode)).catch(t => {
      "CANCELED" === t.statusCode ? this.handleError(new l("CANCEL", t.toString(), {
        cause: t
      })) : this.handleError(new l("ERROR", t.toString(), {
        cause: t
      }));
    });
  };
  onPaymentAuthorized = async t => {
    const e = a(t.paymentMethodData.info.billingAddress),
      o = a(t.shippingAddress, !0);
    return this.setState({
      authorizedEvent: t,
      googlePayToken: t.paymentMethodData.tokenizationData.token,
      googlePayCardNetwork: t.paymentMethodData.info.cardNetwork,
      ...(e && {
        billingAddress: e
      }),
      ...(o && {
        deliveryAddress: o
      })
    }), new Promise(t => {
      this.handleAuthorization().then(this.makePaymentsCall).then(p).then(d).then(e => (t({
        transactionState: "SUCCESS"
      }), e)).then(t => {
        this.handleResponse(t);
      }).catch(e => {
        this.setElementStatus("ready");
        const o = e?.error?.googlePayError,
          s = this.props.i18n.get("error.subtitle.payment"),
          r = "string" == typeof o ? {
            intent: "PAYMENT_AUTHORIZATION",
            reason: "OTHER_ERROR",
            message: o || s
          } : {
            intent: o?.intent || "PAYMENT_AUTHORIZATION",
            reason: o?.reason || "OTHER_ERROR",
            message: o?.message || s
          };
        t({
          transactionState: "ERROR",
          error: r
        });
        const n = {
          ...e,
          error: {
            googlePayError: r
          }
        };
        this.handleFailedResult(n);
      });
    });
  };
  async handleAuthorization() {
    return new Promise((t, e) => {
      this.props.onAuthorized || t();
      const {
        authorizedEvent: o,
        billingAddress: s,
        deliveryAddress: r
      } = this.state;
      this.props.onAuthorized({
        authorizedEvent: o,
        ...(s && {
          billingAddress: s
        }),
        ...(r && {
          deliveryAddress: r
        })
      }, {
        resolve: t,
        reject: e
      });
    }).catch(t => {
      const e = {
        error: {
          googlePayError: t
        }
      };
      return Promise.reject(e);
    });
  }
  get isValid() {
    return !!this.state.googlePayToken;
  }
  async isAvailable() {
    return this.isReadyToPay().then(t => {
      if (!t.result) throw new l("ERROR", "GooglePay is not available");
      if (!1 === t.paymentMethodPresent) throw new l("ERROR", "GooglePay - No paymentMethodPresent");
      return Promise.resolve();
    }).catch(t => Promise.reject(t));
  }
  isReadyToPay = () => this.googlePay.isReadyToPay(this.props);
  prefetch = () => this.googlePay.prefetchPaymentData(this.props, this.core.options.countryCode);
  get browserInfo() {
    return i();
  }
  get icon() {
    return this.props.icon ?? this.resources.getImage()("googlepay");
  }
  render() {
    return this.props.showPayButton ? t(s, {
      buttonColor: this.props.buttonColor,
      buttonType: this.props.buttonType,
      buttonSizeMode: this.props.buttonSizeMode,
      buttonLocale: this.props.buttonLocale,
      buttonRootNode: this.props.buttonRootNode,
      buttonRadius: this.props.buttonRadius,
      paymentsClient: this.googlePay.paymentsClient,
      onClick: this.submit
    }) : null;
  }
}
export { g as default };
