import { Injectable } from '@angular/core';
import { OnInitEffects, createEffect, ofType, Actions } from '@ngrx/effects';
import {
  SupplierActionTypes,
  SelectSupplier,
  SupplierSelected,
  GetDefaultSupplier,
  ClearSelectedSupplier
} from './actions';
import { map, filter, mergeMap, tap, first, switchMap, catchError } from 'rxjs';
import { Action } from '@ngrx/store';
import { ActionTypes, UserReceived } from '../auth/actions';
import { of } from 'rxjs';
import { Supplier } from '../../models/supplier';
import { SupplierService } from '../../services/suppliers/supplier.service';

@Injectable()
export class SupplierEffects {
  constructor(
    private actions$: Actions,
    private supplierService: SupplierService
  ) {}

  selectSupplier$ = createEffect(() =>
    this.actions$.pipe(
      filter(action => action.type === SupplierActionTypes.SelectSupplier),
      map(action => action as SelectSupplier),
      map(action => action.payload.supplierId),
      // switchMap(id => this.supplierService.getSupplier(id, false)), // FC: fix when no supplier present
      switchMap(id =>
        this.supplierService
          .getSupplier(id, false)
          .pipe(catchError(err => of({ id: undefined, name: undefined, extra: undefined } as Supplier)))
      ),
      map(supplier => new SupplierSelected(supplier))
    )
  );

  getDefaultSupplier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierActionTypes.GetDefaultSupplier),
      switchMap(_ => {
        // return this.supplierService.getDefaultSupplier(true); // FC: fix when no supplier present
        return this.supplierService
          .getDefaultSupplier()
          .pipe(catchError(err => of({ id: undefined, name: undefined, extra: undefined } as Supplier)));
      }),
      map(cust => (cust !== null ? new SupplierSelected(cust) : new ClearSelectedSupplier()))
    )
  );

  getSupplierSelected$ = createEffect(
    () =>
      this.actions$.pipe(
        filter(action => action.type === SupplierActionTypes.SupplierSelected),
        map(action => action as SupplierSelected),
        map(action => action.payload),
        tap(supplier => localStorage.setItem('selectedSupplierId', supplier?.id))
      ),
    { dispatch: false }
  );

  userReceived$ = createEffect(() =>
    this.actions$.pipe(
      filter(action => action.type === ActionTypes.UserReceived),
      map(action => action as UserReceived),
      map(() => {
        let supplierId: string | null = null;

        const supplierIdFromLocalstorage = localStorage.getItem('selectedSupplierId');
        if (supplierIdFromLocalstorage != null) {
          supplierId = supplierIdFromLocalstorage;
        }
        return supplierId;
      }),
      switchMap(supplierId => {
        if (!supplierId || supplierId === 'undefined') {
          return of(new GetDefaultSupplier());
        }
        return this.supplierService
          .getSupplierAllowed(supplierId)
          .pipe(map(allowed => (allowed ? new SelectSupplier({ supplierId }) : new GetDefaultSupplier())));
      })
    )
  );
}
