import { Injectable, Input, Directive } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  PimTemplateProperties
} from 'gung-list';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs';
import { ProductTreeListViewComponent } from '../../components/product-tree-list-view/product-tree-list-view.component';
import { ProductTreeTableViewComponent } from '../../components/product-tree-table-view/product-tree-table-view.component';
import { Product, ProductExtended } from '../../models';
import { AuthService } from '../auth/auth.service';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { MetadataService } from '../metadata/metadata.service';
import { ProductListConfigService } from '../product-list-config/product-list-config.service';
import { ProductSortService } from '../product-sort/product-sort.service';
import { ProductService } from '../products/product.service';
import { DynamicColumnsService } from '../dynamic-columns/dynamic-columns.service';
import { CloudPimFiltersService } from '../cloud-pim-filters/cloud-pim-filters.service';
import { CurrentAssortmentService } from '../base-view-config/base-view-config.service';
import { ProductListViewV2Component } from '../../components/product-list-view-v2/product-list-view-v2.component';

@Injectable({
  providedIn: 'root'
})
export class ProductTreeListConfigService extends ProductListConfigService implements ConfigService<Product> {
  productsObjectsSubject: Subject<Product[]> = new BehaviorSubject(null);

  @Input()
  filters: ConfigBaseFilter<Product>[] = [];

  @Input()
  public dynamicColumns?: PimTemplateProperties[];

  @Input()
  public pimOptions?: { [s: string]: any };

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected productSortService: ProductSortService,
    protected gungFlowService: GungFlowService,
    protected metadataService: MetadataService,
    protected dynamicColumnsService: DynamicColumnsService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected currentAssortmentService: CurrentAssortmentService
  ) {
    super(
      productService,
      authService,
      productSortService,
      gungFlowService,
      cloudPimFiltersService,
      currentAssortmentService
    );
  }

  getFilters(): ConfigBaseFilter<Product>[] {
    return this.filters;
  }

  getSearchGroupCss() {
    return '';
  }

  getLayouts(): ListLayout<Product>[] {
    if (this.pimOptions && this.pimOptions.showAsList) {
      return [
        {
          getIconClass: () => 'fa fa-list',
          getListItemComponent: () => ProductListViewV2Component,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'ProductList'
        },
        {
          getIconClass: () => 'fas fa-th',
          getListItemComponent: () => ProductTreeListViewComponent,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'ProductGrid'
        }
      ];
    } else {
      return [
        {
          getIconClass: () => 'fas fa-th',
          getListItemComponent: () => ProductTreeListViewComponent,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'ProductGrid'
        },
        {
          getIconClass: () => 'fa fa-list',
          getListItemComponent: () => ProductListViewV2Component,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'ProductList'
        }
      ];
    }
  }

  getItems(): Observable<Product[]> {
    return this.productsObjectsSubject.pipe(
      map(m =>
        m.sort((a, b) => {
          // SORT by pim sequence high
          const aSort = a.extra.pim && a.extra.pim.sequence;
          const bSort = b.extra.pim && b.extra.pim.sequence;
          if ((!bSort && aSort) || (aSort && bSort && Number(aSort) > Number(bSort))) {
            return -1;
          } else if ((!aSort && bSort) || (aSort && bSort && Number(aSort) < Number(bSort))) {
            return 1;
          } else {
            return 0;
          }
        })
      ),
      map(products => {
        // Dyncamic column values
        products = this.dynamicColumnsService.getDynamicColumnsValuesConcept(this.dynamicColumns, products);
        return this.productSortService.sortProducts(products);
      })
    );
  }

  getDynamicColumns(): PimTemplateProperties[] {
    return this.dynamicColumns;
  }

  getPimOptions(): { [s: string]: any } {
    return this.pimOptions;
  }
}
