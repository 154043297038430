import { AddressModeOptions as e } from "./types.js";
import { CREDIT_CARD_NAME_TOP as o, CREDIT_CARD_NAME_BOTTOM as n, KCP_CARD_NAME_TOP as i, KCP_CARD_NAME_BOTTOM as r, SSN_CARD_NAME_TOP as t, SSN_CARD_NAME_BOTTOM as s, CREDIT_CARD as a, KCP_CARD as l, SSN_CARD as d } from "./layouts.js";
import { PARTIAL_ADDRESS_SCHEMA as u } from "../../../internal/Address/constants.js";
import { BRAND_READABLE_NAME_MAP as c } from "../../../internal/SecuredFields/lib/constants.js";
import { SF_ErrorCodes as m } from "../../../../core/Errors/constants.js";
const p = (e, o) => o({
    type: "card" === e ? "nocard" : e || "nocard",
    extension: "svg"
  })(e),
  C = e => "revolving" === e?.plan || e?.value > 1,
  h = ({
    props: e,
    showKCP: u,
    showBrazilianSSN: c,
    countrySpecificSchemas: m = null,
    billingAddressRequiredFields: p = null
  }) => {
    let C = a;
    const h = e.hasHolderName && e.holderNameRequired;
    if (h && (C = e.positionHolderNameOnTop ? o : n), u && (C = l, h && (C = e.positionHolderNameOnTop ? i : r)), c && (C = d, h && (C = e.positionHolderNameOnTop ? t : s)), m) {
      const i = m.flat(2).filter(e => "number" != typeof e);
      let r = i;
      p && (r = i.filter(e => p.includes(e))), C = a.concat(r), h && (C = e.positionHolderNameOnTop ? o.concat(r) : n.concat(r));
    }
    return C;
  },
  R = (e, o, n) => {
    switch (e) {
      case "socialSecurityNumber":
        return o.get(`boleto.${e}`);
      case "street":
      case "houseNumberOrName":
      case "postalCode":
      case "stateOrProvince":
      case "city":
      case "country":
        return n?.[e] ? o.get(n?.[e]) : o.get(e);
      default:
        return null;
    }
  },
  g = e => ({
    amount: e.amount,
    billingAddressRequired: e.billingAddressRequired,
    billingAddressRequiredFields: e.billingAddressRequiredFields,
    billingAddressAllowedCountries: e.billingAddressAllowedCountries,
    brandsConfiguration: e.brandsConfiguration,
    showStoreDetailsCheckbox: e.showStoreDetailsCheckbox,
    hasCVC: e.hasCVC,
    hasHolderName: e.hasHolderName,
    holderNameRequired: e.holderNameRequired,
    installmentOptions: e.installmentOptions,
    placeholders: e.placeholders,
    positionHolderNameOnTop: e.positionHolderNameOnTop,
    showBrandIcon: e.showBrandIcon,
    showContextualElement: e.showContextualElement,
    lastFour: e.lastFour,
    expiryMonth: e.expiryMonth,
    expiryYear: e.expiryYear,
    disclaimerMessage: e.disclaimerMessage
  }),
  y = e => ({
    autoFocus: e.autoFocus,
    brands: e.brands,
    brandsConfiguration: e.brandsConfiguration,
    clientKey: e.clientKey,
    countryCode: e.countryCode,
    forceCompat: e.forceCompat,
    i18n: e.i18n,
    implementationType: e.implementationType,
    keypadFix: e.keypadFix,
    legacyInputMode: e.legacyInputMode,
    loadingContext: e.loadingContext,
    maskSecurityCode: e.maskSecurityCode,
    exposeExpiryDate: e.exposeExpiryDate,
    minimumExpiryDate: e.minimumExpiryDate,
    onAdditionalSFConfig: e.onAdditionalSFConfig,
    onAdditionalSFRemoved: e.onAdditionalSFRemoved,
    onAllValid: e.onAllValid,
    onAutoComplete: e.onAutoComplete,
    onBinValue: e.onBinValue,
    onConfigSuccess: e.onConfigSuccess,
    handleKeyPress: e.handleKeyPress,
    onError: e.onError,
    onFieldValid: e.onFieldValid,
    onLoad: e.onLoad,
    placeholders: e.placeholders,
    resources: e.resources,
    showContextualElement: e.showContextualElement,
    showWarnings: e.showWarnings,
    trimTrailingSeparator: e.trimTrailingSeparator
  }),
  S = o => o == e.partial ? u : null;
function f(e) {
  return ![m.ERROR_MSG_UNSUPPORTED_CARD_ENTERED, m.ERROR_MSG_CARD_TOO_OLD, m.ERROR_MSG_CARD_TOO_FAR_IN_FUTURE, m.ERROR_MSG_CARD_EXPIRES_TOO_SOON].includes(e);
}
function O(e) {
  return c[e] ?? e;
}
export { g as extractPropsForCardFields, y as extractPropsForSFP, p as getCardImageUrl, O as getFullBrandName, h as getLayout, S as handlePartialAddressMode, C as hasValidInstallmentsObject, f as lookupBlurBasedErrors, R as mapFieldKey };
