import { createElement as e } from "../../../../../external/preact/dist/preact.js";
import a from "../../../../../external/classnames/index.js";
import t from "../../../Img/Img.js";
import o from "../../../Icon/Icon.js";
import { PREFIX as n } from "../../../Icon/constants.js";
const d = ({
  item: d,
  active: c,
  selected: s,
  ...l
}) => e("li", {
  "aria-disabled": !!d.disabled,
  "aria-selected": s,
  className: a(["adyen-checkout__dropdown__element", {
    "adyen-checkout__dropdown__element--active": c,
    "adyen-checkout__dropdown__element--disabled": !!d.disabled
  }]),
  "data-disabled": !0 === d.disabled || null,
  "data-value": d.id,
  onClick: l.onSelect,
  onMouseEnter: l.onHover,
  role: "option",
  id: `listItem-${d.id}`
}, d.icon && e(t, {
  className: "adyen-checkout__dropdown__element__icon",
  alt: d.name,
  src: d.icon
}), e("span", {
  className: "adyen-checkout__dropdown__element__text"
}, d.name), d.secondaryText && e("span", {
  className: "adyen-checkout__dropdown__element__secondary-text"
}, d.secondaryText), s && e(o, {
  type: `${n}checkmark`,
  height: 14,
  width: 14
}));
export { d as default };
