class e {
  callbacks;
  config;
  props;
  state;
  validateForm;
  handleBrandFromBinLookup;
  callbacksHandler;
  configHandler;
  createCardSecuredFields;
  createNonCardSecuredFields;
  createSecuredFields;
  destroySecuredFields;
  handleIOSTouchEvents;
  destroyTouchendListener;
  destroyTouchstartListener;
  handleBinValue;
  handleEncryption;
  handleFocus;
  handleIframeConfigFeedback;
  handleValidation;
  handleSFShiftTab;
  handleShiftTab;
  isConfigured;
  postMessageToAllIframes;
  processAutoComplete;
  processBrand;
  sendBrandToCardSF;
  sendExpiryDatePolicyToSF;
  setFocusOnFrame;
  setupSecuredField;
  touchendListener;
  touchstartListener;
  hasGenuineTouchEvents;
  encryptedAttrName;
  hasRedundantCVCField;
  isSingleBrandedCard;
  securityCode;
  constructor(e) {
    this.props = e, this.state = {}, this.config = {}, this.callbacks = {};
  }
}
export { e as default };
