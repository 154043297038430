@if (!loading) {
  @for (config of configuration | keyvalue; track config.key) {
    @if (config.key !== '@class' && config.key !== 'id') {
      @switch (typeOf(config.value)) {
        @case ('boolean') {
          <div class="form-check form-switch">
            <input class="form-check-input rounded mr-1" style="transform: scale(1.2);" type="checkbox" role="switch"
                   id="{{config.key}}" [(ngModel)]="configuration[config.key]" (ngModelChange)="save()">
            <label class="form-check-label" for="{{config.key}}">{{ config.key | translate }}</label>
          </div>
        }
        @default {
          <lib-gung-text-input
            [gungTranslate]="config.key"
            [(ngModel)]="configuration[config.key]"
            (ngModelChange)="save()" />
        }
      }
    }
  }
}
