const e = {
    test: "https://checkoutshopper-test.adyen.com/checkoutshopper/",
    live: "https://checkoutshopper-live.adyen.com/checkoutshopper/",
    "live-us": "https://checkoutshopper-live-us.adyen.com/checkoutshopper/",
    "live-au": "https://checkoutshopper-live-au.adyen.com/checkoutshopper/",
    "live-apse": "https://checkoutshopper-live-apse.adyen.com/checkoutshopper/",
    "live-in": "https://checkoutshopper-live-in.adyen.com/checkoutshopper/",
    fallback: "https://checkoutshopper-live.adyen.com/checkoutshopper/"
  },
  c = {
    test: "https://checkoutshopper-test.cdn.adyen.com/checkoutshopper/",
    live: "https://checkoutshopper-live.cdn.adyen.com/checkoutshopper/",
    "live-us": "https://checkoutshopper-live-us.cdn.adyen.com/checkoutshopper/",
    "live-au": "https://checkoutshopper-live-au.cdn.adyen.com/checkoutshopper/",
    "live-apse": "https://checkoutshopper-live-apse.cdn.adyen.com/checkoutshopper/",
    "live-in": "https://checkoutshopper-live-in.cdn.adyen.com/checkoutshopper/",
    fallback: "https://checkoutshopper-live.cdn.adyen.com/checkoutshopper/"
  },
  t = {
    test: "https://checkoutanalytics-test.adyen.com/checkoutanalytics/",
    live: "https://checkoutanalytics-live.adyen.com/checkoutanalytics/",
    "live-us": "https://checkoutanalytics-live-us.adyen.com/checkoutanalytics/",
    "live-au": "https://checkoutanalytics-live-au.adyen.com/checkoutanalytics/",
    "live-apse": "https://checkoutanalytics-live-apse.adyen.com/checkoutanalytics/",
    "live-in": "https://checkoutanalytics-live-in.adyen.com/checkoutanalytics/",
    fallback: "https://checkoutanalytics-live.adyen.com/checkoutanalytics/"
  };
export { t as ANALYTICS_ENVIRONMENTS, e as API_ENVIRONMENTS, c as CDN_ENVIRONMENTS };
