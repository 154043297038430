import { createElement as t } from "../../external/preact/dist/preact.js";
import { UIElement as e } from "../internal/UIElement/UIElement.js";
import { CoreProvider as o } from "../../core/Context/CoreProvider.js";
import n from "../internal/PayButton/PayButton.js";
import { KlarnaContainer as i } from "./components/KlarnaContainer/KlarnaContainer.js";
import { TxVariants as s } from "../tx-variants.js";
class a extends e {
  static type = s.klarna;
  static txVariants = [s.klarna, s.klarna_account, s.klarna_paynow, s.klarna_b2b];
  static defaultProps = {
    useKlarnaWidget: !1
  };
  constructor(t, e) {
    super(t, e), this.onComplete = this.onComplete.bind(this), this.updateWithAction = this.updateWithAction.bind(this), this.submit = this.submit.bind(this), this.onLoaded = this.onLoaded.bind(this);
  }
  get isValid() {
    return !0;
  }
  formatData() {
    return {
      paymentMethod: {
        type: this.type,
        ...(this.props.useKlarnaWidget ? {
          subtype: "sdk"
        } : {})
      }
    };
  }
  payButton = e => t(n, {
    amount: this.props.amount,
    onClick: this.submit,
    ...e
  });
  updateWithAction(t) {
    if (t.paymentMethodType !== this.type) throw new Error("Invalid Action");
    this.componentRef.setAction(t);
  }
  onLoaded() {
    this.setElementStatus("ready");
  }
  render() {
    return t(o, {
      i18n: this.props.i18n,
      loadingContext: this.props.loadingContext,
      resources: this.resources
    }, t(i, {
      ...this.props,
      ref: t => {
        this.componentRef = t;
      },
      displayName: this.displayName,
      onComplete: t => this.handleAdditionalDetails(t),
      onError: this.props.onError,
      payButton: this.payButton,
      onLoaded: this.onLoaded
    }));
  }
}
export { a as default };
