import { createElement as e } from "../../external/preact/dist/preact.js";
import { UIElement as t } from "../internal/UIElement/UIElement.js";
import s from "./components/ApplePayButton.js";
import r from "./ApplePayService.js";
import n from "../../utils/base64.js";
import i from "./defaultProps.js";
import { httpPost as o } from "../../core/Services/http.js";
import { APPLEPAY_SESSION_ENDPOINT as a } from "./config.js";
import { preparePaymentRequest as p } from "./payment-request.js";
import { resolveSupportedVersion as l, mapBrands as c, formatApplePayContactToAdyenAddressFormat as d } from "./utils.js";
import h from "../../core/Errors/AdyenCheckoutError.js";
import { TxVariants as m } from "../tx-variants.js";
import { sanitizeResponse as u, verifyPaymentDidNotFail as y } from "../internal/UIElement/utils.js";
import { ANALYTICS_SELECTED_STR as A, ANALYTICS_INSTANT_PAYMENT_BUTTON as P } from "../../core/Analytics/constants.js";
class S extends t {
  static type = m.applepay;
  static defaultProps = i;
  constructor(e, t) {
    super(e, t);
    const {
      isExpress: s,
      onShippingContactSelected: r,
      onShippingMethodSelected: n
    } = this.props;
    if (!1 === s && (r || n)) throw new h("IMPLEMENTATION_ERROR", 'ApplePay - You must set "isExpress" flag to "true" in order to use "onShippingContactSelected" and/or "onShippingMethodSelected" callbacks');
    this.startSession = this.startSession.bind(this), this.submit = this.submit.bind(this), this.validateMerchant = this.validateMerchant.bind(this), this.collectOrderTrackingDetailsIfNeeded = this.collectOrderTrackingDetailsIfNeeded.bind(this), this.handleAuthorization = this.handleAuthorization.bind(this);
  }
  formatProps(e) {
    const t = e.version || l(14),
      s = e.brands?.length ? c(e.brands) : e.supportedNetworks;
    return {
      ...e,
      configuration: e.configuration,
      supportedNetworks: s,
      version: t,
      totalPriceLabel: e.totalPriceLabel || e.configuration?.merchantName
    };
  }
  formatData() {
    const {
        applePayToken: e,
        billingAddress: t,
        deliveryAddress: s
      } = this.state,
      {
        isExpress: r
      } = this.props;
    return {
      paymentMethod: {
        type: S.type,
        applePayToken: e,
        ...(r && {
          subtype: "express"
        })
      },
      ...(t && {
        billingAddress: t
      }),
      ...(s && {
        deliveryAddress: s
      })
    };
  }
  submitAnalytics(e) {
    super.submitAnalytics({
      ...e
    }, this.props);
  }
  submit = () => {
    this.props.isInstantPayment && this.submitAnalytics({
      type: A,
      target: P
    }), this.startSession();
  };
  startSession() {
    const {
        version: e,
        onValidateMerchant: t,
        onPaymentMethodSelected: s,
        onShippingMethodSelected: n,
        onShippingContactSelected: i
      } = this.props,
      o = p({
        companyName: this.props.configuration.merchantName,
        countryCode: this.core.options.countryCode,
        ...this.props
      }),
      a = new r(o, {
        version: e,
        onError: e => {
          this.handleError(new h("ERROR", "ApplePay - Something went wrong on ApplePayService", {
            cause: e
          }));
        },
        onCancel: e => {
          this.handleError(new h("CANCEL", "ApplePay UI dismissed", {
            cause: e
          }));
        },
        onPaymentMethodSelected: s,
        onShippingMethodSelected: n,
        onShippingContactSelected: i,
        onValidateMerchant: t || this.validateMerchant,
        onPaymentAuthorized: (e, t, s) => {
          const r = d(s.payment.billingContact),
            n = d(s.payment.shippingContact, !0);
          this.setState({
            applePayToken: btoa(JSON.stringify(s.payment.token.paymentData)),
            authorizedEvent: s,
            ...(r && {
              billingAddress: r
            }),
            ...(n && {
              deliveryAddress: n
            })
          }), this.handleAuthorization().then(this.makePaymentsCall).then(u).then(y).then(this.collectOrderTrackingDetailsIfNeeded).then(({
            paymentResponse: t,
            orderDetails: s
          }) => (e({
            status: ApplePaySession.STATUS_SUCCESS,
            ...(s && {
              orderDetails: s
            })
          }), t)).then(e => {
            this.handleResponse(e);
          }).catch(e => {
            const s = e?.error?.applePayError;
            t({
              status: ApplePaySession.STATUS_FAILURE,
              errors: s ? Array.isArray(s) ? s : [s] : void 0
            });
            const r = {
              ...e,
              error: {
                applePayError: s
              }
            };
            this.handleFailedResult(r);
          });
        }
      });
    return new Promise((e, t) => this.props.onClick(e, t)).then(() => {
      a.begin();
    }).catch(() => ({}));
  }
  async handleAuthorization() {
    return new Promise((e, t) => {
      this.props.onAuthorized || e();
      const {
        authorizedEvent: s,
        billingAddress: r,
        deliveryAddress: n
      } = this.state;
      this.props.onAuthorized({
        authorizedEvent: s,
        ...(r && {
          billingAddress: r
        }),
        ...(n && {
          deliveryAddress: n
        })
      }, {
        resolve: e,
        reject: t
      });
    }).catch(e => {
      const t = {
        error: {
          applePayError: e
        }
      };
      return Promise.reject(t);
    });
  }
  async collectOrderTrackingDetailsIfNeeded(e) {
    return new Promise((e, t) => {
      if (!this.props.onOrderTrackingRequest) return e();
      this.props.onOrderTrackingRequest(e, t);
    }).then(t => ({
      paymentResponse: e,
      ...(t && {
        orderDetails: t
      })
    })).catch(() => ({
      paymentResponse: e
    }));
  }
  async validateMerchant(e, t) {
    const {
        hostname: s
      } = window.location,
      {
        clientKey: r,
        configuration: i,
        loadingContext: p,
        initiative: l
      } = this.props,
      {
        merchantName: c,
        merchantId: d
      } = i,
      h = {
        loadingContext: p,
        path: `${a}?clientKey=${r}`
      },
      m = {
        displayName: c,
        domainName: s,
        initiative: l,
        merchantIdentifier: d
      };
    try {
      const s = await o(h, m),
        r = n.decode(s.data);
      if (r.success) {
        e(JSON.parse(r.data));
      } else t("Could not decode Apple Pay session");
    } catch (e) {
      t("Could not get Apple Pay session");
    }
  }
  get isValid() {
    return !0;
  }
  async isAvailable() {
    if ("https:" !== document.location.protocol) return Promise.reject(new h("IMPLEMENTATION_ERROR", "Trying to start an Apple Pay session from an insecure document"));
    if (!this.props.onValidateMerchant && !this.props.clientKey) return Promise.reject(new h("IMPLEMENTATION_ERROR", "clientKey was not provided"));
    try {
      if (window.ApplePaySession && ApplePaySession.canMakePayments() && ApplePaySession.supportsVersion(this.props.version)) return Promise.resolve();
    } catch (e) {
      console.warn(e);
    }
    return Promise.reject(new h("ERROR", "Apple Pay is not available on this device"));
  }
  render() {
    return this.props.showPayButton ? e(s, {
      i18n: this.props.i18n,
      buttonColor: this.props.buttonColor,
      buttonType: this.props.buttonType,
      onClick: e => {
        e.preventDefault(), this.submit();
      }
    }) : null;
  }
}
export { S as default };
