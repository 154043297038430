import { createElement as e } from "../../../../external/preact/dist/preact.js";
import { useState as t, useLayoutEffect as r } from "../../../../external/preact/hooks/dist/hooks.js";
import o from "../../FormFields/Field/Field.js";
import { useCoreContext as s } from "../../../../core/Context/CoreProvider.js";
import a from "../../../../core/Services/get-dataset.js";
import n from "../../FormFields/Select/Select.js";
function i(i) {
  const {
      classNameModifiers: l,
      label: c,
      onDropdownChange: d,
      readOnly: m,
      selectedCountry: f,
      specifications: p,
      value: u
    } = i,
    {
      i18n: h,
      loadingContext: g
    } = s(),
    [j, v] = t([]),
    [y, C] = t(!1);
  return r(() => {
    if (!f || !p.countryHasDataset(f)) return v([]), void C(!0);
    a(`states/${f}`, g, h.locale).then(e => {
      const t = e && e.length ? e : [];
      v(t), C(!0);
    }).catch(() => {
      v([]), C(!0);
    });
  }, [f]), y && j.length ? e(o, {
    label: c,
    classNameModifiers: l,
    errorMessage: i.errorMessage,
    isValid: !!u,
    showValidIcon: !1,
    name: "stateOrProvince",
    i18n: h,
    readOnly: m && !!u
  }, e(n, {
    name: "stateOrProvince",
    onChange: d,
    selectedValue: u,
    items: j,
    readonly: m && !!u
  })) : null;
}
export { i as default };
