import e from "../../../../external/classnames/index.js";
import { createElement as a, Fragment as n, toChildArray as t, cloneElement as l } from "../../../../external/preact/dist/preact.js";
import o from "../../Spinner/Spinner.js";
import i from "../../Icon/Icon.js";
import { ARIA_ERROR_SUFFIX as r, ARIA_CONTEXT_SUFFIX as d } from "../../../../core/Errors/constants.js";
import { useRef as s, useState as c, useCallback as u } from "../../../../external/preact/hooks/dist/hooks.js";
import { getUniqueId as m } from "../../../../utils/idGenerator.js";
import { PREFIX as p } from "../../Icon/constants.js";
const h = h => {
  const {
      children: _,
      className: f,
      classNameModifiers: y,
      dir: k,
      disabled: b,
      readOnly: x,
      errorMessage: v,
      helper: E,
      inputWrapperModifiers: N,
      isLoading: F,
      isValid: g,
      label: L,
      labelEndAdornment: $,
      name: j,
      onBlur: w,
      onFieldBlur: I,
      onFocus: A,
      onFocusField: S,
      showValidIcon: T,
      useLabelElement: C,
      showErrorElement: M,
      showContextualElement: V,
      contextualText: q,
      filled: B,
      focused: H,
      i18n: R,
      contextVisibleToScreenReader: W,
      renderAlternativeToLabel: z
    } = h,
    G = W ?? !0,
    O = M && "string" == typeof v && v.length > 0,
    P = V && !O && q?.length > 0,
    D = s(m(`adyen-checkout-${j}`)),
    [J, K] = c(!1),
    [Q, U] = c(!1);
  null != H && K(!!H), null != B && U(!!B);
  const X = u(e => {
      K(!0), A?.(e);
    }, [A]),
    Y = u(e => {
      K(!1), w?.(e), I?.(e);
    }, [w, I]),
    Z = u(() => a(n, null, "string" == typeof L && a("span", {
      className: e({
        "adyen-checkout__label__text": !0,
        "adyen-checkout__label__text--error": v
      }),
      "data-id": j
    }, L), "function" == typeof L && L(), $ && a("span", {
      className: "adyen-checkout__label-adornment--end"
    }, $), E && a("span", {
      className: "adyen-checkout__helper-text"
    }, E)), [L, v, $, E]),
    ee = u(() => {
      const s = a("span", {
          className: e({
            "adyen-checkout-contextual-text--error": !0,
            "adyen-checkout-contextual-text--hidden": !O
          }),
          ...(G && {
            id: `${D.current}${r}`
          }),
          "aria-hidden": G ? null : "true"
        }, v),
        c = a("span", {
          className: e({
            "adyen-checkout-contextual-text": !0,
            "adyen-checkout-contextual-text--hidden": !P
          }),
          ...(G && {
            id: `${D.current}${d}`
          }),
          "aria-hidden": G ? null : "true"
        }, q);
      return a(n, null, a("div", {
        className: e(["adyen-checkout__input-wrapper", ...N.map(e => `adyen-checkout__input-wrapper--${e}`)]),
        dir: k
      }, t(_).map(e => {
        const a = {
          isValid: g,
          onFocusHandler: X,
          onBlurHandler: Y,
          isInvalid: !!v,
          ...(j && {
            uniqueId: D.current
          }),
          showErrorElement: M
        };
        return l(e, a);
      }), F && a("span", {
        className: "adyen-checkout-input__inline-validation adyen-checkout-input__inline-validation--loading"
      }, a(o, {
        size: "small"
      })), g && !1 !== T && a("span", {
        className: "adyen-checkout-input__inline-validation adyen-checkout-input__inline-validation--valid"
      }, a(i, {
        type: `${p}checkmark`,
        alt: R?.get("field.valid")
      })), v && a("span", {
        className: "adyen-checkout-input__inline-validation adyen-checkout-input__inline-validation--invalid"
      }, a(i, {
        type: `${p}field_error`,
        alt: R?.get("error.title")
      }))), s, c);
    }, [_, v, q, F, g, X, Y]),
    ae = u(({
      onFocusField: n,
      focused: t,
      filled: l,
      disabled: o,
      name: i,
      uniqueId: r,
      useLabelElement: d,
      isSecuredField: s,
      children: c,
      renderAlternativeToLabel: u
    }) => {
      const m = {
        onClick: n,
        className: e({
          "adyen-checkout__label": !0,
          "adyen-checkout__label--focused": t,
          "adyen-checkout__label--filled": l,
          "adyen-checkout__label--disabled": o
        })
      };
      return d ? a("label", {
        ...m,
        ...(!s && i && {
          htmlFor: r
        })
      }, c) : u(m, c, r);
    }, []);
  return a("div", {
    className: e("adyen-checkout__field", f, y.map(e => `adyen-checkout__field--${e}`), {
      "adyen-checkout__field--error": v,
      "adyen-checkout__field--valid": g,
      "adyen-checkout__field--inactive": x || b
    })
  }, a(ae, {
    onFocusField: S,
    name: j,
    disabled: b,
    filled: Q,
    focused: J,
    useLabelElement: C,
    uniqueId: D.current,
    isSecuredField: !G,
    renderAlternativeToLabel: z
  }, Z()), ee());
};
h.defaultProps = {
  className: "",
  classNameModifiers: [],
  inputWrapperModifiers: [],
  useLabelElement: !0,
  showErrorElement: !0,
  showContextualElement: !0,
  renderAlternativeToLabel: () => null
};
export { h as default };
