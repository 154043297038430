import e from "./sdks/SrcSdkLoader.js";
import r from "./ClickToPayService.js";
function t(t, c, i) {
  const o = a(t);
  if (!o) return null;
  const s = n(c?.shopperEmail, c?.telephoneNumber),
    l = Object.keys(o),
    p = new e(l, {
      dpaLocale: c?.locale,
      dpaPresentationName: c?.merchantDisplayName
    });
  return new r(o, p, i, s, c?.onTimeout);
}
const n = (e, r) => {
    const t = {
      ...(e && {
        shopperEmail: e
      })
    };
    return Object.keys(t).length > 0 ? t : null;
  },
  a = e => {
    if (!e) return null;
    const {
        visaSrciDpaId: r,
        visaSrcInitiatorId: t,
        mcDpaId: n,
        mcSrcClientId: a
      } = e,
      c = {
        ...(n && a && {
          mc: {
            srciDpaId: n,
            srcInitiatorId: a
          }
        }),
        ...(r && t && {
          visa: {
            srciDpaId: r,
            srcInitiatorId: t
          }
        })
      };
    return 0 === Object.keys(c).length ? null : c;
  };
export { t as default };
