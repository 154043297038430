import { createElement as t, Fragment as r } from "../../../../../external/preact/dist/preact.js";
import { useState as o, useCallback as e } from "../../../../../external/preact/hooks/dist/hooks.js";
import n from "../../../Button/Button.js";
import s from "../../context/useClickToPayContext.js";
import i from "./CtPLoginInput.js";
import { CtPInfo as a } from "../CtPInfo/CtPInfo.js";
import l from "../CtPSection/CtPSection.js";
import m from "../../services/sdks/SrciError.js";
import { useCoreContext as p } from "../../../../../core/Context/CoreProvider.js";
import c from "../../errors/TimeoutError.js";
import { isSrciError as u } from "../../services/utils.js";
const d = () => {
  const {
      i18n: d
    } = p(),
    {
      isCtpPrimaryPaymentMethod: f,
      setIsCtpPrimaryPaymentMethod: g,
      verifyIfShopperIsEnrolled: C,
      startIdentityValidation: P
    } = s(),
    [h, j] = o(null),
    [y, I] = o(!1),
    [S, x] = o(null),
    [E, k] = o(!1),
    [v, T] = o(null),
    b = e(t => {
      T(t);
    }, []),
    w = e(({
      data: t,
      isValid: r
    }) => {
      j(t.shopperLogin), I(r), t?.shopperLogin?.length > 0 && g(!0);
    }, []),
    L = e(async () => {
      if (x(null), y) {
        k(!0);
        try {
          const {
            isEnrolled: t
          } = await C({
            shopperEmail: h
          });
          t ? await P() : (x("NOT_FOUND"), k(!1));
        } catch (t) {
          t instanceof m && console.warn(`CtP - Login error: ${t.toString()}`), t instanceof c && console.warn(t.toString()), u(t) ? x(t?.reason) : console.error(t), k(!1);
        }
      } else v.validateInput();
    }, [C, P, h, y, v]);
  return t(r, null, t(l.Title, {
    endAdornment: t(a, null)
  }, d.get("ctp.login.title")), t(l.Text, null, d.get("ctp.login.subtitle")), t(i, {
    onChange: w,
    onSetInputHandlers: b,
    disabled: E,
    errorMessage: S && d.get(`ctp.errors.${S}`),
    onPressEnter: L
  }), t(n, {
    label: d.get("continue"),
    variant: f ? "primary" : "secondary",
    status: E && "loading",
    onClick: () => {
      L();
    }
  }));
};
export { d as default };
