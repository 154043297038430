import { createElement as e } from "../../../../external/preact/dist/preact.js";
import t from "../../../../external/classnames/index.js";
import { useCoreContext as a } from "../../../../core/Context/CoreProvider.js";
function s({
  children: s,
  classNameModifiers: l = [],
  label: c,
  readonly: d = !1
}) {
  const {
    i18n: r
  } = a();
  return e("fieldset", {
    className: t(["adyen-checkout__fieldset", ...l.map(e => `adyen-checkout__fieldset--${e}`), {
      "adyen-checkout__fieldset--readonly": d
    }])
  }, c && e("legend", {
    className: "adyen-checkout__fieldset__title"
  }, r.get(c)), e("div", {
    className: "adyen-checkout__fieldset__fields"
  }, s));
}
export { s as default };
