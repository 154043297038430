import t from "../../../../utils/Script.js";
import { useRef as a, useState as e, useEffect as o } from "../../../../external/preact/hooks/dist/hooks.js";
import { createElement as n } from "../../../../external/preact/dist/preact.js";
import { KLARNA_WIDGET_URL as r } from "../../constants.js";
function s({
  sdkData: s,
  paymentMethodType: i,
  payButton: c,
  ...d
}) {
  const m = a(null),
    [l, p] = e("ready"),
    y = () => {
      p("error"), d.onComplete({
        data: {
          paymentData: d.paymentData,
          details: {}
        }
      });
    },
    u = () => {
      p("loading");
      try {
        window.Klarna.Payments.authorize({
          payment_method_category: s.payment_method_category
        }, function (t) {
          !0 === t.approved && !0 === t.show_form ? (p("success"), d.onComplete({
            data: {
              paymentData: d.paymentData,
              details: {
                authorization_token: t.authorization_token
              }
            }
          })) : t.approved || !0 !== t.show_form ? y() : (p("ready"), d.onError(t));
        });
      } catch (t) {
        y();
      }
    };
  return o(() => {
    window.klarnaAsyncCallback = function () {
      window.Klarna.Payments.init({
        client_token: s.client_token
      }), window.Klarna.Payments.load({
        container: m.current,
        payment_method_category: s.payment_method_category
      }, function (t) {
        !t.show_form || t.error ? y() : d.onLoaded();
      });
    };
    const a = new t(r);
    return a.load(), () => {
      a.remove();
    };
  }, []), "error" !== l && "success" !== l ? n("div", {
    className: "adyen-checkout__klarna-widget"
  }, n("div", {
    ref: m
  }), c({
    status: l,
    disabled: "loading" === l,
    onClick: u
  })) : null;
}
export { s as KlarnaWidget };
