import { createElement as t } from "../../../../../external/preact/dist/preact.js";
import o from "../../context/useClickToPayContext.js";
import { CtpState as e } from "../../services/ClickToPayService.js";
import r from "../../../../../external/classnames/index.js";
import { useMemo as n } from "../../../../../external/preact/hooks/dist/hooks.js";
import { useCoreContext as s } from "../../../../../core/Context/CoreProvider.js";
const a = () => {
  const {
      ctpState: a,
      logoutShopper: c,
      status: l,
      cards: i
    } = o(),
    {
      i18n: u
    } = s();
  if (!1 === [e.Ready, e.OneTimePassword].includes(a)) return null;
  const d = n(() => a === e.Ready && i.length > 1 ? u.get("ctp.logout.notYourCards") : a === e.Ready && 1 === i.length ? u.get("ctp.logout.notYourCard") : a === e.Ready && 0 === i.length ? u.get("ctp.logout.notYourProfile") : u.get("ctp.logout.notYou"), [u, a]);
  return t("span", {
    role: "button",
    tabIndex: 0,
    className: r("adyen-checkout-ctp__section-logout-button", {
      "adyen-checkout-ctp__section-logout-button--disabled": "loading" === l
    }),
    onClick: c
  }, d);
};
export { a as default };
