import { createElement as t } from "../../external/preact/dist/preact.js";
import { UIElement as r } from "../internal/UIElement/UIElement.js";
import { CoreProvider as e } from "../../core/Context/CoreProvider.js";
import o from "./components/RedirectShopper/RedirectShopper.js";
import s from "../internal/RedirectButton/RedirectButton.js";
import { TxVariants as i } from "../tx-variants.js";
import p from "../../utils/browserInfo.js";
class n extends r {
  static type = i.redirect;
  static defaultProps = {
    type: n.type
  };
  formatData() {
    return {
      paymentMethod: {
        type: this.type
      },
      browserInfo: this.browserInfo
    };
  }
  get isValid() {
    return !0;
  }
  get browserInfo() {
    return p();
  }
  render() {
    return this.props.url && this.props.method ? t(o, {
      url: this.props.url,
      ...this.props
    }) : this.props.showPayButton ? t(e, {
      i18n: this.props.i18n,
      loadingContext: this.props.loadingContext,
      resources: this.resources
    }, t(s, {
      ...this.props,
      showPayButton: this.props.showPayButton,
      name: this.displayName,
      onSubmit: this.submit,
      payButton: this.payButton,
      ref: t => {
        this.componentRef = t;
      }
    })) : null;
  }
}
export { n as default };
