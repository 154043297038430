import { ENCRYPTED_CARD_NUMBER as t, ENCRYPTED_SECURITY_CODE as o } from "../../../constants.js";
import { getPreviousTabbableNonSFElement as i } from "./utils.js";
function s(s, e) {
  let r, a;
  switch (s) {
    case t:
      r = i(t, e);
      break;
    case o:
      a = t;
  }
  return {
    fieldToFocus: a,
    additionalField: r
  };
}
export { s as shiftTabGiftCard };
