import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { ProductListConfigService } from '../../services/product-list-config/product-list-config.service';
import { AuthService } from '../../services/auth/auth.service';
import { Subject, debounceTime, distinctUntilChanged, map, takeUntil, tap } from 'rxjs';
import { Observable } from 'rxjs';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../models';
import { ProductService } from '../../services/products/product.service';

@Component({
  selector: 'lib-product-select-dropdown',
  templateUrl: './product-select-dropdown.component.html',
  styleUrls: ['./product-select-dropdown.component.css']
})
export class ProductSelectDropdownComponent implements OnInit, OnDestroy {
  public products: Product[];
  searching = false;
  searchFailed = false;
  model: any;

  @Input()
  formatterFunction: (result: Product) => string;

  @Input()
  formatterInputFunction: (result: Product) => string;

  @Input()
  gungTranslate: string;

  @Input()
  getSearchTerms: (product: Product) => string[] = (product: Product) => this.productListConfig.getSearchTerms(product);

  @Input()
  placeholderString = 'SEARCH';

  @Output()
  protected productSelected = new EventEmitter<Product>();

  @Input()
  disabled: boolean = false;

  @Input() preselectedProductId: string;

  @ViewChild('inputText', { static: false }) inputTextElement: ElementRef;

  @Input() clearSelected: Observable<void>;
  protected unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    protected productService: ProductService,
    protected productListConfig: ProductListConfigService,
    protected authService: AuthService
  ) { }

  ngOnInit() {
    this.productListConfig.getItems().pipe(
      tap(products => {
        if (!!this.preselectedProductId) {
          this.model = products.find(p => p.id === this.preselectedProductId);
        }
      })
    ).subscribe(ps => (this.products = ps));

    if (this.clearSelected) {
      this.clearSelected.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.model = undefined);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  search = (searchTerm$: Observable<string>) => {
    return searchTerm$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        this.products
          .filter(product => {
            let hasHitAllTerms = true;
            const queryTerms = term.split(' ');
            const terms = this.getSearchTerms(product);
            queryTerms.forEach(queryTerm => {
              const locatedTerm = terms.find(searchTerm => {
                return !!searchTerm && searchTerm.toUpperCase().indexOf(queryTerm.toUpperCase()) >= 0;
              });

              hasHitAllTerms = hasHitAllTerms && !!locatedTerm;
            });
            return hasHitAllTerms;
          })
          .slice(0, 10)
      )
    );
  };

  onItemSelect(event: NgbTypeaheadSelectItemEvent) {
    const product = event.item as Product;
    this.model = this.formatter(event.item);
    this.productSelected.emit(product);
  }

  formatter = (result: Product) => (!!this.formatterFunction ? this.formatterFunction(result) : result.name);

  formatterInput = (result: Product) => (!!this.formatterInputFunction ? this.formatterInputFunction(result) : this.formatter(result));
}
