import { httpPost as e } from "../http.js";
import t from "../../../utils/Storage.js";
const o = 'WARNING: Failed to retrieve "checkoutAttemptId". Consequently, analytics will not be available for this payment. The payment process, however, will not be affected.';
const n = ({
  analyticsContext: n,
  clientKey: r,
  locale: i,
  analyticsPath: c,
  bundleType: a
}) => {
  let l;
  const s = {
    errorLevel: "fatal",
    loadingContext: n,
    path: `${c}?clientKey=${r}`
  };
  return n => {
    const c = {
      version: "6.2.0",
      channel: "Web",
      platform: "Web",
      buildType: a,
      locale: i,
      referrer: window.location.href,
      screenWidth: window.screen.width,
      ...n
    };
    if (l) return l;
    if (!r) return Promise.reject("no-client-key");
    const m = new t("checkout-attempt-id", "sessionStorage"),
      d = m.get();
    return function (e) {
      if (!e?.id) return !1;
      const t = Date.now() - 9e5;
      return e.timestamp > t;
    }(d) ? Promise.resolve(d.id) : (l = e(s, c).then(e => {
      if (e?.checkoutAttemptId) return m.set({
        id: e.checkoutAttemptId,
        timestamp: Date.now()
      }), e.checkoutAttemptId;
    }).catch(() => Promise.reject(o)), l);
  };
};
export { o as FAILURE_MSG, n as default };
