<div class="container mt-5">
  <div class="mb-4">
    <h2 class="text-center mb-5">
      <span> {{ 'ADYEN_ORDER_FAILED' | translate }}</span>
    </h2>
    <div class="d-flex mt-5 justify-content-center">
      <div class="text-center" style="width: 40%">
        <i class="fa-thin fa-binary-slash fa-10x"></i>
      </div>
      <div class="w-40 text-center" style="width: 40%">
        <span> {{ 'ADYEN_ORDER_FAILED_INFO' | translate }}</span>
      </div>
    </div>
  </div>
</div>
