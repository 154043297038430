import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { OrderContext, SimplifiedOrderContext } from '../models/simplifiedOrderContext';

@Injectable({
  providedIn: 'root'
})
export class OrderContextService {
  baseUrl = 'json/order-contexts';

  constructor(protected http: HttpClient) {}

  getSimplifiedOrderContext(contextId: string): Observable<SimplifiedOrderContext> {
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<any>(`${this.baseUrl}/${contextId}`, { headers }).pipe(
      map(resp => {
        if (resp === null) {
          throw new Error('CONTEXT_NOT_FOUND');
        }
        return {
          contextId: resp.id,
          erpOrderIds: resp.erpOrderIds,
          finalized: !!resp.finalized,
          channel: resp.channel,
          status: resp.status,
          originalInput: {
            confirmationEmails: resp.originalInput ? resp.originalInput.confirmationEmails : null
          }
        };
      })
    );
  }

  getOrderContextsByOrderId(orderId: string): Observable<OrderContext> {
    return this.http.get<OrderContext>(`${this.baseUrl}/by-order-id/${orderId}`);
  }

  resendOrderContext(orderContextId: string): Observable<any> {
    const url = `json/order-contexts/resend/` + orderContextId;
    return this.http.post<any>(url, {});
  }
}
