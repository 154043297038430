<div class="row" *ngIf="statistics">
</div>

<div class="row mb-2">
  <h3 class="col-12 page-title text-center" translate>ORDER_PORTAL</h3>
  <span class="order-portal-disclaimer m-auto font-italic small" translate>ORDER_PORTAL_DESCRIPTION</span>

  <div class="col-12 d-sm-none d-md-block">
    <div class="p-3">
      <lib-order-portal-statistics-chart
        [statistics]="statistics"
      ></lib-order-portal-statistics-chart>
    </div>
  </div>

  <!--
  <div class="col-12 col-sm-4 mb-2">
    <a class="card border h-100" routerLink="/admin/order-portal/list" [queryParams]="{ fromDate: getFirstOfJanuary()}" >
      <div
        class="card-body"
        title="{{'Here you can see the list of attempted order creations' | translate}}"
      >
        <h5 class="card-title text-center" translate>ORDER_PORTAL_LIST_THIS_YEAR</h5>
        <p class="card-text text-center" translate>
          ORDER_PORTAL_LIST_THIS_YEAR_DESCRIPTION
        </p>
      </div>
    </a>
  </div>
  -->

  <div class="col-12 col-sm-4 mb-2">
    <a class="card border h-100" routerLink="/admin/order-portal/list" [queryParams]="{ fromDate: getFirstOfThisMonth()}" >
      <div
        class="card-body"
        title="{{'Here you can see the list of attempted order creations' | translate}}"
      >
        <h5 class="card-title text-center" translate>ORDER_PORTAL_LIST_THIS_MONTH</h5>
        <p class="card-text text-center" translate>
          ORDER_PORTAL_LIST_THIS_MONTH_DESCRIPTION
        </p>
      </div>
    </a>
  </div>

  <div class="col-12 col-sm-4 mb-2">
    <a class="card border h-100" routerLink="/admin/order-portal/list" [queryParams]="{ fromDate: getToday()}" >
      <div
        class="card-body"
        title="{{'Here you can see the list of attempted order creations' | translate}}"
      >
        <h5 class="card-title text-center" translate>ORDER_PORTAL_LIST_TODAY</h5>
        <p class="card-text text-center" translate>
          ORDER_PORTAL_LIST_TODAY_DESCRIPTION
        </p>
      </div>
    </a>
  </div>

</div>
<router-outlet></router-outlet>
