import { Component as e, createElement as t } from "../../../../external/preact/dist/preact.js";
import a from "../../../../external/classnames/index.js";
class n extends e {
  formEl;
  componentDidMount() {
    this.formEl.submit(), this.props.onFormSubmit(`${this.props.inputName} sent`);
  }
  render({
    name: e,
    action: n,
    target: r,
    inputName: o,
    inputValue: s
  }) {
    return t("form", {
      ref: e => {
        this.formEl = e;
      },
      method: "POST",
      className: a(["adyen-checkout__threeds2__form", `adyen-checkout__threeds2__form--${e}`]),
      name: e,
      action: n,
      target: r,
      style: {
        display: "none"
      }
    }, t("input", {
      name: o,
      value: s
    }));
  }
}
export { n as default };
