import { optionallyFilterUpiSubTxVariants as t, filterUnsupportedPaymentMethod as e, filterPresent as o, filterAvailable as n } from "./filters.js";
import { getComponentConfiguration as r } from "./getComponentConfiguration.js";
import p from "../../components-name-map.js";
const s = (s, i, m, a) => {
  const f = t(s).filter(e).map(t => {
    const e = "isStoredPaymentMethod" in t && t.isStoredPaymentMethod,
      o = r(t.type, i, e),
      n = a.getComponent(t.type);
    if (!n) return console.warn(`\nDropin: You support the payment method '${t.type}' but this component has not been configured. Make sure to import the Class  '${p(t.type)}' and then pass it in the Dropin's 'paymentMethodComponents' config property if you wish to offer this payment method.`), null;
    const s = {
      ...t,
      ...m,
      ...o
    };
    return new n(a, s);
  }).filter(o);
  return n(f);
};
export { s as default };
