<div class="row" *ngIf="orderConfirmConfiguration">
  <div class="col">
    <div class="d-flex justify-content-between align-items-baseline">
      <div>
        <h5 class="mb-0 mt-0" translate>ORDER_CONFIRMATION_SETTINGS</h5>
        <span class="font-italic small mb-2 d-inline-block" translate>ORDER_CONFIRMATION_SETTINGS_DESCRIPTION</span>
      </div>


      <button
        class="btn btn-default"
        style="font-size: 20px;"
        (click)="save()"
      >
        <i ngbTooltip="{{'SAVE' | translate}}" class="fas fa-save"></i>
      </button>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-3">
        <lib-list-input
          gungTranslate="BCC_RECIPIENTS"
          placeholder="BCC_EMAIL"
          [input]="orderConfirmConfiguration.orderConfirmBCCList"
          (inputUpdated)="orderConfirmConfiguration.orderConfirmBCCList = $event"
        ></lib-list-input>
      </div>
    </div>

  </div>
</div>
