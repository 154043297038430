import { getCardImageUrl as e } from "../utils.js";
import { ENCRYPTED_CARD_NUMBER as t, ENCRYPTED_SECURITY_CODE as s, ENCRYPTED_EXPIRY_DATE as i, ENCRYPTED_EXPIRY_MONTH as n, ENCRYPTED_EXPIRY_YEAR as r, CVC_POLICY_REQUIRED as o, DATE_POLICY_REQUIRED as a, OPTIONAL as l, HIDDEN as d } from "../lib/constants.js";
import { existy as h } from "../../../../utils/commonUtils.js";
import u from "../../../../core/Errors/AdyenCheckoutError.js";
function p(e, t, s, i) {
  return (t !== l && t !== d || 0 !== s[e]) && i[e];
}
var c = {
  handleFocus: function (e) {
    this.numCharsInField[e.fieldType] = e.numChars, this.props.onFocus(e);
  },
  handleOnAllValid: function (e) {
    return !this.state.detectedUnsupportedBrands && (this.setState({
      isSfpValid: e.allValid
    }, () => {
      this.props.onChange(this.state, {
        event: "handleOnAllValid"
      }), this.props.onAllValid(e);
    }), !0);
  },
  handleOnAutoComplete: function (e) {
    this.setState({
      autoCompleteName: e.value
    }, () => {
      this.props.onChange(this.state, {
        event: "handleOnAutoComplete",
        fieldType: e.fieldType
      }), this.setState({
        autoCompleteName: null
      });
    }), this.props.onAutoComplete(e);
  },
  handleOnFieldValid: function (e) {
    return (!this.state.detectedUnsupportedBrands || e.fieldType !== t) && (this.setState(t => ({
      data: {
        ...t.data,
        [e.encryptedFieldName]: e.blob
      },
      valid: {
        ...t.valid,
        [e.encryptedFieldName]: e.valid
      },
      errors: {
        ...t.errors,
        [e.fieldType]: t.errors[e.fieldType] ?? !1
      }
    }), () => {
      this.props.onChange(this.state, {
        event: "handleOnFieldValid",
        fieldType: e.fieldType
      }), this.props.onFieldValid(e);
    }), !0);
  },
  handleOnLoad: function (e) {
    clearTimeout(this.csfLoadFailTimeout), this.csfLoadFailTimeout = null, this.props.onLoad(e), this.csfConfigFailTimeout = setTimeout(() => {
      "ready" !== this.state.status && (this.setState({
        status: "csfConfigFailure"
      }), this.props.onError(new u("ERROR", "secured fields have failed to configure")));
    }, this.csfConfigFailTimeoutMS);
  },
  handleOnConfigSuccess: function (e) {
    clearTimeout(this.csfConfigFailTimeout), this.csfConfigFailTimeout = null, this.setState({
      status: "ready"
    }, () => {
      this.props.onConfigSuccess(e);
    });
  },
  handleOnBrand: function (t) {
    this.setState(e => {
      const l = p(s, t.cvcPolicy, this.numCharsInField, e.errors),
        d = 1 === this.numDateFields ? p(i, t.expiryDatePolicy, this.numCharsInField, e.errors) : null,
        u = 2 === this.numDateFields ? p(n, t.expiryDatePolicy, this.numCharsInField, e.errors) : null,
        c = 2 === this.numDateFields ? p(r, t.expiryDatePolicy, this.numCharsInField, e.errors) : null;
      return {
        brand: t.brand,
        cvcPolicy: t.cvcPolicy ?? o,
        showSocialSecurityNumber: t.showSocialSecurityNumber,
        errors: {
          ...e.errors,
          ...(h(l) && {
            [s]: l
          }),
          ...(h(d) && {
            [i]: d
          }),
          ...(h(u) && {
            [n]: u
          }),
          ...(h(c) && {
            [r]: c
          })
        },
        expiryDatePolicy: t.expiryDatePolicy ?? a
      };
    }, () => {
      this.props.onChange(this.state, {
        event: "handleOnBrand"
      });
      const s = this.props.brandsConfiguration[t.brand]?.icon ?? e(t.brand, this.props.resources);
      this.props.onBrand({
        ...t,
        brandImageUrl: s
      });
    });
  },
  handleOnError: function (e, s = null) {
    const i = e.error;
    return this.setState(n => ({
      errors: {
        ...n.errors,
        [e.fieldType]: i || !1
      },
      ...(s && {
        data: {
          ...n.data,
          [t]: void 0
        }
      }),
      ...(s && {
        valid: {
          ...n.valid,
          [t]: !1
        }
      }),
      ...(s && {
        isSfpValid: !1
      })
    }), () => {
      this.props.onChange(this.state, {
        event: "handleOnError",
        fieldType: e.fieldType
      });
    }), !0;
  },
  handleOnNoDataRequired: function () {
    this.setState({
      status: "ready"
    }, () => this.props.onChange({
      isSfpValid: !0
    }));
  },
  handleOnTouchstartIOS: function (e) {
    this.props.disableIOSArrowKeys?.(e);
  },
  handleKeyPressed: function (e) {
    if ("enterKeyPressed" === e.action) {
      const e = new KeyboardEvent("keypress", {
        bubbles: !0,
        cancelable: !0,
        key: "Enter",
        code: "Enter"
      });
      this.props.handleKeyPress?.(e);
    }
  }
};
export { c as default };
