import { createElement as e } from "../../../../../external/preact/dist/preact.js";
import t from "../../../../../external/classnames/index.js";
import o from "./CVCHint.js";
import a from "../../../../internal/FormFields/Field/Field.js";
import { useCoreContext as r } from "../../../../../core/Context/CoreProvider.js";
import { CVC_POLICY_HIDDEN as i, CVC_POLICY_OPTIONAL as c, ENCRYPTED_SECURITY_CODE as l, CVC_POLICY_REQUIRED as n } from "../../../../internal/SecuredFields/lib/constants.js";
import s from "./DataSfSpan.js";
import { alternativeLabelContent as d } from "./FieldLabelAlternative.js";
function u(u) {
  const {
      label: _,
      onFocusField: m = () => {},
      error: f = "",
      className: p = "",
      classNameModifiers: y = [],
      focused: C,
      filled: h,
      isValid: x,
      frontCVC: F = !1,
      cvcPolicy: b = n,
      showContextualElement: k,
      contextualText: v
    } = u,
    {
      i18n: j
    } = r(),
    V = t(p, {
      "adyen-checkout__field__cvc": !0,
      "adyen-checkout__card__cvc__input--hidden": b === i,
      "adyen-checkout__field__cvc--optional": b === c
    }),
    N = t({
      "adyen-checkout__input": !0,
      "adyen-checkout__input--small": !0,
      "adyen-checkout__card__cvc__input": !0,
      "adyen-checkout__input--error": f,
      "adyen-checkout__input--focus": C,
      "adyen-checkout__input--valid": x
    }),
    T = b !== c ? _ : j.get("creditCard.securityCode.label.optional");
  return e(a, {
    label: T,
    focused: C,
    filled: h,
    classNameModifiers: [...y, "securityCode"],
    onFocusField: () => m(l),
    className: V,
    errorMessage: f,
    isValid: x,
    dir: "ltr",
    name: l,
    i18n: j,
    contextVisibleToScreenReader: !1,
    useLabelElement: !1,
    renderAlternativeToLabel: d,
    showContextualElement: k,
    contextualText: v
  }, e(s, {
    encryptedFieldType: l,
    className: N
  }), e(o, {
    frontCVC: F,
    fieldLabel: T
  }));
}
export { u as default };
