import { hasOwnProperty as t } from "../../../../../../utils/hasOwnProperty.js";
let e;
const r = {
  __NO_BRAND: "noBrand",
  cards: []
};
r.cards.push({
  cardType: "mc",
  startingRules: [51, 52, 53, 54, 55, 22, 23, 24, 25, 26, 27],
  permittedLengths: [16],
  pattern: /^(5[1-5][0-9]{0,14}|2[2-7][0-9]{0,14})$/,
  securityCode: "CVC"
}), r.cards.push({
  cardType: "visadankort",
  startingRules: [4571],
  permittedLengths: [16],
  pattern: /^(4571)[0-9]{0,12}$/
}), r.cards.push({
  cardType: "visa",
  startingRules: [4],
  permittedLengths: [13, 16, 19],
  pattern: /^4[0-9]{0,18}$/,
  securityCode: "CVV"
}), r.cards.push({
  cardType: "amex",
  startingRules: [34, 37],
  permittedLengths: [15],
  pattern: /^3[47][0-9]{0,13}$/,
  securityCode: "CID"
}), r.cards.push({
  cardType: "diners",
  startingRules: [36],
  permittedLengths: [14, 16],
  pattern: /^(36)[0-9]{0,12}$/
}), r.cards.push({
  cardType: "maestrouk",
  startingRules: [6759],
  permittedLengths: [16, 18, 19],
  pattern: /^(6759)[0-9]{0,15}$/
}), r.cards.push({
  cardType: "solo",
  startingRules: [6767],
  permittedLengths: [16, 18, 19],
  pattern: /^(6767)[0-9]{0,15}$/
}), r.cards.push({
  cardType: "laser",
  startingRules: [6304, 6706, 677117, 677120],
  permittedLengths: [16, 17, 18, 19],
  pattern: /^(6304|6706|6709|6771)[0-9]{0,15}$/,
  cvcPolicy: "optional"
}), r.cards.push({
  cardType: "discover",
  startingRules: [6011, 644, 645, 646, 647, 648, 649, 65],
  permittedLengths: [16, 17, 18, 19],
  pattern: /^(6011[0-9]{0,12}|(644|645|646|647|648|649)[0-9]{0,13}|65[0-9]{0,14})$/
}), r.cards.push({
  cardType: "jcb",
  startingRules: [3528, 3529, 353, 354, 355, 356, 357, 358],
  permittedLengths: [16, 19],
  pattern: /^(352[8,9]{1}[0-9]{0,15}|35[4-8]{1}[0-9]{0,16})$/,
  securityCode: "CAV"
}), r.cards.push({
  cardType: "bcmc",
  startingRules: [6703, 479658, 606005],
  permittedLengths: [16, 17, 18, 19],
  pattern: /^((6703)[0-9]{0,15}|(479658|606005)[0-9]{0,13})$/,
  cvcPolicy: "hidden"
}), r.cards.push({
  cardType: "bijcard",
  startingRules: [5100081],
  permittedLengths: [16],
  pattern: /^(5100081)[0-9]{0,9}$/
}), r.cards.push({
  cardType: "dankort",
  startingRules: [5019],
  permittedLengths: [16],
  pattern: /^(5019)[0-9]{0,12}$/
}), r.cards.push({
  cardType: "hipercard",
  startingRules: [606282],
  permittedLengths: [16],
  pattern: /^(606282)[0-9]{0,10}$/
}), r.cards.push({
  cardType: "cup",
  startingRules: [62, 81],
  permittedLengths: [14, 15, 16, 17, 18, 19],
  pattern: /^(62|81)[0-9]{0,17}$/
}), r.cards.push({
  cardType: "maestro",
  startingRules: [50, 56, 57, 58, 6],
  permittedLengths: [16, 17, 18, 19],
  pattern: /^(5[0|6-8][0-9]{0,17}|6[0-9]{0,18})$/,
  cvcPolicy: "optional"
}), r.cards.push({
  cardType: "elo",
  startingRules: [506699, 50670, 50671, 50672, 50673, 50674, 50675, 50676, 506770, 506771, 506772, 506773, 506774, 506775, 506776, 506777, 506778, 401178, 438935, 451416, 457631, 457632, 504175, 627780, 636297, 636368],
  permittedLengths: [16],
  pattern: /^((((506699)|(506770)|(506771)|(506772)|(506773)|(506774)|(506775)|(506776)|(506777)|(506778)|(401178)|(438935)|(451416)|(457631)|(457632)|(504175)|(627780)|(636368)|(636297))[0-9]{0,10})|((50676)|(50675)|(50674)|(50673)|(50672)|(50671)|(50670))[0-9]{0,11})$/
}), r.cards.push({
  cardType: "uatp",
  startingRules: [1],
  permittedLengths: [15],
  pattern: /^1[0-9]{0,14}$/,
  cvcPolicy: "optional"
}), r.cards.push({
  cardType: "cartebancaire",
  startingRules: [4, 5, 6],
  permittedLengths: [16],
  pattern: /^[4-6][0-9]{0,15}$/
}), r.cards.push({
  cardType: "visaalphabankbonus",
  startingRules: [450903],
  permittedLengths: [16],
  pattern: /^(450903)[0-9]{0,10}$/
}), r.cards.push({
  cardType: "mcalphabankbonus",
  startingRules: [510099],
  permittedLengths: [16],
  pattern: /^(510099)[0-9]{0,10}$/
}), r.cards.push({
  cardType: "hiper",
  startingRules: [637095, 637568, 637599, 637609, 637612],
  permittedLengths: [16],
  pattern: /^(637095|637568|637599|637609|637612)[0-9]{0,10}$/
}), r.cards.push({
  cardType: "oasis",
  startingRules: [982616],
  permittedLengths: [16],
  pattern: /^(982616)[0-9]{0,10}$/,
  cvcPolicy: "optional"
}), r.cards.push({
  cardType: "karenmillen",
  startingRules: [98261465],
  permittedLengths: [16],
  pattern: /^(98261465)[0-9]{0,8}$/,
  cvcPolicy: "optional"
}), r.cards.push({
  cardType: "warehouse",
  startingRules: [982633],
  permittedLengths: [16],
  pattern: /^(982633)[0-9]{0,10}$/,
  cvcPolicy: "optional"
}), r.cards.push({
  cardType: "mir",
  startingRules: [220],
  permittedLengths: [16, 17, 18, 19],
  pattern: /^(220)[0-9]{0,16}$/
}), r.cards.push({
  cardType: "codensa",
  startingRules: [590712],
  permittedLengths: [16],
  pattern: /^(590712)[0-9]{0,10}$/
}), r.cards.push({
  cardType: "naranja",
  startingRules: [377798, 377799, 402917, 402918, 527571, 527572, 589562],
  permittedLengths: [16, 17, 18, 19],
  pattern: /^(37|40|5[28])([279])\d*$/
}), r.cards.push({
  cardType: "cabal",
  startingRules: [589657, 600691, 603522, 6042, 6043, 636908],
  permittedLengths: [16, 17, 18, 19],
  pattern: /^(58|6[03])([03469])\d*$/
}), r.cards.push({
  cardType: "shopping",
  startingRules: [2799, 589407, 603488],
  permittedLengths: [16, 17, 18, 19],
  pattern: /^(27|58|60)([39])\d*$/
}), r.cards.push({
  cardType: "argencard",
  startingRules: [501],
  permittedLengths: [16, 17, 18, 19],
  pattern: /^(50)(1)\d*$/
}), r.cards.push({
  cardType: "troy",
  startingRules: [9792],
  permittedLengths: [16],
  pattern: /^(97)(9)\d*$/
}), r.cards.push({
  cardType: "forbrugsforeningen",
  startingRules: [600722],
  permittedLengths: [16],
  pattern: /^(60)(0)\d*$/
}), r.cards.push({
  cardType: "vpay",
  startingRules: [401, 408, 413, 434, 435, 437, 439, 441, 442, 443, 444, 446, 447, 455, 458, 460, 461, 463, 466, 471, 479, 482, 483, 487],
  permittedLengths: [13, 14, 15, 16, 17, 18, 19],
  pattern: /^(40[1,8]|413|43[4,5]|44[1,2,3,4,6,7]|45[5,8]|46[0,1,3,6]|47[1,9]|48[2,3,7])[0-9]{0,16}$/
}), r.cards.push({
  cardType: "rupay",
  startingRules: [508528],
  permittedLengths: [16],
  pattern: /^(100003|508(2|[5-9])|60(69|[7-8])|652(1[5-9]|[2-5][0-9]|8[5-9])|65300[3-4]|8172([0-1]|[3-5]|7|9)|817(3[3-8]|40[6-9]|410)|35380([0-2]|[5-6]|9))[0-9]{0,12}$/
}), r.cards.push({
  cardType: "ticket",
  expiryDatePolicy: "hidden"
});
var s = {
  detectCard: (e, s) => {
    let a, n, p;
    if (s) {
      if (a = r.cards.filter(t => s.includes(t.cardType)).filter(r => t(r, "pattern") && e.match(r.pattern)), a.length) {
        if (1 === a.length) return a[0];
        for (n = 0, p = a.length; n < p; n += 1) if (!a[n].longestRule) {
          const t = a[n].startingRules.reduce((t, e) => t > e ? t : e);
          a[n].longestRule = String(t).length;
        }
        return a.reduce((t, e) => t.longestRule >= e.longestRule ? t : e);
      }
      return {
        cardType: r.__NO_BRAND
      };
    }
    return {
      cardType: r.__NO_BRAND
    };
  },
  detectCardLength: (t, e) => {
    let s,
      a,
      n = 0,
      p = !1,
      d = e;
    const c = t.cardType !== r.__NO_BRAND ? t.permittedLengths[t.permittedLengths.length - 1] : 0;
    if (c && d > c && (n = d.length - c, n > 0 && (d = d.substring(0, d.length - n), a = d)), t.permittedLengths.forEach(t => {
      d.length === t && (p = !0);
    }), d.length === c) {
      const e = Math.floor(d.length / 4);
      s = c + (d.length % 4 > 0 ? e : e - 1), "amex" === t.cardType.toLowerCase() && (s = c + 2);
    }
    return {
      shortenedNewValue: a,
      maxLength: s,
      reachedValidLength: p
    };
  },
  getShortestPermittedCardLength: () => {
    if (!e) {
      let t = [];
      r.cards.forEach(e => {
        t = t.concat(e.permittedLengths ?? []);
      }), e = Math.min.apply(null, t);
    }
    return e;
  },
  getCardByBrand: t => r.cards.filter(e => e.cardType === t)[0],
  isGenericCardType: t => {
    if (!t) throw new Error("Error: isGenericCardType: type param has not been specified");
    return "card" === t || "scheme" === t;
  },
  __NO_BRAND: r.__NO_BRAND,
  allCards: r.cards
};
export { s as default };
