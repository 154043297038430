const t = {
  isExpress: !1,
  userAction: "pay",
  commit: !0,
  vault: !1,
  enableMessages: !1,
  style: {
    height: 50
  },
  blockPayPalCreditButton: !1,
  blockPayPalPayLaterButton: !1,
  blockPayPalVenmoButton: !1
};
export { t as default };
