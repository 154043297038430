class e {
  session;
  options;
  constructor(e, t) {
    this.options = t, this.session = new ApplePaySession(t.version, e), this.session.onvalidatemerchant = e => {
      this.onvalidatemerchant(e, t.onValidateMerchant);
    }, this.session.onpaymentauthorized = e => {
      this.onpaymentauthorized(e, t.onPaymentAuthorized);
    }, this.session.oncancel = e => {
      this.oncancel(e, t.onCancel);
    }, "function" == typeof t.onPaymentMethodSelected && (this.session.onpaymentmethodselected = e => {
      this.onpaymentmethodselected(e, t.onPaymentMethodSelected);
    }), "function" == typeof t.onShippingContactSelected && (this.session.onshippingcontactselected = e => {
      this.onshippingcontactselected(e, t.onShippingContactSelected);
    }), "function" == typeof t.onShippingMethodSelected && (this.session.onshippingmethodselected = e => {
      this.onshippingmethodselected(e, t.onShippingMethodSelected);
    });
  }
  begin() {
    return this.session.begin();
  }
  onvalidatemerchant(e, t) {
    return new Promise((n, o) => t(n, o, e.validationURL)).then(e => {
      this.session.completeMerchantValidation(e);
    }).catch(e => {
      console.error(e), this.session.abort(), this.options.onError(e);
    });
  }
  onpaymentauthorized(e, t) {
    return new Promise((n, o) => t(n, o, e)).then(e => {
      this.session.completePayment(e);
    }).catch(e => {
      this.session.completePayment(e);
    });
  }
  onpaymentmethodselected(e, t) {
    return new Promise((n, o) => t(n, o, e)).then(e => {
      this.session.completePaymentMethodSelection(e);
    }).catch(e => {
      this.session.completePaymentMethodSelection(e);
    });
  }
  onshippingcontactselected(e, t) {
    return new Promise((n, o) => t(n, o, e)).then(e => {
      this.session.completeShippingContactSelection(e);
    }).catch(e => {
      this.session.completeShippingContactSelection(e);
    });
  }
  onshippingmethodselected(e, t) {
    return new Promise((n, o) => t(n, o, e)).then(e => {
      this.session.completeShippingMethodSelection(e);
    }).catch(e => {
      this.session.completeShippingMethodSelection(e);
    });
  }
  oncancel(e, t) {
    t(e);
  }
}
export { e as default };
