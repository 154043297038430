var o = {
  type: "card",
  keypadFix: !0,
  rootNode: null,
  loadingContext: null,
  brands: [],
  showWarnings: !1,
  autoFocus: !0,
  trimTrailingSeparator: !0,
  onChange: () => {},
  onLoad: () => {},
  onConfigSuccess: () => {},
  onAllValid: () => {},
  onFieldValid: () => {},
  onBrand: () => {},
  onError: () => {},
  onBinValue: () => {},
  onFocus: () => {},
  onAutoComplete: () => {},
  handleKeyPress: () => {},
  styles: {}
};
export { o as default };
