import { ENCRYPTED_CARD_NUMBER as c, ENCRYPTED_EXPIRY_DATE as t, ENCRYPTED_SECURITY_CODE as o, ENCRYPTED_PWD_FIELD as a } from "../../../internal/SecuredFields/lib/constants.js";
const n = "holderName",
  e = "socialSecurityNumber",
  r = [c, t, o],
  i = [n, c, t, o],
  s = [c, t, o, n],
  l = ["taxNumber", a],
  m = r.concat(l),
  u = i.concat(l),
  b = s.concat(l),
  d = r.concat([e]),
  N = i.concat([e]),
  p = s.concat([e]);
export { r as CREDIT_CARD, s as CREDIT_CARD_NAME_BOTTOM, i as CREDIT_CARD_NAME_TOP, m as KCP_CARD, b as KCP_CARD_NAME_BOTTOM, u as KCP_CARD_NAME_TOP, d as SSN_CARD, p as SSN_CARD_NAME_BOTTOM, N as SSN_CARD_NAME_TOP };
