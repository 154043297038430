import { Component, OnInit, OnDestroy, Input, SimpleChanges, Output, EventEmitter, Optional, OnChanges } from '@angular/core';
import { PriceConfigService } from '../../services/price-config/price-config.service';
import { CustomerProductPrice, Price } from '../../models/price';
import { Subject } from 'rxjs';
import { Inject } from '@angular/core';
import { Product } from '../../models';

export enum PriceLevelsDisplayType {
  HIDDEN = 'HIDDEN',                   // Hide price-levels [DEFAULT BEHAVIOUR]
  COMPACT_LIST = 'COMPACT_LIST',      // For list-views
  COMPACT_GRID = 'COMPACT_GRID',      // For grid-views
  FULL = 'FULL'                      // For product-details
}

@Component({
  selector: 'lib-price-inside',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.css']
})
export class PriceComponent implements OnInit, OnDestroy, OnChanges {
  private _price: CustomerProductPrice | string;
  @Input() set price(value: CustomerProductPrice | string) {
    if (value === undefined || value === null || this._price === value) {
      return;
    }
    this._price = value;
    // this.initValue();
  }
  get price(): CustomerProductPrice | Price | string {
    return this._price;
  }

  priceHTML: string;

  @Input()
  type?: 'RRP' | 'NET' | 'GROSS' | 'STAFFED' | string;

  typeMode: string;

  @Input()
  display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean;

  @Input()
  displayOnlyPrice?: boolean;

  private _currency: string;
  @Input() set currency(value: string) {
    if (value === undefined || value === null || this._currency === value) {
      return;
    }
    this._currency = value;
    // this.initValue();
  }
  get currency(): string {
    return this._currency;
  }

  protected unsubscribe: Subject<void> = new Subject();
  public hidePrice: boolean;

  @Output()
  staffedPrice = new EventEmitter<boolean>();

  @Input()
  useStaffedPrice: boolean;

  @Input()
  displayPriceLevels: boolean = this.environment?.displayPriceLevels || false;

  // Controls the type of layout of the price levels
  @Input()
  priceLevelsDisplayType: string = PriceLevelsDisplayType.HIDDEN;

  // To display the current price level and staffed price it will use this quantity instead of the current quantity in cart if set
  @Input()
  currentQty: number = undefined;

  @Optional()
  @Input()
  product: Product;

  constructor(protected priceConfigService: PriceConfigService, @Optional() @Inject('environment') protected environment) { }

  ngOnInit() {
    this.initValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.price || changes.currency) {
      this.initValue();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initValue() {
    // Check if STAFFED price LEVELS
    this.checkStaffedPriceLevels(this.price as CustomerProductPrice);
    this.typeMode = this.checkModes(this.type);
    if (this.type === 'STAFFED') {
      this.priceConfigService
        .getStaffedPriceHTML(
          this.price as CustomerProductPrice,
          this.typeMode,
          this.display,
          this.currency,
          this.displayOnlyPrice,
          this.currentQty,
          this.product
        )
        .subscribe(priceHTML => {
          this.priceHTML = priceHTML;
        });
    } else {
      this.priceHTML = this.priceConfigService.getPriceHTML(this.price, this.typeMode, this.display, this.currency, this.product);
    }
    this.priceConfigService.hidePrice.subscribe(hidePrice => (this.hidePrice = hidePrice));
  }

  checkModes(type: string): string {
    /* Fabio - Not sure why set a default mode if we don't pass type
    if (!type) {
      return 'customerGrossPrice';
    }
    */
    let mode: string;
    switch (type?.toUpperCase()) {
      case 'RRP':
        mode = 'recommendedRetailPrice';
        break;
      case 'NET':
        mode = 'customerNetPrice';
        break;
      case 'GROSS':
        mode = 'customerGrossPrice';
        break;
      case 'STAFFED':
        mode = 'customerStaffedNetPrice';
        break;
      default:
        if (type && type.length > 0) {
          mode = type;
        } /* else {
          mode = 'customerGrossPrice';
        } */
        break;
    }
    return mode;
  }

  /**
   * check if product price have levels to use STAFFED price
   * @param price as CustomerProductPrice
   */
  checkStaffedPriceLevels(price: CustomerProductPrice) {
    if ((price !== undefined && price !== null) && (this.useStaffedPrice === undefined || this.useStaffedPrice === null)) {
      this.useStaffedPrice = this.useStaffedPrice || !!(price?.backendPrice?.levels?.length > 1);
    }
    if (this.useStaffedPrice && this.type === 'NET') {
      // ONLY REPLACE TO STAFFED IF NET ASKED PRICE
      this.type = 'STAFFED';
    }
    this.staffedPrice.emit(this.type === 'STAFFED');
  }
}
