import { createElement as o } from "../../../external/preact/dist/preact.js";
import { useEffect as t } from "../../../external/preact/hooks/dist/hooks.js";
import { CtpState as e } from "./services/ClickToPayService.js";
import n from "./context/useClickToPayContext.js";
import r from "./components/CtPOneTimePassword/CtPOneTimePassword.js";
import s from "./components/CtPCards/CtPCards.js";
import i from "./components/CtPSection/CtPSection.js";
import a from "./components/CtPLoader/CtPLoader.js";
import m from "./components/CtPLogin/CtPLogin.js";
import p from "./services/sdks/SrciError.js";
const c = ({
  onDisplayCardComponent: c
}) => {
  const {
    ctpState: d,
    onReady: l,
    startIdentityValidation: C,
    logoutShopper: f
  } = n();
  return t(() => {
    [e.OneTimePassword, e.Login, e.Ready].includes(d) && l();
  }, [d, l]), t(() => {
    d === e.ShopperIdentified && async function () {
      try {
        await C();
      } catch (o) {
        o instanceof p && console.warn(`CtP - Identity Validation error: ${o.toString()}`), await f();
      }
    }();
  }, [d]), d === e.NotAvailable ? null : o(i, null, [e.Loading, e.ShopperIdentified].includes(d) && o(a, null), d === e.OneTimePassword && o(r, {
    onDisplayCardComponent: c
  }), d === e.Ready && o(s, {
    onDisplayCardComponent: c
  }), d === e.Login && o(m, null));
};
export { c as default };
