<div class="settings-sidebar-hamburger-button p-1">
  <button class="btn btn-link" (click)="toggleSideMenu()">
    <i class="fa-regular fa-bars fa-xl"></i>
  </button>
</div>

<div class="position-relative">
  <div class="row">
    <div class="d-none d-md-flex col-md-3 menu-list-separator">
      <ng-container *ngTemplateOutlet="menuList"></ng-container>
    </div>

    <div class="col-sm-12 col-md-9 pl-0 pr-0 pl-md-3 pr-md-3 pt-3 pt-md-0">
      <ng-template #hostView></ng-template>
    </div>
  </div>
</div>

<div class="settings-sidebar" [ngClass]="{'sidebar-open': !!sideMenuOpen}">
  <ng-container *ngTemplateOutlet="menuList"></ng-container>
</div>

<div class="settings-sidebar-background" [ngClass]="{'sidebar-open': !!sideMenuOpen}" (click)="closeSideMenu()"></div>

<ng-template #menuList>
  <ul class="menu-list">
    <li *ngFor="let item of gungSettingsMenu; index as idx">
      <div *ngIf="!item.hide && !item.isSeparator" class="pt-1 pb-1">
        <div class="prepend-selection" [ngClass]="{'bg-primary': idx === selectedIndex}"></div>
      </div>

      <button
        *ngIf="!item.hide && !item.isSeparator"
        class="btn btn-link settings-menu-button"
        [ngClass]="{'settings-menu-button-selected': idx === selectedIndex}"
        [disabled]="item.disabled"
        (click)="selectMenuItem(idx)"
      >
        <span>
          <i style="min-width: 1rem" *ngIf="!!item.prependIcon" [class]="item.prependIcon + ' mr-2'"></i>
          <span>{{ item.translationLabel | translate }}</span>
          <i *ngIf="!!item.appendIcon" [class]="item.appendIcon + ' ml-2'"></i>
        </span>
      </button>

      <hr *ngIf="!item.hide && item.isSeparator" class="m-2 w-100">
    </li>
  </ul>
</ng-template>
