import { Component, OnInit } from '@angular/core';
import { ConfigurationsService } from '../../services/configurations.service';
import { catchError, first, forkJoin, of, switchMap } from 'rxjs';
import { GungNotificationService } from 'gung-common';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '../../services/users/users.service';

@Component({
  selector: 'lib-home-navigation-config',
  templateUrl: './home-navigation-config.component.html',
  styleUrl: './home-navigation-config.component.scss'
})
export class HomeNavigationConfigComponent implements OnInit {
  private readonly configurationId = 'HomePageConfiguration';

  homePageConfiguration: HomePageConfiguration;
  rolesToShow: string[] = [];
  hasRoleError: { [key: string]: boolean } = {};

  constructor(
    protected configurationService: ConfigurationsService,
    protected gungNotificationService: GungNotificationService,
    protected translateService: TranslateService,
    protected usersService: UsersService
  ) {}

  ngOnInit(): void {
    this.usersService
      .getDefaultUserRoles()
      .pipe(
        first(),
        switchMap(roles =>
          forkJoin([of(roles), this.configurationService.getConfigurationsByIds([this.configurationId]).pipe(first())])
        )
      )
      .subscribe(([roles, configuration]) => {
        if (!configuration || configuration.length === 0) {
          return;
        }

        const config = configuration[0];
        this.rolesToShow = roles.map(role => role.id).filter(role => role !== 'ACTUATOR');
        this.rolesToShow.forEach(role => {
          if (!config.roleHomePages[role]) {
            config.roleHomePages[role] = '';
          }
        });
        this.homePageConfiguration = config;
      });
  }

  save(): void {
    for (const role of this.rolesToShow) {
      if (this.hasRoleError[role]) {
        return;
      }
    }

    this.configurationService
      .updateConfiguration(this.homePageConfiguration)
      .pipe(
        first(),
        catchError((err, caught) => {
          this.gungNotificationService.notify(
            this.translateService.instant('ERROR_SAVING_CONFIGURATION'),
            this.translateService.instant('ERROR_SAVING_CONFIGURATION'),
            'error'
          );
          return of(null);
        })
      )
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.gungNotificationService.notify(
          this.translateService.instant('CONFIGURATION_SAVED'),
          this.translateService.instant('CONFIGURATION_SAVED'),
          'success'
        );
      });
  }

  validateInput(value: string, role: string) {
    if (!value) {
      return;
    }

    if (!value.startsWith('/')) {
      this.hasRoleError[role] = true;
    } else {
      this.hasRoleError[role] = false;
    }
  }
}

interface HomePageConfiguration {
  roleHomePages: { [role: string]: string };
}
