import { select as e, getAttribute as t } from "../../utilities/dom.js";
import { DATA_ENCRYPTED_FIELD_ATTR as i, CVC_POLICY_REQUIRED as s, DATE_POLICY_REQUIRED as o, ENCRYPTED_EXPIRY_YEAR as n, SF_CONFIG_TIMEOUT as r, ALL_SECURED_FIELDS as a, DATA_INFO as d, DATA_UID as l } from "../../constants.js";
import { existy as c } from "../../../../../../utils/commonUtils.js";
import h from "../utils/cardType.js";
import u from "../../securedField/SecuredField.js";
import p from "../../../../../../core/Errors/AdyenCheckoutError.js";
function f() {
  this.encryptedAttrName = i;
  const n = e(this.props.rootNode, `[${this.encryptedAttrName}]`).filter(e => {
      const i = t(e, this.encryptedAttrName),
        s = a.includes(i);
      return s || console.warn(`WARNING: '${i}' is not a valid type for the '${this.encryptedAttrName}' attribute. A SecuredField will not be created for this element.`), s;
    }),
    r = s,
    d = o;
  return this.config.isCreditCardType ? (this.isSingleBrandedCard = !1, this.securityCode = "", this.createCardSecuredFields(n, r, d), n.length) : (this.createNonCardSecuredFields(n), n.length);
}
async function m(e) {
  for (let t = 0; t < e.length; t++) {
    const i = e[t];
    await this.setupSecuredField(i).catch(e => {
      window._b$dl && console.log("Secured fields setup failure. e=", e);
    });
  }
}
async function y(e, t, i) {
  let n = this.state.type;
  if ("card" === n && 1 === this.config.cardGroupTypes.length && (n = this.config.cardGroupTypes[0], this.state.type = n), this.isSingleBrandedCard = "card" !== n, this.isSingleBrandedCard) {
    const e = h.getCardByBrand(n);
    c(e) ? (t = e.cvcPolicy || s, i = e.expiryDatePolicy || o, this.securityCode = e.securityCode) : this.state.type = "unrecognised-single-brand";
  }
  for (let s = 0; s < e.length; s++) {
    const o = e[s];
    window._b$dl && console.log("\nAbout to set up securedField:", o), await this.setupSecuredField(o, t, i).catch(e => {
      window._b$dl && console.log("Secured fields setup failure. e=", e);
    }), window._b$dl && console.log("Finished setting up securedField:", o);
  }
  if (window._b$dl && console.log("Finished setting up all securedFields"), this.isSingleBrandedCard) {
    const e = {
      type: this.state.type,
      rootNode: this.props.rootNode,
      brand: n,
      cvcPolicy: t,
      expiryDatePolicy: i,
      cvcText: this.securityCode
    };
    setTimeout(() => {
      this.callbacks.onBrand(e);
    }, 0);
  }
}
function g(e, i, s) {
  return new Promise((o, a) => {
    const c = t(e, this.encryptedAttrName);
    c === n && (this.state.hasSeparateDateFields = !0);
    const h = {
        fieldType: c,
        extraFieldData: t(e, d),
        uid: t(e, l),
        cvcPolicy: i,
        holderEl: e,
        expiryDatePolicy: s,
        txVariant: this.state.type,
        cardGroupTypes: this.config.cardGroupTypes,
        iframeUIConfig: this.config.iframeUIConfig,
        sfLogAtStart: this.config.sfLogAtStart,
        trimTrailingSeparator: this.config.trimTrailingSeparator,
        isCreditCardType: this.config.isCreditCardType,
        iframeSrc: this.config.iframeSrc,
        loadingContext: this.config.loadingContext,
        showWarnings: this.config.showWarnings,
        legacyInputMode: this.config.legacyInputMode,
        minimumExpiryDate: this.config.minimumExpiryDate,
        maskSecurityCode: this.props.maskSecurityCode,
        exposeExpiryDate: this.props.exposeExpiryDate,
        disableIOSArrowKeys: this.props.shouldDisableIOSArrowKeys,
        implementationType: this.props.implementationType,
        showContextualElement: this.props.showContextualElement,
        placeholders: this.props.placeholders
      },
      f = new u(h, this.props.i18n).onIframeLoaded(() => {
        if (this.state.iframeCount += 1, window._b$dl && console.log("### createSecuredFields::onIframeLoaded:: this.state.iframeCount=", this.state.iframeCount), this.state.iframeCount > this.state.numIframes) throw this.destroySecuredFields(), new p("ERROR", `One or more securedFields has just loaded new content. This should never happen. securedFields have been removed.\n                        iframe load count=${this.state.iframeCount}. Expected count:${this.state.numIframes}`);
        if (f.loadToConfigTimeout = setTimeout(() => {
          a({
            type: h.fieldType,
            failReason: "sf took too long to config"
          });
        }, r), this.state.iframeCount === this.state.originalNumIframes) {
          const e = {
            iframesLoaded: !0
          };
          this.callbacks.onLoad(e);
        }
      }).onConfig(e => {
        this.handleIframeConfigFeedback(e), clearTimeout(f.loadToConfigTimeout), f.loadToConfigTimeout = null, o(e);
      }).onFocus(e => {
        this.handleFocus(e);
      }).onBinValue(e => {
        this.handleBinValue(e);
      }).onTouchstart(e => {
        this.props.shouldDisableIOSArrowKeys && (this.hasGenuineTouchEvents || e.hasGenuineTouchEvents) && this.callbacks.onTouchstartIOS({
          fieldType: e.fieldType
        }), (e.hasGenuineTouchEvents || this.hasGenuineTouchEvents) && this.postMessageToAllIframes({
          fieldType: e.fieldType,
          fieldClick: !0
        });
      }).onShiftTab(e => {
        this.handleSFShiftTab(e.fieldType);
      }).onEncryption(e => {
        this.handleEncryption(e);
      }).onValidation(e => {
        this.handleValidation(e);
      }).onAutoComplete(e => {
        this.processAutoComplete(e);
      }).onKeyPressed(e => {
        const {
          numKey: t,
          ...i
        } = e;
        this.callbacks.onKeyPressed(i);
      });
    this.state.securedFields[c] = f;
  });
}
export { y as createCardSecuredFields, m as createNonCardSecuredFields, f as createSecuredFields, g as setupSecuredField };
