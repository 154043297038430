import { createElement as t } from "../../../external/preact/dist/preact.js";
import { useCoreContext as e } from "../../../core/Context/CoreProvider.js";
const r = () => {
  const {
    i18n: r
  } = e();
  return t("p", {
    className: "adyen-checkout-form-instruction"
  }, r.get("form.instruction"));
};
export { r as default };
