import { CHALLENGE_WINDOW_SIZES as e, DEFAULT_CHALLENGE_WINDOW_SIZE as t } from "../constants.js";
import { getOrigin as r } from "../../../utils/getOrigin.js";
import s from "../../../utils/base64.js";
import { pick as n } from "../../../utils/commonUtils.js";
const a = e => "success" in e && !e.success,
  o = e => {
    const t = s.decode(e);
    if (t.success) try {
      return JSON.parse(t.data);
    } catch (e) {
      return {
        success: !1,
        error: "Could not JSON parse token"
      };
    }
    return t;
  },
  i = e => {
    if (!e || !Object.keys(e).length) throw new Error("No (populated) data object to encode");
    return s.encode(JSON.stringify(e));
  },
  c = r => {
    const s = 1 === r.length ? `0${r}` : r;
    return Object.prototype.hasOwnProperty.call(e, s) ? s : t;
  },
  p = t => e[c(t)],
  d = ({
    token: e,
    size: t
  }) => {
    const s = o(e);
    if (a(s)) return s;
    const {
        acsTransID: n,
        acsURL: i,
        messageVersion: d,
        threeDSNotificationURL: l,
        threeDSServerTransID: u
      } = s,
      h = r(l);
    return {
      acsURL: i,
      cReqData: {
        acsTransID: n,
        messageVersion: d,
        threeDSServerTransID: u,
        messageType: "CReq",
        challengeWindowSize: c(t)
      },
      iframeSizeArr: p(t),
      postMessageDomain: h
    };
  },
  l = ({
    token: e,
    notificationURL: t
  }) => {
    const s = o(e);
    if (a(s)) return s;
    const {
        threeDSMethodNotificationURL: n,
        threeDSMethodUrl: i,
        threeDSServerTransID: c
      } = s,
      p = t || n;
    return {
      threeDSServerTransID: c,
      threeDSMethodURL: i,
      threeDSMethodNotificationURL: p,
      postMessageDomain: r(p)
    };
  },
  u = (e, t, r) => ({
    data: {
      [e]: i({
        threeDSCompInd: t.threeDSCompInd
      }),
      paymentData: r
    }
  }),
  h = (e, t, r) => ({
    data: {
      details: {
        "threeds2.fingerprint": i(t)
      },
      paymentData: r
    }
  }),
  m = (e, t, r) => ({
    data: {
      details: {
        [e]: i({
          transStatus: t,
          authorisationToken: r
        })
      }
    }
  }),
  D = (e, t, r) => ({
    data: {
      details: {
        "threeds2.challengeResult": i({
          transStatus: t
        })
      },
      paymentData: r
    }
  }),
  S = e => {
    let t = window.btoa(e).split("=")[0];
    return t = t.replace(/\+/g, "-"), t = t.replace(/\//g, "_"), t;
  },
  f = ["elementRef"],
  g = ["createFromAction", "onAdditionalDetails"],
  R = (e, t) => {
    if ("fingerprint" === e) {
      const e = t.elementRef ? f : g,
        r = n(e).from(t);
      return r.showSpinner = !t.isDropin, r.statusType = "loading", r;
    }
    return {
      statusType: "custom",
      i18n: t.i18n
    };
  };
export { m as createChallengeResolveData, u as createFingerprintResolveData, D as createOldChallengeResolveData, h as createOldFingerprintResolveData, o as decodeAndParseToken, S as encodeBase64URL, i as encodeObject, R as get3DS2FlowProps, p as getChallengeWindowSize, a as isErrorObject, d as prepareChallengeData, l as prepareFingerPrintData, c as validateChallengeWindowSize };
