import e from "./AbstractCSF.js";
import { handleConfig as s } from "./extensions/handleConfig.js";
import { configureCallbacks as t } from "./extensions/configureCallbacks.js";
import { handleValidation as i } from "./extensions/handleValidation.js";
import { handleEncryption as r } from "./extensions/handleEncryption.js";
import { createSecuredFields as o, createNonCardSecuredFields as a, createCardSecuredFields as n, setupSecuredField as d } from "./extensions/createSecuredFields.js";
import h from "./extensions/handleIOSTouchEvents.js";
import l from "./extensions/handleTab.js";
import c, { sendBrandToCardSF as u, sendExpiryDatePolicyToSF as p } from "./extensions/handleBrandFromBinLookup.js";
import { setFocusOnFrame as m } from "./partials/setFocusOnFrame.js";
import { postMessageToAllIframes as f } from "./partials/postMessageToAllIframes.js";
import F from "./partials/processBrand.js";
import { processAutoComplete as y } from "./partials/processAutoComplete.js";
import { handleFocus as C } from "./partials/handleFocus.js";
import { handleIframeConfigFeedback as g } from "./partials/handleIframeConfigFeedback.js";
import { isConfigured as S } from "./partials/isConfigured.js";
import b from "./partials/validateForm.js";
import { handleBinValue as j } from "./partials/handleBinValue.js";
import { destroySecuredFields as T } from "./utils/destroySecuredFields.js";
import I from "./utils/iframes/postMessageToIframe.js";
import k from "./utils/iframes/getIframeContentWin.js";
import { warn as O } from "../utilities/logger.js";
import { selectOne as x } from "../utilities/dom.js";
import { on as v } from "../../../../../utils/listenerUtils.js";
import { partial as L } from "../utilities/commonUtils.js";
import { hasOwnProperty as B } from "../../../../../utils/hasOwnProperty.js";
import K from "./utils/userAgent.js";
const P = (e = "You cannot use secured fields") => {
  O(`${e} - they are not yet configured. Use the 'onConfigSuccess' callback to know when this has happened.`);
};
class V extends e {
  constructor(e) {
    super(e), this.state = {
      type: this.props.type,
      brand: "card" !== this.props.type ? {
        brand: this.props.type,
        cvcPolicy: "required"
      } : {
        brand: null,
        cvcPolicy: "required"
      },
      allValid: void 0,
      numIframes: 0,
      originalNumIframes: 0,
      iframeCount: 0,
      iframeConfigCount: 0,
      isConfigured: !1,
      hasSeparateDateFields: !1,
      currentFocusObject: null,
      registerFieldForIos: !1,
      securedFields: {},
      isKCP: !1
    };
    const I = {
      csfState: this.state,
      csfConfig: this.config,
      csfProps: this.props,
      csfCallbacks: this.callbacks
    };
    this.configHandler = s, this.callbacksHandler = t, this.validateForm = L(b, I), this.isConfigured = L(S, I, this.validateForm), this.handleIframeConfigFeedback = L(g, I, this.isConfigured), this.processBrand = L(F, I), this.handleValidation = i, this.handleEncryption = r, this.createSecuredFields = o, this.createNonCardSecuredFields = a, this.createCardSecuredFields = n, this.setupSecuredField = d, this.postMessageToAllIframes = L(f, I), this.handleIOSTouchEvents = h.handleTouchend.bind(this), this.touchendListener = h.touchendListener.bind(this), this.destroyTouchendListener = h.destroyTouchendListener.bind(this), this.touchstartListener = h.touchstartListener.bind(this), this.destroyTouchstartListener = h.destroyTouchstartListener.bind(this), this.setFocusOnFrame = L(m, I), this.handleFocus = L(C, I, this.handleIOSTouchEvents), this.handleSFShiftTab = l.handleSFShiftTab, this.handleShiftTab = l.handleShiftTab, this.destroySecuredFields = T, this.processAutoComplete = L(y, I), this.handleBinValue = L(j, I), this.handleBrandFromBinLookup = c, this.sendBrandToCardSF = u, this.sendExpiryDatePolicyToSF = p, this.init();
  }
  init() {
    this.configHandler(this.props), this.callbacksHandler(this.props.callbacks);
    const e = this.createSecuredFields();
    this.state.numIframes = this.state.originalNumIframes = e, this.state.isKCP = !!this.props.isKCP, K.__IS_IOS && this.props.shouldDisableIOSArrowKeys && (this.hasGenuineTouchEvents = !1, v(document, "touchstart", this.touchstartListener));
  }
  createReturnObject() {
    return {
      updateStyles: e => {
        this.state.isConfigured ? this.postMessageToAllIframes({
          styleObject: e
        }) : O("You cannot update the secured fields styling - they are not yet configured. Use the 'onConfigSuccess' callback to know when this has happened.");
      },
      setFocusOnFrame: e => {
        this.state.isConfigured ? this.setFocusOnFrame(e) : P("You cannot set focus on any secured field");
      },
      isValidated: (e, s) => {
        if (this.state.isConfigured) {
          if (B(this.state.securedFields, e)) {
            this.state.securedFields[e].hasError = !0, "" === this.state.securedFields[e].errorType && (this.state.securedFields[e].errorType = "isValidated");
            const t = {
              txVariant: this.state.type,
              fieldType: e,
              externalValidation: !0,
              code: s,
              numKey: this.state.securedFields[e].numKey
            };
            I(t, k(this.state, e), this.config.loadingContext);
          }
        } else P("You cannot set validated on any secured field");
      },
      hasUnsupportedCard: (e, s) => {
        if (this.state.isConfigured) {
          if (B(this.state.securedFields, e)) {
            this.state.securedFields[e].hasError = !!s, this.state.securedFields[e].errorType = s;
            const t = {
              txVariant: this.state.type,
              fieldType: e,
              unsupportedCard: !!s,
              code: s,
              numKey: this.state.securedFields[e].numKey
            };
            I(t, k(this.state, e), this.config.loadingContext);
          }
        } else P("You cannot set hasUnsupportedCard on any secured field");
      },
      destroy: () => {
        this.state.isConfigured ? this.destroySecuredFields() : P("You cannot destroy secured fields");
      },
      brandsFromBinLookup: (e, s) => {
        if (!this.config.isCreditCardType) return null;
        this.state.isConfigured ? this.handleBrandFromBinLookup(e, s) : P("You cannot set pass brands to secured fields");
      },
      addSecuredField: e => {
        const s = x(this.props.rootNode, `[data-cse="${e}"]`);
        s && (this.state.numIframes += 1, this.setupSecuredField(s));
      },
      removeSecuredField: e => {
        if (this.state.securedFields[e]) {
          this.state.securedFields[e].destroy(), delete this.state.securedFields[e], this.state.numIframes -= 1, this.state.iframeCount -= 1;
          const s = {
            additionalIframeRemoved: !0,
            fieldType: e,
            type: this.state.type
          };
          this.callbacks.onAdditionalSFRemoved(s);
        }
      },
      setKCPStatus: e => {
        this.state.isKCP = e;
      },
      sfIsOptionalOrHidden: e => this.state.securedFields[e].isOptionalOrHidden()
    };
  }
}
export { V as default };
