import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent, AuthService, AvailabilityService, Availability } from 'gung-standard';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { map } from 'rxjs';
import { parse } from 'date-fns';

export const getMinDate = (av: Availability): Date => {
  if (av.currentAvailability > 0) {
    return new Date();
  }
  if (av.maxFutureStock === 0) {
    return new Date();
  }
  const valids = Object.keys(av.availabilities).filter(entry => av.availabilities[entry] > 0);
  const dateFormat = 'yyMMdd';
  return valids.length > 0 ? parse(valids[0], dateFormat, new Date()) : new Date();
};

@Component({
  selector: 'gung-jeeves-order-terms-jeeves',
  templateUrl: './order-terms-jeeves.component.html',
  styleUrls: ['./order-terms-jeeves.component.css']
})
export class OrderTermsJeevesComponent extends CheckoutStepComponent implements OnInit {
  loadingComponent = true;
  form: FormGroup;

  minDate: Date;

  constructor(
    protected authService: AuthService,
    protected formBuilder: FormBuilder,
    protected datePipe: DatePipe,
    protected availabilityService: AvailabilityService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.onNextBtnClicked.subscribe(_ => this.handleNextButtonClicked());
    this.initForm();
    this.loadingComponent = false;

    this.setMinDate();
  }

  protected setMinDate() {
    this.availabilityService
    .getAvailabilities(
      this.checkout.rows.map(r => r.id),
      undefined,
      true
    )
    .pipe(map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities }))))
    .subscribe(avs => {
      let minDate = new Date();
      for (const row of this.checkout.rows) {
        if (row.extra.orp.ordberlevdat) {
          const tempDate = new Date(row.extra.orp.ordberlevdat);
          if (minDate < tempDate) {
            minDate = tempDate;
          }
        } else {
          const av = avs.find(a => a.productId === row.id);
          if (av) {
            const tempDate = this.getMinDateInternal(av);
            if (minDate < tempDate) {
              minDate = tempDate;
            }
          }
        }
      }
      this.minDate = minDate;
    });
  }
  
  protected getMinDateInternal(av: Availability): Date {
    return getMinDate(av);
  }

  protected initForm() {
    this.form = this.formBuilder.group({
      levsattkod: this.formBuilder.control(this.checkout.extra.procargs.levsattkod || ''),
      levvillkkod: this.formBuilder.control(this.checkout.extra.procargs.levvillkkod || ''),
      betkod: this.formBuilder.control(this.checkout.extra.oh.betkod || ''),
      saljare: this.formBuilder.control(this.checkout.extra.procargs.saljare || '')
    });
  }

  handleNextButtonClicked(): void {
    if (this.form.invalid) {
      return;
    }

    this.assignFormValues(this.form);
    this.stepDone.emit(this.checkout);
  }

  protected assignFormValues(formGroup: FormGroup) {
    const rawValues = formGroup.getRawValue();
    this.checkout.extra = {
      ...this.checkout.extra,
      procargs: {
        ...this.checkout.extra.procargs,
        levsattkod: rawValues.levsattkod,
        levvillkkod: rawValues.levvillkkod,
        saljare: rawValues.saljare
      },
      oh: {
        ...this.checkout.extra.oh,
        betkod: rawValues.betkod
      }
    };
  }

  dateSelected(event) {
    const date = event.date;
    const dString: string = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.checkout.extra.oh.ordberlevdat = dString;
  }

  getLabel(): string {
    return 'TERMS_AND_CONDITIONS';
  }
}
