const e = (e, r) => {
    let t = [];
    return e && "function" == typeof e.querySelectorAll && (t = [].slice.call(e.querySelectorAll(r))), t;
  },
  r = (e, r) => {
    if (e) return e.querySelector(r);
  },
  t = (e, r) => {
    if (e) return e.getAttribute(r);
  },
  l = e => {
    for (; e.firstChild;) e.removeChild(e.firstChild);
  };
export { t as getAttribute, l as removeAllChildren, e as select, r as selectOne };
