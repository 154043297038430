import { VISA_SDK_PROD as t, VISA_SDK_TEST as e, getVisaSetttings as i } from "./config.js";
import s from "./AbstractSrcInitiator.js";
import n from "./SrciError.js";
const a = {
  email: "EMAIL",
  telephoneNumber: "MOBILE_NUMBER"
};
class o extends s {
  schemeName = "visa";
  constructor(i, s) {
    super(i.toLowerCase().includes("live") ? t : e, s);
  }
  isSdkIsAvailableOnWindow() {
    return !!window.vAdapters?.VisaSRCI;
  }
  assignSdkReference() {
    this.schemeSdk = new window.vAdapters.VisaSRCI();
  }
  async init(t, e) {
    try {
      const s = {
        ...t,
        ...i(this.customSdkConfiguration),
        srciTransactionId: e
      };
      await this.schemeSdk.init(s);
    } catch (t) {
      throw new n(t, "init", this.schemeName);
    }
  }
  async identityLookup({
    identityValue: t,
    type: e
  }) {
    try {
      const i = {
        identityValue: t,
        type: a[e]
      };
      return await this.schemeSdk.identityLookup(i);
    } catch (t) {
      throw new n(t, "identityLookup", this.schemeName);
    }
  }
  async completeIdentityValidation(t) {
    try {
      return await this.schemeSdk.completeIdentityValidation(t);
    } catch (t) {
      throw new n(t, "completeIdentityValidation", this.schemeName);
    }
  }
}
export { o as default };
