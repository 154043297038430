const a = "https://sandbox-assets.secure.checkout.visa.com/checkout-widget/resources/js/src-i-adapter/visa-sdk.js?v2",
  s = "https://assets.secure.checkout.visa.com/checkout-widget/resources/js/src-i-adapter/visa-sdk.js?v2",
  e = "https://sandbox.src.mastercard.com/sdk/srcsdk.mastercard.js",
  t = "https://src.mastercard.com/sdk/srcsdk.mastercard.js",
  c = ({
    dpaLocale: a = "en_US",
    dpaPresentationName: s = ""
  }) => ({
    dpaTransactionOptions: {
      dpaLocale: a,
      payloadTypeIndicator: "NON_PAYMENT",
      customInputData: {
        checkoutOrchestrator: "merchant"
      }
    },
    dpaData: {
      dpaPresentationName: s
    }
  }),
  r = ({
    dpaLocale: a = "en_US",
    dpaPresentationName: s = ""
  }) => ({
    dpaTransactionOptions: {
      dpaLocale: a,
      paymentOptions: {
        dynamicDataType: "CARD_APPLICATION_CRYPTOGRAM_SHORT_FORM"
      },
      consumerNameRequested: !0,
      customInputData: {
        "com.mastercard.dcfExperience": "PAYMENT_SETTINGS"
      },
      confirmPayment: !1
    },
    dpaData: {
      dpaPresentationName: s
    }
  });
export { t as MC_SDK_PROD, e as MC_SDK_TEST, s as VISA_SDK_PROD, a as VISA_SDK_TEST, r as getMastercardSettings, c as getVisaSetttings };
