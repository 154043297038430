<div class="form-group">
  <label *ngIf="label">
    {{ label | translate }}
    <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
  </label>
  <div
    ngbDropdown
    #dropDownEle=ngbDropdown
    [autoClose]="'outside'"
    (openChange)="openChange($event)"
    class="border {{ dropdownCustomClass }}"
  >
    <button class="btn btn-block text-left input-multi-select" ngbDropdownToggle [disabled]="disabled" (click)="focusInput ? filterListSearch.focusInput() : undefined">
      <div class="d-flex align-items-center">
        <span class="flex-grow-1 text-truncate">
          {{ previewList && previewList.length > 0 ? previewList.join(',') : (previewEmpty | translate) }}
        </span>
        <i
          class="fa float-right ml-2"
          [ngClass]="{ 'fa-chevron-down': !dropdownIsOpen, 'fa-chevron-up': dropdownIsOpen }"
        ></i>
      </div>
    </button>
    <ul class="input-multi-select-list" ngbDropdownMenu style="z-index: 9999;">
      <li *ngIf="optionsFilterList.length == 0" class="multi-select-no-results">
        <span translate>NO_RESULTS_FOUND</span>
      </li>
      <li [class.d-none]="optionsList.length === 0">
        <lib-filter-list-search #filterListSearch (searchUpdated)="setSearch($event)"></lib-filter-list-search>
      </li>
      <li *ngFor="let option of optionsFilterList" [ngClass]="{ 'd-none': (!option.id && !showEmptyIds) || option.hide }">
        <button
          class="btn top-filter-item w-100 text-left"
          [class.bg-light]="option.selected"
          (click)="option.selected = !option.selected; setSelectValue(option.id, option.selected)"
          translate
        >
          <i *ngIf="!singleSelection" class="far" [ngClass]="{ 'fa-square': !option.selected, 'fa-check-square': option.selected }"></i>
          <ng-container *ngIf="!singleSelection && !previewField; else newVersion">
            {{ showId ? option.id : '' }}{{ showId && option.name && option.id ? ' - ' : '' }}{{ option.name }}
          </ng-container>
          <ng-template #newVersion>
            {{ getPreviewField(option) }}
          </ng-template>
        </button>
      </li>
    </ul>
  </div>
</div>
