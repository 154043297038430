const e = "1.0.0",
  t = "1.0.0",
  r = "deviceFingerprint",
  o = 2e4,
  n = {
    result: {
      type: r,
      value: "df-timedOut"
    },
    errorCode: "timeout"
  },
  i = {
    TIME_OUT: "timeout",
    WRONG_ORIGIN: "wrongOrigin",
    WRONG_DATA_TYPE: "wrongDataType",
    MISSING_PROPERTY: "missingProperty",
    UNKNOWN: "unknownError"
  },
  d = {
    timeout: "iframe loading timed out",
    wrongOrigin: "Result did not come from the expected origin",
    wrongDataType: "Result data was not of the expected type",
    missingProperty: "Result data did not contain the expected properties",
    unknownError: "An unknown error occurred"
  };
export { r as DEVICE_FINGERPRINT, o as DF_TIMEOUT, t as DF_VERSION, i as ERRORS, d as ERROR_MESSAGES, n as FAILED_DFP_RESOLVE_OBJECT_TIMEOUT, e as RISK_DATA_VERSION };
