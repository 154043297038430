import { createElement as e } from "../../../external/preact/dist/preact.js";
import { useRef as s } from "../../../external/preact/hooks/dist/hooks.js";
import a from "../../../external/classnames/index.js";
import { useModal as o } from "./useModal.js";
const r = ({
  children: r,
  classNameModifiers: l = [],
  isOpen: i,
  onClose: d,
  isDismissible: t = !0,
  labelledBy: c,
  describedBy: n,
  focusFirst: m,
  focusAfterClose: p,
  ...u
}) => {
  const f = s(),
    {
      closeModal: h,
      handleClickOutside: b
    } = o({
      modalElement: f.current,
      isOpen: i,
      isDismissible: t,
      focusFirst: m,
      focusAfterClose: p,
      onClose: d
    });
  return e("div", {
    className: a("adyen-checkout__modal-wrapper", l.map(e => `adyen-checkout__modal-wrapper--${e}`), {
      "adyen-checkout__modal-wrapper--open": i
    }),
    role: "dialog",
    "aria-labelledby": c,
    "aria-describedby": n,
    "aria-modal": "true",
    "aria-hidden": !i,
    onClick: b,
    ...u
  }, e("div", {
    className: "adyen-checkout__modal",
    ref: f
  }, r({
    onCloseModal: h
  })));
};
export { r as Modal };
