import { Injectable } from '@angular/core';
import { first, forkJoin, Observable, switchMap, mergeMap, toArray } from 'rxjs';
import {
  ConfigService,
  ListSortOption,
  ListLayout,
  GridLayoutComponent,
  ConfigBaseFilter,
  ListLayoutMultipleComponent,
  SelectionAction
} from 'gung-list';
import { Product } from '../../models/product';
import { ProductService } from '../products/product.service';
import { AuthService } from '../auth/auth.service';
import { ProductExportCardComponent } from '../../components/product-export-card/product-export-card.component';
import { ProductExportTableComponent } from '../../components/product-export-table/product-export-table.component';
import { ProductExportService } from '../product-export/product-export.service';
import { ProductListConfigService } from '../product-list-config/product-list-config.service';
import { ProductSortService } from '../product-sort/product-sort.service';
import { ProductExportActionConfigService } from '../product-export-action-config/product-export-action-config.service';
import { ProductListV2ConfigService } from '../product-list-v2-config/product-list-v2-config.service';
import { BaseViewConfigService, ProductViewType } from '../base-view-config/base-view-config.service';

@Injectable({
  providedIn: 'root'
})
export class ProductExportListConfigService implements ConfigService<Product> {
  topFilter = this.productListConfigService.topFilter;
  digitalAssets = false;

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected productExportService: ProductExportService,
    protected productSortService: ProductSortService,
    protected productListConfigService: ProductListConfigService,
    protected productExportActionConfigService: ProductExportActionConfigService,
    protected productListV2ConfigService: ProductListV2ConfigService,
    protected baseViewConfigService: BaseViewConfigService
  ) {}

  getFilters(): ConfigBaseFilter<Product>[] {
    if (this.baseViewConfigService.productViewType === ProductViewType.productV2) {
      return this.productListV2ConfigService.getFilters();
    } else {
      return this.productListConfigService.getFilters();
    }
  }

  getSortOptions(): ListSortOption<Product>[] {
    return this.productListConfigService.getSortOptions();
  }

  getBatchSizes(): number[] {
    return this.productListConfigService.getBatchSizes();
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => 'fas fa-th',
        getListItemComponent: () => ProductExportCardComponent,
        getListLayoutComponent: () => GridLayoutComponent,
        getName: () => 'ProductGrid'
      },
      {
        getIconClass: () => 'fas fa-list',
        getListItemComponent: () => ProductExportTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductList'
      }
    ];
  }

  getItems(): Observable<Product[]> {
    return this.authService.getCurrentUser().pipe(
      first(),
      switchMap(user => {
        this.productListConfigService.assortmentSubject.next(user.assortment);
        if (this.digitalAssets) {
          return this.productService.getProductsForDigitalAssets(
            user.assortment,
            this.productListConfigService.displayProductsOnVariantLevel
          );
        } else {
          if (this.baseViewConfigService.productViewType === ProductViewType.productV2) {
            return this.productListV2ConfigService.getItems();
          } else {
            return this.productListConfigService.getItems();
          }
        }
      })
    );
  }

  getItemId(item: Product) {
    return this.productListConfigService.getItemId(item);
  }

  getSearchTerms(item: Product) {
    return this.productListConfigService.getSearchTerms(item);
  }

  getSelectionActions(): Observable<SelectionAction<Product>[]> {
    return forkJoin([
      this.productExportActionConfigService.getSelectionActions().pipe(first()),
      this.productExportActionConfigService.getDynamicSelectionActions().pipe(first())
    ]).pipe(
      mergeMap(([staticActions, dynamicActions]) => {
        const res = [];
        res.push(...staticActions);
        res.push(...dynamicActions);
        return res;
      }),
      toArray()
    );
  }

  getSelectionMarkingActions(): Observable<SelectionAction<Product>[]> {
    return this.productExportActionConfigService.getSelectionMarkingActions();
  }

  getNothingFoundTranslateTag() {
    if (this.digitalAssets) {
      return 'ADD_FLOW_ADIGITAL_ASSET';
    } else {
      return 'NOTHING_FOUND_CONTACT_GUNG';
    }
  }
}
