import { createElement as e } from "../../../../external/preact/dist/preact.js";
import a from "../../../../external/classnames/index.js";
import { ARIA_ERROR_SUFFIX as c, ARIA_CONTEXT_SUFFIX as n } from "../../../../core/Errors/constants.js";
function t({
  classNameModifiers: t = [],
  label: o,
  isInvalid: s,
  onChange: r,
  ...l
}) {
  const {
    uniqueId: i,
    showErrorElement: d,
    showContextualElement: m,
    ...h
  } = l;
  return e("label", {
    className: "adyen-checkout__checkbox",
    htmlFor: i
  }, e("input", {
    id: i,
    ...h,
    ...(d && {
      "aria-describedby": `${i}${c}`
    }),
    ...(m && {
      "aria-describedby": `${i}${n}`
    }),
    className: a(["adyen-checkout__checkbox__input", [l.className], {
      "adyen-checkout__checkbox__input--invalid": s
    }, t.map(e => `adyen-checkout__input--${e}`)]),
    type: "checkbox",
    onChange: r
  }), e("span", {
    className: "adyen-checkout__checkbox__label"
  }, o));
}
t.defaultProps = {
  onChange: () => {}
};
export { t as default };
