import { createElement as t } from "../../../external/preact/dist/preact.js";
import e from "./InputBase.js";
function a(a) {
  return t(e, {
    ...a,
    type: "email",
    autoCapitalize: "off"
  });
}
export { a as default };
