import { ENCRYPTED_SECURITY_CODE as t, GIFT_CARD as e, SF_FIELDS_MAP as c, ENCRYPTED_SECURITY_CODE_3_DIGITS as a, ENCRYPTED_SECURITY_CODE_4_DIGITS as s } from "../constants.js";
var r = (r, d, i, o) => {
  const n = {
      ...r
    },
    u = {};
  switch (i) {
    case "ach":
    case e:
      break;
    default:
      if (o === t) u[a] = d.get("creditCard.securityCode.contextualText.3digits"), u[s] = d.get("creditCard.securityCode.contextualText.4digits");else {
        const t = `creditCard.${c[o]}.contextualText`,
          e = d.get(t);
        e !== t && (u[o] = e);
      }
  }
  return Object.keys(u).length && (n.contextualTexts = u), n;
};
export { r as default };
