import { warn as t } from "../../../utilities/logger.js";
const e = (e, a, i) => {
    const s = e.origin,
      o = a.indexOf("/checkoutshopper/");
    let n = o > -1 ? a.substring(0, o) : a;
    const r = n.length - 1;
    return "/" === n.charAt(r) && (n = n.substring(0, r)), s === n || (i && t("WARNING postMessageValidation: postMessage listener for iframe::origin mismatch!\n Received message with origin:", s, "but the only allowed origin for messages to CSF is", n, "### event.data=", e.data), !1);
  },
  a = t => t.data && t.data.type && "string" == typeof t.data.type && t.data.type.indexOf("webpack") > -1,
  i = t => t.data && "string" == typeof t.data && t.data.indexOf("cvox") > -1;
export { i as isChromeVoxPostMsg, a as isWebpackPostMsg, e as originCheckPassed };
