import { Component as e, createElement as s } from "../../../../external/preact/dist/preact.js";
import r from "../../../../components/internal/IFrame/Iframe.js";
import t from "../../../../utils/promiseTimeout.js";
import { DEVICE_FINGERPRINT as i, FAILED_DFP_RESOLVE_OBJECT_TIMEOUT as o, DF_TIMEOUT as n } from "../../constants.js";
import a from "../../../../utils/get-process-message-handler.js";
import { getOrigin as m } from "../../../../utils/getOrigin.js";
class p extends e {
  postMessageDomain;
  processMessageHandler;
  deviceFingerPrintPromise;
  constructor(e) {
    super(e), this.postMessageDomain = m(this.props.loadingContext) || this.props.loadingContext;
  }
  getDfpPromise() {
    return new Promise((e, s) => {
      this.processMessageHandler = a(this.postMessageDomain, e, s, i), window.addEventListener("message", this.processMessageHandler);
    });
  }
  componentDidMount() {
    this.deviceFingerPrintPromise = t(n, this.getDfpPromise(), o), this.deviceFingerPrintPromise.promise.then(e => {
      this.props.onCompleteFingerprint(e), window.removeEventListener("message", this.processMessageHandler);
    }).catch(e => {
      this.props.onErrorFingerprint(e), window.removeEventListener("message", this.processMessageHandler);
    });
  }
  render({
    dfpURL: e
  }) {
    return s("div", {
      className: "adyen-checkout-risk__device-fingerprint"
    }, s(r, {
      name: "dfIframe",
      src: e,
      allow: "geolocation; microphone; camera;",
      title: "devicefingerprinting iframe"
    }));
  }
}
export { p as default };
