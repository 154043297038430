<div *ngIf="loading">
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</div>
<div class="d-flex justify-content-center align-items-center">
  <div class="w-50" id="adyen-container">
    <div #hook id="drop-in"></div>
  </div>
</div>

<div *ngIf="successfulPayment" class="container mt-5">
  <div class="mb-4">
    <h2 class="text-center mb-5">
      <span>{{ 'ADYEN_PAYMENT_SUCCESSFUL' | translate }}</span>
    </h2>
    <div class="d-flex mt-5 justify-content-center">
      <div class="text-center" style="width: 100%">
        <i class="fa-solid fa-spinner fa-10x"></i>
      </div>
    </div>
  </div>
</div>
