<div class="my-company-wrapper">
  <div class="row">
    <div class="col-12">
      <h3 translate>THE_COMPANY</h3>
    </div>
  </div>
  <div class="row" *ngIf="form; else loading" [formGroup]="form">
    <div class="col-12">
      <nav ngbNav #nav="ngbNav" class="nav-tabs">
        <ng-container ngbNavItem>
          <a (click)="selectedNavItem = 'information'" ngbNavLink translate>INFORMATION</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-12 col-md-6">
                <lib-gung-text-input gungTranslate="COMPANY_NAME" formControlName="name"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="ADDRESS1" formControlName="address1"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="ADDRESS2" formControlName="address2"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="ZIP" formControlName="zipCode"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="CITY" formControlName="city"></lib-gung-text-input>
              </div>
              <div class="col-12 col-md-6">
                <lib-gung-text-input
                  gungTranslate="ORG_NUMBER"
                  formControlName="organizationNumber"
                ></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="EMAIL_ADDRESS" formControlName="email"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="TELEPHONE" formControlName="telephone"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="WEBADDRESS" formControlName="webAddress"></lib-gung-text-input>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a (click)="selectedNavItem = 'logo'" ngbNavLink translate>LOGO</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-12">
                <h5 class="mt-2">
                  <span translate>FAVICON</span>
                </h5>
                <ng-template
                  *ngTemplateOutlet="
                    uploadImage;
                    context: {
                      $implicit: objectFileImagePreview['favicon'],
                      imgTag: 'favicon',
                      inputAccept: '.ico'
                    }
                  "
                ></ng-template>
              </div>
              <div class="col-12">
                <h5 class="mt-2">
                  <span translate>CUSTOMIZE_NAVBAR_LOGO</span>
                </h5>
                <ng-template
                  *ngTemplateOutlet="
                    uploadImage;
                    context: {
                      $implicit: objectFileImagePreview['logo'],
                      imgTag: 'logo'
                    }
                  "
                ></ng-template>
              </div>
              <div class="col-12">
                <h5 class="mt-2">
                  <span translate>CUSTOMIZE_LOGIN_LOGO</span>
                </h5>
                <ng-template
                  *ngTemplateOutlet="
                    uploadImage;
                    context: {
                      $implicit: objectFileImagePreview['logoLogin'],
                      imgTag: 'logoLogin'
                    }
                  "
                ></ng-template>
              </div>
              <div class="col-12">
                <h5 class="mt-2">
                  <span translate>CUSTOMIZE_ORDER_CONFIRM_LOGO</span>
                </h5>
                <ng-template
                  *ngTemplateOutlet="
                    uploadImage;
                    context: {
                      $implicit: objectFileImagePreview['logoOrderConfirm'],
                      imgTag: 'logoOrderConfirm'
                    }
                  "
                ></ng-template>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a (click)="selectedNavItem = 'footer'" ngbNavLink translate>FOOTER</a>
          <ng-template ngbNavContent>
            <lib-my-company-footer [company]="myCompany"></lib-my-company-footer>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a (click)="selectedNavItem = 'theme'" ngbNavLink translate>THEME</a>
          <ng-template ngbNavContent>
            <div class="row" formGroupName="colors">
              <div class="col-12 col-md-4">
                <h3 translate>COLOR</h3>

                <div class="d-flex align-items-end">
                  <lib-gung-text-input gungTranslate="PRIMARY_COLOR"
                                       formControlName="primary"></lib-gung-text-input>
                  <i
                    class="fas fa-square fa-3x ml-2"
                    (click)="colorPicker1.click()"
                    [ngStyle]="{ color: form.get('colors').get('primary').value }"
                  ></i>
                  <input #colorPicker1 type="color" formControlName="primary"
                         style="height: 0px; width: 0px; visibility: hidden;" class="mt-5">
                </div>
                <div class="d-flex align-items-end">
                  <lib-gung-text-input
                    gungTranslate="SECONDARY_COLOR"
                    formControlName="secondary"
                  ></lib-gung-text-input>
                  <i
                    class="fas fa-square fa-3x ml-2"
                    (click)="colorPicker2.click()"
                    [ngStyle]="{ color: form.get('colors').get('secondary').value }"
                  ></i>
                  <input #colorPicker2 type="color" formControlName="secondary"
                         style="height: 0px; width: 0px; visibility: hidden;" class="mt-5">
                </div>
              </div>

              <div class="col-12 col-md-4">
                <h3 style="visibility: hidden" translate>COLOR</h3>

                <div class="d-flex align-items-end">
                  <lib-gung-text-input
                    gungTranslate="HOVER_COLOR"
                    formControlName="hover"
                  ></lib-gung-text-input>
                  <i
                    class="fas fa-square fa-3x ml-2"
                    (click)="colorPicker3.click()"
                    [ngStyle]="{ color: form.get('colors').get('hover').value }"
                  ></i>
                  <input #colorPicker3 type="color" formControlName="hover"
                         style="height: 0px; width: 0px; visibility: hidden;" class="mt-5">
                </div>
                <div class="d-flex align-items-end">
                  <lib-gung-text-input
                    gungTranslate="CONTRASTING_COLOR"
                    formControlName="contrasting"
                  ></lib-gung-text-input>
                  <i
                    class="fas fa-square fa-3x ml-2"
                    (click)="colorPicker4.click()"
                    [ngStyle]="{ color: form.get('colors').get('contrasting').value }"
                  ></i>
                  <input #colorPicker4 type="color" formControlName="contrasting"
                         style="height: 0px; width: 0px; visibility: hidden;" class="mt-5">
                </div>
              </div>

              <div class="col-12 col-md-4">
                <!--
                Removed this since it looks like it does not work.
                If noone pings me about this in a few weeks time I'll most likely remove it completely.
                <div class="d-flex flex-column justify-content-end h-100">
                  <button type="button" (click)="setupCustomization()" style="max-width: 6rem;" class="btn btn-primary" translate>TEST</button>
                </div>
                -->
              </div>

              <div class="col-12">
                  <hr />
              </div>

              <div class="col-12">
                <div class="d-flex">
                  <h3 translate class="mt-1">LOGIN_BACKGROUND</h3>
                </div>
                <ng-template
                  *ngTemplateOutlet="
                    uploadImage;
                    context: {
                      $implicit: objectFileImagePreview['loginBackgroundImage'],
                      imgTag: 'loginBackgroundImage'
                    }
                  "
                ></ng-template>
              </div>
            </div>
          </ng-template>
        </ng-container>

        <ng-container ngbNavItem>
          <a (click)="selectedNavItem = 'script'" ngbNavLink translate>SCRIPT</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-12 col-md-6" formGroupName="extra">
                <h3 translate>SCRIPT</h3>
                <div formGroupName="customization">
                  <lib-gung-text-area-input
                    suppressLabel="true"
                    gungTranslate="INDEX_HEADER"
                    formControlName="indexHead"
                    rowsNumber="10"
                  >
                  </lib-gung-text-area-input>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </nav>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-right" *ngIf="shouldDisplayButtons()">
      <button type="button" class="btn btn-secondary" (click)="initForm()" translate>RESET</button>
      <button type="button" class="btn btn-primary" (click)="save()" translate>SAVE</button>
    </div>
  </div>
</div>

<ng-template #loading> LOADING <i class="fas fa-spinner fa-spin ml-2"></i></ng-template>

<ng-template #uploadImage let-fileImagePrev let-imgTag="imgTag" let-inputAccept="inputAccept">
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4 my-3">
      <lib-gung-input-file
        [accept]="inputAccept || 'image/*,application/pdf'"
        multiple="true"
        enableDragDrop="true"
        (onFileSelect)="onDrop($event, imgTag)" />
    </div>
    <div class="col-12 col-md-6 col-lg-8">
      <div *ngIf="fileImagePrev" class="text-center">
        @if (fileImagePrev.s3ETag && fileImagePrev.s3Uri.endsWith('.ico')) {
          <img class="img-fluid" style="max-height: 150px;"
               [src]="'https://cdn2.gung.io/'+ fileImagePrev.s3Uri + '?etag='+fileImagePrev.s3ETag"
               alt="{{ fileImagePrev.description }}"
          />
        } @else if (fileImagePrev.s3ETag) {
          <img class="img-fluid" style="max-height: 150px;"
               [src]="fileImagePrev.s3Uri | gungImageUrl
          :'etag:'+fileImagePrev.s3ETag : 'type:thumbnail' : 'size:800x500'
        "
               alt="{{ fileImagePrev.description }}"
          />
        } @else if (fileImagePrev.s3Uri) {
          <img class="img-fluid" style="max-height: 150px;" [src]="fileImagePrev.s3Uri"
               alt="{{ fileImagePrev.name }}" />
        } @else {
        }
      </div>
    </div>
  </div>
</ng-template>
