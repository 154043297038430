import { HttpClient, HttpResponse } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, filter, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs';
import trim from 'lodash/trim';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { GungDownloadUrl } from '../services/gung-image-url/gung-image-url.service';

/**
 * Gung function to add/remove spinner to HTML element (eg btn)
 * input the $event in the HTML and pass element.target in the TS
 * @param htmlElement html element where the spinner will appear
 * @returns true if spinner added; false if spinner removed
 */
export function gungAddRemoveSpinner(htmlElement: HTMLElement, showConsoleLogs?: boolean): boolean {
  if (showConsoleLogs) {
    console.log('gungAddRemoveSpinner - htmlElement', htmlElement);
  }
  if (!htmlElement) {
    return;
  }
  const spinnerHtml = '<i class="fa fa-spinner fa-spin ml-1"></i>';
  const oemHtml: string = htmlElement.innerHTML;
  if (showConsoleLogs) {
    console.log('gungAddRemoveSpinner - innerHTML', oemHtml);
  }
  if (oemHtml.includes(spinnerHtml)) {
    // remove spinner
    htmlElement.innerHTML = oemHtml.slice(0, -spinnerHtml.length);
    if (htmlElement instanceof HTMLInputElement || htmlElement instanceof HTMLButtonElement) {
      (htmlElement as HTMLInputElement).disabled = false;
    }
    return false;
  } else {
    // add spinner
    htmlElement.innerHTML += spinnerHtml;
    if (htmlElement instanceof HTMLInputElement || htmlElement instanceof HTMLButtonElement) {
      (htmlElement as HTMLInputElement).disabled = true;
    }
    return true;
  }
}

/**
 * Gung function to check if file exist before download in one request
 * @param http HttpClient inicialized in the constructor
 * @param url request endpoint
 * @param filename used if the endpoint don't return the filename
 * @param showConsoleLogs enable console logs
 * @returns true if file exists and downloaded: false if don't exists or error
 */
export function gungCheckAndDownloadFile(
  http: HttpClient,
  url: string,
  filename: string = 'export',
  showConsoleLogs?: boolean,
  getOrPost: 'get' | 'post' = 'get'
): Promise<boolean> {
  return (getOrPost === 'get' ?
    http.get(url, { responseType: 'blob' as 'json', observe: 'response' })
    : http.post(url, null, { responseType: 'blob' as 'json', observe: 'response' })
  ).pipe(
    switchMap((response: HttpResponse<Blob>) => {
      if (showConsoleLogs) {
        console.log('gungCheckAndDownloadFile - response', response);
      }
      if (!response) {
        return of(false);
      }
      const dataType = response.body.type;
      const binaryData = [];
      binaryData.push(response.body);
      const downloadLink = document.createElement('a');
      downloadLink.target = '_blank';
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
      const disposition = response.headers.get('content-disposition');
      if (disposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      if (filename) {
        downloadLink.setAttribute('download', filename);
      }
      document.body.appendChild(downloadLink);
      downloadLink.click();
      return of(true);
    }),
    catchError((err, caught) => {
      if (showConsoleLogs) {
        console.log('gungCheckAndDownloadFile - err', err);
      }
      return of(false);
    })
  )
    .toPromise();
}

/**
 * Return the property from the path of the object.
 * Similar to GungExtraUtils.getDotSeparatedMapValue() in backend.
 * @param path
 * @param object
 * @returns
 */
export function gungGetPropertyOfObject(
  path: string,
  object: any
): any {
  // Get field form object if exist
  const getField = (obje: object, field: string) => {
    if (field.includes('[') && field.includes(']')) {
      // Used when propr have brakets, e.g. table[field]
      const idx = field.slice(field.indexOf('[') + 1, field.indexOf(']'));
      field = field.slice(0, field.indexOf('['));
      if (!obje.hasOwnProperty(field)) {
        return null;
      }
      return obje[field][Number(idx)] || obje[field][idx];
    }
    if (!obje.hasOwnProperty(field)) {
      return null;
    }
    return obje[field];
  };

  if (!path || !object) {
    return undefined;
  }
  // Get property from object
  let obj: any = Object.assign({}, object);
  let fields = path.split('.');
  for (const field of fields) {
    if (!obj) {
      break;
    }
    obj = getField(obj, field);
  }
  return obj;
}

/**
 * Set the property from the path of the object.
 * Similar to GungExtraUtils.setDotSeparatedMapValue() in backend.
 * @param object
 * @param path
 * @param value
 * @param forceDelete
 * @returns
 */
export function gungSetPropertyOfObject(
  object: any,
  path: string,
  value: any,
  forceDelete?: boolean
): boolean {

  let fields = path.split('.');
  for (let index = 0; index < fields.length; index++) {
    const field = fields[index];
    if (!object.hasOwnProperty(field)) {
      if (index === (fields.length - 1)) {
        if (forceDelete && (value === null || value === undefined)) {
          return true;
        }
        object[field] = value;
        return true;
      } else {
        object[field] = {};
      }
      // return false; // If property don't exist it will be created
    }
    if (index === (fields.length - 1)) {
      if (forceDelete && (value === null || value === undefined)) {
        delete object[field];
        return true;
      }
      object[field] = value;
      return true;
    }
    object = object[field];
  }
  return false;
}

/**
 * Returns url and queryParams to use in HTML [routerLink]="link" [queryParams]="queryParams"
 * @param link object {url: string, queryParams?: {[s: string]: any}}
 * @param showConsoleLogs enable console logs
 * @returns object {url: string, queryParams?: {[s: string]: any}}
 */
export function gungGetUrlFormated(
  link: { url: string; queryParams?: { [s: string]: any } },
  showConsoleLogs?: boolean
): { url: string; queryParams?: { [s: string]: any } } {
  const linkUrl: string = link.url;
  const url = new URL('http://localhost' + linkUrl);
  const queryString = url.search;
  if (showConsoleLogs) {
    console.log('gungGetUrlFormated - queryString', queryString);
  }
  const urlParams = new URLSearchParams(queryString);
  if (showConsoleLogs) {
    console.log('gungGetUrlFormated - urlParams', urlParams.toString());
  }
  const queryParams = {};
  urlParams.forEach((value, key) => {
    const values = urlParams.get(key);
    queryParams[key] = values;
    // urlParams.delete('filters');
  });
  if (showConsoleLogs) {
    console.log('gungGetUrlFormated - queryParams', queryParams);
  }
  link.queryParams = queryParams;
  link.url = url.pathname;
  if (showConsoleLogs) {
    console.log('gungGetUrlFormated - link', link);
  }
  return link;
}

/**
 * Compare two value in ASC or DESC
 * @param aValue Value or Array of values
 * @param bValue Value to compare
 * @param ascSort ascending (1) or descending (-1)
 * @returns number (0 equal, 1, -1)
 */
export function gungComparatorHelper(aValue: any, bValue: any, ascSort: -1 | 1 | (1 | -1)[], sortList?: any[], orderStringByNumber?: boolean): -1 | 0 | 1 | number {
  const chekEmpty = (value) => {
    return value === undefined || value === null || value === '';
  };
  if (Array.isArray(aValue) && Array.isArray(bValue) && aValue.length === bValue.length) {
    // If we want to compare multiple fields
    for (let index = 0; index < aValue.length; index++) {
      const aValueSingle = aValue[index];
      const bValueSingle = bValue[index];
      const ascSortSingle = (Array.isArray(ascSort) && ascSort[index]) || ascSort;
      const helper = gungComparatorHelper(aValueSingle, bValueSingle, ascSortSingle, sortList);
      if (helper !== 0) {
        return helper;
      }
    }
    return 0;
  }
  ascSort = (Array.isArray(ascSort) && ascSort[0]) || (ascSort as 1 | -1); // Maybe need to convert to a new const
  if (chekEmpty(aValue) && chekEmpty(bValue)) {
    return 0;
  }
  if (chekEmpty(aValue)) {
    return 1; // Empty always to the bottom
    // return -1 * ascSort;
  }
  if (chekEmpty(bValue)) {
    return -1;  // Empty always to the bottom
    // return 1 * ascSort;
  }
  if (sortList) {
    return sortList.indexOf(aValue) - sortList.indexOf(bValue);
  }
  let aNumber;
  let bNumber;
  if (typeof aValue === 'number') {
    aNumber = aValue;
  }
  if (typeof bValue === 'number') {
    bNumber = bValue;
  }
  if (chekEmpty(aNumber) && aValue.replace && !isNaN(Number(aValue.replace(',', '.')))) {
    aNumber = Number(aValue.replace(',', '.'));
  }
  if (chekEmpty(bNumber) && bValue.replace && !isNaN(Number(bValue.replace(',', '.')))) {
    bNumber = Number(bValue.replace(',', '.'));
  }
  // Number
  if (!chekEmpty(aNumber) && !chekEmpty(bNumber)) {
    if (Number(aNumber) < Number(bNumber)) {
      return -1 * ascSort;
    }
    if (Number(bNumber) < Number(aNumber)) {
      return 1 * ascSort;
    }
    return 0;
  }
  // Date
  if (aValue instanceof Date && bValue instanceof Date) {
    return (aValue.getTime() === bValue.getTime() ? 0 : aValue.getTime() < bValue.getTime() ? 1 : -1) * ascSort;
  }

  aValue = String(aValue);
  bValue = String(bValue);

  // String
  if (!isNaN(aValue.split(' ')[0]) && !isNaN(bValue.split(' ')[0]) && orderStringByNumber) {
    const aStringNumber = aValue.split(' ')[0];
    const bStringNumber = bValue.split(' ')[0];
    if (Number(aStringNumber) < Number(bStringNumber)) {
      return -1 * ascSort;
    }
    if (Number(bStringNumber) < Number(aStringNumber)) {
      return 1 * ascSort;
    }
    return 0;
  }
  return aValue.localeCompare(bValue) * ascSort;
}

const FILTER_PARAM_SPLIT_STRING1 = '__|__'; // BETWEEN FILTERS
const FILTER_PARAM_SPLIT_STRING2 = '__:__'; // BETWEEN FILTER NAME AND SELECTIONS
const FILTER_PARAM_SPLIT_STRING3 = '_____'; // BETWEEN FILTER SELECTIONS

/**
 * Extract the special encoding from the string
 * @param paramMapStr this.route.snapshot.queryParams
 * @returns object with the filters
 */
export function gungFilterParamStrToObj(paramMapStr: string | null): { [filterName: string]: string[] } {
  // TODO
  if (!paramMapStr || paramMapStr === '') {
    return {};
  }
  const splittedByFilters = paramMapStr.split(FILTER_PARAM_SPLIT_STRING1);
  const selectedOptionsByFilter: { [filterName: string]: string[] } = splittedByFilters
    .map(filterStr => filterStr.split(FILTER_PARAM_SPLIT_STRING2))
    .filter(f => {
      const valid = f.length === 2;
      if (!valid) {
        console.error('Filter query param is not valid', f);
      }
      return valid;
    })
    .map(f => ({
      [f[0]]: f[1].split(FILTER_PARAM_SPLIT_STRING3)
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});
  return selectedOptionsByFilter;
}

/**
 * gungCheckFileExistsLocally - check if the file exist on the project
 * @param filePath string of the file path
 * @returns true if existe false if not
 */
export function gungCheckFileExistsLocally(
  filePath: string,
  httpClient: HttpClient,
  showConsoleLogs?: boolean
): Observable<boolean> {
  return httpClient.get(filePath, { observe: 'response', responseType: 'blob' }).pipe(
    map(result => {
      if (showConsoleLogs) {
        console.log('gungCheckFileExistsLocally - result', result);
      }
      return true;
    }),
    catchError(err => {
      if (showConsoleLogs) {
        console.log('gungCheckFileExistsLocally - err', err);
      }
      return of(false);
    })
  );
}

/**
 * Calculate HSL from color (used in theming)
 * @param color
 * @returns
 */
export function gungColorToHsl(color: string): any[] {
  color = color?.trim();
  const rgbToHsl = (red, green, blue) => {
    const r = Number(trim(red)) / 255;
    const g = Number(trim(green)) / 255;
    const b = Number(trim(blue)) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          break;
      }
      h /= 6;
    }

    h = Math.round(360 * h);
    s = Math.round(100 * s);
    l = Math.round(100 * l);

    return [h, s, l];
  };

  if (color?.startsWith('#')) {
    const hexToRgb = hex =>
      hex
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1)
        .match(/.{2}/g)
        .map(x => parseInt(x, 16));
    const rgb = hexToRgb(color);
    return rgbToHsl(rgb[0], rgb[1], rgb[2]);
    // if (color.length === 4) {
    //   const r = parseInt(color.substr(1, 1) + color.substr(1, 1), 16);
    //   const g = parseInt(color.substr(2, 1) + color.substr(2, 1), 16);
    //   const b = parseInt(color.substr(3, 1) + color.substr(3, 1), 16);
    //   return rgbToHsl(r, g, b);
    // } else {
    //   const r = parseInt(color.substr(1, 2), 16);
    //   const g = parseInt(color.substr(3, 2), 16);
    //   const b = parseInt(color.substr(5, 2), 16);
    //   return rgbToHsl(r, g, b);
    // }
  } else if (color?.startsWith('rgba')) {
    const [r, g, b] = color.slice(5, -1).split(',');
    return rgbToHsl(r, g, b).slice(0, 3);
  } else if (color?.startsWith('rgb')) {
    const [r, g, b] = color.slice(4, -1).split(',');
    return rgbToHsl(r, g, b);
  } else if (color?.startsWith('hsla')) {
    return color.slice(5, -1).split(',').slice(0, 3);
  } else if (color?.startsWith('hsl')) {
    return color.slice(4, -1).split(',');
  } else {
    // named color values are not yet supported
    console.error(
      'Named color values are not supported in the config. Convert it manually using this chart: https://htmlcolorcodes.com/color-names/'
    );
    return [0, 0, 16]; // defaults to dark gray
  }
}

/**
 * Use this to call the autocomplete
 * @param text$
 * @param inputList
 * @param getField
 * @param minLength
 * @param maxOutput
 * @param debounceTimeValue
 * @returns
 */
export function gungTypeaheadSearchStreamFn(
  text$: Observable<string>,
  inputList: any[],
  getField: ((a: any) => string) | ((a: any) => string)[] = (s: any) => { return s; },
  minLength = 2,
  maxOutput = 10,
  debounceTimeValue = 200
): Observable<any[]> {
  // Fix special caracters in regex search term
  const fixRegex = (s: string) => {
    let res = s;
    res = res.replace(/\+/g, "\\+");
    return res;
  };

  return text$.pipe(
    debounceTime(debounceTimeValue),
    distinctUntilChanged(),
    filter((term: string) => term.length >= minLength),
    map((term: string) => {
      if (Array.isArray(getField)) {
        return inputList.filter(d => {
          return getField.find(fn => new RegExp(fixRegex(term), 'mi').test(fn(d)))
        }).slice(0, maxOutput)
      }
      return inputList.filter(d => new RegExp(fixRegex(term), 'mi').test(getField(d))).slice(0, maxOutput)
    })
  );
}

/**
 * Use to get the embed html/url from a video url
 * @param url
 * @returns
 */
export function mediaEmbed(url: string, extraClass?: string, width?: string, height?: string): { url: string; embedUrl: string; embedHtml: string; } {
  let format: 'youtube' | 'vimeo' | string;
  if (url.includes('youtube.com') || url.includes('youtu.be')) {
    format = 'youtube';
  } else if (url.includes('vimeo.com')) {
    format = 'vimeo';
  } else {
    const idx = url.lastIndexOf('.') + 1;
    format = url.substring(idx);;
  }
  let embedUrl: string
  let embedHtml: string
  switch (format) {
    case 'youtube':
      if (url.includes('youtube.com') && url.includes('embed')) {
        embedUrl = url;
      } else if (url.includes('youtu.be')) {
        const idx = url.indexOf('https://youtu.be/') + 'https://youtu.be/'.length;
        const code = url.substring(idx);
        embedUrl = `https://www.youtube.com/embed/${code}`;
      } else {
        const idx = url.indexOf('watch?v=') + 'watch?v='.length;
        const code = url.substring(idx);
        embedUrl = `https://www.youtube.com/embed/${code}`;
      }
      embedHtml = `
        <iframe
          class="${extraClass}"
          width="${width}" height="${height}"
          src="${embedUrl}"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen>
        </iframe>
      `;
      break;
    case 'vimeo':
      if (url.includes('player')) {
        embedUrl = url;
      } else {
        const idx = url.indexOf('vimeo.com/') + 'vimeo.com/'.length;
        const code = url.substring(idx);
        embedUrl = `https://player.vimeo.com/video/${code}`;
      }
      embedHtml = `
        <iframe
          class="${extraClass}"
          src="${embedUrl}"
          width="${width}" height="${height}"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen>
        </iframe>
      `;
      break;
    default:
      if (url.includes('http')) {
        embedUrl = url;
      } else {
        embedUrl = GungDownloadUrl(url);
      }
      embedHtml = `
        <video controls autoplay class="${extraClass}" width="${width}" height="${height}">
          <source src="${embedUrl}" type="video/${format}">
          Your browser does not support the video tag.
        </video>
      `;
      break;
  }

  return {
    url,
    embedUrl,
    embedHtml
  }
}

export function gungFormValidationErrors(form: FormGroup): { control: string; error: string; value: string; controller: AbstractControl; }[] {

  // console.log('%c ==>> Validation Errors: ', 'color: red; font-weight: bold; font-size:25px;');

  let totalErrors = 0;
  const errors = [];

  Object.keys(form.controls).forEach(key => {
    const controlErrors: ValidationErrors = form.get(key).errors;
    if (controlErrors != null) {
      totalErrors++;
      Object.keys(controlErrors).forEach(keyError => {
        errors.push({
          controller: form.get(key),
          control: key,
          error: keyError,
          value: controlErrors[keyError]
        });
        //  console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
      });
    }
  });

  // console.log('Number of errors: ' ,totalErrors);
  return errors;
}

export function gungJsonValidator(value: string): { json: any, error: any } {
  const result = { json: undefined, error: undefined };
  try {
    result.json = JSON.parse(value);
    return result;
  } catch (err) {
    result.error = err;
    return result;
  }
}

export function gungParseJsonToFortmatedString(object: any, space = 4): string {
  if (object === null) {
    return;
  }
  return JSON.stringify(object, undefined, space);
}

/* compare two objects */
export function checkObjectEquals(obj1, obj2, withTrimString?: boolean, ignoredProps?: string[]) {
  const x = clearEmpties(JSON.parse(JSON.stringify(obj1)), withTrimString);
  const y = clearEmpties(JSON.parse(JSON.stringify(obj2)), withTrimString);
  if (x === y) {
    return true;
  }
  if (!(x instanceof Object) || !(y instanceof Object)) {
    return false;
  }
  if (x.constructor !== y.constructor) {
    return false;
  }
  for (const p in x) {
    if (!x.hasOwnProperty(p)) {
      continue;
    }
    if (ignoredProps && ignoredProps.includes(p)) {
      continue;
    }
    if (!y.hasOwnProperty(p)) {
      return false;
    }
    if (typeof x[p] === 'string' && typeof y[p] === 'string') {
      if (x[p].localeCompare(y[p]) === 0) {
        continue;
      }
      return false;
    }
    if (x[p] === y[p]) {
      continue;
    }
    if (typeof x[p] !== 'object' || typeof y[p] !== 'object') {
      return false;
    }
    if (!checkObjectEquals(x[p], y[p], withTrimString, ignoredProps)) {
      return false;
    }
  }
  for (const p in y) {
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
      return false;
    }
  }
  return true;
}

export function clearEmpties(o, withTrimString?: boolean) {
  for (const k in o) {
    if (!o[k] || o[k] === null || o[k] === undefined) {
      delete o[k];
      continue; // If null or not an object, skip to the next iteration
    }
    if (typeof o[k] === 'string') {
      if (withTrimString) {
        o[k] = o[k].trim();
      }
      if (o[k] === '') {
        delete o[k];
      }
    }
    if (typeof o[k] !== 'object') {
      continue;
    }

    // The property is an object
    o[k] = clearEmpties(o[k], withTrimString); // <-- Make a recursive call on the nested object
    if (Object.keys(o[k]).length === 0) {
      delete o[k]; // The object had no properties, so delete that property
    }
  }
  return o;
}

export function countCharactersIgnoringHTML(html: string): number {
  const tagRegex = /<[^>]*>/g;
  const textOnly = html.replace(tagRegex, '');
  return textOnly.length;
}

export function shortenCharactersIgnoringHTML(html: string, comprimentoMaximo: number): string {
  let result = '';

  let idx = html.indexOf(' ', comprimentoMaximo);
  result = html.slice(0, idx)
  const openHtmlElements = findUnclosedOpenTags(result);
  // Check if open LI elements
  if (openHtmlElements.includes('li')) {
    // Get the open tag
    idx = result.lastIndexOf('<li', idx); // If you want close tag use indexOf('</li>', idx);
    result = result.slice(0, idx);
  }
  return result;
}

export function findUnclosedOpenTags(html: string): string[] {
  const openTagsStack: string[] = [];
  const unclosedOpenTags: string[] = [];
  const tagRegex = /<\s*\/?\s*([a-zA-Z0-9\-_]+)\s*[^>]*>/g;

  let match;

  while ((match = tagRegex.exec(html)) !== null) {
    const [, tagName] = match;

    if (tagName.startsWith('/')) {
      // It's a closing tag, check if there's a matching open tag in the stack
      const openTag = openTagsStack.pop();

      if (!openTag || openTag !== tagName.substring(1)) {
        // Found a closing tag without a matching open tag in the stack
        unclosedOpenTags.push(tagName);
      }
    } else {
      // It's an opening tag, add it to the stack
      openTagsStack.push(tagName);
    }
  }

  // Add any remaining unclosed open tags at the end of the HTML to the list
  while (openTagsStack.length > 0) {
    unclosedOpenTags.push(openTagsStack.pop()!);
  }

  return unclosedOpenTags.reverse();
}

export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function generatePassword(length: number) {
  let pass = '';
  let str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
    btoa(Math.random().toString(36)) +
    'abcdefghijklmnopqrstuvwxyz0123456789@#$';

  for (let i = 1; i <= length; i++) {
    let char = Math.floor(Math.random()
      * str.length + 1);

    pass += str.charAt(char)
  }

  return btoa(pass);
}


export function gungLoadStyle(stylePath = 'assets/style/_custom.scss', styleId = 'custom-theme') {
  const head = document.getElementsByTagName('head')[0];

  let themeLink = document.getElementById(styleId) as HTMLLinkElement;
  if (themeLink) {
    themeLink.href = stylePath;
  } else {
    const style = document.createElement('link');
    style.id = styleId;
    style.rel = 'stylesheet';
    style.type = 'text/css';
    style.href = stylePath;

    head.appendChild(style);
  }
}

export function gungLoadScript(scriptBody?: string, sricptPath?: string, scriptId?: string, async?: boolean, defer?: boolean, showConsoleLogs?: boolean) {
  if (!scriptBody && !sricptPath) {
    return;
  }
  const body = <HTMLDivElement>document.body;
  let found = false;
  let script: HTMLScriptElement;

  if (scriptId) {
    script = document.getElementById(scriptId) as HTMLScriptElement;
    found = !!script;
  }
  if (!found) {
    script = document.createElement('script');
  }
  if (scriptId) {
    script.id = scriptId;
  } else {
    script.id = (new Date()).getTime().toString();
  }
  // console.log(`gungLoadScript ${script.id} - CREATED`);
  script.type = 'text/javascript';
  // script.charset = 'utf-8';
  script.async = async;
  script.defer = defer;
  if (scriptBody) {
    script.text = scriptBody;
  } else if (sricptPath) {
    script.src = sricptPath;
  }

  if (showConsoleLogs) {
    script.onload = () => {
      console.log(`gungLoadScript ${script.id} - LOADED`);
    };
  }

  if (!found) {
    body.appendChild(script);
  }
}
