import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { gungCheckFileExistsLocally } from 'gung-common';
import { AuthService } from '../auth/auth.service';
import { filter, first, forkJoin, map, mergeMap, Observable, of, switchMap } from 'rxjs';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { CartService } from '../cart/cart.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { Router } from '@angular/router';
import { GungFlow } from '../../state/flow/types';
import { BaseViewConfigService, ProductViewType } from '../base-view-config/base-view-config.service';
import { Assortment, AssortmentService } from '../assortment.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationConfigService } from '../location-config/location-config.service';
import { GungCompanyService } from '../gung-company/gung-company.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { NavigationConfig } from '../../models/navigation-config';

export interface GungShopMenu {
  heading: string;
  link?: string;
  links?: GungShopMenu[];
  action?: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class GungNavbarService {
  logoUrl: string;

  isAssortmentBased = !!this.environment?.isAssortmentBasedNavigation || false;
  flowsAsDropDrown = false;
  public assortmentMenuLevel = 0;

  currentLang = this.translateService.currentLang || 'se';
  navbarHeight = 115;

  constructor(
    protected authService: AuthService,
    protected gungCompanyService: GungCompanyService,
    protected http: HttpClient,
    protected selectedCustomerService: SelectedCustomerService,
    protected gungFlowService: GungFlowService,
    protected cartService: CartService,
    protected gungModalService: GungModalService,
    protected router: Router,
    protected baseViewConfigService: BaseViewConfigService,
    protected assortmentService: AssortmentService,
    protected translateService: TranslateService,
    protected locationConfigService: LocationConfigService,
    @Inject('environment')
    protected environment: NavigationConfig
  ) { }

  getUserRole(roles: string[]): string {
    if (roles.includes('ADMIN')) {
      return 'ADMIN';
    } else if (roles.includes('SALES')) {
      return 'SALES';
    } else if (roles.includes('USER') && roles.length > 1) {
      return roles.filter(role => role !== 'USER')[0];
    } else if (roles.includes('USER') && roles.length === 1) {
      return 'USER';
    } else {
      return '';
    }
  }

  public getLogoUrl() {
    return this.logoUrl;
  }

  public getLogoUrlObservable(): Observable<string> {
    return this.gungCompanyService.getLogoUrlObservable().pipe(
      switchMap(logo =>
        forkJoin({
          logo: of(logo),
          assetsLogoExists: gungCheckFileExistsLocally(this.getLogoUrl(), this.http, false).pipe(first())
        })
      ),
      map(({ logo, assetsLogoExists }) => {
        return logo || (assetsLogoExists ? this.getLogoUrl() : 'https://cdn3.gung.io/_gung_common/_design/logo.png');
      })
    );
  }

  getCustomerInfo(): Observable<{ id: string; description: string }> {
    return this.selectedCustomerService.getSelectedCustomer().pipe(
      filter(cust => !!cust),
      map(selectedCustomer => ({
        id: selectedCustomer.id,
        description: `${selectedCustomer.extra?.gme?.displayId || selectedCustomer.id} - ${selectedCustomer.extra?.gme?.displayName || selectedCustomer.name}`
      }))
    );
  }

  getShopMenu(): Observable<GungShopMenu[]> {
    if (this.isAssortmentBased) {
      return this.getAssortmentMenu();
    } else {
      return this.getFlowMenu();
    }

  }

  public getFlowMenu(): Observable<GungShopMenu[]> {
    return this.gungFlowService.getGungFlows().pipe(
      map(gungFlow => {
        const baseMenu: GungShopMenu[] = [];
        gungFlow = gungFlow.sort((a, b) =>
          a.extra.sortingPriority > b.extra.sortingPriority
            ? 1
            : b.extra.sortingPriority > a.extra.sortingPriority
              ? -1
              : 0
        );
        for (const flow of gungFlow) {
          if (!flow.extra.digitalAssets) {
            baseMenu.push({
              heading: flow.name,
              action: () => {
                this.selectFlow(flow.id);
                this.redirectToProducts();

              }
            });
          }
        }
        return baseMenu;
      })
    );
  }

  getAssortmentMenu(): Observable<GungShopMenu[]> {
    return this.authService.getCurrentUser().pipe(
      filter(user => !!user),
      first(),
      mergeMap(user => {
        return this.assortmentService.getAssortmentRecursive(user.assortment).pipe(first());
      }),
      map(assortment => {
        if (this.assortmentMenuLevel === 0) {
          return assortment.children.map(childAssortment => {
            const link = {
              heading:
                (this.currentLang && childAssortment.extra?.i18n?.[this.currentLang]?.assortmentName) ||
                childAssortment.name,
              action: () => {
                this.cleanScroll();
              },
              link: this.getProductListBaseRoute() + `/${childAssortment.id}`
            } as GungShopMenu;
            return link;
          });
        } else {
          return this.getAssortmentWithLevels(assortment)
        }

      })
    );
  }

  getAssortmentWithLevels(assortments): GungShopMenu[] {
    return [this.buildAssortmentNavigation(assortments, 0, this.getProductListBaseRoute()+'/', this.assortmentMenuLevel)];
  }

  protected buildAssortmentNavigation(assort: Assortment, level: number = 0, lastPath: string = '', limit): GungShopMenu {
    if (assort.assortment && level < limit) {

      const path: string = (level !== 0) ? lastPath + '/' + assort.id : lastPath;
      const links = assort?.children.map(assort => this.buildAssortmentNavigation(assort, level + 1, path, limit)).filter(v => !!v);
      return {
        heading: assort.name,
        link: path,
        links: links
      }
    } else {
      return undefined;
    }
  }

  selectFlow(flowId: string): void {
    forkJoin({ flow: this.gungFlowService.getSelectedFlow().pipe(first()), cart: this.cartService.getCurrentCart().pipe(first()) })
      .pipe(first())
      .subscribe(({ flow, cart }) => {
        if (flow.id === flowId) {
          return;
        }
        if (cart.length === 0) {
          this.gungFlowService.selectFlow(flowId);
          return;
        }
        this.gungModalService
          .openConfirmYesNoModal('FLOW_CHANGE', 'FLOW_CHANGE_CLEAR_CART_CONFIRM', {}, 'OK', 'CANCEL')
          .then(
            result => {
              if (result) {
                this.gungFlowService.selectFlow(flowId);
              }
            },
            reason => undefined
          );
      });
  }


  cleanScroll() {
    const locationPosition = this.locationConfigService.get('products');
    if (!!locationPosition) {
      this.locationConfigService.remove(locationPosition);
    }
  }

  redirectToProducts(flow?: GungFlow): void {
    this.router.navigate([this.getProductListBaseRoute()]);
  }

  getProductListBaseRoute(): string {
    if (this.baseViewConfigService.productViewType === ProductViewType.assortmentTreeView) {
      return '/articles';
    }
    return '/products';
  }
}
