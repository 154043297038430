import { createElement as e } from "../../../../../external/preact/dist/preact.js";
import r from "../../../../internal/FormFields/Field/Field.js";
import { useCoreContext as o } from "../../../../../core/Context/CoreProvider.js";
import a from "../../../../internal/FormFields/InputText.js";
import { CREDITCARD_HOLDER_NAME_INVALID as l } from "../../../../../core/Errors/constants.js";
function t({
  onBlur: t,
  onInput: n,
  placeholder: d,
  value: i,
  required: c,
  error: s = !1,
  isValid: m,
  disabled: u,
  onFieldFocusAnalytics: p,
  onFieldBlurAnalytics: h
}) {
  const {
    i18n: _
  } = o();
  return e(r, {
    label: _.get("creditCard.holderName"),
    className: "adyen-checkout__card__holderName",
    errorMessage: s && _.get(l),
    isValid: !!m,
    name: "holderName",
    i18n: _,
    onFocus: e => p("holderName", e),
    onBlur: e => h("holderName", e)
  }, e(a, {
    name: "holderName",
    className: "adyen-checkout__card__holderName__input adyen-checkout__input",
    placeholder: d,
    autocomplete: "cc-name",
    value: i,
    required: c,
    onBlur: t,
    onInput: n,
    disabled: u
  }));
}
export { t as default };
