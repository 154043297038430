<div class="row">
  <div class="col-12">
    <h4 class="mt-0 text-center text-md-left" translate>ORDER</h4>
    <hr>
  </div>
  <div class="col-12">
    <lib-gung-settings-fortnox-order-confirmation></lib-gung-settings-fortnox-order-confirmation>
  </div>
  <div class="col-12">
    <lib-order-confirm-configuration></lib-order-confirm-configuration>
  </div>
</div>
