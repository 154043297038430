import e from "./createCardVariantSwitcher.js";
import { BRAND_ICON_UI_EXCLUSION_LIST as n } from "../lib/constants.js";
const t = (e, n) => e.reduce((e, t) => e || n.includes(t.brand), !1),
  r = e => e.map(e => ({
    ...e
  })),
  s = (e, n = "mc", t = "visa") => {
    const s = r(e);
    return s[0].brand !== n && s[0].brand !== t && s.reverse(), s.length = 1, s;
  };
function o(o, u, d, a = {}) {
  const {
      type: c,
      cvcPolicy: i
    } = o,
    {
      sfp: p
    } = u,
    {
      dualBrandSelectElements: l,
      setDualBrandSelectElements: g,
      setSelectedBrandValue: B,
      issuingCountryCode: C,
      setIssuingCountryCode: b
    } = d;
  return {
    processBinLookup: (o, u) => {
      const d = o?.issuingCountryCode ? o.issuingCountryCode.toLowerCase() : null;
      if (b(d), !o || !Object.keys(o).length) {
        g([]), B("");
        const e = u && "card" !== c ? c : null;
        return p.current.processBinLookupResponse(o, {
          brand: e,
          cvcPolicy: i
        }), void (a.current = 0);
      }
      if (o.supportedBrands?.length) {
        const u = t(o.supportedBrands, n),
          d = u ? s(o.supportedBrands) : r(o.supportedBrands);
        if (d.length > 1) {
          const n = e(d);
          g(n.dualBrandSelectElements), B(n.selectedBrandValue), p.current.processBinLookupResponse({
            issuingCountryCode: o.issuingCountryCode,
            supportedBrands: [n.leadBrand]
          }), n.leadBrand.panLength > 0 && (a.current = n.leadBrand.panLength);
        } else g([]), B(""), u || B(d[0].brand), p.current.processBinLookupResponse({
          issuingCountryCode: o.issuingCountryCode,
          supportedBrands: d
        }), d[0].panLength > 0 && (a.current = d[0].panLength);
      }
    },
    handleDualBrandSelection: e => {
      let n = e;
      if (e instanceof Event) {
        const t = e.target;
        n = t.getAttribute("data-value") || t.getAttribute("alt");
      }
      let t = [];
      n && (t = l.reduce((e, t) => (t.brandObject.brand === n && e.push(t.brandObject), e), []), t.length && (B(n), p.current.processBinLookupResponse({
        issuingCountryCode: C,
        supportedBrands: t
      })));
    }
  };
}
export { r as cloneBrandsArr, t as containsExcludedBrand, o as default, s as removeExcludedBrand };
