import { getFormattingRegEx as o, SPECIAL_CHARS as t, trimValWithOneSpace as a } from "../../../utils/validator-utils.js";
const e = t => ({
    formatterFn: a => a.replace(o("^\\d", "g"), "").substring(0, t),
    format: new Array(t).fill("9").join(""),
    maxlength: t
  }),
  r = o(t),
  l = o => a(o).replace(r, ""),
  s = {
    postalCode: {
      formatterFn: (o, t) => {
        const a = t.state.data.country,
          e = m[a]?.postalCode.formatterFn;
        return e ? e(o) : o;
      }
    },
    street: {
      formatterFn: l
    },
    houseNumberOrName: {
      formatterFn: l
    },
    city: {
      formatterFn: l
    }
  },
  m = {
    AT: {
      postalCode: e(4)
    },
    AU: {
      postalCode: e(4)
    },
    BE: {
      postalCode: e(4)
    },
    BG: {
      postalCode: e(4)
    },
    BR: {
      postalCode: {
        formatterFn: t => {
          const a = t.replace(o("^\\d-", "g"), ""),
            e = a.indexOf("-") > -1 ? 9 : 8;
          return a.substring(0, e);
        },
        format: "12345678 or 12345-678",
        maxlength: 9
      }
    },
    CA: {
      postalCode: {
        format: "A9A 9A9 or A9A9A9",
        maxlength: 7
      }
    },
    CH: {
      postalCode: e(4)
    },
    CY: {
      postalCode: e(4)
    },
    CZ: {
      postalCode: {
        format: "999 99",
        maxlength: 6
      }
    },
    DE: {
      postalCode: e(5)
    },
    DK: {
      postalCode: {
        format: "9999",
        maxlength: 7
      }
    },
    EE: {
      postalCode: e(5)
    },
    ES: {
      postalCode: e(5)
    },
    FI: {
      postalCode: e(5)
    },
    FR: {
      postalCode: e(5)
    },
    GB: {
      postalCode: {
        formatterFn: a => a.replace(o(t), "").substring(0, 8),
        format: "AA99 9AA or A99 9AA or A9 9AA",
        maxlength: 8
      }
    },
    GR: {
      postalCode: {
        format: "999 99",
        maxlength: 6
      }
    },
    HR: {
      postalCode: {
        format: "[1-5]9999",
        maxlength: 5
      }
    },
    HU: {
      postalCode: e(4)
    },
    IE: {
      postalCode: {
        format: "A99 A999",
        maxlength: 8
      }
    },
    IS: {
      postalCode: e(3)
    },
    IT: {
      postalCode: e(5)
    },
    LI: {
      postalCode: e(4)
    },
    LT: {
      postalCode: {
        format: "9999 or 99999 or LT-99999",
        maxlength: 8
      }
    },
    LU: {
      postalCode: e(4)
    },
    LV: {
      postalCode: {
        format: "9999 or LV-9999",
        maxlength: 7
      }
    },
    MC: {
      postalCode: {
        format: "980NN",
        maxlength: 5
      }
    },
    MT: {
      postalCode: {
        format: "AA99 or AAA99 or AA9999 or AAA9999",
        maxlength: 8
      }
    },
    MY: {
      postalCode: e(5)
    },
    NL: {
      postalCode: {
        format: "9999AA",
        maxlength: 7
      }
    },
    NZ: {
      postalCode: e(4)
    },
    NO: {
      postalCode: e(4)
    },
    PL: {
      postalCode: {
        formatterFn: t => {
          const a = t.replace(o("^\\d-", "g"), ""),
            e = a.indexOf("-") > -1 ? 6 : 5;
          return a.substring(0, e);
        },
        format: "99999 or 99-999",
        maxlength: 6
      }
    },
    PT: {
      postalCode: {
        formatterFn: t => t.replace(o("^\\d-", "g"), "").substring(0, 8),
        format: "9999-999",
        maxlength: 8
      }
    },
    RO: {
      postalCode: e(6)
    },
    SI: {
      postalCode: {
        format: "9999 or SI-9999",
        maxlength: 7
      }
    },
    SE: {
      postalCode: e(5)
    },
    SG: {
      postalCode: e(6)
    },
    SK: {
      postalCode: {
        format: "99999 or SK-99999",
        maxlength: 8
      }
    },
    JP: {
      postalCode: {
        format: "999-9999",
        maxlength: 8
      }
    },
    US: {
      postalCode: {
        formatterFn: t => {
          const a = t.replace(o("^\\d-", "g"), ""),
            e = a.indexOf("-") > -1 ? 10 : 5;
          return a.substring(0, e);
        },
        format: "99999 or 99999-9999"
      }
    }
  };
export { s as addressFormatters, m as countrySpecificFormatters };
