import { DATE_POLICY_REQUIRED as e, ENCRYPTED_CARD_NUMBER as t, ENCRYPTED_EXPIRY_DATE as s, DATE_POLICY_HIDDEN as i, ENCRYPTED_SECURITY_CODE as a, ENCRYPTED_EXPIRY_MONTH as r, ENCRYPTED_EXPIRY_YEAR as c } from "../../constants.js";
import d from "../utils/iframes/postMessageToIframe.js";
import { hasOwnProperty as o } from "../../../../../../utils/hasOwnProperty.js";
import n from "../utils/iframes/getIframeContentWin.js";
function l(e) {
  if (o(this.state.securedFields, t)) {
    const s = {
      txVariant: this.state.type,
      ...e,
      fieldType: t,
      numKey: this.state.securedFields[t].numKey
    };
    d(s, n(this.state, t), this.config.loadingContext);
  }
}
function h(e) {
  (o(this.state.securedFields, r) && o(this.state.securedFields, c) ? [r, c] : [s]).forEach(t => {
    const s = {
      txVariant: this.state.type,
      ...e,
      fieldType: t,
      numKey: this.state.securedFields[t].numKey
    };
    d(s, n(this.state, t), this.config.loadingContext);
  });
}
function y(d, n) {
  const l = "card" === this.state.type;
  if (!d || !Object.keys(d).length) return l ? (this.sendBrandToCardSF({
    brand: "reset"
  }), this.sendExpiryDatePolicyToSF({
    expiryDatePolicy: e
  })) : n && this.processBrand({
    ...n,
    fieldType: t
  }), void ("card" === this.state.type && o(this.state.securedFields, s) && (this.state.securedFields[s].expiryDatePolicy = e));
  const h = d.supportedBrands[0],
    y = h.brand,
    u = h.expiryDatePolicy ?? (!0 === h.showExpiryDate ? e : i),
    p = {
      brand: y,
      cvcPolicy: h.cvcPolicy,
      expiryDatePolicy: u,
      cvcText: "Security code",
      showSocialSecurityNumber: h.showSocialSecurityNumber ?? !1,
      fieldType: t
    };
  if (this.processBrand(p), l) {
    const e = {
      brand: y,
      enableLuhnCheck: !1 !== d.supportedBrands[0].enableLuhnCheck,
      ...(h?.panLength && {
        panLength: h?.panLength
      })
    };
    this.sendBrandToCardSF(e), this.sendExpiryDatePolicyToSF({
      expiryDatePolicy: u
    });
  }
  o(this.state.securedFields, a) && (this.state.securedFields[a].cvcPolicy = h.cvcPolicy), o(this.state.securedFields, s) ? this.state.securedFields[s].expiryDatePolicy = u : o(this.state.securedFields, r) && o(this.state.securedFields, c) && (this.state.securedFields[r].expiryDatePolicy = u, this.state.securedFields[c].expiryDatePolicy = u), this.validateForm();
}
export { y as default, l as sendBrandToCardSF, h as sendExpiryDatePolicyToSF };
