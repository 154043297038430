import { ENCRYPTED_CARD_NUMBER as e, CREDIT_CARD_SF_FIELDS as r } from "../../../internal/SecuredFields/lib/constants.js";
import { selectOne as t } from "../../../internal/SecuredFields/lib/utilities/dom.js";
const n = (e, t, n) => {
    e && (r.includes(n) ? t.current.setFocusOn(n) : o(n, t));
  },
  s = (e, r, t) => n => {
    e("billingAddress", n.data), r("billingAddress", n.isValid), t("billingAddress", n.errors);
  },
  i = (e, r, t) => n => {
    e(n.currentFocusObject), !0 === n.focus ? r(n.fieldType, n) : t(n.fieldType, n);
  },
  c = (t, n, s) => () => {
    t.current || (t.current = !0, Promise.resolve().then(() => {
      const i = s.findIndex(r => r === e),
        c = s.slice(i + 1);
      for (const e of c) {
        if (!r.includes(e)) {
          o(e, n);
          break;
        }
        if (!n.current.sfIsOptionalOrHidden(e)) {
          n.current.setFocusOn(e);
          break;
        }
      }
      t.current = !1;
    }));
  },
  o = (e, r) => {
    let n = e;
    if ("taxNumber" === n && (n = "kcpTaxNumberOrDOB"), "country" === n || "stateOrProvince" === n) {
      const e = t(r.current.rootNode, `.adyen-checkout__field--${n} .adyen-checkout__filter-input`);
      e?.focus();
    } else {
      const e = t(r.current.rootNode, `[name="${n}"]`);
      e?.focus();
    }
  };
export { s as getAddressHandler, c as getAutoJumpHandler, i as getFocusHandler, n as setFocusOnFirstField };
