import { options as _ } from "../../dist/preact.js";
var n,
  t,
  r,
  o,
  u = 0,
  e = [],
  c = _,
  i = c.__b,
  f = c.__r,
  a = c.diffed,
  h = c.__c,
  s = c.unmount,
  v = c.__;
function l(_, n) {
  c.__h && c.__h(t, _, u || n), u = 0;
  var r = t.__H || (t.__H = {
    __: [],
    __h: []
  });
  return _ >= r.__.length && r.__.push({}), r.__[_];
}
function m(_) {
  return u = 1, p(U, _);
}
function p(_, r, o) {
  var u = l(n++, 2);
  if (u.t = _, !u.__c && (u.__ = [o ? o(r) : U(void 0, r), function (_) {
    var n = u.__N ? u.__N[0] : u.__[0],
      t = u.t(n, _);
    n !== t && (u.__N = [t, u.__[1]], u.__c.setState({}));
  }], u.__c = t, !t.u)) {
    var e = function (_, n, t) {
      if (!u.__c.__H) return !0;
      var r = u.__c.__H.__.filter(function (_) {
        return !!_.__c;
      });
      if (r.every(function (_) {
        return !_.__N;
      })) return !c || c.call(this, _, n, t);
      var o = !1;
      return r.forEach(function (_) {
        if (_.__N) {
          var n = _.__[0];
          _.__ = _.__N, _.__N = void 0, n !== _.__[0] && (o = !0);
        }
      }), !(!o && u.__c.props === _) && (!c || c.call(this, _, n, t));
    };
    t.u = !0;
    var c = t.shouldComponentUpdate,
      i = t.componentWillUpdate;
    t.componentWillUpdate = function (_, n, t) {
      if (this.__e) {
        var r = c;
        c = void 0, e(_, n, t), c = r;
      }
      i && i.call(this, _, n, t);
    }, t.shouldComponentUpdate = e;
  }
  return u.__N || u.__;
}
function H(_, r) {
  var o = l(n++, 3);
  !c.__s && C(o.__H, r) && (o.__ = _, o.i = r, t.__H.__h.push(o));
}
function d(_, r) {
  var o = l(n++, 4);
  !c.__s && C(o.__H, r) && (o.__ = _, o.i = r, t.__h.push(o));
}
function E(_) {
  return u = 5, N(function () {
    return {
      current: _
    };
  }, []);
}
function N(_, t) {
  var r = l(n++, 7);
  return C(r.__H, t) && (r.__ = _(), r.__H = t, r.__h = _), r.__;
}
function y(_, n) {
  return u = 8, N(function () {
    return _;
  }, n);
}
function A(_) {
  var r = t.context[_.__c],
    o = l(n++, 9);
  return o.c = _, r ? (null == o.__ && (o.__ = !0, r.sub(t)), r.props.value) : _.__;
}
function F() {
  for (var _; _ = e.shift();) if (_.__P && _.__H) try {
    _.__H.__h.forEach(k), _.__H.__h.forEach(q), _.__H.__h = [];
  } catch (n) {
    _.__H.__h = [], c.__e(n, _.__v);
  }
}
c.__b = function (_) {
  t = null, i && i(_);
}, c.__ = function (_, n) {
  _ && n.__k && n.__k.__m && (_.__m = n.__k.__m), v && v(_, n);
}, c.__r = function (_) {
  f && f(_), n = 0;
  var o = (t = _.__c).__H;
  o && (r === t ? (o.__h = [], t.__h = [], o.__.forEach(function (_) {
    _.__N && (_.__ = _.__N), _.i = _.__N = void 0;
  })) : (o.__h.forEach(k), o.__h.forEach(q), o.__h = [], n = 0)), r = t;
}, c.diffed = function (_) {
  a && a(_);
  var n = _.__c;
  n && n.__H && (n.__H.__h.length && (1 !== e.push(n) && o === c.requestAnimationFrame || ((o = c.requestAnimationFrame) || g)(F)), n.__H.__.forEach(function (_) {
    _.i && (_.__H = _.i), _.i = void 0;
  })), r = t = null;
}, c.__c = function (_, n) {
  n.some(function (_) {
    try {
      _.__h.forEach(k), _.__h = _.__h.filter(function (_) {
        return !_.__ || q(_);
      });
    } catch (t) {
      n.some(function (_) {
        _.__h && (_.__h = []);
      }), n = [], c.__e(t, _.__v);
    }
  }), h && h(_, n);
}, c.unmount = function (_) {
  s && s(_);
  var n,
    t = _.__c;
  t && t.__H && (t.__H.__.forEach(function (_) {
    try {
      k(_);
    } catch (_) {
      n = _;
    }
  }), t.__H = void 0, n && c.__e(n, t.__v));
};
var b = "function" == typeof requestAnimationFrame;
function g(_) {
  var n,
    t = function () {
      clearTimeout(r), b && cancelAnimationFrame(n), setTimeout(_);
    },
    r = setTimeout(t, 100);
  b && (n = requestAnimationFrame(t));
}
function k(_) {
  var n = t,
    r = _.__c;
  "function" == typeof r && (_.__c = void 0, r()), t = n;
}
function q(_) {
  var n = t;
  _.__c = _.__(), t = n;
}
function C(_, n) {
  return !_ || _.length !== n.length || n.some(function (n, t) {
    return n !== _[t];
  });
}
function U(_, n) {
  return "function" == typeof n ? n(_) : n;
}
export { y as useCallback, A as useContext, H as useEffect, d as useLayoutEffect, N as useMemo, p as useReducer, E as useRef, m as useState };
