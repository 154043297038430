import t from "../utils/userAgent.js";
import "../../utilities/logger.js";
import { shiftTabCreditCard as i } from "../utils/tabbing/tabScenarioCreditCard.js";
import { shiftTabACH as a } from "../utils/tabbing/tabScenarioACH.js";
import { shiftTabGiftCard as s } from "../utils/tabbing/tabScenarioGiftCard.js";
import { shiftTabKCP as e } from "../utils/tabbing/tabScenarioKCP.js";
import { focusExternalField as o } from "../utils/tabbing/utils.js";
var r = {
  handleShiftTab: function (t) {
    let r;
    switch (this.state.type) {
      case "ach":
        r = a(t);
        break;
      case "giftcard":
        r = s(t, this.props.rootNode);
        break;
      default:
        r = this.state.isKCP ? e(t, this.props.rootNode, this.state.hasSeparateDateFields) : i(t, this.props.rootNode, this.state.hasSeparateDateFields, this.state.numIframes);
    }
    const l = r.fieldToFocus,
      n = r.additionalField;
    l ? this.setFocusOnFrame(l, false) : n && o(n);
  },
  handleSFShiftTab: function (i) {
    (t.__IS_FIREFOX || t.__IS_IE && t.__IS_IE <= 11) && this.handleShiftTab(i);
  }
};
export { r as default };
