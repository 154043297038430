import { createElement as e } from "../../../external/preact/dist/preact.js";
const n = ({
  inline: n = !1,
  size: a = "large"
}) => e("div", {
  "data-testid": "spinner",
  className: "adyen-checkout__spinner__wrapper " + (n ? "adyen-checkout__spinner__wrapper--inline" : "")
}, e("div", {
  className: `adyen-checkout__spinner adyen-checkout__spinner--${a}`
}));
export { n as default };
