import { createElement as e, Fragment as t } from "../../../../../../external/preact/dist/preact.js";
import { useRef as o } from "../../../../../../external/preact/hooks/dist/hooks.js";
import { CtPBrand as s } from "../../CtPBrand/CtPBrand.js";
import { useCoreContext as a } from "../../../../../../core/Context/CoreProvider.js";
import c from "../../../../../../core/Context/useImage.js";
import { Modal as l } from "../../../../Modal/Modal.js";
import n from "../../../../Img/Img.js";
import r from "../../../../Button/Button.js";
let i = Date.now();
function p() {
  return i += 1, `adyen-${i}`;
}
const m = ({
  isOpen: i,
  onClose: m,
  focusAfterClose: d
}) => {
  const u = o(),
    {
      i18n: f
    } = a(),
    g = c(),
    C = p(),
    _ = p();
  return e(l, {
    onClose: m,
    isOpen: i,
    classNameModifiers: ["ctp"],
    labelledBy: C,
    describedBy: _,
    focusFirst: u.current,
    focusAfterClose: d
  }, ({
    onCloseModal: o
  }) => e(t, null, e(n, {
    className: "adyen-checkout__ctp-modal-header-image",
    src: g({
      imageFolder: "components/"
    })("ctp_landscape"),
    alt: ""
  }), e("h1", {
    id: C,
    className: "adyen-checkout__ctp-modal-title"
  }, f.get("ctp.infoPopup.title")), e("div", {
    id: _
  }, e("p", {
    tabIndex: -1,
    ref: u,
    className: "adyen-checkout__ctp-modal-text"
  }, f.get("ctp.infoPopup.subtitle")), e("ul", {
    className: "adyen-checkout__ctp-modal-text adyen-checkout__ctp-modal-benefits",
    type: "disc"
  }, e("li", null, f.get("ctp.infoPopup.benefit1")), e("li", null, f.get("ctp.infoPopup.benefit2")), e("li", null, f.get("ctp.infoPopup.benefit3"))), e(s, {
    classNameModifiers: ["popup"]
  })), e(r, {
    onClick: o,
    label: f.get("close")
  })));
};
export { m as CtPInfoModal };
