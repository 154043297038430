import { createElement as e } from "../../../../../../external/preact/dist/preact.js";
import { useState as l, useEffect as t } from "../../../../../../external/preact/hooks/dist/hooks.js";
import n from "../../../../../internal/FormFields/Field/Field.js";
import { useCoreContext as a } from "../../../../../../core/Context/CoreProvider.js";
import s from "../../../../../internal/FormFields/Fieldset/Fieldset.js";
import i from "../../../../../internal/FormFields/RadioGroup/RadioGroup.js";
import o from "../../../../../internal/FormFields/Select/Select.js";
import { alternativeLabelContent as m } from "../FieldLabelAlternative.js";
function r(r) {
  const {
      i18n: u
    } = a(),
    {
      amount: d,
      brand: v,
      onChange: c,
      type: p
    } = r,
    g = r.installmentOptions[v] || r.installmentOptions.card,
    f = 1 === g?.values?.length,
    [b, h] = l(g?.preselectedValue || g?.values[0]),
    [F, C] = l("onetime"),
    j = g?.plans?.includes("revolving"),
    x = e => {
      const l = e.target.value;
      h(Number(l));
    },
    E = e => {
      const l = e.currentTarget.getAttribute("value");
      C(l);
    },
    N = e => {
      let l, t;
      var n;
      return "amount" === p ? (l = "installmentOption", t = {
        count: e,
        values: {
          times: e,
          partialValue: (n = e, u.amount(d.value / n, d.currency))
        }
      }) : (l = "installmentOptionMonths", t = {
        count: e,
        values: {
          times: e
        }
      }), {
        id: e,
        name: d.value ? u.get(l, t) : `${e}`
      };
    };
  return t(() => {
    g?.values?.includes(b) || h(g?.preselectedValue ?? g?.values[0]);
  }, [v]), t(() => {
    const e = {
      value: b,
      ...(j && "revolving" === F && {
        plan: F,
        value: 1
      }),
      ...(j && "onetime" === F && {
        value: 1
      })
    };
    c(g ? e : {
      value: null
    });
  }, [b, g, F]), g ? 0 === d.value ? null : e("div", {
    className: "adyen-checkout__installments"
  }, j ? e(n, {
    label: u.get("installments"),
    classNameModifiers: ["installments"],
    name: "installmentsPseudoLabel",
    useLabelElement: !1,
    showContextualElement: !1,
    renderAlternativeToLabel: m
  }, e(s, {
    classNameModifiers: ["revolving-plan"],
    label: ""
  }, e(i, {
    items: [{
      id: "onetime",
      name: "installments.oneTime"
    }, {
      id: "installments",
      name: "installments.installments"
    }, {
      id: "revolving",
      name: "installments.revolving"
    }],
    onChange: E,
    value: F,
    ariaLabel: u.get("installments")
  }), e(n, {
    className: "installments" !== F ? "revolving-plan-installments__disabled" : "revolving-plan-installments",
    classNameModifiers: ["revolving-plan-installments"],
    name: "",
    useLabelElement: !1,
    showContextualElement: !1
  }, e(o, {
    filterable: !1,
    items: g.values.map(N),
    selectedValue: b,
    onChange: x,
    name: "installments",
    disabled: "installments" !== F
  })))) : e(n, {
    label: u.get("installments"),
    classNameModifiers: ["installments"],
    name: "installments",
    showContextualElement: !1
  }, e(o, {
    filterable: !1,
    items: g.values.map(N),
    selectedValue: b,
    onChange: x,
    name: "installments",
    readonly: f,
    allowIdOnButton: !0
  }))) : null;
}
r.defaultProps = {
  brand: "",
  amount: {},
  onChange: () => {}
};
export { r as default };
