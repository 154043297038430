import { Injectable } from '@angular/core';
import { FilterListRoutingService } from 'gung-list';

@Injectable({
  providedIn: 'root'
})
export class SjoborgConceptFilterNavigationHelperService {
  protected currentFilterQueryParams: { [s: string]: string } = undefined;

  constructor(
    protected filterListRoutingService: FilterListRoutingService
  ) { }

  setFilterQueryParams(queryParams: { [s: string]: string }): void {
    this.currentFilterQueryParams = queryParams;
  }

  useFilterQueryParams() {
    if (!this.currentFilterQueryParams) {
      return;
    }
    this.filterListRoutingService.setQueryParams(this.currentFilterQueryParams);
    this.currentFilterQueryParams = undefined;
  }
}
