const t = Object.prototype.toString;
function n(t) {
  return "object" == typeof t && null !== t && "[object Array]" === Object.prototype.toString.call(t);
}
function e(t) {
  return null != t;
}
function r(t) {
  return !1 !== t && e(t);
}
function o(t) {
  return !!t && "object" == typeof t;
}
function c(e) {
  return !r(e) || !(!("number" == typeof (c = e) || o(c) && "[object Number]" === t.call(c)) || 0 !== e && !Number.isNaN(e)) || !(!n(e) && !function (n) {
    return "string" == typeof n || o(n) && "[object String]" === t.call(n);
  }(e) || 0 !== e.length) || !(!o(e) || 0 !== Object.keys(e).length);
  var c;
}
function u(t) {
  return !c(t);
}
function f(...t) {
  const e = n(t[0]) ? t[0] : t;
  return {
    from: t => e.map(n => n in t ? {
      [n]: t[n]
    } : {}).reduce((t, n) => ({
      ...t,
      ...n
    }), {})
  };
}
function i(...t) {
  const e = n(t[0]) ? t[0] : t;
  return {
    from: t => f(...Object.keys(t).filter(t => !e.includes(t))).from(t)
  };
}
export { e as existy, c as falsy, n as isArray, u as notFalsy, f as pick, i as reject, r as truthy };
