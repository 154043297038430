import { Component, OnInit } from '@angular/core';
import { GungSettingsBaseViewComponent } from '../../gung-settings-base-view/gung-settings-base-view.component';
import { ConfigurationsService, GungConfiguration } from '../../../services/configurations.service';
import { checkObjectEquals } from 'gung-common';

@Component({
  selector: 'lib-gung-settings-fortnox-order-confirmation',
  templateUrl: './gung-settings-fortnox-order-confirmation.component.html',
  styleUrl: './gung-settings-fortnox-order-confirmation.component.scss'
})
export class GungSettingsFortnoxOrderConfirmationComponent extends GungSettingsBaseViewComponent implements OnInit {
  public loading = true;
  public configuration: GungConfiguration;
  constructor(protected configurationsService: ConfigurationsService) {
    super();
  }

  ngOnInit(): void {
    this.configurationsService.getConfigurationsByIds(['FortnoxOrderConfirmationConfig'], true).subscribe(configs => {
      if (!configs || configs.length == 0) {
        return;
      }
      this.configuration = structuredClone(configs[0]);
      this.loading = false;
    });
  }

  public typeOf(variable: any): string {
    return typeof variable;
  }

  public save() {
    this.configurationsService.updateConfiguration(this.configuration).subscribe(_ => {});
  }
}
