import { Inject, Injectable, Optional } from '@angular/core';
import { first, Observable, of, switchMap, tap } from 'rxjs';
import { CheckoutObject } from '../../models/checkout';
import { HttpClient } from '@angular/common/http';
import { Order } from '../../models/order';
import {
  AdditionalDetailsData,
  Address,
  Card,
  CheckoutAdvancedFlowResponse,
  PaymentAmount,
  PaymentData,
  PaymentMethod,
  PaymentMethodsResponse,
  PaymentResponseData,
  PayPal,
  SubmitData,
  UIElement
} from '@adyen/adyen-web';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { AdyenPaymentCompletedEvent } from '../../components/adyen-payment/adyen-payment.component';
import { User } from '../../models';
import { Invoice } from '../../models/invoice';

export interface AdyenOrder {
  merchantReference: string;
  orderIds: string[];
  pspReference: string;
  originalInput: Order;
  lineItems: any[];
  amount: any;
  orderContextId: string;
  gungEntityId: string;
  gungUser: User;
  paymentLinkId: string;
  adyenOrderType: AdyenOrderType;
  adyenStatus: any;
  orderStatus: any;
  merchantAccount: string;
  paymentMethodId: string;

  /*
   @Id
    private String merchantReference;
    private List<String> orderIds;
    private String pspReference;

    // A field to indicate a Gung entity that is connected to this AdyenOrder. It is used e.g. to know which Invoice to update when a payment is successful.
    private String gungEntityId;

    private Order originalInput;
    private GungUser gungUser;
    private String orderContextId;
    private List<LineItem> lineItems;
    private Amount amount;
    private String paymentLinkId;
    private AdyenOrderType adyenOrderType;
    private AdyenStatus adyenStatus;
    private OrderStatus orderStatus;
    private String merchantAccount;
    private String paymentMethodId;

  */
}

export enum AdyenOrderType {
  ADYEN_ORDER = 'ADYEN_ORDER',
  ADYEN_INVOICE = 'ADYEN_INVOICE'
}

export interface AdyenPaymentDetailsResponse {
  resultCode: string;
  pspReference: string;
}

export interface AdyenPaymentMethodsRequest {
  merchantReference: string;
  shopperLocale: string;
  amount: PaymentAmount;
  countryCode: string;
}

export interface AdyenCreatePaymentRequest {
  merchantReference: string;
  redirectUrl: string;
  adyenPaymentData: {
    [key: string]: any;
  };
  adyenOrderType: AdyenOrderType;
}

export interface AdyenPaymentAuthorisedRequest {
  merchantReference: string;
  pspReference: string;
  paymentMethodType: string;
}

export interface AdyenPaymentAuthorisedResponse {
  orderType: AdyenOrderType;
  orderContextId?: string;
}

export interface AdyenPaymentLinkResponse {
  // A unique identifier of the payment link.
  id: string;
  // The date when the payment link expires.  [ISO 8601](https://www.w3.org/TR/NOTE-datetime) format: YYYY-MM-DDThh:mm:ss+TZD, for example, **2020-12-18T10:15:30+01:00**.  The maximum expiry date is 70 days after the payment link is created.  If not provided, the payment link expires 24 hours after it was created.
  expiresAt: Date;
  // A reference that is used to uniquely identify the payment in future communications about the payment status.
  reference: string;
  // Status of the payment link. Possible values: * **active**: The link can be used to make payments. * **expired**: The expiry date for the payment link has passed. Shoppers can no longer use the link to make payments. * **completed**: The shopper completed the payment. * **paymentPending**: The shopper is in the process of making the payment. Applies to payment methods with an asynchronous flow.
  status: string;
  // The URL at which the shopper can complete the payment.
  url: string;
  amount: {
    // Currency code
    currency: string;
    // Value, most often multiplied by 100, sometimes by 1000 (https://docs.adyen.com/development-resources/currency-codes/)
    value: number;
  };
}

export interface AdyenPaymentLinkRequest {
  // The three-character [ISO currency code](https://docs.adyen.com/development-resources/currency-codes).
  currency: string;
  // The shopper's two-letter country code.
  countryCode: string;
  // A short description visible on the payment page. Maximum length: 280 characters.
  description: string;
  // The language to be used in the payment page, specified by a combination of a language and country code. For example, `en-US`.
  shopperLocale: string;
  // Your reference to uniquely identify this shopper, for example user ID or account ID. Minimum length: 3 characters. > Your reference must not include personally identifiable information (PII), for example name or email address.
  shopperReference: string;
  // The merchant account identifier for which the payment link is created.
  merchantAccount?: string;
  // Gung order to be created if payment succeeds.
  order: CheckoutObject;
}

@Injectable({
  providedIn: 'root'
})
export class AdyenService {
  constructor(
    protected http: HttpClient,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any },
    protected flowService: GungFlowService
  ) {}

  /**
   * Used for synchronous payments (a session is created towards Adyen in which the user pays immediately)
   */
  createSession(checkout: CheckoutObject): Observable<any> {
    return this.http.post<any>('json/adyen/session', checkout);
  }

  createOrderAfterPaymentCompleted(
    pspReference: string,
    merchantReference: string,
    paymentMethod: string
  ): Observable<any> {
    return of(null);
  }

  onPaymentCompleted(event: AdyenPaymentCompletedEvent): Observable<AdyenPaymentAuthorisedResponse> {
    const url: string = 'json/adyen/handle-payment-authorised';
    let body: AdyenPaymentAuthorisedRequest = {
      merchantReference: event.merchantReference,
      pspReference: event.pspReference,
      paymentMethodType: event.selectedPaymentMethod
    };

    return this.http.post<AdyenPaymentAuthorisedResponse>(url, body).pipe(tap(response => console.log(response)));
  }

  /**
   * Used for asynchronous payments (the user gets a link which they can use to pay later).
   */
  createPaymentLink(paymentLinkRequest: AdyenPaymentLinkRequest): Observable<AdyenPaymentLinkResponse> {
    return this.http.post<AdyenPaymentLinkResponse>('json/adyen/link', paymentLinkRequest);
  }

  public cancelPaymentLink(paymentLinkId: string): Observable<AdyenPaymentLinkResponse> {
    const url: string = `json/adyen/link/${paymentLinkId}/cancel`;
    return this.http.post<AdyenPaymentLinkResponse>(url, null);
  }

  /**
   * Used for creation of payments through the Advanced Flow, returns type any, as we have a mismatch between the data structures from Adyen in the backend
   * and frontend libraries.
   */
  public createPayment(merchantReference: string, orderType: AdyenOrderType, data: PaymentData): Observable<any> {
    const url = 'json/adyen/create-payment';

    // URL used when Adyen requires a redirect for additional actions, such as 3D Secure authentication. This URL is used in the backend to handle the request
    // which comes as a GET request, and we answer with a redirect back to our frontend. The reason is because a GET request cannot be handled by Angular. - Adam
    const redirectUrl =
      this.environment.backendUrl +
      'adyen/redirect-after-action?baseUrl=' +
      window.location.origin +
      '/adyen-redirect-result';
    let request: AdyenCreatePaymentRequest = {
      merchantReference: merchantReference,
      redirectUrl: redirectUrl,
      adyenPaymentData: data,
      adyenOrderType: orderType
    };

    return this.http.post<any>(url, request).pipe(tap(response => console.log(response)));
  }

  /**
   * Used for details of payments through the Advanced Flow. It is called when the user is expected to perform additional actions during the payment, such as
   * 3D Secure authentication.
   */
  public getPaymentDetails(data: AdditionalDetailsData): Observable<any> {
    const url: string = 'json/adyen/payment-details';
    return this.http.post<any>(url, data.data).pipe(tap(response => console.log(response)));
  }

  public isEnabled(): boolean {
    // By checking the environment for the key we can support multiple websites where some should have Adyen
    // and some should not.
    return !!this.environment.adyenClientKey;
  }

  public decorateCheckout(checkout: CheckoutObject): Observable<CheckoutObject> {
    // We want to decorate the checkout with the url origin in the cases we have it. That will make the backend
    // automatically know the correct redirect base urls to send to Adyen. Otherwise, we need to specify it as a
    // property in the backend, which only works when the customer have 1 frontend.
    if (location.origin) {
      checkout.extra._urlOrigin = location.origin;
    }

    checkout.billingCustomerId = checkout?.billingCustomerId || checkout.deliveryCustomerId;
    return of(checkout);
  }

  public shouldUseAdyenStep(checkout: CheckoutObject): Observable<boolean> {
    // Default implementation is to never pay with Adyen. This will almost always be customer specific.
    return of(false);
  }

  public shouldUsePayByLink(checkout: CheckoutObject): boolean {
    // Default implementation is to pay directly in Gung.
    return false;
  }

  public shouldUseAdvancedFlow(): boolean {
    // Default implementation is to use the sessions flow. The advanced flow should only be used for specific use cases.
    return true;
  }

  public canPayInvoiceWithAdyen(invoice: Invoice): boolean {
    // Default implementation is to not pay invoices with Adyen.
    return false;
  }

  public getAdyenOrder(merchantReference: string): Observable<AdyenOrder> {
    const urlString = `json/adyen-order/${merchantReference}`;
    return this.http.get<AdyenOrder>(urlString);
  }

  /**
   * Only used for advanced flow, in the default sessions flow, the drop-in component fetches the paymentmethods itself.
   */
  public fetchPaymentMethods(request: AdyenPaymentMethodsRequest): Observable<PaymentMethodsResponse> {
    const url: string = 'json/adyen/get-payment-methods';
    const headers = {
      maxAge: '-1'
    };

    return this.http.post<PaymentMethodsResponse>(url, request, { headers });
  }

  public createAdyenOrder(checkout: CheckoutObject, orderType: AdyenOrderType): Observable<AdyenOrder> {
    const url: string = 'json/adyen/create-adyen-order';
    return this.flowService.getSelectedFlow().pipe(
      first(),
      switchMap(flow => {
        let body = {
          order: checkout,
          flowId: flow.id,
          orderType: orderType
        };

        return this.http.post<AdyenOrder>(url, body);
      })
    );
  }

  public pollLinkStatus(paymentLinkId: string): Observable<AdyenPaymentLinkResponse> {
    const url: string = `json/adyen/link/${paymentLinkId}`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<AdyenPaymentLinkResponse>(url, { headers });
  }

  public pollOrderStatus(paymentLinkReference: string): Observable<AdyenOrder> {
    const url: string = `json/adyen-order/${paymentLinkReference}`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<AdyenOrder>(url, { headers });
  }
}
