import { getCardGroupTypes as i } from "../utils/getCardGroupTypes.js";
import { NON_CREDIT_CARD_TYPE_SECURED_FIELDS as o, SF_VERSION as t } from "../../constants.js";
import { warn as n } from "../../utilities/logger.js";
function e(e) {
  this.config.cardGroupTypes = i(e.cardGroupTypes);
  const r = e.loadingContext;
  if (!r) return void n("WARNING Config :: no loadingContext has been specified!");
  var a;
  this.config.loadingContext = "/" === (a = r).charAt(a.length - 1) ? r : `${r}/`, this.config.isCreditCardType = !1 === o.includes(e.type), this.config.iframeUIConfig = e.iframeUIConfig ?? {}, this.config.autoFocus = !(!1 === e.autoFocus || "false" === e.autoFocus), this.config.showWarnings = !0 === e.showWarnings || "true" === e.showWarnings, this.config.trimTrailingSeparator = !(!1 === e.trimTrailingSeparator || "false" === e.trimTrailingSeparator), this.config.keypadFix = !(!1 === e.keypadFix || "false" === e.keypadFix), this.config.legacyInputMode = e.legacyInputMode || null, this.config.minimumExpiryDate = e.minimumExpiryDate || null, this.config.sfLogAtStart = !0 === window._b$dl;
  let s = this.config.isCreditCardType ? "card" : e.type;
  s.indexOf("sepa") > -1 && (s = "iban");
  const c = btoa(window.location.origin),
    f = `${s}${!!e.forceCompat || !("function" == typeof window.TextEncoder) ? "Compat" : ""}`;
  this.config.iframeSrc = `${this.config.loadingContext}securedfields/${e.clientKey}/${t}/securedFields.html?type=${f}&d=${c}`;
}
export { e as handleConfig };
