function e(e) {
  return {
    beforeSubmit: e.beforeSubmit,
    onSubmit: e.onSubmit,
    elementRef: e.elementRef,
    showPayButton: e.showPayButton,
    isDropin: !0
  };
}
export { e as getCommonProps };
