import { createElement as e, Fragment as t } from "../../../../external/preact/dist/preact.js";
function a({
  paymentMethods: a
}) {
  return e(t, null, e("ul", {
    className: "adyen-checkout__instant-payment-methods-list"
  }, a.map(t => e("li", {
    key: t.type
  }, t.render()))));
}
export { a as default };
