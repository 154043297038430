import { createElement as e, Fragment as t } from "../../../../../external/preact/dist/preact.js";
import n from "../../../../../external/classnames/index.js";
import o from "../../../Img/Img.js";
function a({
  filterable: t,
  toggleButtonRef: n,
  ...o
}) {
  if (t) {
    const {
      id: t,
      ...a
    } = o;
    return e("div", {
      ...a,
      ref: n
    });
  }
  return e("button", {
    id: o.id,
    "aria-describedby": o.ariaDescribedBy,
    type: "button",
    ...o,
    ref: n
  });
}
function d(d) {
  const {
      active: c,
      selected: r,
      inputText: i,
      readonly: l,
      showList: s
    } = d,
    u = r.selectedOptionName || r.name || d.placeholder || "",
    _ = s ? i : u,
    p = l ? null : d.filterable ? e => {
      e.preventDefault(), document.activeElement === d.filterInputRef.current ? d.showList || d.toggleList(e) : d.filterInputRef.current && d.filterInputRef.current.focus();
    } : d.toggleList,
    f = l ? null : d.onFocus,
    b = c.id ? `listItem-${c.id}` : "";
  return e(a, {
    className: n({
      "adyen-checkout__dropdown__button": !0,
      "adyen-checkout__dropdown__button--readonly": l,
      "adyen-checkout__dropdown__button--active": s,
      "adyen-checkout__dropdown__button--invalid": d.isInvalid,
      "adyen-checkout__dropdown__button--valid": d.isValid,
      "adyen-checkout__dropdown__button--disabled": r.disabled
    }),
    disabled: d.disabled,
    filterable: d.filterable,
    onClick: p,
    onKeyDown: l ? null : d.onButtonKeyDown,
    toggleButtonRef: d.toggleButtonRef,
    ...(d.allowIdOnButton && d.id && {
      id: d.id
    })
  }, d.filterable ? e(t, null, !s && r.icon && e(o, {
    className: "adyen-checkout__dropdown__button__icon",
    src: r.icon,
    alt: r.name
  }), e("input", {
    value: _,
    "aria-autocomplete": "list",
    "aria-controls": d.selectListId,
    "aria-expanded": s,
    "aria-owns": d.selectListId,
    autoComplete: "off",
    className: "adyen-checkout__filter-input",
    onInput: d.onInput,
    onFocus: f,
    ref: d.filterInputRef,
    role: "combobox",
    "aria-activedescendant": b,
    type: "text",
    readOnly: d.readonly,
    id: d.id,
    "aria-describedby": d.ariaDescribedBy
  }), !s && r.secondaryText && e("span", {
    className: "adyen-checkout__dropdown__button__secondary-text"
  }, r.secondaryText)) : e(t, null, r.icon && e(o, {
    className: "adyen-checkout__dropdown__button__icon",
    src: r.icon,
    alt: r.name
  }), e("span", {
    className: "adyen-checkout__dropdown__button__text"
  }, u), r.secondaryText && e("span", {
    className: "adyen-checkout__dropdown__button__secondary-text"
  }, r.secondaryText)));
}
export { d as default };
