import { log as e, warn as i } from "../utilities/logger.js";
import s from "./utils/createIframe.js";
import { selectOne as t, removeAllChildren as r } from "../utilities/dom.js";
import { on as o, off as n } from "../../../../../utils/listenerUtils.js";
import a from "../CSF/utils/iframes/postMessageToIframe.js";
import { originCheckPassed as l, isWebpackPostMsg as h, isChromeVoxPostMsg as f } from "../CSF/utils/iframes/postMessageValidation.js";
import { ENCRYPTED_EXPIRY_DATE as c, ENCRYPTED_EXPIRY_MONTH as d, ENCRYPTED_EXPIRY_YEAR as u, DATE_POLICY_OPTIONAL as m, DATE_POLICY_HIDDEN as p, ENCRYPTED_SECURITY_CODE as g, CVC_POLICY_OPTIONAL as C, CVC_POLICY_HIDDEN as y } from "../constants.js";
import { generateRandomNumber as b } from "../utilities/commonUtils.js";
import L from "./AbstractSecuredField.js";
import { reject as E } from "../../../../../utils/commonUtils.js";
import { processAriaConfig as w } from "./utils/processAriaConfig.js";
import { processPlaceholders as T } from "./utils/processPlaceholders.js";
import { hasOwnProperty as k } from "../../../../../utils/hasOwnProperty.js";
class P extends L {
  constructor(e, i) {
    super();
    const s = E(["loadingContext", "holderEl", "iframeSrc", "showContextualElement", "placeholders"]).from(e);
    this.sfConfig = {
      ...s,
      iframeUIConfig: {
        ...s.iframeUIConfig
      }
    };
    const {
      iframeSrc: t,
      placeholders: r,
      showContextualElement: o
    } = e;
    return this.loadingContext = e.loadingContext, this.holderEl = e.holderEl, this.isValid = !1, this.iframeContentWindow = null, this.numKey = b(), this.isEncrypted = !1, this.hasError = !1, this.errorType = "", this.cvcPolicy = e.cvcPolicy, this.expiryDatePolicy = e.expiryDatePolicy, this.init(i, t, r, o);
  }
  init(e, i, r, n) {
    const a = w(this.sfConfig.txVariant, this.sfConfig.fieldType, e, n);
    this.sfConfig.iframeUIConfig.ariaConfig = a, this.sfConfig.iframeUIConfig.placeholders = T(this.sfConfig.txVariant, this.sfConfig.fieldType, r);
    const l = {
        src: i,
        title: a[this.sfConfig.fieldType].iframeTitle,
        policy: "origin"
      },
      h = s(l);
    this.holderEl.appendChild(h);
    const f = t(this.holderEl, ".js-iframe");
    return f && (this.iframeContentWindow = f.contentWindow, this.iframeOnLoadListener = this.iframeOnLoadListenerFn, o(f, "load", this.iframeOnLoadListener, !1)), this.iframeRef = f, this;
  }
  iframeOnLoadListenerFn() {
    this.postMessageListener = this.postMessageListenerFn, o(window, "message", this.postMessageListener, !1);
    const e = {
      ...this.sfConfig,
      numKey: this.numKey
    };
    window._b$dl && console.log("### SecuredField:::: onIframeLoaded:: created configObj=", e), a(e, this.iframeContentWindow, this.loadingContext), this.onIframeLoadedCallback();
  }
  postMessageListenerFn(s) {
    if (!l(s, this.loadingContext, this.sfConfig.showWarnings)) return;
    let t;
    try {
      t = JSON.parse(s.data);
    } catch (i) {
      return h(s) ? void (this.sfConfig.showWarnings && e("### SecuredField::postMessageListenerFn:: PARSE FAIL - WEBPACK")) : f(s) ? void (this.sfConfig.showWarnings && e("### SecuredField::postMessageListenerFn:: PARSE FAIL - CHROMEVOX")) : void (this.sfConfig.showWarnings && e("### SecuredField::postMessageListenerFn:: PARSE FAIL - UNKNOWN REASON: event.data=", s.data));
    }
    if (k(t, "action") && k(t, "numKey")) {
      if (this.numKey === t.numKey) switch (t.action) {
        case "encryption":
          this.isValid = !0, this.onEncryptionCallback(t);
          break;
        case "config":
          window._b$dl && console.log("### SecuredField::postMessageListenerFn:: configured - calling onConfigCallback", t.fieldType), this.onConfigCallback(t);
          break;
        case "focus":
          this.onFocusCallback(t);
          break;
        case "binValue":
          this.onBinValueCallback(t);
          break;
        case "touch":
          this.onTouchstartCallback(t);
          break;
        case "shifttab":
          this.onShiftTabCallback(t);
          break;
        case "autoComplete":
          this.onAutoCompleteCallback(t);
          break;
        case "enterKeyPressed":
          this.onKeyPressedCallback(t);
          break;
        default:
          this.isValid = !1, this.onValidationCallback(t);
      } else this.sfConfig.showWarnings && i("WARNING SecuredField :: postMessage listener for iframe :: data mismatch! (Probably a message from an unrelated securedField)");
    } else this.sfConfig.showWarnings && i("WARNING SecuredField :: postMessage listener for iframe :: data mismatch!");
  }
  destroy() {
    n(window, "message", this.postMessageListener, !1), n(this.iframeRef, "load", this.iframeOnLoadListener, !1), this.iframeContentWindow = null, r(this.holderEl);
  }
  isOptionalOrHidden() {
    if (this.sfConfig.fieldType === c || this.sfConfig.fieldType === d || this.sfConfig.fieldType === u) switch (this.expiryDatePolicy) {
      case p:
        return !0;
      case m:
        return !this.hasError;
      default:
        return !1;
    }
    if (this.sfConfig.fieldType === g) switch (this.cvcPolicy) {
      case y:
        return !0;
      case C:
        return !this.hasError;
      default:
        return !1;
    }
    return !1;
  }
  onIframeLoaded(e) {
    return this.onIframeLoadedCallback = e, this;
  }
  onEncryption(e) {
    return this.onEncryptionCallback = e, this;
  }
  onValidation(e) {
    return this.onValidationCallback = e, this;
  }
  onConfig(e) {
    return this.onConfigCallback = e, this;
  }
  onFocus(e) {
    return this.onFocusCallback = e, this;
  }
  onBinValue(e) {
    return this.onBinValueCallback = e, this;
  }
  onTouchstart(e) {
    return this.onTouchstartCallback = e, this;
  }
  onShiftTab(e) {
    return this.onShiftTabCallback = e, this;
  }
  onAutoComplete(e) {
    return this.onAutoCompleteCallback = e, this;
  }
  onKeyPressed(e) {
    return this.onKeyPressedCallback = e, this;
  }
  get errorType() {
    return this._errorType;
  }
  set errorType(e) {
    this._errorType = e;
  }
  get hasError() {
    return this._hasError;
  }
  set hasError(e) {
    this._hasError = e;
  }
  get isValid() {
    if (this.sfConfig.fieldType === g) switch (this.cvcPolicy) {
      case y:
        return !0;
      case C:
        return !this.hasError;
      default:
        return this._isValid;
    }
    if (this.sfConfig.fieldType === c || this.sfConfig.fieldType === d || this.sfConfig.fieldType === u) switch (this.expiryDatePolicy) {
      case p:
        return !0;
      case m:
        return !this.hasError;
      default:
        return this._isValid;
    }
    return this._isValid;
  }
  set isValid(e) {
    this._isValid = e;
  }
  get cvcPolicy() {
    return this._cvcPolicy;
  }
  set cvcPolicy(e) {
    this.sfConfig.fieldType === g && e !== this.cvcPolicy && (this._cvcPolicy = e, this.hasError && "isValidated" === this.errorType && (this.hasError = !1));
  }
  get expiryDatePolicy() {
    return this._expiryDatePolicy;
  }
  set expiryDatePolicy(e) {
    this.sfConfig.fieldType !== c && this.sfConfig.fieldType !== d && this.sfConfig.fieldType !== u || e !== this.expiryDatePolicy && (this._expiryDatePolicy = e, this.hasError && "isValidated" === this.errorType && (this.hasError = !1));
  }
  get iframeContentWindow() {
    return this._iframeContentWindow;
  }
  set iframeContentWindow(e) {
    this._iframeContentWindow = e;
  }
  get isEncrypted() {
    return this._isEncrypted;
  }
  set isEncrypted(e) {
    this._isEncrypted = e;
  }
  get numKey() {
    return this._numKey;
  }
  set numKey(e) {
    this._numKey = e;
  }
  get iframeOnLoadListener() {
    return this._iframeOnLoadListener;
  }
  set iframeOnLoadListener(e) {
    this._iframeOnLoadListener = e.bind(this);
  }
  get postMessageListener() {
    return this._postMessageListener;
  }
  set postMessageListener(e) {
    this._postMessageListener = e.bind(this);
  }
}
export { P as default };
