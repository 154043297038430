import { createElement as e } from "../../../../../external/preact/dist/preact.js";
import n from "../PaymentMethodIcon.js";
import { getFullBrandName as t } from "../../../../Card/components/CardInput/utils.js";
const a = ({
  brands: a,
  excludedUIBrands: r = [],
  isPaymentMethodSelected: s
}) => {
  if (s) return null;
  const m = a.filter(e => !r?.includes(e.name)),
    {
      visibleBrands: o,
      leftBrandsAmount: d
    } = (e => {
      const n = e.length <= 4 ? e : e.slice(0, 3);
      return {
        visibleBrands: n,
        leftBrandsAmount: e.length - n.length
      };
    })(m);
  return e("span", {
    className: "adyen-checkout__payment-method__brands"
  }, o.map(a => e(n, {
    key: a.name,
    altDescription: t(a.name),
    type: a.name,
    src: a.icon
  })), 0 !== d && e("span", {
    className: "adyen-checkout__payment-method__brand-number"
  }, "+", d));
};
export { a as default };
