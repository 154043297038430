import e from "../../../../external/classnames/index.js";
import { createElement as a } from "../../../../external/preact/dist/preact.js";
const t = ({
  displayName: t,
  additionalInfo: n,
  isSelected: d
}) => a("span", {
  className: "adyen-checkout__payment-method__name_wrapper"
}, a("span", {
  className: e({
    "adyen-checkout__payment-method__name": !0,
    "adyen-checkout__payment-method__name--selected": d
  })
}, t), n && a("span", {
  className: e({
    "adyen-checkout__payment-method__additional-info": !0,
    "adyen-checkout__payment-method__additional-info--selected": d
  })
}, n));
export { t as default };
