import e from "../../../external/classnames/index.js";
import { createElement as t } from "../../../external/preact/dist/preact.js";
function a({
  buttonId: a,
  showRadioButton: d,
  isSelected: n,
  expandContentId: o,
  children: r,
  classNameModifiers: c = []
}) {
  return t("button", {
    className: e("adyen-checkout__payment-method__header__title", ...c.map(e => `adyen-checkout-expand-button--${e}`)),
    id: a,
    role: "radio",
    "aria-checked": n,
    "aria-expanded": n,
    "aria-controls": o,
    type: "button"
  }, d && t("span", {
    className: e({
      "adyen-checkout__payment-method__radio": !0,
      "adyen-checkout__payment-method__radio--selected": n
    }),
    "aria-hidden": "true"
  }), r);
}
export { a as default };
