import { ValidationRuleResult as r } from "./ValidationRuleResult.js";
class t {
  validationResults;
  constructor(r) {
    this.validationResults = r;
  }
  get isValid() {
    return this.validationResults.reduce((r, t) => r && t.isValid, !0);
  }
  hasError(r = !1) {
    return Boolean(this.getError(r));
  }
  getError(r = !1) {
    return this.validationResults.find(t => t.hasError(r));
  }
  getAllErrors() {
    return this.validationResults.filter(r => r.hasError());
  }
}
class s {
  rules = {
    default: {
      validate: () => !0,
      modes: ["blur", "input"]
    }
  };
  constructor(r) {
    this.setRules(r);
  }
  setRules(r) {
    this.rules = {
      ...this.rules,
      ...r
    };
  }
  getRulesFor(r) {
    let t = this.rules[r] ?? this.rules.default;
    return Array.isArray(t) || (t = [t]), t;
  }
  validate({
    key: s,
    value: e,
    mode: l = "blur"
  }, i) {
    const a = this.getRulesFor(s).map(t => new r(t, e, l, i));
    return new t(a);
  }
}
export { s as default };
