<div class="container mt-5">
  <div class="mb-4">
    <h2 class="text-center">
      <span> {{ 'ADYEN_PAYMENT_FAILED' | translate }}</span>
    </h2>
    <p class="text-center">{{ 'ADYEN_PAYMENT_FAILED_INFO' | translate }}</p>
    <div class="row">
      <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
        <a class="btn btn-primary btn-block" routerLink="/checkout"> {{ 'RETURN_TO_CART' | translate }}</a>
      </div>
    </div>
  </div>
</div>
