<div class="row" *ngIf="allItems">
  <div class="col">
    <div class="d-flex justify-content-between align-items-baseline">
      <div>
        <h5 class="mb-0 mt-0" translate>MENU_CONFIGURATION_MATRIX_TITLE</h5>
        <span class="font-italic small mb-2 d-inline-block" translate>MENU_CONFIGURATION_MATRIX_DESCRIPTION</span>
      </div>


      <button
        class="btn btn-default"
        style="font-size: 20px;"
        (click)="save()"
      >
        <i ngbTooltip="{{'SAVE' | translate}}" class="fas fa-save"></i>
      </button>
    </div>

    <div class="d-flex flex-column">
      <div class="table-responsive">
        <table class="table table-striped table-sticky">
          <thead>
          <tr>
            <th class="icon-column"></th>
            <th class="title-column"><strong>{{ 'TITLE' | translate }}</strong></th>
            <th><strong>{{ 'PAGE_PATH' | translate }}</strong></th>
            <th class="group-column"><strong>{{ 'MENU_GROUP' | translate }}</strong></th>
            @for (role of roles; track role) {
              <th class="text-center">
                <strong>{{ role | translate }}</strong>
              </th>
            }
            <th><!-- Icons / Actions to the right --></th>
          </tr>
          </thead>
          <tbody cdkDropList class="table-list" (cdkDropListDropped)="onDrop($event)" [cdkDropListData]="allItems">
          <tr *ngFor="let row of allItems; index as i" class="matrix-row" cdkDrag>
            <td [colSpan]="5 + roles.length" class="p-0" *cdkDragPlaceholder>
              <div class="row-ist-placeholder"></div>
            </td>

            <td class="icon-column p-0 pl-2">
              <span class="mr-3" cdkDragHandle><i style="width: 1rem;" class="fas fa-arrows-alt drag-handle"></i></span>
              <span><i style="width: 1rem;" class="{{ row.iconCss }}"></i></span>
            </td>
            <td class="title-column">
              <span>{{ row.title | translate }}</span>
            </td>
            <td>
              <lib-gung-text-input
                [suppressLabel]="true"
                [(ngModel)]="row.pagePath"
                (change)="onValueChange(row.id, 'pagePath', row.pagePath)"
                [disabled]="row.isStandard"
              >
              </lib-gung-text-input>
            </td>
            <td class="group-column">
              <lib-gung-select-option-input
                [suppressLabel]="true"
                [optionsList]="availableGroups"
                [(ngModel)]="row.groupId"
                (change)="onValueChange(row.id, 'groupId', row.groupId)"
              >
              </lib-gung-select-option-input>
            </td>
            @for (role of roles; track role) {
              <td class="role-column">
                <lib-gung-checkbox-input-horizontal
                  [suppressLabel]="true"
                  [(ngModel)]="row.visibleForRoles[role]"
                  (change)="onValueChange(row.id, 'visibleForRoles', row.visibleForRoles)"
                >
                </lib-gung-checkbox-input-horizontal>
              </td>
            }
            <td class="text-right">
              <i *ngIf="row.dependantOnFeatureId | translate"
                 [ngbTooltip]="'LINK_DEPENDANT_ON_FEATURE' | translate : {'featureId': row.dependantOnFeatureId}"
                 class="fa-solid fa-triangle-exclamation mr-2 warning-icon mr-2"></i>

              <i *ngIf="!row.isStandard"
                 (click)="removeRow(row)" [ngbTooltip]="'DELETE' | translate"
                 class="fa-solid fa-trash mr-2 delete-icon mr-2"></i>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr *ngIf="newRowForm" [formGroup]="newRowForm" class="create-matrix-row">
            <td class="icon-column">
              <lib-gung-text-input
                [suppressLabel]="true"
                formControlName="iconCss"
              ></lib-gung-text-input>
              <lib-forms-message-error [controller]="newRowForm.get('iconCss')"></lib-forms-message-error>
            </td>
            <td class="title-column">
              <lib-gung-text-input
                [suppressLabel]="true"
                formControlName="title"
              >
              </lib-gung-text-input>
              <lib-forms-message-error [controller]="newRowForm.get('title')"></lib-forms-message-error>
            </td>
            <td>
              <lib-gung-text-input
                [suppressLabel]="true"
                formControlName="pagePath"
              ></lib-gung-text-input>
              <lib-forms-message-error [controller]="newRowForm.get('pagePath')"></lib-forms-message-error>
            </td>
            <td class="group-column">
              <lib-gung-select-option-input
                [suppressLabel]="true"
                formControlName="groupId"
                [optionsList]="availableGroups"
              >
              </lib-gung-select-option-input>
              <lib-forms-message-error [controller]="newRowForm.get('groupId')"></lib-forms-message-error>
            </td>
            @for (role of roles; track role) {
              <td class="role-column" [formGroup]="newRowRolesForm">
                <lib-gung-checkbox-input-horizontal
                  [suppressLabel]="true"
                  formControlName="{{role}}"
                >
                </lib-gung-checkbox-input-horizontal>
                <lib-forms-message-error [controller]="newRowRolesForm.get(role)"></lib-forms-message-error>
              </td>
            }
            <td class="text-right">
              <i (click)="addRow()" [ngbTooltip]="'ADD' | translate"
                 class="create-row-icon fa-solid fa-save mr-2"></i>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>
