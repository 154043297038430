import "../external/preact/dist/preact.js";
import t from "../core/Errors/AdyenCheckoutError.js";
function e(e) {
  const o = e.replace("_", "-"),
    r = new RegExp("([a-z]{2})([-])([A-Z]{2})");
  if (r.test(o)) return o;
  const [n, c] = o.split("-");
  if (2 !== n.length) throw new t("IMPLEMENTATION_ERROR", `Locale '${e}' does not match the expected format`);
  if (!c) return n.toLowerCase();
  const s = [n.toLowerCase(), c.toUpperCase()].join("-");
  if (r.test(s)) return s;
  throw new t("IMPLEMENTATION_ERROR", `Locale '${e}' does not match the expected format`);
}
function o(t = {}) {
  return Object.keys(t).reduce((o, r) => (o[e(r)] = t[r], o), {});
}
const r = (t, e) => t.replace(/%{(\w+)}/g, (t, o) => e[o] || ""),
  n = (t, e, o = {
    values: {},
    count: 0
  }) => {
    const n = `${e}__plural`,
      c = t => `${e}__${t}`;
    return Object.prototype.hasOwnProperty.call(t, c(o.count)) ? r(t[c(o.count)], o.values) : Object.prototype.hasOwnProperty.call(t, n) && o.count > 1 ? r(t[n], o.values) : Object.prototype.hasOwnProperty.call(t, e) ? r(t[e], o.values) : null;
  },
  c = (t, e) => {
    const o = t.split(/%#(.*?)%#/gm);
    if (e.length !== Math.floor(o.length / 2)) throw Error("The number of functions provided does not match the number of elements in the translation string.");
    return o.map((t, o) => {
      const r = Math.floor(o / 2);
      return o % 2 == 0 ? t : e[r](t);
    });
  };
export { o as formatCustomTranslations, e as formatLocale, n as getTranslation, c as interpolateElement };
