import { ANALYTICS_ACTION_STR as e, ANALYTICS_VALIDATION_ERROR_STR as o, errorCodeMapping as s, ALLOWED_ANALYTICS_DATA as n } from "./constants.js";
import t from "../../utils/uuid.js";
import { digitsOnlyFormatter as r } from "../../utils/Formatters/formatters.js";
import { ERROR_FIELD_REQUIRED as a, ERROR_INVALID_FORMAT_EXPECTS as i } from "../Errors/constants.js";
import { THREEDS2_FULL as d, DEFAULT_CHALLENGE_WINDOW_SIZE as l } from "../../components/ThreeDS2/constants.js";
import u from "../../components/Card/components/CardInput/defaultProps.js";
import { DEFAULT_CARD_GROUP_TYPES as m } from "../../components/internal/SecuredFields/lib/constants.js";
import { notFalsy as p } from "../../utils/commonUtils.js";
const c = () => Date.now(),
  g = s => ({
    timestamp: String(c()),
    component: s.component,
    id: t(),
    ...("error" === s.event && {
      code: s.code,
      errorType: s.errorType,
      message: s.message
    }),
    ...("log" === s.event && {
      type: s.type,
      message: s.message
    }),
    ...("log" === s.event && (s.type === e || s.type === d) && {
      subType: s.subtype
    }),
    ...("log" === s.event && s.type === d && {
      result: s.result
    }),
    ...("info" === s.event && {
      type: s.type,
      target: s.target
    }),
    ...("info" === s.event && s.issuer && {
      issuer: s.issuer
    }),
    ...("info" === s.event && {
      isExpress: s.isExpress,
      expressPage: s.expressPage
    }),
    ...("info" === s.event && s.isStoredPaymentMethod && {
      isStoredPaymentMethod: s.isStoredPaymentMethod,
      brand: s.brand
    }),
    ...("info" === s.event && s.type === o && {
      validationErrorCode: y(s.validationErrorCode, s.target),
      validationErrorMessage: s.validationErrorMessage
    }),
    ...(s.configData && {
      configData: s.configData
    }),
    ...(s.metadata && {
      metadata: s.metadata
    })
  }),
  y = (e, o) => {
    if (e === a || e === i) return s[`${e}.${o}`] ?? e;
    let n = s[e] ?? e;
    return isNaN(Number(n)) && (n = r(n)), n;
  },
  h = e => Object.keys(e).reduce((o, s) => (n.includes(s) && (o[s] = e[s]), o), {}),
  b = e => {
    const {
        autoFocus: o,
        billingAddressAllowedCountries: s,
        billingAddressMode: n,
        billingAddressRequired: t,
        billingAddressRequiredFields: r,
        brands: a = m,
        brandsConfiguration: i,
        challengeWindowSize: d = l,
        configuration: c,
        countryCode: g,
        data: y,
        disclaimerMessage: h,
        disableIOSArrowKeys: b,
        doBinLookup: f,
        enableStoreDetails: S,
        exposeExpiryDate: C,
        forceCompat: A,
        hasHolderName: O,
        hideCVC: B,
        holderNameRequired: F,
        installmentOptions: P,
        keypadFix: k,
        legacyInputMode: v,
        maskSecurityCode: w,
        minimumExpiryDate: E,
        name: M,
        placeholders: x,
        positionHolderNameOnTop: D,
        showBrandIcon: N,
        showInstallmentAmounts: V,
        showPayButton: I = !1,
        styles: j,
        onAllValid: L,
        onBinLookup: q,
        onBinValue: R,
        onBlur: T,
        onBrand: K,
        onConfigSuccess: H,
        onEnterKeyPressed: z,
        onFieldValid: J,
        onFocus: W,
        onLoad: $
      } = e,
      U = JSON.stringify(u.data),
      G = e.modules?.srPanel?.enabled,
      Q = e.modules?.srPanel?.moveFocus,
      X = e.modules?.risk?.enabled,
      Y = e.onAddressLookup ? "lookup" : n;
    let Z = "none";
    !0 === c?.koreanAuthenticationRequired && (Z = "kr" === g?.toLowerCase() ? "atStart" : "auto");
    return {
      autoFocus: o,
      ...(s?.length > 0 && {
        billingAddressAllowedCountries: s.toString().substring(0, 128)
      }),
      billingAddressMode: Y,
      billingAddressRequired: t,
      billingAddressRequiredFields: r?.toString()?.substring(0, 128),
      brands: a?.toString()?.substring(0, 128),
      challengeWindowSize: d,
      disableIOSArrowKeys: b,
      doBinLookup: f,
      enableStoreDetails: S,
      exposeExpiryDate: C,
      forceCompat: A,
      hasBrandsConfiguration: p(i),
      hasData: y && JSON.stringify(e.data) !== U,
      hasDisclaimerMessage: !!h,
      hasHolderName: O,
      hasInstallmentOptions: p(P),
      hasPlaceholders: p(x),
      hasStylesConfigured: p(j),
      hideCVC: B,
      holderNameRequired: F,
      keypadFix: k,
      legacyInputMode: v,
      maskSecurityCode: w,
      minimumExpiryDate: !!E,
      name: M,
      positionHolderNameOnTop: D,
      riskEnabled: X,
      showBrandIcon: N,
      showInstallmentAmounts: !!V,
      showKCPType: Z,
      showPayButton: I,
      socialSecurityNumberMode: c?.socialSecurityNumberMode,
      srPanelEnabled: G,
      srPanelMoveFocus: Q,
      hasOnAllValid: L !== u.onAllValid,
      hasOnBinValue: R !== u.onBinValue,
      hasOnBlur: T !== u.onBlur,
      hasOnBrand: K !== u.onBrand,
      hasOnConfigSuccess: H !== u.onConfigSuccess,
      hasOnFieldValid: J !== u.onFieldValid,
      hasOnFocus: W !== u.onFocus,
      hasOnLoad: $ !== u.onLoad,
      hasOnBinLookup: !!q,
      hasOnEnterKeyPressed: !!z
    };
  };
export { g as createAnalyticsObject, b as getCardConfigData, c as getUTCTimestamp, h as processAnalyticsData };
