function e(e = "TEST") {
  switch (e.toLowerCase()) {
    case "production":
    case "live":
    case "live-au":
    case "live-apse":
    case "live-us":
    case "live-in":
      return "PRODUCTION";
    default:
      return "TEST";
  }
}
function s(e, s) {
  if (e) return {
    postalCode: e.postalCode,
    country: e.countryCode,
    street: [e.address1, e.address2, e.address3].join(" ").trim(),
    houseNumberOrName: "ZZ",
    city: e.locality || "",
    ...(e.administrativeArea && {
      stateOrProvince: e.administrativeArea
    }),
    ...(s && {
      firstName: e.name
    })
  };
}
const t = ["en", "ar", "bg", "ca", "cs", "da", "de", "el", "es", "et", "fi", "fr", "hr", "id", "it", "ja", "ko", "ms", "nl", "no", "pl", "pt", "ru", "sk", "sl", "sr", "sv", "th", "tr", "uk", "zh"];
function r(e = "") {
  const s = e.toLowerCase().substring(0, 2);
  return t.includes(s) ? s : null;
}
export { s as formatGooglePayContactToAdyenAddressFormat, r as getGooglePayLocale, e as resolveEnvironment };
