import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, of, ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs';
import { News } from '../../models/news';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  public centerAlignNews = true;

  private newsSubject: ReplaySubject<News[]>;
  protected baseUrl = 'json/pages';

  constructor(protected http: HttpClient, protected usersService: UsersService) {}

  getActiveRoles(): Observable<{ name: string; value: boolean; translationKey: string }[]> {
    return this.usersService.getDefaultUserRoles().pipe(
      first(),
      switchMap(roles => {
        if (roles.indexOf('ACTUATOR') > -1) {
          roles.splice(
            roles.findIndex(r => r.id === 'ACTUATOR'),
            1
          );
        }
        return of(
          roles.map(r => ({
            name: r.id,
            value: false,
            translationKey: r.id
          }))
        );
      })
    );
  }

  deleteNews(id: string): Observable<boolean> {
    const url = `${this.baseUrl}/` + id;
    return this.http.delete<boolean>(url).pipe(
      first(),
      tap(result => {
        if (result) {
          this.newsSubject.pipe(first()).subscribe(value => {
            const index = value.findIndex(item => item.id === id);
            if (index > -1) {
              value.splice(index, 1);
              this.newsSubject.next(value);
            }
          });
        }
      })
    );
  }

  protected updateNewsSubject(news: News): void {
    if (!this.newsSubject) {
      return;
    } else {
      this.newsSubject.pipe(first()).subscribe(value => {
        const index = value.findIndex(item => item.id === news.id);
        if (index > -1) {
          value.splice(index, 1, news);
        } else {
          value.unshift(news);
        }

        this.newsSubject.next(value);
      });
    }
  }

  protected internalGetNews(nocache?: boolean): Observable<News[]> {
    const url = `${this.baseUrl}`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };

    return this.http.get<News[]>(url, { headers }).pipe(
      first(),
      map(news =>
        news.sort((a, b) => {
          if (a.extra.timestamp < b.extra.timestamp) {
            return 1;
          }
          if (a.extra.timestamp > b.extra.timestamp) {
            return -1;
          }
          return 0;
        })
      )
    );
  }

  public postNews(news: News): Observable<any> {
    const url = `${this.baseUrl}`;
    return this.http.post<any>(url, news).pipe(tap(() => this.updateNewsSubject(news)));
  }

  getAllNews(nocache?: boolean): Observable<News[]> {
    if (!this.newsSubject) {
      this.newsSubject = new ReplaySubject<News[]>();
      this.internalGetNews(nocache)
        .pipe(first())
        .subscribe(news => this.newsSubject.next(news));
    }

    return this.newsSubject.asObservable();
  }

  getNewsById(newsId: string): Observable<News> {
    return this.getAllNews().pipe(
      first(),
      map(news => news.find(n => n.id === newsId))
    );
  }
  getUserNewsByRolesAndGroupsById(newsId: string): Observable<News> {
    return this.getUserNewsByRolesAndGroups().pipe(
      first(),
      map(news => news.find(n => n.id === newsId))
    );
  }

  getInternalActiveUserNews(nocache?: boolean): Observable<News[]> {
    const url = `${this.baseUrl}/active-for-user-by-roles`;
    const headers = {
      maxAge: nocache ? '-1' : '3600'
    };

    return this.http.get<News[]>(url, { headers }).pipe(
      first(),
      map(news =>
        news.sort((a, b) => {
          if (a.extra.timestamp < b.extra.timestamp) {
            return 1;
          }
          if (a.extra.timestamp > b.extra.timestamp) {
            return -1;
          }
          return 0;
        })
      )
    );
  }

  getUserNewsByGroups(nocache?: boolean): Observable<News[]> {
    const url = `${this.baseUrl}/for-user`;
    const headers = {
      maxAge: nocache ? '-1' : '3600'
    };

    return this.http.get<News[]>(url, { headers }).pipe(
      first(),
      map(news =>
        news.sort((a, b) => {
          if (a.extra.timestamp < b.extra.timestamp) {
            return 1;
          }
          if (a.extra.timestamp > b.extra.timestamp) {
            return -1;
          }
          return 0;
        })
      ),
      map(news => news.filter((c, index) => news.findIndex(n => n.id === c.id) === index))
    );
  }

  getUserNewsByRolesAndGroups(nocache?: boolean): Observable<News[]> {
    return forkJoin([this.getInternalActiveUserNews().pipe(first()), this.getUserNewsByGroups().pipe(first())]).pipe(
      first(),
      switchMap(([byRoles, byGroups]) => {
        return of(byRoles.filter(r => byGroups.findIndex(g => g.id === r.id) > -1));
      }),
      map(news => news.filter((c, index) => news.findIndex(n => n.id === c.id) === index))
    );
  }

  getShowInModalUserNews(): Observable<News[]> {
    // return this.getInternalActiveUserNews().pipe(
    return this.getUserNewsByRolesAndGroups().pipe(
      first(),
      map(news => news.filter(item => item.extra.showInPopup))
    );
  }

  getShowInPageUserNews(): Observable<News[]> {
    // return this.getInternalActiveUserNews().pipe(
    return this.getUserNewsByRolesAndGroups().pipe(
      first(),
      map(news => news.filter(item => item.extra.showInPage))
    );
  }

  public getPublicNewById(newsId: string): Observable<News> {
    const url = this.baseUrl + '/' + newsId;

    return this.http.get<News>(url);
  }
}
