import { createElement as e } from "../../../../../../external/preact/dist/preact.js";
import { useState as t, useEffect as o, useCallback as r } from "../../../../../../external/preact/hooks/dist/hooks.js";
import s from "../../../context/useClickToPayContext.js";
import c from "../../../../../../external/classnames/index.js";
import { useCoreContext as n } from "../../../../../../core/Context/CoreProvider.js";
import a from "../../../../Icon/Icon.js";
import { isSrciError as d } from "../../../services/utils.js";
import { PREFIX as i } from "../../../../Icon/constants.js";
const l = ({
  onError: l,
  onResendCode: p,
  disabled: m
}) => {
  const [u, f] = t(null),
    [k, h] = t(!1),
    {
      i18n: y
    } = n(),
    {
      startIdentityValidation: _
    } = s();
  o(() => {
    let e = null;
    return u > 0 && (e = setTimeout(() => f(u - 1), 1e3)), () => clearTimeout(e);
  }, [u]), o(() => {
    let e = null;
    return k && (e = setTimeout(() => {
      h(!1), f(60);
    }, 2e3)), () => clearTimeout(e);
  }, [k]);
  const x = r(async e => {
    e.preventDefault();
    try {
      p(), h(!0), await _();
    } catch (e) {
      if (f(0), h(!1), !d(e)) return void console.error(e);
      l(e.reason);
    }
  }, [_, l, p]);
  return k ? e("div", {
    className: "adyen-checkout-ctp__otp-resend-code--confirmation"
  }, y.get("ctp.otp.codeResent"), e(a, {
    type: `${i}checkmark`,
    height: 14,
    width: 14
  })) : u > 0 ? e("div", {
    className: "adyen-checkout-ctp__otp-resend-code--disabled"
  }, y.get("ctp.otp.resendCode"), " -", " ", e("span", {
    className: "adyen-checkout-ctp__otp-resend-code-counter"
  }, " ", u > 0 && `${u}s`, " ")) : e("div", {
    role: "link",
    tabIndex: 0,
    className: c("adyen-checkout-ctp__otp-resend-code", {
      "adyen-checkout-ctp__otp-resend-code--disabled": m
    }),
    onClick: x
  }, y.get("ctp.otp.resendCode"));
};
export { l as default };
