import { createElement as e } from "../../../external/preact/dist/preact.js";
import { useState as a, useCallback as n, useEffect as o } from "../../../external/preact/hooks/dist/hooks.js";
import t from "./PaypalButtons.js";
import s from "../../internal/Spinner/Spinner.js";
import { getPaypalUrl as r } from "../utils/get-paypal-url.js";
import p from "../../../utils/Script.js";
function c({
  onApprove: c,
  onCancel: i,
  onChange: l,
  onError: u,
  onSubmit: d,
  onScriptLoadFailure: m,
  ...y
}) {
  const [_, f] = a("pending");
  this.setStatus = f;
  const h = n((e, a) => {
      f("processing"), c(e, a);
    }, [c]),
    g = () => {
      f("ready");
    },
    N = e => {
      m(e);
    };
  return o(() => {
    const e = r(y),
      a = {
        ...(y.cspNonce && {
          nonce: y.cspNonce
        })
      },
      n = {
        ...(y.cspNonce && {
          cspNonce: y.cspNonce
        })
      },
      o = new p(e, "body", a, n);
    return o.load().then(g).catch(N), () => {
      o.remove();
    };
  }, []), "pending" === _ ? e("div", {
    className: "adyen-checkout__paypal",
    "aria-live": "polite",
    "aria-busy": "true"
  }, e("div", {
    className: "adyen-checkout__paypal__status adyen-checkout__paypal__status--pending",
    "data-testid": "paypal-loader"
  }, e(s, null))) : e("div", {
    className: "adyen-checkout__paypal"
  }, e(t, {
    ...y,
    onCancel: i,
    onChange: l,
    onError: u,
    onSubmit: d,
    onApprove: h,
    isProcessingPayment: "processing" === _,
    paypalRef: window.paypal
  }));
}
export { c as default };
