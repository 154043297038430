import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { BackendFeatureService, GungFeature, GungFeatureMap } from '../../services/backend-feature.service';
import { first, map, Observable, of, switchMap } from 'rxjs';
import { HasLocalFeatureOverrideService } from '../../services/has-local-feature-override.service';

@Injectable()
export class FeatureActivatedGuardWrapper {
  private readonly GUNG_BASE_FEATURE_ID = 'gung';
  constructor(
    protected backendFeatureService: BackendFeatureService,
    protected router: Router,
    protected localFeatureOverrideService: HasLocalFeatureOverrideService
  ) {}

  /**
   * Checks if a feature is activated and returns the result instantly. In contrast to isActivated, this method does
   * not return an observable wrapped result. Other than that the logic is the same.
   * @param features The features present in the application.
   * @param featureId The feature id to check.
   * @param minimumBaseVersion The minimum base version required for the feature to be activated.
   * @param failIfNoBaseVersion If true, the feature will not be activated if the base version is missing.
   */
  isActivatedInstant(
    features: GungFeatureMap,
    featureId: string,
    minimumBaseVersion: string,
    failIfNoBaseVersion?: boolean
  ): boolean {
    // boolean if there is an override for the feature (with true/false response)
    // undefined if there was no local override found for the feature.
    const hasLocalOverride: boolean | undefined = this.localFeatureOverrideService.hasLocalOverride(featureId);
    if (hasLocalOverride) {
      return true;
    }

    const baseVersion = features[this.GUNG_BASE_FEATURE_ID];

    // If we force that a base version has to exist, and we don't have one, we should always return false.
    // We had a case where we wanted to add a tab to the customer details with hubspot data if that feature was
    // activated, but the behaviour on old customer that did not even have a recent enough backend to have a base
    // version was to display this, even though the feature did not exist. This flag check will solve those cases.
    if (!!failIfNoBaseVersion && !baseVersion) {
      return false;
    }

    // If we don't have the base version at all and localOverride returned undefined, we need to keep assuming
    // that the feature is activated, otherwise there is no way for us to know this. If localOverride returned false
    // though, then we know that the feature should be "disabled" by default
    if (!baseVersion && hasLocalOverride === undefined) {
      return true;
    }

    const feature = features[featureId];
    if (feature && !baseVersion) {
      return true;
    }

    if (!feature || !this.baseVersionMatching(baseVersion, minimumBaseVersion)) {
      return false;
    }
    return true;
  }

  /**
   * Checks if a feature is activated and returns an observable with the result.
   * @param featureId The feature id to check.
   * @param minimumBaseVersion The minimum base version required for the feature to be activated.
   * @param failIfNoBaseVersion If true, the feature will not be activated if the base version is missing.
   */
  isActivated(featureId: string, minimumBaseVersion: string, failIfNoBaseVersion?: boolean): Observable<boolean> {
    // boolean if there is an override for the feature (with true/false response)
    // undefined if there was no local override found for the feature.
    const hasLocalOverride: boolean | undefined = this.localFeatureOverrideService.hasLocalOverride(featureId);
    if (hasLocalOverride) {
      return of(true);
    }

    return this.backendFeatureService.getAvailableFeatures().pipe(
      first(),
      switchMap(features => {
        return of(this.isActivatedInstant(features, featureId, minimumBaseVersion, failIfNoBaseVersion));
      })
    );
  }

  isActivateWithUrlTree(featureId: string, minimumBaseVersion: string): Observable<boolean | UrlTree> {
    return this.isActivated(featureId, minimumBaseVersion).pipe(
      switchMap(activated => {
        if (!activated) {
          return of(this.router.createUrlTree(['inactive-feature', featureId]));
        } else {
          return of(activated);
        }
      })
    );
  }

  private baseVersionMatching(baseVersion: GungFeature, minimumBaseVersion: string) {
    const base = this.parseBaseVersionIdToIntArray(baseVersion.version);
    const minimum = this.parseBaseVersionIdToIntArray(minimumBaseVersion);

    const allowedByMajor = base[0] > minimum[0];
    if (allowedByMajor) {
      return true;
    }

    const allowedByMinor = base[0] >= minimum[0] && base[1] > minimum[1];
    if (allowedByMinor) {
      return true;
    }

    const allowedByPatch = base[0] >= minimum[0] && base[1] >= minimum[1] && base[2] >= minimum[2];
    if (allowedByPatch) {
      return true;
    }

    return false;
  }

  private parseBaseVersionIdToIntArray(version: string): number[] {
    const splitted = version.split('-');
    const numberPartSplitted = splitted[0].split('.');
    return [parseInt(numberPartSplitted[0]), parseInt(numberPartSplitted[1]), parseInt(numberPartSplitted[2])];
  }
}

export const featureActivatedGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(FeatureActivatedGuardWrapper).isActivateWithUrlTree(
    route.data.featureId,
    route.data.minimumGungBaseVersion
  );
};
