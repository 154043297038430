import { createElement as e } from "../../../../../external/preact/dist/preact.js";
import r from "./CardNumber.js";
import a from "./CVC.js";
import t from "./ExpirationDate.js";
import { useCoreContext as d } from "../../../../../core/Context/CoreProvider.js";
import n from "../../../../../external/classnames/index.js";
import { BRAND_ICON_UI_EXCLUSION_LIST as c, DATE_POLICY_HIDDEN as i, ENCRYPTED_EXPIRY_DATE as o, ENCRYPTED_SECURITY_CODE as l, ENCRYPTED_CARD_NUMBER as s } from "../../../../internal/SecuredFields/lib/constants.js";
import u from "./AvailableBrands/AvailableBrands.js";
function m({
  brand: m,
  brandsIcons: p,
  brandsConfiguration: y,
  dualBrandingElements: x,
  dualBrandingChangeHandler: C,
  dualBrandingSelected: f,
  errors: _,
  focusedElement: b,
  hasCVC: g,
  cvcPolicy: h,
  expiryDatePolicy: E,
  onFocusField: v,
  showBrandIcon: B,
  valid: F,
  showContextualElement: N
}) {
  const {
      i18n: j
    } = d(),
    k = (e, r) => e[r] ? j.get(e[r]) : null,
    w = p?.filter(e => !c?.includes(e.name)),
    D = "amex" === m,
    S = D ? j.get("creditCard.securityCode.contextualText.4digits") : j.get("creditCard.securityCode.contextualText.3digits");
  return e("div", {
    className: "adyen-checkout__card__form"
  }, e(r, {
    brand: m,
    brandsConfiguration: y,
    error: k(_, s),
    focused: b === s,
    isValid: !!F.encryptedCardNumber,
    label: j.get("creditCard.cardNumber.label"),
    onFocusField: v,
    filled: !!_.encryptedCardNumber || !!F.encryptedCardNumber,
    showBrandIcon: B,
    dualBrandingElements: x,
    dualBrandingChangeHandler: C,
    dualBrandingSelected: f
  }), e(u, {
    activeBrand: m,
    brands: w
  }), e("div", {
    className: n("adyen-checkout__card__exp-cvc adyen-checkout__field-wrapper", {
      "adyen-checkout__card__exp-cvc__exp-date__input--hidden": E === i
    })
  }, e(t, {
    error: k(_, o),
    focused: b === o,
    isValid: !!F.encryptedExpiryMonth && !!F.encryptedExpiryYear,
    filled: !!_.encryptedExpiryDate || !!F.encryptedExpiryYear,
    label: j.get("creditCard.expiryDate.label"),
    onFocusField: v,
    className: "adyen-checkout__field--50",
    expiryDatePolicy: E,
    showContextualElement: N,
    contextualText: j.get("creditCard.expiryDate.contextualText")
  }), g && e(a, {
    error: k(_, l),
    focused: b === l,
    cvcPolicy: h,
    isValid: !!F.encryptedSecurityCode,
    filled: !!_.encryptedSecurityCode || !!F.encryptedSecurityCode,
    label: j.get("creditCard.securityCode.label"),
    onFocusField: v,
    className: "adyen-checkout__field--50",
    frontCVC: D,
    showContextualElement: N,
    contextualText: S
  })));
}
export { m as default };
