import { createElement as e } from "../../../../../external/preact/dist/preact.js";
import t from "../../../../../external/classnames/index.js";
import o from "./SelectListItem.js";
import { useCoreContext as n } from "../../../../../core/Context/CoreProvider.js";
function s({
  selected: s,
  active: i,
  filteredItems: r,
  showList: c,
  ...d
}) {
  const {
    i18n: l
  } = n();
  return e("ul", {
    className: t({
      "adyen-checkout__dropdown__list": !0,
      "adyen-checkout__dropdown__list--active": c
    }),
    id: d.selectListId,
    ref: d.selectListRef,
    role: "listbox"
  }, r.length ? r.map(t => e(o, {
    active: t.id === i.id,
    item: t,
    key: t.id,
    onSelect: d.onSelect,
    onHover: d.onHover,
    selected: t.id === s.id
  })) : e("div", {
    className: "adyen-checkout__dropdown__element adyen-checkout__dropdown__element--no-options"
  }, l.get("select.noOptionsFound")));
}
export { s as default };
