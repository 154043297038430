import e from "../Errors/AdyenCheckoutError.js";
class r {
  resourceContext;
  constructor(r) {
    if (!r) throw new e("IMPLEMENTATION_ERROR", 'Resources module: "environmentsUrls.cdn" is not a valid URL');
    this.resourceContext = r;
  }
  returnImage = ({
    name: e,
    resourceContext: r,
    imageFolder: t = "",
    parentFolder: o = "",
    extension: n,
    size: s = "",
    subFolder: i = ""
  }) => `${r}images/${t}${i}${o}${e}${s}.${n}`;
  getImageUrl = ({
    resourceContext: e,
    extension: r = "svg",
    ...t
  }) => o => {
    const n = {
      extension: r,
      resourceContext: e,
      imageFolder: "logos/",
      parentFolder: "",
      name: o,
      ...t
    };
    return this.returnImage(n);
  };
  getImage(e = {}) {
    return this.getImageUrl({
      ...e,
      resourceContext: this.resourceContext
    });
  }
}
export { r as Resources };
