import { Injectable, Type } from '@angular/core';
import { GungSettingsBaseViewComponent } from '../components/gung-settings-base-view/gung-settings-base-view.component';
import { Observable, of } from 'rxjs';
import { GungSettingsUsersViewComponent } from '../components/gung-settings/gung-settings-users-view/gung-settings-users-view.component';
import { ConfigurationsService } from './configurations.service';
import { MyCompanyComponent } from '../components/my-company/my-company.component';
import { GungSettingsSecurityViewComponent } from '../components/gung-settings/gung-settings-security-view/gung-settings-security-view.component';
import { GungSettingsCustomerTemplatesComponent } from '../components/gung-settings/gung-settings-customer-templates/gung-settings-customer-templates.component';
import { GungSettingsConfigOrderViewComponent } from '../components/gung-settings/gung-settings-config-order-view/gung-settings-config-order-view.component';
import { GungSettingsConfigOfferViewComponent } from '../components/gung-settings/gung-settings-config-offer-view/gung-settings-config-offer-view.component';
import { GungSettingsNavigationViewComponent } from '../components/gung-settings/gung-settings-general-view/gung-settings-navigation-view.component';

export interface GungSettingsMenuItem {
  translationLabel: string;
  componentView: Type<GungSettingsBaseViewComponent> | undefined;
  prependIcon?: string;
  appendIcon?: string;
  isSeparator?: boolean;
  disabled?: boolean;
  hide?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class GungSettingsConfigService {
  constructor(protected configurationsService: ConfigurationsService) {}

  public getGungSettingsMenu(): Observable<GungSettingsMenuItem[]> {
    const menu: GungSettingsMenuItem[] = [];

    menu.push({
      translationLabel: 'NAVIGATION',
      prependIcon: 'fa-light fa-compass',
      componentView: GungSettingsNavigationViewComponent,
      disabled: false
    });

    menu.push({
      translationLabel: 'MY_COMPANY',
      prependIcon: 'fa-light fa-building',
      componentView: MyCompanyComponent
    });

    menu.push({
      translationLabel: 'USERS',
      prependIcon: 'fa-light fa-user',
      componentView: GungSettingsUsersViewComponent
    });

    menu.push({
      translationLabel: 'SECURITY',
      prependIcon: 'fa-light fa-lock',
      componentView: GungSettingsSecurityViewComponent,
      disabled: false
    });

    menu.push({
      translationLabel: 'CUSTOMER_TEMPLATES',
      prependIcon: 'fa-light fa-users',
      componentView: GungSettingsCustomerTemplatesComponent,
      isSeparator: false
    });

    menu.push({
      translationLabel: 'ORDERS',
      prependIcon: 'fa-solid fa-briefcase',
      componentView: GungSettingsConfigOrderViewComponent,
      disabled: false
    });

    menu.push({
      translationLabel: 'OFFERS',
      prependIcon: 'fa-light fa-briefcase',
      componentView: GungSettingsConfigOfferViewComponent,
      disabled: false
    });

    return of(menu);
  }
}
