import { makeCallbackObjectsEncryption as t } from "../utils/callbackUtils.js";
import { ENCRYPTED_EXPIRY_YEAR as s, ENCRYPTED_SECURITY_CODE as e, ENCRYPTED_EXPIRY_MONTH as i, ENCRYPTED_CARD_NUMBER as o, ENCRYPTED_EXPIRY_DATE as r } from "../../constants.js";
import { processErrors as a } from "../utils/processErrors.js";
import { truthy as n } from "../../../../../../utils/commonUtils.js";
import l from "../utils/iframes/postMessageToIframe.js";
import { hasOwnProperty as p } from "../../../../../../utils/hasOwnProperty.js";
import c from "../utils/iframes/getIframeContentWin.js";
function d(d) {
  const m = d.fieldType;
  let f, u;
  this.config.autoFocus && ("year" !== d.type && m !== s || this.setFocusOnFrame(e), m === i && this.setFocusOnFrame(s));
  const h = d[m];
  this.state.securedFields[m].isEncrypted = !0, a({
    error: "",
    fieldType: m
  }, this.state.securedFields[m], this.state.type, this.props.rootNode, this.callbacks.onError);
  const y = t({
    fieldType: m,
    txVariant: this.state.type,
    rootNode: this.props.rootNode,
    encryptedObjArr: h
  });
  if (m === i && p(this.state.securedFields, s)) {
    const t = {
      txVariant: this.state.type,
      code: d.code,
      blob: h[0].blob,
      fieldType: s,
      numKey: this.state.securedFields[s].numKey
    };
    l(t, c(this.state, s), this.config.loadingContext);
  }
  for (m === o && n(d.endDigits) && (y[0].endDigits = d.endDigits), m === o && n(d.issuerBin) && (y[0].issuerBin = +d.issuerBin), m === r && n(d.expiryDate) && (y[1].expiryDate = d.expiryDate), f = 0, u = y.length; f < u; f += 1) this.callbacks.onFieldValid(y[f]);
  this.validateForm();
}
export { d as handleEncryption };
