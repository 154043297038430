
<span *ngIf="showOnlyText; else inputs">{{ metaValue }}</span>

<ng-template #inputs>
  <lib-gung-text-input
    *ngIf="!horizontal"
    [(ngModel)]="metaValue"
    [renderStatic]="true"
    [gungTranslate]="gungTranslate"
    [suppressLabel]="suppressLabel"
    [labelBold]="labelBold"
  ></lib-gung-text-input>
  <lib-gung-text-input-horizontal
    *ngIf="horizontal"
    [(ngModel)]="metaValue"
    [labelClass]="labelClass"
    [renderStatic]="true"
    [gungTranslate]="gungTranslate"
    [suppressLabel]="suppressLabel"
    [labelBold]="labelBold"
  ></lib-gung-text-input-horizontal>
</ng-template>
