const e = "N/A",
  a = ["street", "houseNumberOrName", "postalCode", "city", "stateOrProvince", "country", "firstName", "lastName"],
  [t, s, l, r, o, c, i, d] = a,
  p = {
    AU: {
      hasDataset: !0,
      labels: {
        [s]: "apartmentSuite",
        [o]: "state",
        [t]: "address"
      },
      optionalFields: [s],
      placeholders: {
        [o]: "select.state"
      },
      schema: [c, t, s, r, [[o, 50], [l, 50]]]
    },
    BR: {
      hasDataset: !0,
      labels: {
        [o]: "state"
      },
      placeholders: {
        [o]: "select.state"
      }
    },
    CA: {
      hasDataset: !0,
      labels: {
        [s]: "apartmentSuite",
        [o]: "provinceOrTerritory",
        [t]: "address"
      },
      optionalFields: [s],
      schema: [c, t, s, [[r, 70], [l, 30]], o]
    },
    GB: {
      labels: {
        [r]: "cityTown"
      },
      schema: [c, [[s, 30], [t, 70]], [[r, 70], [l, 30]], o]
    },
    US: {
      hasDataset: !0,
      labels: {
        [l]: "zipCode",
        [s]: "apartmentSuite",
        [o]: "state",
        [t]: "address"
      },
      optionalFields: [s],
      placeholders: {
        [o]: "select.state"
      },
      schema: [c, t, s, r, [[o, 50], [l, 50]]]
    },
    default: {
      optionalFields: [],
      placeholders: {
        [o]: "select.provinceOrTerritory"
      },
      schema: [c, [[t, 70], [s, 30]], [[l, 30], [r, 70]], o]
    }
  },
  h = {
    default: {
      labels: {
        [l]: "zipCode"
      },
      schema: [l]
    }
  },
  m = Object.keys(p);
export { a as ADDRESS_SCHEMA, p as ADDRESS_SPECIFICATIONS, r as CITY, m as COUNTRIES_WITH_CUSTOM_SPECIFICATION, c as COUNTRY, e as FALLBACK_VALUE, i as FIRST_NAME, s as HOUSE_NUMBER_OR_NAME, d as LAST_NAME, h as PARTIAL_ADDRESS_SCHEMA, l as POSTAL_CODE, o as STATE_OR_PROVINCE, t as STREET };
