import t from "./SrciError.js";
import e from "../../../../../utils/Script.js";
class i {
  schemeSdk;
  customSdkConfiguration;
  sdkUrl;
  scriptElement = null;
  constructor(t, e) {
    if (!t) throw Error("AbstractSrcInitiator: Invalid SDK URL");
    this.sdkUrl = t, this.customSdkConfiguration = e;
  }
  async loadSdkScript() {
    this.isSdkIsAvailableOnWindow() || (this.scriptElement = new e(this.sdkUrl), await this.scriptElement.load()), this.assignSdkReference();
  }
  removeSdkScript() {
    this.scriptElement.remove();
  }
  async checkout(e) {
    try {
      return await this.schemeSdk.checkout(e);
    } catch (e) {
      throw new t(e, "checkout", this.schemeName);
    }
  }
  async unbindAppInstance() {
    try {
      await this.schemeSdk.unbindAppInstance();
    } catch (e) {
      throw new t(e, "unbindAppInstance", this.schemeName);
    }
  }
  async isRecognized() {
    try {
      return await this.schemeSdk.isRecognized();
    } catch (e) {
      throw new t(e, "isRecognized", this.schemeName);
    }
  }
  async initiateIdentityValidation() {
    try {
      return await this.schemeSdk.initiateIdentityValidation();
    } catch (e) {
      throw new t(e, "initiateIdentityValidation", this.schemeName);
    }
  }
  async getSrcProfile(e) {
    try {
      return await this.schemeSdk.getSrcProfile({
        idTokens: e
      });
    } catch (e) {
      throw new t(e, "getSrcProfile", this.schemeName);
    }
  }
}
export { i as default };
