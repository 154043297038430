function e(e) {
  const r = [];
  for (let t = e; t > 0; t--) r.push(t);
  try {
    return r.find(e => e && window.ApplePaySession && ApplePaySession.supportsVersion(e));
  } catch (e) {
    return console.warn(e), null;
  }
}
function r(e) {
  const r = {
    mc: "masterCard",
    amex: "amex",
    visa: "visa",
    elodebit: "elo",
    elo: "elo",
    interac: "interac",
    discover: "discover",
    jcb: "jcb",
    electron: "electron",
    maestro: "maestro",
    girocard: "girocard",
    cartebancaire: "cartesBancaires",
    eftpos_australia: "eftpos"
  };
  return e.reduce((e, t) => (r[t] && !e.includes(r[t]) && e.push(r[t]), e), []);
}
function t(e, r) {
  if (e) return {
    city: e.locality,
    country: e.countryCode,
    houseNumberOrName: "ZZ",
    postalCode: e.postalCode,
    street: e.addressLines?.join(" ").trim(),
    ...(e.administrativeArea && {
      stateOrProvince: e.administrativeArea
    }),
    ...(r && {
      firstName: e.givenName,
      lastName: e.familyName
    })
  };
}
export { t as formatApplePayContactToAdyenAddressFormat, r as mapBrands, e as resolveSupportedVersion };
