import { useState as o, useEffect as r } from "../../../../external/preact/hooks/dist/hooks.js";
function e(e) {
  const [s, t] = o({});
  return r(() => {
    t(e.reduce((o, r) => ({
      ...o,
      ...(r.props.brand && r.icon && {
        [r.props.brand]: r.icon
      })
    }), {}));
  }, [e]), s;
}
export { e as useBrandLogoConfiguration };
