import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption, SimpleConfigBaseFilter } from 'gung-list';
import { map, Observable } from 'rxjs';
import { AccountRequestsCardListComponent } from '../../components/accoun-request/account-requests-card-list/account-requests-card-list.component';
import { AccountRequest, AccountRequestService } from '../account-request/account-request.service';
import { DateUtilService } from 'gung-common';

export class AccountRequestStatusFilter extends SimpleConfigBaseFilter<AccountRequest> {
  constructor() {
    super();
  }

  getName(): string {
    return 'STATUS';
  }

  getOptionIds(account: AccountRequest): string[] {
    if (!account.processed && !account.rejected) {
      return ['PENDING'];
    } else if(account.processed && account.rejected) {
      return ['REJECTED'];
    } else if (account.processed && !account.rejected) {
      return ['ACCEPTED'];
    }
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class AccountRequestDateFilter extends SimpleConfigBaseFilter<AccountRequest> {
  type = 'dateRangeFilter';
  constructor(
    protected dateUtilService: DateUtilService
  ) {
    super();
  }

  getOptionIds(item: AccountRequest): string[] {
    if (item.creationDate) {
      return [this.dateUtilService.getFormattedDateString(new Date(item.creationDate), 'yyMMdd')];
    }
    return [];
  }
  getOptionName(key: string): string {
    return key;
  }
  getName(): string {
    return 'DATE';
  }
}

@Injectable({
  providedIn: 'root'
})
export class AccountRequestListConfigService implements ConfigService<AccountRequest> {
  constructor(protected accountRequestService: AccountRequestService, protected dateUtilService: DateUtilService) {}

  getFilters(): ConfigBaseFilter<AccountRequest>[] {
    return [new AccountRequestStatusFilter(),new AccountRequestDateFilter(this.dateUtilService)];
  }

  getSortOptions(): ListSortOption<AccountRequest>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: AccountRequest) {
    return item.id;
  }

  getSearchTerms(item: AccountRequest) {
    return [item.id, item.name];
  }

  getSearchGroupCss() {
    return 'd-none';
  }

  getLayouts(): ListLayout<AccountRequest>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => AccountRequestsCardListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'AssortmentGrid'
      }
    ];
  }

  getItems(): Observable<AccountRequest[]> {
    return this.accountRequestService.getAccountRequestInternal().pipe(
      map(accounts =>{
        return accounts.sort((a,b)=>{
          if(a.creationDate > b.creationDate){
            return -1;
          }else if(a.creationDate < b.creationDate){
            return 1;
          }else{
            return 0;
          }
        });
      }));
  }
}
