import { ADDRESS_SPECIFICATIONS as s } from "./constants.js";
class t {
  specifications;
  constructor(t) {
    this.specifications = {
      ...s,
      ...t
    };
  }
  countryHasDataset(s) {
    return !!this.specifications?.[s]?.hasDataset;
  }
  countryHasOptionalField(s, t) {
    return !!this.specifications?.[s]?.optionalFields?.includes(t);
  }
  getAddressSchemaForCountry(s) {
    return this.specifications?.[s]?.schema || this.specifications.default.schema;
  }
  getAddressLabelsForCountry(s) {
    return this.specifications?.[s]?.labels || this.specifications.default.labels;
  }
  getOptionalFieldsForCountry(s) {
    return this.specifications?.[s]?.optionalFields || this.specifications.default?.optionalFields || [];
  }
  getKeyForField(s, t) {
    return this.specifications?.[t]?.labels?.[s] || this.specifications?.default?.labels?.[s] || s;
  }
  getPlaceholderKeyForField(s, t) {
    return this.specifications?.[t]?.placeholders?.[s] || this.specifications?.default?.placeholders?.[s];
  }
  getAddressSchemaForCountryFlat(s) {
    return this.getAddressSchemaForCountry(s).flat(2).filter(s => "string" == typeof s);
  }
}
export { t as default };
