import { createElement as e, Fragment as o } from "../../../../../../external/preact/dist/preact.js";
import t from "../../../../../../external/classnames/index.js";
import c from "../../../../FormFields/Field/Field.js";
import s from "../../../../FormFields/Checkbox/Checkbox.js";
import { useCoreContext as a } from "../../../../../../core/Context/CoreProvider.js";
import { useState as n, useCallback as i } from "../../../../../../external/preact/hooks/dist/hooks.js";
import r from "../../../context/useClickToPayContext.js";
import k from "../../../../../../utils/isScreenSmall.js";
function p() {
  const {
      i18n: p
    } = a(),
    {
      updateStoreCookiesConsent: l,
      isStoringCookies: m
    } = r(),
    [d, C] = n(m),
    [h, x] = n(k()),
    b = i(() => {
      const e = !d;
      C(e), l(e);
    }, [l, C, d]);
  return e("div", {
    className: t("adyen-checkout-ctp__otp-checkbox-container", {
      "adyen-checkout-ctp__otp-checkbox-container--checked": d
    })
  }, e(c, {
    classNameModifiers: ["consentCheckbox"],
    name: "clickToPayCookiesCheckbox",
    showContextualElement: !1,
    useLabelElement: !1,
    i18n: p
  }, e(s, {
    name: "clickToPayCookiesCheckbox",
    onInput: b,
    label: p.get("ctp.otp.saveCookiesCheckbox.label"),
    checked: d,
    "aria-describedby": "adyen-ctp-cookies-info"
  })), e("p", {
    className: "adyen-checkout-ctp__otp-checkbox-info"
  }, h ? e(o, null, e("span", {
    id: "adyen-ctp-cookies-info"
  }, p.get("ctp.otp.saveCookiesCheckbox.shorterInfo"), " "), e("button", {
    className: "adyen-checkout-ctp__otp-readmore-button",
    onClick: () => x(!1)
  }, p.get("readMore"), "..")) : e("span", {
    id: "adyen-ctp-cookies-info"
  }, p.get("ctp.otp.saveCookiesCheckbox.information"))));
}
export { p as default };
