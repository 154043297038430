import { createElement as e } from "../../../../external/preact/dist/preact.js";
import { useCoreContext as t } from "../../../../core/Context/CoreProvider.js";
import o from "../../../../external/classnames/index.js";
const n = ({
  id: n,
  open: a,
  onDisable: c,
  onCancel: d
}) => {
  const {
    i18n: i
  } = t();
  return e("div", {
    id: n,
    "aria-hidden": !a,
    className: o({
      "adyen-checkout__payment-method__disable-confirmation": !0,
      "adyen-checkout__payment-method__disable-confirmation--open": a
    })
  }, e("div", {
    className: "adyen-checkout__payment-method__disable-confirmation__content"
  }, i.get("storedPaymentMethod.disable.confirmation"), e("div", {
    className: "adyen-checkout__payment-method__disable-confirmation__buttons"
  }, e("button", {
    type: "button",
    className: o("adyen-checkout__button", "adyen-checkout__payment-method__disable-confirmation__button", "adyen-checkout__payment-method__disable-confirmation__button--remove"),
    disabled: !a,
    onClick: c
  }, i.get("storedPaymentMethod.disable.confirmButton")), e("button", {
    type: "button",
    className: o("adyen-checkout__button", "adyen-checkout__payment-method__disable-confirmation__button", "adyen-checkout__payment-method__disable-confirmation__button--cancel"),
    disabled: !a,
    onClick: d
  }, i.get("storedPaymentMethod.disable.cancelButton")))));
};
export { n as default };
