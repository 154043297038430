import { createElement as e } from "../../../../external/preact/dist/preact.js";
import r from "../../FormFields/Field/Field.js";
import o from "./StateField.js";
import t from "./CountryField.js";
import { useCoreContext as s } from "../../../../core/Context/CoreProvider.js";
import a from "../../FormFields/InputText.js";
function i(i) {
  const {
      i18n: n
    } = s(),
    {
      classNameModifiers: l = [],
      data: d,
      errors: c,
      valid: u,
      fieldName: m,
      onInput: f,
      onBlur: p,
      trimOnBlur: g,
      maxLength: F,
      disabled: C
    } = i,
    M = d[m],
    y = d.country,
    b = i.specifications.countryHasOptionalField(y, m),
    j = i.specifications.getKeyForField(m, y),
    w = b ? ` ${n.get("field.title.optional")}` : "",
    h = `${n.get(j)}${w}`,
    v = function (e, r, o, t) {
      if ("object" == typeof e[r]?.errorMessage) {
        const {
          translationKey: t,
          translationObject: s
        } = e[r].errorMessage;
        return o.get(t, s);
      }
      return o.get(e[r]?.errorMessage, {
        values: {
          label: t.toLowerCase()
        }
      }) || !!e[r];
    }(c, m, n, h);
  switch (m) {
    case "country":
      return e(t, {
        allowedCountries: i.allowedCountries,
        classNameModifiers: l,
        label: h,
        errorMessage: v,
        onDropdownChange: i.onDropdownChange,
        value: M
      });
    case "stateOrProvince":
      return e(o, {
        classNameModifiers: l,
        label: h,
        errorMessage: v,
        onDropdownChange: i.onDropdownChange,
        selectedCountry: y,
        specifications: i.specifications,
        value: M
      });
    default:
      return e(r, {
        label: h,
        classNameModifiers: l,
        errorMessage: v,
        isValid: u[m],
        name: m,
        i18n: n,
        onFocus: e => i.onFieldFocusAnalytics(m, e),
        onBlur: e => i.onFieldBlurAnalytics(m, e)
      }, e(a, {
        name: m,
        classNameModifiers: l,
        value: M,
        onInput: f,
        onBlur: p,
        maxlength: F,
        trimOnBlur: g,
        disabled: C,
        required: !b
      }));
  }
}
export { i as default };
