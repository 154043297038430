<div
  class="bg-container"
  [ngStyle]="{ 'background-image': !videoBackground && backgroundImage && 'url(&quot;' + backgroundImage + '&quot;)' }"
>
  <video
    *ngIf="!!videoBackground"
    playsinline
    autoplay
    [muted]="'muted'"
    loop
    class="video-bg"
    [poster]="backgroundImage"
  >
    <source [src]="videoBackground" type="video/mp4" />
  </video>
  <div class="container fashion-login">
    <div class="login-container" [class.p-0]="!!showInTabsEnabled">
      <ng-container [ngSwitch]="showInTabsEnabled">
        <ng-template [ngSwitchCase]="true">
          <lib-gung-tabset class="login-tabs" justify="fill" (tabChange)="tabChanged($event)" [activeId]="view">
            <lib-gung-tab [title]="'LOGIN' | translate" id="login" class="p-5">
              <ng-template libGungTabContent>
                <ng-container *ngTemplateOutlet="login; context: { $implicit: showInTabsEnabled }"></ng-container>
              </ng-template>
            </lib-gung-tab>
            <lib-gung-tab [title]="'REQUEST_ACCOUNT' | translate" id="request-account">
              <ng-template libGungTabContent>
                <!-- <lib-request-account></lib-request-account> -->
                <ng-template
                  [libCustomHostDynamic]="requestAccountComponent"
                  [inputs]="{}"
                  [outputs]="{ returnToLogin: returnToLoginFn.bind(this) }"
                ></ng-template>
              </ng-template>
            </lib-gung-tab>
          </lib-gung-tabset>
        </ng-template>
        <ng-template [ngSwitchDefault]>
          <ng-container *ngTemplateOutlet="login"></ng-container>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #login let-showInTabsEnabled>
  <div class="login-logo">
    <img class="d-none" [src]="logoUrl || './assets/logo.png'" />
  </div>
  <lib-language-selection *ngIf="!showLanguageDropDown; else languageDropDown"></lib-language-selection>
  <ng-template #languageDropDown>
    <lib-language-selection-dropdown class="mt-4 form-width d-block"></lib-language-selection-dropdown>
  </ng-template>

  <div class="mb-2 mt-3" *ngIf="!!this.environment?.oauth2Enabled">
    <h5 style="font-weight: bold; font-size: 22px" translate>SSO_LOGIN</h5>

    <div class="d-flex">
      <button *ngIf="!loading" class="btn btn-primary" (click)="loginOauth()" translate>SSO_LOGIN</button>
    </div>
  </div>

  <form *ngIf="view === 'login'" [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="mt-3">
    <div class="form-group">
      <label for="username" translate>USERNAME</label>
      <input
        type="text"
        formControlName="username"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
        [placeholder]="'USERNAME' | translate"
      />
      <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
        <div *ngIf="f.username.errors.required" translate>USERNAME_REQUIRED</div>
      </div>
    </div>
    <div class="form-group">
      <!-- <label for="password" translate>PASSWORD</label>
          <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            [placeholder]="'PASSWORD' | translate"/> -->
      <lib-gung-password-input
        [enablePasswordRequirement]="false"
        [suppressLabel]="true"
        gungTranslate="PASSWORD"
        [placeholder]="'PASSWORD' | translate"
        formControlName="password"
        extraInputClass="custom-login-input {{ submitted && f.password.errors ? ', is-invalid' : '' }}"
        [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
      ></lib-gung-password-input>
      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
        <div *ngIf="f.password.errors.required" translate>PASSWORD_REQUIRED</div>
      </div>
    </div>
    <div
      *ngIf="
        error &&
        (error.type === ActionTypes.BadLogin ||
          error.type === ActionTypes.BackendDown ||
          error.type === ActionTypes.UserDisabled)
      "
      class="alert alert-danger"
    >
      <span *ngIf="error.type === ActionTypes.UserDisabled" translate>LOGIN_USER_DISABLED</span>
      <span *ngIf="error.type === ActionTypes.BadLogin || error.type === ActionTypes.BackendDown" translate
        >LOGIN_FAILED</span
      >
      <!-- <span *ngIf="error.type === ActionTypes.BackendDown" translate>BACKEND_DOWN</span> -->
    </div>

    <div *ngIf="error && error.type === ActionTypes.UserForceChangePassword" class="alert alert-warning">
      <span *ngIf="error.type === ActionTypes.UserForceChangePassword">{{
        'USER_FORCE_CHANGE_PASSWORD' | translate
      }}</span>
    </div>

    <div class="form-group d-flex justify-content-between align-items-center">
      <a (click)="$event.stopPropagation(); view = 'forgot-password'" class="forgot-password-text" translate
        >FORGOT_PASSWORD</a
      >
      <a
        *ngIf="requestAccountEnabled && !showInTabsEnabled"
        (click)="$event.stopPropagation(); view = 'request-account'"
        class="forgot-password-text"
        translate
        >REQUEST_ACCOUNT</a
      >
      <button *ngIf="!loading" class="btn btn-primary" translate>LOGIN</button>
      <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg"></i>
    </div>
  </form>
  <lib-forgot-password *ngIf="view === 'forgot-password'" (returnToLogin)="view = 'login'"></lib-forgot-password>
  <!-- <lib-request-account *ngIf="view === 'request-account'" (returnToLogin)="view = 'login'"></lib-request-account> -->
  <ng-template
    *ngIf="view === 'request-account'"
    [libCustomHostDynamic]="requestAccountComponent"
    [inputs]="{}"
    [outputs]="{ returnToLogin: returnToLoginFn.bind(this) }"
  ></ng-template>
</ng-template>
