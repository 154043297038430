import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { isDate, isSameDay } from 'date-fns';
import { Subject } from 'rxjs';
import { CommonModalService } from '../../services/common-modal/common-modal.service';
import { DateUtilService } from '../../services/date-util/date-util.service';
import { BaseInputDirective } from '../base-input/base-input.directive';
import { GungError } from '../modals/gung-error-handler-modal/gung-error-handler-modal.component';

@Component({
  selector: 'lib-gung-date-picker',
  templateUrl: './gung-date-picker.component.html',
  styleUrls: ['./gung-date-picker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungDatePickerComponent),
      multi: true
    }
  ]
})
export class GungDatePickerComponent extends BaseInputDirective<NgbDate> implements OnInit, OnChanges, OnDestroy {

  @Input()
  public label = 'DELIVERY_DATE';

  @Input()
  public suppressLabel = false;

  @Input()
  public footnote = false;

  @Input('currentselectedDate')
  set _currentselectedDate(value: Date | string) {
    if (!value) {
      return;
    }
    this.currentselectedDate = value;
    if (this.currentselectedDate && !isDate(this.currentselectedDate)) {
      this.currentselectedDate = this.dateUtilService.parseDate(this.currentselectedDate);
    }
    const valueDate = this.currentselectedDate as Date;
    this.value = new NgbDate(valueDate.getFullYear(), valueDate.getMonth() + 1, valueDate.getDate());
  }
  public currentselectedDate?: Date | string;

  @Input()
  public minInitialDate?: NgbDate | Date | string;

  @Input()
  public maxDate?: Date | string;

  @Input()
  tooltip?: string;

  @Output()
  protected selectedDate = new EventEmitter<Date>();

  @Input()
  public labelBold = false;

  @Input()
  public customClass?: string;

  @Input()
  public extraInputClass = '';

  @Input()
  public extraBtnClass = '';

  @Input()
  public placement = ['bottom-start', 'bottom-end', 'top-start', 'top-end'];

  @Input()
  public positionTarget;

  public loaded = false;
  public anyError = false;
  private gungError: GungError;
  public maxDatePickerDate: NgbDate;

  // public ngModel?: NgbDateStruct;


  protected unsubscribe: Subject<void> = new Subject();

  public firstDayOfWeek: number = this.dateUtilService.getFirstDayOfWeekRealNumber(true);

  constructor(
    public dateUtilService: DateUtilService,
    protected commonModalService: CommonModalService
  ) {
    super();
  }

  ngOnInit() {
    this.checkMinAndMax();
    this.loaded = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  checkMinAndMax(){
    if (this.minInitialDate && !(this.minInitialDate as NgbDate).year) {
      if (!isDate(this.minInitialDate)) {
        this.minInitialDate = this.dateUtilService.parseDate(this.minInitialDate);
      }
      this.minInitialDate = this.dateUtilService.createNgbDateFromDate(this.minInitialDate as Date);
    }
    if(this.maxDate){
      this.maxDatePickerDate = this.dateUtilService.createNgbDateFromDate(this.maxDate as Date)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && (changes.minInitialDate || changes.maxDate)){
      this.checkMinAndMax();
    }
  }


  toNgbDateStruct(dateObj: Date | NgbDate): NgbDateStruct {
    const date = this.dateUtilService.parseDate(dateObj);
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  dateSelected(event: NgbDate) {
    const date = this.dateUtilService.createDateFromNgbDate(event);
    this.selectedDate.emit(date);
  }

  private setError(i18nKeyMessage: string): void {
    this.loaded = false;
    this.anyError = true;
    this.gungError = {
      i18nKeyMessage,
      name: '',
      message: ''
    };

    this.openErrorModal();
  }

  openErrorModal(): void {
    this.commonModalService.openGungErrorHandler(this.gungError);
  }
}
