import { selectOne as t, select as e } from "../../../utilities/dom.js";
const n = (n, o, a) => ((t, n = !0) => {
    const o = Array.prototype.slice.call(e(document, "*[data-cse], a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), object, embed, *[tabindex], *[contenteditable]")),
      a = [];
    o.forEach(t => {
      const e = t.getAttribute("tabindex"),
        n = !e || parseInt(e, 10) >= 0,
        o = t.getBoundingClientRect(),
        r = o.width > 0 && o.height > 0;
      n && r && a.push(t);
    });
    const r = ((t, e) => {
      for (let n = 0; n < t.length; n += 1) if (e(t[n])) return n;
      return -1;
    })(a, e => e === t || t.contains(e));
    return a[r + (n ? -1 : 1)];
  })(t(o, `[data-cse=${n}]`), a),
  o = t => {
    t && (t.focus(), t.blur(), t.focus());
  };
export { o as focusExternalField, n as getPreviousTabbableNonSFElement };
