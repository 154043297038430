import { Component, OnInit } from '@angular/core';
import {
  GungMainMenuService,
  GungMenuConfig,
  GungMenuItem
} from '../../services/gung-main-menu/gung-main-menu.service';
import { ConfigurationsService } from '../../services/configurations.service';
import { catchError, first, forkJoin, of, switchMap } from 'rxjs';
import { gungSetPropertyOfObject } from '../../utils/gung-utils';
import { FormUtilService, GungNotificationService, OptionsList } from 'gung-common';
import { CdkDragDrop, CdkDropListGroup, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '../../services/users/users.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

@Component({
  selector: 'lib-menu-configuration-matrix',
  templateUrl: './menu-configuration-matrix.component.html',
  styleUrl: './menu-configuration-matrix.component.scss'
})
export class MenuConfigurationMatrixComponent implements OnInit {
  roles: string[] = [];

  config: GungMenuConfig;
  customItems: { [k: string]: GungMenuItem };
  standardItems: { [k: string]: GungMenuItem };

  availableGroups: OptionsList[] = [];

  allItems: MenuItem[];

  newRowForm: FormGroup;
  newRowRolesForm: FormGroup;

  constructor(
    protected gungMainMenuService: GungMainMenuService,
    protected configurationsService: ConfigurationsService,
    protected gungNotificationService: GungNotificationService,
    protected translateService: TranslateService,
    protected usersService: UsersService,
    protected formBuilder: FormBuilder,
    protected formUtilService: FormUtilService
  ) {}

  ngOnInit(): void {
    this.configurationsService
      .getConfigurationsByIds(['MenuConfiguration'])
      .pipe(
        first(),
        switchMap(configs =>
          forkJoin({ config: of(configs), roles: this.usersService.getDefaultUserRoles().pipe(first()) })
        )
      )
      .subscribe(({ config, roles }) => {
        this.roles = roles.map(role => role.id).filter(role => role !== 'ACTUATOR');
        this.config = config[0];
        this.initForm();
        this.customItems = config[0].menuItems;
        this.standardItems = this.gungMainMenuService.getStandardMenuItems().reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {});

        const seenGroups = new Set<string>();
        const allItems = [];
        for (const item of this.gungMainMenuService.getStandardMenuItems()) {
          // We don't want to show the standard actuator item in the matrix, should always be visible to only Actuators.
          if (item.id === 'STANDARD_ACTUATOR') {
            continue;
          }

          if (!seenGroups.has(item.groupId)) {
            seenGroups.add(item.groupId);
            this.availableGroups.push({ id: item.groupId, name: this.translateService.instant(item.groupId) });
          }

          if (this.customItems[item.id]) {
            allItems.push({
              ...item,
              ...Object.fromEntries(Object.entries(this.customItems[item.id]).filter(([_, v]) => v != null)),
              // Ensure we properly merge inside the visible for roles object, since that will always exist on config
              // items. We only care about the keys inside. If we don't do this, we will overwrite standard values with empty
              // object if we have not made any access rights changes in the config.
              visibleForRoles: {
                ...item.visibleForRoles,
                ...this.customItems[item.id].visibleForRoles
              },
              isStandard: !!this.standardItems[item.id]
            });
          } else {
            allItems.push({
              ...item,
              // Ensure we properly merge inside the visible for roles object, since that will always exist on config
              // items. We only care about the keys inside. If we don't do this, we will overwrite standard values with empty
              // object if we have not made any access rights changes in the config.
              visibleForRoles: {
                ...item.visibleForRoles
              },
              isStandard: !!this.standardItems[item.id]
            });
          }
        }

        for (const id of Object.keys(this.customItems)) {
          if (!this.standardItems[id]) {
            allItems.push({
              ...this.customItems[id],
              isStandard: false
            });
          }
        }

        this.allItems = allItems.sort((a, b) => b.sortingPriority - a.sortingPriority);
      });
  }

  save() {
    this.config.menuItems = this.customItems;

    this.configurationsService
      .updateConfiguration(this.config)
      .pipe(
        first(),
        catchError((err, caught) => {
          this.gungNotificationService.notify(
            this.translateService.instant('ERROR_SAVING_CONFIGURATION'),
            this.translateService.instant('ERROR_SAVING_CONFIGURATION'),
            'error'
          );
          return of(null);
        })
      )
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.gungNotificationService.notify(
          this.translateService.instant('CONFIGURATION_SAVED'),
          this.translateService.instant('CONFIGURATION_SAVED'),
          'success'
        );
      });
  }

  onValueChange(id: string, fieldPath: string, value: any) {
    if (!this.customItems[id]) {
      this.customItems[id] = { id: id } as GungMenuItem;
    }

    gungSetPropertyOfObject(this.customItems[id], fieldPath, value);
  }

  initForm() {
    this.newRowRolesForm = this.formBuilder.group({});
    this.roles.forEach(role => {
      this.newRowRolesForm.addControl(role, this.formBuilder.control(false));
    });

    this.newRowForm = this.formBuilder.group({
      title: this.formBuilder.control('', Validators.required),
      pagePath: this.formBuilder.control('', [Validators.required, startsWithSlash]),
      groupId: this.formBuilder.control('', Validators.required),
      iconCss: this.formBuilder.control(''),
      sortingPriority: this.formBuilder.control(undefined)
    });
  }

  onDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    // Adjust sorting priority of all items.
    for (let i = 0; i < this.allItems.length; i++) {
      // Bottom item should get 1000, next 2000, etc. Top item should be 1000 * length
      this.allItems[i].sortingPriority = (this.allItems.length - i) * 1000;
      this.onValueChange(this.allItems[i].id, 'sortingPriority', this.allItems[i].sortingPriority);
    }
  }

  removeRow(row: MenuItem) {
    delete this.customItems[row.id];
    this.allItems = this.allItems.filter(item => item.id !== row.id);
  }

  addRow() {
    if (this.newRowForm.invalid || this.newRowRolesForm.invalid) {
      this.newRowForm.markAllAsTouched();
      this.newRowRolesForm.markAllAsTouched();
      return;
    }

    const itemToAdd = {
      ...this.newRowForm.value,
      visibleForRoles: this.newRowRolesForm.value,
      isStandard: false,
      id: Date.now().toString()
    };

    this.allItems.push(itemToAdd);
    this.customItems[itemToAdd.id] = itemToAdd;

    // Reset the form
    this.formUtilService.resetForm(this.newRowRolesForm);
    this.formUtilService.resetForm(this.newRowForm);
  }

  protected readonly FormControl = FormControl;
}

interface MenuItem extends GungMenuItem {
  isStandard: boolean;
}

function startsWithSlash(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (typeof value === 'string' && value.startsWith('/')) {
    return null;
  }
  return { startsWithSlash: true };
}
