class e extends Error {
  reason;
  message;
  source;
  scheme;
  errorFromCardSchemeSdk;
  constructor(e, r, s) {
    super();
    const o = "error" in e ? e?.error?.message : e?.message,
      t = "error" in e ? e?.error?.reason : e?.reason;
    this.message = o, this.reason = t, this.source = r, this.scheme = s, this.errorFromCardSchemeSdk = e;
  }
  toString() {
    return `Reason: ${this.reason} / Source: ${this.source} / Scheme: ${this.scheme} / Message: ${this.message}`;
  }
}
export { e as default };
