const t = {
  isDropin: !0,
  instantPaymentTypes: [],
  paymentMethodComponents: [],
  paymentMethodsConfiguration: {},
  openFirstPaymentMethod: !0,
  openFirstStoredPaymentMethod: !0,
  showStoredPaymentMethods: !0,
  showPaymentMethods: !0,
  disableFinalAnimation: !1
};
export { t as default };
