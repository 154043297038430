<!-- Hide it from non Admin -->
<div class="row" *ngIf="!isAdmin">
  <div class="col-12">
    <span translate>EDITOR_FOR_ADMIN</span>
  </div>
</div>

<!-- Start -->
<div>
  <!-- Alerts -->
  <div class="row mt-2 mb-2">
    <div class="col">
      <div
        *ngIf="alertMessage"
        class="alert mt-2 text-center"
        role="alert"
        [ngClass]="[alertMessage.typeClass]"
        (click)="alertMessage = null"
      >
        <span>{{ alertMessage.message | translate }}</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <h5 class="mt-1" translate>CUSTOM_PAGES_MENU_TITLE</h5>
    </div>
  </div>

  <div class="row">
    <div class="col-12">

      <div class="d-flex">
        <div class="d-flex flex-column mr-4" style="width: 300px;">
          <ul class="menu-list">
            <li *ngFor="let option of customPageList; index as i">
              <div class="pt-1 pb-1">
                <div class="prepend-selection" [ngClass]="{'bg-primary': option.pageName === selectedPage}"></div>
              </div>

              <button
                class="btn btn-link settings-menu-button"
                [ngClass]="{'settings-menu-button-selected': option.pageName === selectedPage}"
                (click)="setSelectedPage(option.pageName)">
                <span class="float-left">
                  <span style="padding-left: 10px">{{ option.pageName }}</span>
                </span>
                <span class="float-right">
                  <i *ngIf="editedId.includes(option.pageName)"
                 class="fas fa-exclamation-triangle"
                 title="{{ 'UNSAVED_CHANGES' | translate }}"></i>
                </span>
              </button>
            </li>
            <li *ngIf="enableCreateCustomPage">
              <button
                type="button"
                class="btn btn-primary createBtn"
                (click)="createTemplate()"
              >
                <span><i class="fas fa-plus"></i></span>
              </button>
            </li>
          </ul>
        </div>


        <div class="d-flex flex-column w-100 mr-4" style="max-width: 1920px;">

          <div class="d-flex justify-content-between align-items-center" *ngIf="selectedPage">

            <div class="d-flex">
              <div *ngIf="templateList.length > 0" class="pr-2" style="width: 300px;">
                <lib-gung-select-option-input
                  gungTranslate="SELECTED_TEMPLATE"
                  [optionsList]="templateListOption"
                  [(ngModel)]="selectedTemplate"
                  (ngModelChange)="changeTemplate()"
                >
                </lib-gung-select-option-input>
              </div>

              <div *ngIf="settingsGroups && settingsGroups.length >= 0" style="width: 300px;">
                <!-- Groups selection -->
                <lib-gung-input-multi-select
                  label="VISIBLE_FOR_GROUPS"
                  gungTranslate="VISIBLE_FOR_GROUPS"
                  [previewEmpty]="previewEmpty"
                  [optionsList]="userGroups"
                  (selectedValues)="setSelectedValues($event)"
                >
                </lib-gung-input-multi-select>
                <div *ngIf="noGroups" class="alert alert-warning" role="alert">
                  <i class="fa-solid fa-triangle-exclamation mr-1"></i><span
                  translate>WILL_NOT_SHOW_TO_ANY_GROUP</span>
                </div>
              </div>
            </div>

            <div>
              <button type="button" class="btn btn-outline-primary mr-2"
                (click)="$event.preventDefault(); $event.stopPropagation(); deleteCustomPage(dataTemplate, $event)">
                <span translate>DELETE</span>
              </button>

              <button type="button" class="btn btn-primary float-right" (click)="onSave()" [disabled]="loader ? true : null">
                <span translate>SAVE</span><i *ngIf="loader" class="fas fa-spinner fa-spin ml-2"></i>
              </button>
            </div>
          </div>

          <div class="d-flex justify-content-start">
            <span class="pr-1">{{'THIS_PAGE_IS_VISIBLE_AT' | translate}}</span>
            <a [href]="getCurrentPageUrl()" target="_blank">{{getCurrentPageUrl()}}</a>
            <span class="pl-1 text-lowercase">{{'FOR_THE_ABOVE_GROUPS' | translate}}</span>.
          </div>


          <!-- Templates -->
          <div class="row mt-5">
            <div class="col-12">
              <ng-container #viewChild libCustomPageDirective></ng-container>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
<ng-template #loading> LOADING...</ng-template>
