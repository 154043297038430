export interface User {
  username: string;
  name: string;
  roles: string[];
  email: string;
  managedMultistockIds: string[];
  managedCompanyIds: string[];
  managedCompanyQuery?: string;
  managedAssortmentIds: string[];
  assortment: string;
  password: string;
  activeGroups: { [s: string]: any };
  phone: string;
  extra?: { [s: string]: any };
  managedSupplierIds?: string[];
  managedSupplierQuery?: any;
  authenticationMethod?: string;
  active?: boolean;
  forceChangePassword?: boolean;
}

export interface AuthState {
  user?: User;
  badLogin: boolean;
  authenticated: boolean;
  internalServerError: boolean;
  userDisabled: boolean;
  userForceChangePassword: boolean;
}

export const featureName = 'auth';
