import { makeCallbackObjectsValidation as t } from "../utils/callbackUtils.js";
import { processErrors as s } from "../utils/processErrors.js";
import { existy as i } from "../../../../../../utils/commonUtils.js";
import { ENCRYPTED_SECURITY_CODE as e, ENCRYPTED_CARD_NUMBER as o } from "../../constants.js";
import { hasOwnProperty as r } from "../../../../../../utils/hasOwnProperty.js";
function c(c) {
  let l;
  const a = c.fieldType;
  if ("card" === this.state.type && r(c, "cvcPolicy") && i(c.cvcPolicy) && r(this.state.securedFields, e) && (this.state.securedFields[e].cvcPolicy = c.cvcPolicy), s(c, this.state.securedFields[a], this.state.type, this.props.rootNode, this.callbacks.onError), this.state.securedFields[a].isEncrypted) {
    l = t({
      fieldType: a,
      txVariant: this.state.type,
      rootNode: this.props.rootNode
    }), a === o && (l[0].endDigits = "");
    for (let t = 0, s = l.length; t < s; t += 1) this.callbacks.onFieldValid(l[t]);
    this.state.securedFields[a].isEncrypted = !1;
  }
  this.validateForm(), r(c, "brand") && this.processBrand(c);
}
export { c as handleValidation };
