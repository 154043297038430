<div *ngIf="!sentRequest; else PasswordResetRequestSent" class="forgot-password">
  <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="email" translate>EMAIL</label>
      <input
        type="text"
        formControlName="email"
        class="form-control"
        [ngClass]="{ 'is-invalid': forgotPasswordForm.invalid }"
        [placeholder]="'EMAIL' | translate"
      />
      <div class="invalid-feedback" *ngIf="forgotPasswordForm.invalid">
        <span *ngIf="forgotPasswordForm.controls.email.errors.badEmail" translate>BAD_EMAIL</span>
        <span *ngIf="forgotPasswordForm.controls.email.errors.multipleEmails" translate
          >PASSWORD_RESET_ERROR_USERS_MULTIPLE</span
        >
      </div>
    </div>

    <div class="form-group d-flex justify-content-between align-items-center">
      <a
        *ngIf="!routeToLogin"
        (click)="$event.stopPropagation(); returnToLogin.emit()"
        class="login-instead-text"
        translate
        >LOGIN_INSTEAD</a
      >
      <a *ngIf="!!routeToLogin" routerLink="/login" class="login-instead-text" translate>LOGIN_INSTEAD</a>
      <button
        type="submit"
        class="btn btn-primary send-password-reset"
        [disabled]="forgotPasswordForm.disabled"
        translate
      >
        SEND_PASSWORD_RESET
      </button>
    </div>
  </form>
  <div class="alert alert-success mt-5" *ngIf="sentRequest" translate>RESET_PASSWORD_REQUEST_SENT</div>
</div>

<ng-template #PasswordResetRequestSent>
  <div class="mt-2">
    <p translate>RESET_PASSWORD_CHECK_EMAIL</p>
    <p><span translate>RESET_CHECK_SPAM</span><span class="link secondary" (click)="sentRequest = false;" translate>TRY_A_DIFFRENT_EMAIL</span></p>
    <div class="text-center mt-3">
      <span *ngIf="!routeToLogin; else RouteToLogin" class="mr-1">{{'RETURN_TO' | translate}} <a (click)="$event.stopPropagation(); returnToLogin.emit()" translate>SIGN_IN</a></span>
      <ng-template #RouteToLogin>
        <span class="mr-1" translate>RETURN_TO</span><a routerLink="/login" translate>SIGN_IN</a>
      </ng-template>
    </div>
  </div>
</ng-template>


