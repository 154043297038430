<div class="table-responsive gung-responsive">
  <table class="table table-borderless table-striped">
    <thead>
      <tr>
        <th translate>INVOICE</th>
        <th translate>INVOICE_STATUS</th>
        <th translate>CUSTOMER_NAME</th>
        <th translate>INVOICE_DATE</th>
        <th translate>DUE_DATE</th>
        <th translate>TOTAL_VALUE</th>
        <th translate>LEFT_TO_PAY</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let invoice of data">
        <td [attr.data-label]="'INVOICE' | translate">
          {{ invoice.id }}
        </td>
        <td [attr.data-label]="'INVOICE_STATUS' | translate">
          {{invoice.extra.gme.status | translate}}
        </td>
        <td [attr.data-label]="'CUSTOMER_NAME' | translate">
          {{invoice.extra.gme.customerName}}
        </td>
        <td [attr.data-label]="'INVOICE_DATE' | translate">
          @if(invoice.extra.gme?.invoiceDate){
            <span>
              {{invoice.extra.gme.invoiceDate | date: dateUtilService.dateFormat }}
            </span>
          }

        </td>
        <td [attr.data-label]="'DUE_DATE' | translate">
          @if(invoice.extra.gme?.dueDate){
            <span>
              {{invoice.extra.gme.dueDate | date: dateUtilService.dateFormat }}
            </span>
          }
        </td>
        <td [attr.data-label]="'TOTAL_VALUE' | translate">
          <lib-price
            [price]="invoice.extra.gme.amountIncludingVat"
            [currency]="invoice.extra.gme.currencyCode"
          ></lib-price>
        </td>
        <td [attr.data-label]="'LEFT_TO_PAY' | translate">
          <lib-price
            [price]="invoice.extra.gme.amountIncludingVat - invoice.extra.gme.amountPaid"
            [currency]="invoice.extra.gme.currencyCode"
          ></lib-price>
        </td>
        <td>
          <lib-action-btn [item]="invoice" [config]="actionConfigService"></lib-action-btn>
        </td>
      </tr>
    </tbody>
  </table>
</div>
