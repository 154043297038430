class a {
  sfConfig;
  loadingContext;
  holderEl;
  iframeRef;
  loadToConfigTimeout;
  _isValid;
  _iframeContentWindow;
  _numKey;
  _isEncrypted;
  _hasError;
  _errorType;
  _cvcPolicy;
  _expiryDatePolicy;
  _iframeOnLoadListener;
  _postMessageListener;
  onIframeLoadedCallback;
  onConfigCallback;
  onEncryptionCallback;
  onValidationCallback;
  onFocusCallback;
  onBinValueCallback;
  onTouchstartCallback;
  onShiftTabCallback;
  onAutoCompleteCallback;
  onKeyPressedCallback;
  constructor() {
    this.sfConfig = {};
  }
}
export { a as default };
