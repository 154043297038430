import { useMemo as e, useReducer as a, useCallback as t, useEffect as r } from "../../external/preact/hooks/dist/hooks.js";
import s from "../Validator/Validator.js";
import { getReducer as l, init as o } from "./reducer.js";
function d(d) {
  const {
      rules: m = {},
      formatters: i = {},
      defaultData: c = {},
      fieldProblems: u = {},
      schema: f = []
    } = d,
    n = e(() => new s(m), [m]),
    h = ({
      key: e,
      value: a,
      mode: t
    }, r) => {
      const s = i?.[e],
        l = function (e) {
          return e && "formatterFn" in e;
        }(s) ? s.formatterFn : s,
        o = l && "function" == typeof l ? l(a ?? "", r) : a;
      return [o, n.validate({
        key: e,
        value: o,
        mode: t
      }, r)];
    },
    [p, y] = a(l(h), {
      defaultData: c,
      schema: f ?? [],
      processField: h,
      fieldProblems: u
    }, o),
    v = e(() => p.schema.reduce((e, a) => e && p.valid[a], !0), [p.schema, p.valid]),
    k = t((e = null) => {
      y({
        type: "validateForm",
        selectedSchema: e
      });
    }, []),
    F = t((e, a) => y({
      type: "setErrors",
      key: e,
      value: a
    }), []),
    b = t((e, a) => y({
      type: "setValid",
      key: e,
      value: a
    }), []),
    g = t((e, a) => y({
      type: "setData",
      key: e,
      value: a
    }), []),
    P = t(e => y({
      type: "setSchema",
      schema: e,
      defaultData: c
    }), [p.schema]),
    V = t(e => y({
      type: "mergeForm",
      formValue: e
    }), []),
    D = t(e => y({
      type: "setFieldProblems",
      fieldProblems: e
    }), [p.schema]);
  return r(() => {
    D(u ?? {});
  }, [JSON.stringify(u)]), {
    handleChangeFor: (e, a) => t => {
      const r = ((e, a) => a.target ? "checkbox" === a.target.type ? !p.data[e] : a.target.value : a)(e, t);
      y({
        type: "updateField",
        key: e,
        value: r,
        mode: a
      });
    },
    triggerValidation: k,
    setSchema: P,
    setData: g,
    setValid: b,
    setErrors: F,
    isValid: v,
    mergeForm: V,
    setFieldProblems: D,
    schema: p.schema,
    valid: p.valid,
    errors: p.errors,
    data: p.data,
    fieldProblems: p.fieldProblems
  };
}
export { d as default };
