import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrderService, Order, CustomerService, Customer, OrderRow } from 'gung-standard';
import { Observable, of, forkJoin } from 'rxjs';
import { first, map, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JeevesOrderService extends OrderService {
  constructor(protected http: HttpClient, protected customerService: CustomerService) {
    super(http, customerService);
  }

  postAppendToOrder(order: any) {
    const url = `json/order/append`;
    return this.http.post<Order[]>(url, order);
  }

  updateOrderHeader(order: Order): Observable<Order> {
    const url = `json/order/${order.id}/extra/oh`;
    return this.http.put<Order>(url, order.extra.oh).pipe(
      switchMap(_order => forkJoin([of(_order), this.customerService.getCustomer(_order.deliveryCustomerId)])),
      map(([_order, customer]) => this.orderMapper(_order, customer))
    );
  }

  updateOrderRows(orderId: string, orderRows: OrderRow[]): Observable<OrderRow[]> {
    const url = `json/order-rows/${orderId}`;
    return this.http.put<OrderRow[]>(url, orderRows);
  }

  getOrderRowsByCustomerId(customerId: string): Observable<OrderRow[]> {
    const url = `json/order-rows/customer/${customerId}`;
    return this.http.get<OrderRow[]>(url);
  }

  getOpenOrderRowsByCustomerId(customerId: string): Observable<OrderRow[]> {
    return this.getOrderRowsByCustomerId(customerId).pipe(
      first(), 
      map(rows => rows.filter(row => row.extra.orp.ordradst === 13 || row.extra.orp.ordradst === 20 || row.extra.orp.ordradst === 50))
    );
  }

  protected orderMapper(order: Order, customer: Customer): Order {
    return {
      ...order,
      extra: {
        ...order.extra,
        deliveryCustomer: customer,
        status: this.statusMapper(order.extra.oh.ordstat)
      }
    };
  }

  protected statusMapper(value: number): string {
    if (value === 13) {
      return 'REGISTERED';
    }
    if (value === 10) {
      return 'ORDER_STATUS_WARNING_ENTRY_IN_PROGRESS';
    }
    if (value === 5) {
      return 'WEBORDER';
    }
    if (value === 11) {
      return 'QUOTE';
    }
    return 'UNDEFINED';
  }
}
