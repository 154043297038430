import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first, forkJoin } from 'rxjs';
import { AdyenOrderType, AdyenPaymentAuthorisedResponse, AdyenService } from '../../services/adyen/adyen.service';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { AdyenPaymentCompletedEvent, AdyenPaymentFailedEvent } from '../adyen-payment/adyen-payment.component';
import { AuthService } from '../../services/auth/auth.service';
import { AdyenInvoiceService } from '../../services/adyen/adyen-invoice.service';
import { Customer } from '../../models';
import { TranslateService } from '@ngx-translate/core';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { Location } from '@angular/common';
import { InvoiceService } from '../../services/invoices/invoice.service';

@Component({
  selector: 'lib-adyen-pay-invoice',
  templateUrl: './adyen-pay-invoice.component.html',
  styleUrl: './adyen-pay-invoice.component.scss'
})
export class AdyenPayInvoiceComponent {
  checkout: any = undefined;
  adyenOrderType: AdyenOrderType = AdyenOrderType.ADYEN_INVOICE;
  isAdvancedFlow: boolean = false;

  selectedCustomer: Customer;
  invoiceId: string;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected selectedCustomerService: SelectedCustomerService,
    protected adyenService: AdyenService,
    protected authService: AuthService,
    protected adyenInvoiceService: AdyenInvoiceService,
    protected translateService: TranslateService,
    protected gungModalService: GungModalService,
    protected location: Location,
    protected invoiceService: InvoiceService
  ) {}

  ngOnInit(): void {
    this.invoiceId = this.route.snapshot.paramMap.get('invoiceId');
    this.isAdvancedFlow = this.adyenService.shouldUseAdvancedFlow();

    // First always check if the invoice has already been paid. If so display that to the user.
    this.adyenInvoiceService
      .getPaymentStatus(this.invoiceId)
      .pipe(first())
      .subscribe(paymentStatus => {
        if (!!paymentStatus) {
          const title = this.translateService.instant('ADYEN_INVOICE_ALREADY_PAID', { invoiceId: this.invoiceId });
          const body = this.translateService.instant('ADYEN_INVOICE_ALREADY_PAID_LONG', { invoiceId: this.invoiceId });
          this.gungModalService.openBasicModal(title, body).finally(() => {
            this.location.back();
          });
        } else {
          this.invoiceService
            .getInvoice(this.invoiceId)
            .pipe(first())
            .subscribe(invoice => {
              let checkoutExtra = { adyenInvoice: 'true' };
              this.checkout = {
                billingCustomerId: invoice.customerId,
                rows: [
                  {
                    id: this.invoiceId,
                    quantity: 1,
                    extra: { adyenInvoice: 'true' }
                  }
                ],
                extra: checkoutExtra
              };
            });
        }
      });
  }

  onPaymentCompleted(event: AdyenPaymentCompletedEvent) {
    try {
      forkJoin({
        currentUser: this.authService.getCurrentUser().pipe(first()),
        response: this.adyenService.onPaymentCompleted(event).pipe(first())
      }).subscribe({
        next: ({ currentUser, response }) => {
          console.log('paymentCompleted');
          if (
            currentUser.roles.indexOf('USER') > -1 &&
            currentUser.roles.indexOf('ADMIN') === -1 &&
            currentUser.roles.indexOf('SALES') === -1
          ) {
            this.router.navigate(['/customers', this.checkout.billingCustomerId], {
              queryParams: { tab: 'lib-gung-tab-4' }
            });
          } else {
            this.router.navigate(['/invoices']);
          }
        },
        error: error => {
          console.error(error);
          this.router.navigateByUrl('order-failure-adyen');
        }
      });
    } catch (error) {
      console.error(error);
      this.router.navigateByUrl('order-failure-adyen');
    }
  }
  protected onPaymentFailed(event: AdyenPaymentFailedEvent) {
    this.router.navigateByUrl('payment-failure-adyen');
  }
}
