import { Component, Input, OnInit } from '@angular/core';
import { RequirementsPlanningOrder, RequirementsPlanningOrderType, RequirementsPlanningProductData } from '../../models/product-requirements';
import { OrderSubmitService } from '../../../../services/order-submit.service';
import { ProductionOrderService } from '../../services/production-order.service';
import { Subscription, first, interval, startWith, switchMap } from 'rxjs';
import { ProductionManagementSuggestedOrderToRequestBodyMapperService } from '../../services/production-management-suggested-order-to-request-body-mapper.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MetadataService } from '../../../../services/metadata/metadata.service';
import { OrderContextService } from '../../../../services/order-context.service';
import { DateUtilService } from 'gung-common';
import { GungCompanyService } from '../../../../services/gung-company/gung-company.service';


@Component({
  selector: 'lib-production-management-create-purchase-order-modal',
  templateUrl: './production-management-create-purchase-order-modal.component.html',
  styleUrls: ['./production-management-create-purchase-order-modal.component.css']
})
export class ProductionManagementCreatePurchaseOrderModalComponent implements OnInit {

  @Input() order: RequirementsPlanningOrder;
  @Input() orderIndex: number;
  @Input() productData: RequirementsPlanningProductData;
  purchaseOrder: FormGroup;
  paymentTerms;
  creatingOrder = false;
  processing = false;
  public context?;
  @Input() suggestedOrders: RequirementsPlanningOrder[];

  timeInterval: Subscription;

  constructor(
    protected orderSubmitService: OrderSubmitService,
    protected productionOrderService: ProductionOrderService,
    protected suggestedOrderToRequestBodyMapperService: ProductionManagementSuggestedOrderToRequestBodyMapperService,
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected metadataService: MetadataService,
    protected orderContextService: OrderContextService,
    public dateUtilService: DateUtilService,
    protected gungCompanyService: GungCompanyService
  ) {

  }

  ngOnInit(): void {
    this.paymentTerms = this.metadataService.getMetadataTableList('TermsOfPayments');
    this.gungCompanyService.getGungCompany().pipe(first()).subscribe(companyInfo => {
      this.initForm(companyInfo);
    });
  }

  initForm(companyInfo) {
    this.purchaseOrder = this.formBuilder.group({
      deliveryDate: this.formBuilder.control(this.suggestedOrders ? new Date(Math.min(...this.suggestedOrders.map(order => Date.parse(order.orderDate)))) : this.order.orderDate || '', [Validators.required]),
      paymentTermsCode: this.formBuilder.control('', [Validators.required]),
      deliveryName: this.formBuilder.control(companyInfo.name || '', [Validators.required]),
      deliveryAddress: this.formBuilder.control(companyInfo.address1 || '', [Validators.required]),
      deliveryZipCode: this.formBuilder.control(companyInfo.zipCode || '', [Validators.required]),
      deliveryCity: this.formBuilder.control(companyInfo.city || '', [Validators.required]),
    });
  }

  save() {
    if (this.purchaseOrder.invalid) {
      this.purchaseOrder.markAllAsTouched();

      return;
    }
    this.creatingOrder = true;
    this.processing = true;
    const formValues = this.purchaseOrder.getRawValue();

    const mappedOrder = this.suggestedOrderToRequestBodyMapperService.map(this.suggestedOrders || [this.order], this.productData, formValues);
    this.orderSubmitService
      .postOrder('json/order/create/CREATE_PURCHASE_ORDER', mappedOrder)
      .pipe(first())
      .subscribe(result => {
        this.timeInterval = interval(1000)
          .pipe(
            startWith(0),
            switchMap(() => this.orderContextService.getSimplifiedOrderContext(result.contextId)),
            first(e => e.finalized || (e.erpOrderIds && e.erpOrderIds.length > 0))
          )
          .subscribe(
            context => {
              this.processing = false;
              this.context = context;
              console.log('context', context);
            },
            err => {
              console.log('err', err);
            }
          );
      });
  }

}
