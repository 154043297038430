import { FALLBACK_CONTEXT as e, DEFAULT_HTTP_TIMEOUT as r } from "../config.js";
import o from "../Errors/AdyenCheckoutError.js";
function t(t, a) {
  const {
      headers: i = [],
      errorLevel: s = "warn",
      loadingContext: c = e,
      method: l = "GET",
      path: f,
      timeout: d = r
    } = t,
    u = {
      method: l,
      mode: "cors",
      cache: "default",
      credentials: "same-origin",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "POST" === l ? "application/json" : "text/plain",
        ...i
      },
      redirect: "follow",
      referrerPolicy: "no-referrer-when-downgrade",
      ...(AbortSignal?.timeout && {
        signal: AbortSignal?.timeout(d)
      }),
      ...(a && {
        body: JSON.stringify(a)
      })
    },
    h = `${c}${f}`;
  return fetch(h, u).then(async e => {
    const r = await e.json();
    if (e.ok) return r;
    if (function (e) {
      return e && e.errorCode && e.errorType && e.message && e.status;
    }(r)) return void n(r.message, s, r);
    n(t.errorMessage || `Service at ${h} is not available`, s, r);
  }).catch(e => {
    if (e instanceof o) throw e;
    n(t.errorMessage || `Call to ${h} failed. Error= ${e}`, s, e);
  });
}
function n(e, r, t) {
  switch (r) {
    case "silent":
      break;
    case "info":
    case "warn":
    case "error":
      console[r](e);
      break;
    default:
      throw new o("NETWORK_ERROR", e, {
        cause: t
      });
  }
}
function a(e, r) {
  return t({
    ...e,
    method: "GET"
  }, r);
}
function i(e, r) {
  return t({
    ...e,
    method: "POST"
  }, r);
}
export { t as http, a as httpGet, i as httpPost };
