import { ANALYTICS_EVENT_ERROR as e, ANALYTICS_EVENT_LOG as t, ANALYTICS_ACTION_STR as a, ANALYTICS_SUBMIT_STR as s, ANALYTICS_VALIDATION_ERROR_STR as n, ANALYTICS_EVENT_INFO as o, ANALYTICS_SELECTED_STR as r, ANALYTICS_DOWNLOAD_STR as c, ANALYTICS_INPUT_STR as p, ANALYTICS_DISPLAYED_STR as i, ANALYTICS_UNFOCUS_STR as y, ANALYTICS_FOCUS_STR as d, ANALYTICS_CONFIGURED_STR as m, ANALYTICS_RENDERED_STR as l, ANALYTICS_EXPRESS_PAGES_ARRAY as v } from "./constants.js";
import { THREEDS2_ERROR as b, THREEDS2_FULL as g } from "../../components/ThreeDS2/constants.js";
import E, { SDK_ERROR as u } from "../Errors/AdyenCheckoutError.js";
import { getCardConfigData as f } from "./utils.js";
const h = h => (k, A, M = {}) => {
  const {
    type: P,
    target: S
  } = A;
  if (!P) throw new E(u, "You are trying to create an analytics event without a type");
  switch (P) {
    case l:
      {
        const {
            isStoredPaymentMethod: e,
            brand: t,
            configData: a
          } = A,
          {
            isExpress: s,
            expressPage: n
          } = M,
          r = n && v.includes(n),
          {
            type: c
          } = M;
        let p = null;
        "scheme" !== c && "bcmc" !== c && "customcard" !== c || (p = f(M));
        const i = {
          component: k,
          type: P,
          ...("boolean" == typeof e && {
            isStoredPaymentMethod: e
          }),
          ...(t && {
            brand: t
          }),
          ...("boolean" == typeof s && {
            isExpress: s
          }),
          ...(!0 === s && r && {
            expressPage: n
          }),
          ...(p && {
            configData: p
          }),
          ...(a && {
            configData: a
          })
        };
        h.createAnalyticsEvent({
          event: o,
          data: i
        });
        break;
      }
    case m:
      {
        const {
            isStoredPaymentMethod: e,
            brand: t
          } = A,
          a = {
            component: k,
            type: P,
            isStoredPaymentMethod: e,
            brand: t
          };
        h.createAnalyticsEvent({
          event: o,
          data: a
        });
        break;
      }
    case d:
    case y:
    case i:
    case p:
    case c:
      h.createAnalyticsEvent({
        event: o,
        data: {
          component: k,
          type: P,
          target: S
        }
      });
      break;
    case r:
      {
        const {
          issuer: e
        } = A;
        h.createAnalyticsEvent({
          event: o,
          data: {
            component: k,
            type: P,
            target: S,
            issuer: e
          }
        });
        break;
      }
    case n:
      {
        const {
          validationErrorCode: e,
          validationErrorMessage: t
        } = A;
        h.createAnalyticsEvent({
          event: o,
          data: {
            component: k,
            type: P,
            target: S,
            validationErrorCode: e,
            validationErrorMessage: t
          }
        });
        break;
      }
    case s:
      h.createAnalyticsEvent({
        event: t,
        data: {
          component: k,
          type: P,
          message: "Shopper clicked pay"
        }
      });
      break;
    case a:
      {
        const {
          subtype: e,
          message: a
        } = A;
        h.createAnalyticsEvent({
          event: t,
          data: {
            component: k,
            type: P,
            subtype: e,
            message: a
          }
        });
        break;
      }
    case g:
      {
        const {
          message: e,
          metadata: a,
          subtype: s,
          result: n
        } = A;
        h.createAnalyticsEvent({
          event: t,
          data: {
            component: k,
            type: P,
            message: e,
            metadata: a,
            subtype: s,
            result: n
          }
        });
        break;
      }
    case b:
      {
        const {
          message: t,
          code: a,
          errorType: s
        } = A;
        h.createAnalyticsEvent({
          event: e,
          data: {
            component: k,
            type: P,
            message: t,
            code: a,
            errorType: s
          }
        });
        break;
      }
    default:
      h.createAnalyticsEvent(A);
  }
};
export { h as analyticsPreProcessor };
