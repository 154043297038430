import { createElement as t } from "../../../external/preact/dist/preact.js";
import { useRef as o, useEffect as e } from "../../../external/preact/hooks/dist/hooks.js";
const n = n => {
  const a = o(void 0);
  return e(() => {
    const {
      onClick: t,
      buttonRadius: o,
      buttonColor: e,
      buttonType: u,
      buttonLocale: r,
      buttonSizeMode: s,
      buttonRootNode: c,
      paymentsClient: i
    } = n;
    i.then(n => n.createButton({
      onClick: t,
      buttonType: u,
      buttonColor: e,
      buttonLocale: r,
      buttonSizeMode: s,
      buttonRootNode: c,
      ...(o && {
        buttonRadius: o
      })
    })).then(t => {
      a.current && a.current.appendChild(t);
    });
  }, [n.buttonColor, n.buttonType, n.buttonLocale, n.buttonSizeMode, n.buttonRootNode, n.paymentsClient]), t("div", {
    "data-testid": "googlepay-button-container",
    className: "adyen-checkout__paywithgoogle",
    ref: a
  });
};
export { n as default };
