import { Component, forwardRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService } from '@ngx-translate/core';
import { AngularEditorConfigService } from '../../services/angular-editor-config/angular-editor-config.service';
import { BaseInputDirective } from '../base-input/base-input.directive';

@Component({
  selector: 'lib-gung-html-editor',
  templateUrl: './gung-html-editor.component.html',
  styleUrls: ['./gung-html-editor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungHtmlEditorComponent),
      multi: true
    }
  ]
})
export class GungHtmlEditorComponent extends BaseInputDirective<string> implements OnInit {
  /* Disable image copy paste / drag and drop to gung html editor */
  @HostListener('paste', ['$event'])
  protected pasteFromClipboard(event: any): void {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    if (event?.clipboardData) {
      if (event.clipboardData.files && event.clipboardData.files.length && this.isImageFile(event.clipboardData.files[0])) {
        return;
      }
    }
    window.document.execCommand("insertText", false, text);
  }

  @ViewChild('editor') editorComponent;

  @Input()
  public extraInputClass?: string;

  @Input()
  public labelClass?: string;

  @Input()
  public labelBold = false;

  @Input()
  public horizontal = false;

  @Input()
  public angularEditorConfig: AngularEditorConfig = this.angularEditorConfigService.getDefaultEditorConfig();

  constructor(
    protected translateService: TranslateService,
    protected angularEditorConfigService: AngularEditorConfigService
  ) {
    super();
    if (this.labelBold) {
      this.labelClass = (this.labelClass || '') + ' font-weight-bold';
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.angularEditorConfig = this.angularEditorConfigService.getDefaultEditorConfig();
  }

  protected isImageFile(file: File): boolean {
    return file.type.search(/^image\//i) === 0;
  }
}
