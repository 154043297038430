import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  PriceService,
  GungFlowService,
  AuthService,
  ConceptDetailProductListViewComponent,
  AssortmentTreeImageSelectionService,
  GungAnonymousConfigService
} from 'gung-standard';
import { first } from 'rxjs';
import { SjoborgConceptFilterNavigationHelperService } from '../../services/sjoborg-concept-filter-navigation-helper.service';

@Component({
  selector: 'sjoborg-concept-detail-product-list-view',
  templateUrl: './sjoborg-concept-detail-product-list-view.component.html',
  styleUrls: ['./sjoborg-concept-detail-product-list-view.component.scss']
})
export class SjoborgConceptDetailProductListViewComponent
  extends ConceptDetailProductListViewComponent
  implements OnInit, AfterViewInit {
  isSales: boolean = false;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected assortmentTreeImageSelectionService: AssortmentTreeImageSelectionService,
    protected router: Router,
    protected gungAnonymousConfigService: GungAnonymousConfigService,
    protected sjoborgConceptFilterNavigationHelperService: SjoborgConceptFilterNavigationHelperService
  ) {
    super(
      priceService,
      gungFlowService,
      authService,
      assortmentTreeImageSelectionService,
      router,
      gungAnonymousConfigService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(
        roles =>
        (this.isSales =
          roles.filter(role => role.toUpperCase() === 'ADMIN' || role.toUpperCase() === 'SALES').length > 0)
      );
    console.log(this.isSales);
  }

  ngAfterViewInit(): void {
    this.sjoborgConceptFilterNavigationHelperService.useFilterQueryParams();
  }

  getPackageSize(item: any): number {
    if (!!item.extra.ar.artfsgforp) {
      return parseInt(item.extra.ar.artfsgforp, 10);
    }

    return 0;
  }
}
