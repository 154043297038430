import { SchemeNames as t } from "../services/sdks/utils.js";
class a {
  dateOfCardLastUsed;
  dateOfCardCreated;
  panLastFour;
  srcDigitalCardId;
  scheme;
  artUri;
  srcCorrelationId;
  tokenId;
  isExpired;
  panExpirationMonth;
  panExpirationYear;
  descriptorName;
  status = null;
  constructor(t, a, r) {
    this.dateOfCardLastUsed = t.dateOfCardLastUsed, this.dateOfCardCreated = t.dateOfCardCreated, this.panLastFour = t.panLastFour, this.srcDigitalCardId = t.srcDigitalCardId, this.descriptorName = t.digitalCardData.descriptorName, this.tokenId = t.tokenId, this.scheme = a, this.artUri = t.digitalCardData.artUri, this.srcCorrelationId = r, this.panExpirationMonth = t.panExpirationMonth, this.panExpirationYear = t.panExpirationYear, this.status = t.digitalCardData.status, this.isExpired = this.confirmCardIsExpired();
  }
  get title() {
    return "visa" === this.scheme ? t[this.scheme] : this.descriptorName || t[this.scheme];
  }
  get isDcfPopupEmbedded() {
    return "mc" === this.scheme;
  }
  confirmCardIsExpired() {
    if ("ACTIVE" !== this.status) return !0;
    if (!this.panExpirationYear && !this.panExpirationMonth) return !1;
    const [t, a] = [new Date().getMonth() + 1, new Date().getFullYear()];
    return !(Number(this.panExpirationYear) > a) && !(Number(this.panExpirationYear) === a && Number(this.panExpirationMonth) >= t);
  }
}
export { a as default };
