import { createElement as e } from "../../../../../external/preact/dist/preact.js";
import { DATA_ENCRYPTED_FIELD_ATTR as t, DATA_INFO as a, DATA_UID as n } from "../../../../internal/SecuredFields/lib/constants.js";
function r(r) {
  const s = {
    [t]: r.encryptedFieldType,
    [a]: r["data-info"],
    [n]: r.uniqueId,
    className: r.className
  };
  return e("span", s, r.children);
}
export { r as default };
