<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>CREATE_NEW_PLANOGRAM</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="col-12">
    <lib-gung-text-input-horizontal
      [(ngModel)]="planogram.itemNo"
      gungTranslate="ITEM_NO"
    ></lib-gung-text-input-horizontal>
  </div>
  <div class="col-12">
    <lib-gung-text-input-horizontal [(ngModel)]="planogram.name" gungTranslate="NAME"></lib-gung-text-input-horizontal>
  </div>
  <div class="col-12">
    <lib-gung-text-input-horizontal
      [(ngModel)]="planogram.assortmentId"
      gungTranslate="ASSORTMENT_ID"
    ></lib-gung-text-input-horizontal>
  </div>
  <div class="col-12">
    <lib-gung-number-input-horizontal
      [(ngModel)]="planogram.width"
      [renderStatic]="isEdit"
      gungTranslate="WIDTH_MM"
    ></lib-gung-number-input-horizontal>
  </div>
  <div class="col-12">
    <lib-gung-number-input-horizontal
      [(ngModel)]="planogram.widthStep"
      [renderStatic]="isEdit"
      gungTranslate="WIDTH_STEP"
    ></lib-gung-number-input-horizontal>
  </div>
  <div class="col-12">
    <lib-gung-number-input-horizontal
      [(ngModel)]="planogram.shelfs"
      gungTranslate="SHELFS"
    ></lib-gung-number-input-horizontal>
  </div>
  <div class="col-12">
    <lib-gung-text-input-horizontal
      [(ngModel)]="planogram.furniture"
      gungTranslate="FURNITURE"
    ></lib-gung-text-input-horizontal>
  </div>
  <div class="col-12">
    <lib-gung-text-input-horizontal
      [(ngModel)]="planogram.customer"
      gungTranslate="CUSTOMER"
    ></lib-gung-text-input-horizontal>
  </div>
  <div class="col-12">
    <lib-gung-text-input-horizontal
      [(ngModel)]="planogram.country"
      gungTranslate="COUNTRY"
    ></lib-gung-text-input-horizontal>
  </div>
  <div class="col-12">
    <lib-gung-text-input-horizontal [(ngModel)]="planogram.year" gungTranslate="YEAR"></lib-gung-text-input-horizontal>
  </div>
  <div class="col-12">
    <lib-gung-text-input-horizontal
      [(ngModel)]="planogram.period"
      gungTranslate="PERIOD"
    ></lib-gung-text-input-horizontal>
  </div>
  <div class="col-12">
    <div><strong translate>ADDITIONAL_PRODUCTS</strong></div>
    <div *ngFor="let additionalProduct of additionalProducts; index as i">
      <div class="row">
        <div class="col-5">
          <lib-gung-text-input-horizontal
            [(ngModel)]="additionalProduct.id"
            gungTranslate="ID"
          ></lib-gung-text-input-horizontal>
        </div>
        <div class="col-5">
          <lib-gung-number-input-horizontal
            [(ngModel)]="additionalProduct.qty"
            gungTranslate="QTY"
          ></lib-gung-number-input-horizontal>
        </div>
        <div class="col-2 text-right">
          <button class="btn btn-outline-secondary" (click)="removeAdditionalProduct(i)">
            <i class="fa-solid fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
    <button class="btn btn-sm btn-outline-secondary" (click)="addAdditionalProduct()" translate>ADD_ADDITIONAL_PRODUCT</button>
  </div>
  <div class="col-12 mt-3">
    <div><strong translate>EXPIRED_MODULES</strong></div>
    <div class="removed-list">
      <div class="removed-modules" *ngFor="let item of this.planogram.expiredAndRemovedItems">
        <span class="mr-2">{{ item }}</span
        ><span (click)="removeExpired(item)"><i class="fas fa-times"></i></span>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="btn-group btn-group-justified w-100">
    <button class="btn btn-secondary" (click)="cancel()" translate>CANCEL</button>
    <button class="btn btn-primary" (click)="confirm()" translate>OK</button>
  </div>
</div>
