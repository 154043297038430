import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MetadataService } from '../../services/metadata/metadata.service';

@Component({
  selector: 'lib-gung-meta-output',
  templateUrl: './gung-meta-output.component.html',
  styleUrls: ['./gung-meta-output.component.css']
})
export class GungMetaOutputComponent implements OnInit, OnChanges {
  @Input()
  public value: string;

  @Input()
  public metadataTable: string;

  @Input()
  public metaOptionName: string;

  @Input()
  public gungTranslate: string;

  @Input()
  public horizontal?: string;

  @Input()
  public labelClass: string | string[];

  @Input()
  public suppressLabel = false;

  @Input()
  public showId?: boolean;

  @Input()
  public currentLang?: string;

  @Input()
  public langKey?: string;

  @Input()
  public showOnlyText?: boolean = false;

  @Input()
  public showIfNotFound?: boolean = false;

  @Input()
  public labelBold = false;

  public metaValue;

  constructor(protected metadataService: MetadataService) { }

  ngOnInit() {
    this.updateData();
  }

  ngOnChanges() {
    this.updateData();
  }

  updateData() {
    if (!this.currentLang && !this.langKey) {
      this.metaValue = this.metadataService.getMetadataValue(this.metadataTable, this.metaOptionName, this.value);
    } else {
      this.metaValue = this.metadataService.getMetaValueWithLang(
        this.metadataTable,
        this.value,
        this.currentLang,
        this.langKey
      );
    }
    if (this.showId) {
      this.metaValue = this.value + ' - ' + this.metaValue;
    }

    if (!this.metaValue && this.showIfNotFound) {
      this.metaValue = this.value;
    }
  }
}
