class s {
  shouldValidate;
  isValid;
  errorMessage;
  constructor(s, i, a, e) {
    this.shouldValidate = s.modes.includes(a), this.isValid = s.validate(i, e), this.errorMessage = s.errorMessage;
  }
  hasError(s = !1) {
    return s ? !this.isValid && this.shouldValidate : null != this.isValid && !this.isValid && this.shouldValidate;
  }
}
export { s as ValidationRuleResult };
