let e = "undefined" != typeof navigator && /(android)/i.test(navigator.userAgent),
  n = "undefined" != typeof navigator && function () {
    const e = navigator.userAgent,
      n = e.indexOf("MSIE ");
    if (n > 0) return parseInt(e.substring(n + 5, e.indexOf(".", n)), 10);
    if (e.indexOf("Trident/") > 0) {
      const n = e.indexOf("rv:");
      return parseInt(e.substring(n + 3, e.indexOf(".", n)), 10);
    }
    const t = e.indexOf("Edge/");
    return t > 0 && parseInt(e.substring(t + 5, e.indexOf(".", t)), 10);
  }();
var t = {
  __IS_ANDROID: e,
  __IS_IE: n,
  __IS_IOS: "undefined" != typeof navigator && /iphone|ipod|ipad/i.test(navigator.userAgent),
  __IS_FIREFOX: "undefined" != typeof navigator && /(firefox)/i.test(navigator.userAgent),
  __IS_SAFARI: "undefined" != typeof navigator && /(safari)/i.test(navigator.userAgent) && !/(chrome)/i.test(navigator.userAgent)
};
export { t as default };
