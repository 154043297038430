import { createElement as o, Fragment as e } from "../../../../../external/preact/dist/preact.js";
import { useState as t, useRef as r, useCallback as a } from "../../../../../external/preact/hooks/dist/hooks.js";
import { CtPInfoModal as s } from "./CtPInfoModal/CtPInfoModal.js";
import n from "../../../../../core/Context/useImage.js";
import { useCoreContext as i } from "../../../../../core/Context/CoreProvider.js";
import m from "../../../Img/Img.js";
const c = () => {
  const [c, l] = t(!1),
    f = r(),
    {
      i18n: p
    } = i(),
    u = n()({
      imageFolder: "components/"
    })("info"),
    d = a(() => {
      l(!1);
    }, []),
    C = a(() => {
      l(!0);
    }, []);
  return o(e, null, o("button", {
    ref: f,
    onClick: C,
    className: "adyen-web__ctp-info-button",
    "aria-label": p.get("ctp.aria.infoModalButton"),
    type: "button"
  }, o(m, {
    height: "15",
    src: u,
    ariaHidden: !0
  })), o(s, {
    isOpen: c,
    onClose: d,
    focusAfterClose: f.current
  }));
};
export { c as CtPInfo };
