import { Component } from '@angular/core';

@Component({
  selector: 'lib-adyen-order-failure',
  templateUrl: './adyen-order-failure.component.html',
  styleUrl: './adyen-order-failure.component.scss'
})
export class AdyenOrderFailureComponent {

}
