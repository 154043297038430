import { createElement as e } from "../../../../../external/preact/dist/preact.js";
import n from "../../../../../external/classnames/index.js";
import a from "./BrandIcon.js";
import r from "./DualBrandingIcon/DualBrandingIcon.js";
import d from "../../../../internal/FormFields/Field/Field.js";
import { useCoreContext as o } from "../../../../../core/Context/CoreProvider.js";
import i from "./DataSfSpan.js";
import { ENCRYPTED_CARD_NUMBER as t } from "../../../../internal/SecuredFields/lib/constants.js";
import { alternativeLabelContent as c } from "./FieldLabelAlternative.js";
function l(l) {
  const {
      i18n: s
    } = o(),
    {
      error: u = "",
      isValid: _ = !1,
      onFocusField: m = () => {},
      dualBrandingElements: f,
      dualBrandingChangeHandler: p,
      dualBrandingSelected: b
    } = l;
  return e(d, {
    label: l.label,
    focused: l.focused,
    filled: l.filled,
    classNameModifiers: ["cardNumber"],
    onFocusField: () => m(t),
    errorMessage: u,
    isValid: _,
    dir: "ltr",
    name: t,
    showValidIcon: !1,
    i18n: s,
    contextVisibleToScreenReader: !1,
    useLabelElement: !1,
    renderAlternativeToLabel: c
  }, e(i, {
    encryptedFieldType: t,
    className: n({
      "adyen-checkout__input": !0,
      "adyen-checkout__input--large": !0,
      "adyen-checkout__card__cardNumber__input": !0,
      "adyen-checkout__input--error": u,
      "adyen-checkout__input--focus": l.focused,
      "adyen-checkout__input--valid": _,
      "adyen-checkout__card__cardNumber__input--noBrand": !l.showBrandIcon
    })
  }), l.showBrandIcon && !f && e(a, {
    brandsConfiguration: l.brandsConfiguration,
    brand: l.brand
  }), f && !u && e("div", {
    className: n(["adyen-checkout__card__dual-branding__buttons", {
      "adyen-checkout__card__dual-branding__buttons--active": _
    }])
  }, f.map(n => e(r, {
    key: n.id,
    brand: n.id,
    brandsConfiguration: l.brandsConfiguration,
    onClick: p,
    dataValue: n.id,
    notSelected: "" !== b && b !== n.id
  }))));
}
export { l as default };
