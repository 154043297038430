import { Component, OnInit } from '@angular/core';
import { OrderContextService } from '../../services/order-context.service';
import { ActivatedRoute } from '@angular/router';
import {
  Subscription,
  interval,
  startWith,
  switchMap,
  first,
  Observable,
  delay,
  filter,
  forkJoin,
  mergeMap,
  of,
  take
} from 'rxjs';
import { SimplifiedOrderContext } from '../../models/simplifiedOrderContext';
import { AdyenOrder, AdyenService } from '../../services/adyen/adyen.service';
import { Product } from '../../models';
import { ProductService } from '../../services/products/product.service';

@Component({
  selector: 'lib-adyen-payment-successful',
  templateUrl: './adyen-payment-successful.component.html',
  styleUrls: ['./adyen-payment-successful.component.scss']
})
export class AdyenPaymentSuccessfulComponent implements OnInit {
  public context?: SimplifiedOrderContext;

  timeInterval: Subscription;
  public error?: any;
  public processing = true;

  order: AdyenOrder;

  maxAttempts = 5;
  delayTime = 250;
  attemptsCnt = 0;

  products: { [id: string]: Product } = {};

  constructor(
    protected orderContextService: OrderContextService,
    protected router: ActivatedRoute,
    protected adyenService: AdyenService,
    protected productService: ProductService
  ) {}

  ngOnInit() {
    const merchantReference = this.router.snapshot.params.adyenOrderId;
    if (merchantReference) {
      this.getAdyenOrder(merchantReference)
        .pipe(
          // expand((res: AdyenOrder) => {
          //   if (this.attemptsCnt++ === this.maxAttempts) {
          //     console.log(`no results after ${this.attemptsCnt - 1} attempts`);
          //   } else {
          //     console.log(`recursive call #${this.attemptsCnt}`)
          //   }
          //   return !res ? this.getAdyenOrder(contextId) : of(res);
          // }),
          // take(this.maxAttempts),
          filter((res: AdyenOrder) => !!res),
          take(1),
          mergeMap(order =>
            forkJoin({
              order: of(order),
              products: this.productService.getFullProductsByIds(order.lineItems.map(p => p.id))
            })
          )
        )
        .subscribe({
          next: ({ order, products }) => {
            this.order = order;
            this.products = products.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {});
            this.processing = false;
          },
          error: e => {
            console.error(e);
            this.error = e;
          },
          complete: () => {}
        });
    }
  }

  getAdyenOrder(merchantReference: string): Observable<AdyenOrder> {
    return this.adyenService.getAdyenOrder(merchantReference).pipe(delay(this.attemptsCnt === 0 ? 0 : this.delayTime));
  }
}
