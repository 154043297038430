import { checkPaymentMethodsResponse as t, processPaymentMethods as e, processStoredPaymentMethods as s } from "./utils.js";
class o {
  paymentMethods = [];
  storedPaymentMethods = [];
  constructor(o, n = {}) {
    t(o), this.paymentMethods = o ? e(o.paymentMethods, n) : [], this.storedPaymentMethods = o ? s(o.storedPaymentMethods, n) : [];
  }
  mapCreatedComponentType(t) {
    return "card" === t ? "scheme" : t;
  }
  has(t) {
    return Boolean(this.paymentMethods.find(e => e.type === this.mapCreatedComponentType(t)));
  }
  find(t) {
    return this.paymentMethods.find(e => e.type === this.mapCreatedComponentType(t));
  }
}
export { o as default };
