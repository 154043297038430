import { AddressModeOptions as e } from "./types.js";
var o = {
  type: "scheme",
  setComponentRef: () => {},
  autoFocus: !0,
  billingAddressAllowedCountries: [],
  billingAddressMode: e.full,
  billingAddressRequired: !1,
  billingAddressRequiredFields: ["street", "houseNumberOrName", "postalCode", "city", "stateOrProvince", "country"],
  configuration: {
    koreanAuthenticationRequired: !1,
    socialSecurityNumberMode: "auto"
  },
  data: {
    billingAddress: {}
  },
  disableIOSArrowKeys: !1,
  enableStoreDetails: !1,
  exposeExpiryDate: !1,
  forceCompat: !1,
  hasHolderName: !1,
  holderNameRequired: !1,
  hasCVC: !0,
  hideCVC: !1,
  installmentOptions: {},
  keypadFix: !0,
  legacyInputMode: !1,
  maskSecurityCode: !1,
  minimumExpiryDate: null,
  name: null,
  placeholders: {},
  positionHolderNameOnTop: !1,
  showBrandIcon: !0,
  showInstallmentAmounts: null,
  styles: {},
  isPayButtonPrimaryVariant: !0,
  showContextualElement: !0,
  onLoad: () => {},
  onConfigSuccess: () => {},
  onAllValid: () => {},
  onFieldValid: () => {},
  onBrand: () => {},
  onError: () => {},
  onBinValue: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {}
};
export { o as default };
