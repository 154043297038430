import e from "./CSF.js";
import t from "./utils/cardType.js";
import { error as o, warn as r } from "../utilities/logger.js";
import { falsy as i } from "../../../../../utils/commonUtils.js";
import { hasOwnProperty as n } from "../../../../../utils/hasOwnProperty.js";
import { selectOne as s } from "../utilities/dom.js";
const c = s => {
    if (!s) throw new Error("No securedFields configuration object defined");
    const c = {
      ...s
    };
    try {
      const e = t.isGenericCardType(c.type);
      c.type = e ? "card" : c.type;
    } catch (e) {
      c.type = "card";
    }
    if (!n(c, "rootNode")) return o('ERROR: SecuredFields configuration object is missing a "rootNode" property');
    if (i(c.clientKey)) return r('WARNING: AdyenCheckout configuration object is missing a "clientKey" property.');
    const p = d(c.rootNode);
    if (!p) return o(`ERROR: SecuredFields cannot find a valid rootNode element for ${c.type}`);
    c.rootNode = p;
    return new e(c).createReturnObject();
  },
  d = e => {
    let t;
    return "object" == typeof e && (t = e), "string" != typeof e || (t = s(document, e), t) ? t : null;
  };
export { c as default };
