import { useState as e, useEffect as t } from "../../../external/preact/hooks/dist/hooks.js";
const o = ({
  rootElement: o,
  focusFirst: n,
  shouldTrap: s = !0
}) => {
  const [d, i] = e(n);
  t(() => {
    s && d?.focus();
  }, [d, s]), t(() => {
    if (!s) return;
    const e = o.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'),
      t = e[0],
      d = e[e.length - 1];
    i(n || t);
    const r = e => {
      if ("Tab" === e.key || 9 === e.keyCode) return e.shiftKey && document.activeElement === t ? (d.focus(), void e.preventDefault()) : document.activeElement === d ? (t.focus(), void e.preventDefault()) : void 0;
    };
    return o.addEventListener("keydown", r), () => {
      i(null), o.removeEventListener("keydown", r);
    };
  }, [o, n, s]);
};
export { o as useTrapFocus };
